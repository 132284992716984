/**
 * Слайдшоу плеер для виджета слайдшоу. Общий для конструктора и для вьювера!!!
 */

import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import Scale from './scale';
import { Utils } from './utils';
import templates from '../../templates/common/slideshow-player.tpl';

const SlideshowPlayer = Backbone.View.extend({
  // высота панельки с превьюшками в самом виджете
  THUMBNAILS_HEIGHT: 72,

  // высота панельки с точечками-переключалками картинок в самом виджете
  COUNTERS_HEIGHT: 24,

  COUNTER_WIDTH: 8 + 3 + 3,
  THUMBNAIL_WIDTH: 56,
  THUMBNAIL_PADDING: 4 + 4,
  THUMBNAILS_PADDING: 8 + 8, // отступы от краев виджета панельки с тумбами

  AUTOPLAY_INTERVAL: 4000,

  swipeStartDistance: 5, // Расстояние, пройденное пальцем, после которого мы считаем что начался свайп
  scrollStartDistance: 3, // Аналогично для скролла
  swipeDistance: 35, // Расстояние, после которого мы считаем что произошел swipe

  IMG_ACCELERATION_DELAY: 500, // Через сколько снимать временное аппаратное ускорение с картинок при листании

  // в объекте флага содержаться указания сколько картинок
  // вперед и назад от текущей должны иметь display: block
  // на все остальные навешивается флаг .hidden, делающий отдаленные картинки display: none
  // флаг используется в moveToImage.
  PREDISPLAY_COUNT: {
    backward: 1,
    forward: Modernizr.isdesktop ? 5 : Modernizr.isphone ? 3 : 2,
  },

  events: {
    'click .images .prev-picture-arrow-middle': 'prevImage',
    'click .images .next-picture-arrow-middle': 'nextImage',
    'click .images .prev-picture-arrow-bottom': 'prevImage',
    'click .images .next-picture-arrow-bottom': 'nextImage',
    'click .images .fullscreen': 'toggleFullscreen',
    'click .counters-dots .counter': 'counterClick',
    'click .bottom-arrows': 'updateArrowsState',
    'click .thumbnails .thumb': 'thumbnailClick',
    'click .images': 'onImageClick',
    'blur .captions .caption': 'onCaptionBlur',
    'mouseenter .mouseover': 'onMouseMove',
    'mouseleave .mouseover': 'onMouseMove',
  },

  initialize: function(params, $container, environment, block, mag) {
    _.bindAll(this);

    this.template = templates['template-common-slideshow-player'];

    this.block = block;
    this.model = block && block.model;
    this.params = params;
    this.$container = $container;
    this.environment = environment;
    this.preloadImagesList = [];
    this.mag = mag;

    if (this.environment === 'constructor') {
      this.checkNeedUpdateWidget();
    }

    // округляем ширину до большего целого числа,
    // т.к. часто Виджет приходит с шириной типа  500.125
    // и из-за этого между картинками видна полоска фона.
    // всем внутренним элементам Виджета Слайдшоу при рендеринге ставится округленная ширина.
    // сам же контейнер Виджета остается с оригинальной шириной, т.к. она ставится не здесь.
    // кажется, проблем нет.
    this.params.w = Math.ceil(this.params.w);

    // стоит ли нам обслуживать вариант с кружочками (для конструктора всегда, для вьювера только если тема подходит и кружки включены)
    this.handleCounters =
      environment === 'constructor' ||
      ((params.theme_data.counters && params.theme_data.counters_type === 'dots') ||
        params.current_theme === 'theme_captions');

    // стоит ли нам обслуживать вариант с превьюшками (для конструктора всегда, для вьювера только если тема подходит и превьюшки включены)
    this.handleThumbnails = environment === 'constructor' || params.theme_data.thumbnails;

    // в этом объекте будут храниться данные о текущей картинке
    // $el & num ставятся в moveToImage
    this.currentPic = {
      $el: null,
      num: null,
    };

    this.initialRender = true;
    this.render();

    // применяем текущий размер и внешний вид слайдшоу
    this.applyVisualState(params);

    // добавляем картинки, превьюшки, подписи и кружочки counters
    this.applyPictures(params);

    // применяем стили к текстовым подписям
    this.applyTextStyles(params);

    // для производительности во Вьювере или в случае первого рендера в Конструкторе
    // нижеследующие действия
    // не запускаются внутри applyVisualState & applyPictures, чтобы не делать двойную работу.
    // поэтому мы запускаем их здесь один раз после всего процесса инициализации-рендеринга
    // и применения apply... методов.
    var pictures = this.params.pictures || [];

    if (this.handleCounters)
      // задаем ширину контейнера над кружками.
      this.$counters_container.css('width', pictures.length * this.COUNTER_WIDTH);
    //  выделяем первый кружок.
    this.$counters_container.children(':first-child').addClass('active');

    if (this.handleThumbnails)
      // задаем ширину контейнера над тумбочками.
      this.$thumbnails_container.css(
        'width',
        pictures.length * (this.THUMBNAIL_WIDTH + this.THUMBNAIL_PADDING) - this.THUMBNAIL_PADDING
      );
    // выдяляем первую тумбочку.
    this.$thumbnails_container.children(':first-child').addClass('active');

    // пересчитываем положение блока с кружками и их внутренний скролл.
    // внутри стоит логика не запускаться, если у нас тумбочки, а не кружки.
    this.recalcCounters(params);

    // пересчитываем положение блока с превьюшками и их внутренний скролл.
    // внутри стоит логика не запускаться, если у нас кружки, а не тумбочки.
    this.recalcThumbnails(params);

    this.moveToImage(this.currentImageID, false, 'change-pictures');

    if (this.environment === 'viewer') {
      // генерим событие load
      // totalImagesToPreload это массив с урлами картинок, загрузки которых надо дождаться,
      // чтобы сказать что виджет готов к первоначальному показу
      // это основная картинка + все тмбочки видимые в данный момент на ленте (если тумбы вообще включены)
      // этот список формируется в preloadImages и в preloadThumbnails (которые вызываются из moveToImage, а он в свою очередь из applyPictures)

      var totalImagesToPreload = this.preloadImagesList.length;
      if (!totalImagesToPreload) {
        return _.defer(
          _.bind(function() {
            this.trigger('ready');
          }, this)
        );
      }
      var imagePreloaded = _.bind(function() {
        totalImagesToPreload--;
        if (!totalImagesToPreload && !this.isDestroyed) this.trigger('ready');
      }, this);

      _.each(
        this.preloadImagesList,
        function(img) {
          if (this.isDestroyed) {
            return;
          } // Если в процессе у плейера вызывали destroy

          img.preload = new Image();
          $(img.preload)
            .on('load', imagePreloaded)
            .on('error', imagePreloaded);
          img.preload.src = img.url;
        },
        this
      );
    }

    // все действия над this.$el (this.apply.... и т.п.) просходили
    // не в ДОМе для производительности.
    // теперь вставляем в ДОМ.
    this.$container.append(this.$el);

    this.initialRender = false;

    if (this.handleCounters) {
      if (Modernizr.isdesktop) {
        // навешиваем плагин для скрола кружочков колесиком мыши.
        this.counters_scroll = this.$counters_scroll_wrapper
          .RMScroll({
            $container: this.$counters.find('.items-wrapper'),
            $content: this.$counters_container,
            $handle: this.$counters.find('.scroll'),
            wheelScrollSpeed: 0.1,
            scrollStep: 14,
            tp: 'horizontal',
            needRecalc: false,
          })
          .data('scroll');
      } else {
        // а ниего не надо делать нафиг
      }
    }

    if (this.handleThumbnails) {
      if (Modernizr.isdesktop) {
        // навешиваем плагин для скрола превьюшек колесиком мыши.
        this.thumbnails_scroll = this.$thumbnails_scroll_wrapper
          .RMScroll({
            $container: this.$thumbnails.find('.items-wrapper'),
            $content: this.$thumbnails_container,
            $handle: this.$thumbnails.find('.scroll'),
            wheelScrollSpeed: 0.3,
            onScroll: this.preloadThumbImages,
            tp: 'horizontal',
            needRecalc: false,
          })
          .data('scroll');
      } else {
        this.$thumbnails.find('.items-wrapper').bind('scroll', this.preloadThumbImages);
      }
    }

    // навешиваем обработчик свайпа картинок
    this.setSwipeAction();
  },

  render: function() {
    // создаем this.$el из шаблона, но
    // вставка this.$el в this.$container (т.е. в ДОМ) производится
    // в конце this.initialize() после всех this.apply... для производительности.
    this.setElement($(this.template({ tp: this.environment })));

    // .common-slideshow получает уникальный айди, по которому стили, созданные
    // в setNewPicsWidths & setPicsTransitions буду энкапсулированы для этого Слайдшоу,
    // чтобы предотвратить конфликт стилей с другими Слайдшоу в Мэге.
    this.$el.attr('data-id', this.params._id);

    // кэшируем поиск часто используемыех элементов
    this.$images = this.$('.images');
    this.$images_wrapper = this.$images.find('.images-wrapper');

    this.$middle_arrows = this.$images.find('.arrow-middle');
    this.$bottom_arrows = this.$images.find('.arrow-bottom');
    this.$bottom_arrows_container = this.$images.find('.bottom-arrows');

    this.$counters = this.$('.counters-dots');
    this.$counters_container = this.$counters.find('.items');
    this.$counters_scroll_wrapper = this.$counters.find('.scroll-wrapper');

    this.$thumbnails = this.$('.thumbnails');
    this.$thumbnails_container = this.$thumbnails.find('.items');
    this.$thumbnails_scroll_wrapper = this.$thumbnails.find('.scroll-wrapper');

    this.$captions = this.$('.captions');
  },

  // проверяет и в случае необходимости добавляет/удаляет некоторые свойства виджета(вкл/выкл стрелок, тип стрелок, тип счетчика и т.д);
  // появление данного метода обусловлено большим обновлением виджета слайдшоу, в том числе объединением двух
  // тем ("theme_captions" и "theme_classic") в одну и добавлением новых фич, которых не было ни в одной из тем;
  // метод является своего рода миграцией, которая обеспечит совместимость со "старыми"(созданными до объединения тем) виджетами.
  checkNeedUpdateWidget: function() {
    if (!_.has(this.model.attributes, 'active_arrows') && this.model.get('current_theme') !== 'theme_captions') {
      this.model.set(
        {
          active_arrows: 'bottom',
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.attributes, 'active_arrows') && this.model.get('current_theme') === 'theme_captions') {
      this.model.set(
        {
          active_arrows: 'middle',
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'arrows')) {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            arrows: true,
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'arrows_type')) {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            arrows_type: 'default',
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'counters_type') && this.model.get('current_theme') !== 'theme_captions') {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            counters_type: 'numbers',
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'counters_type') && this.model.get('current_theme') === 'theme_captions') {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            counters_type: 'dots',
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'captions') && this.model.get('current_theme') !== 'theme_captions') {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            captions: false,
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'captions') && this.model.get('current_theme') === 'theme_captions') {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            captions: true,
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'counters')) {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            counters: true,
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'left_icon_noun')) {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            left_icon_noun: {},
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'right_icon_noun')) {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            right_icon_noun: {},
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.attributes, 'current_mode')) {
      this.model.set(
        {
          current_mode: 'default',
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'arrows_view_mode')) {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            arrows_view_mode: 'normal',
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (!_.has(this.model.get('theme_data'), 'radius')) {
      this.model.set(
        {
          theme_data: _.extend(this.model.get('theme_data'), {
            radius: 0,
          }),
        },
        {
          silent: true,
        }
      );
    }

    if (_.has(this.model.attributes, 'current_theme')) {
      this.model.unset('current_theme', { silent: true });
    }
  },

  // меняет внешний вид виджета: размеры, цвет контролов, кнопку fullscreen и пр.
  // dopParams это специальное дополнение
  // в основном все параметры есть в params, но иногда мы хотим переписать параметры в params другими значениями
  // для этого служит dopParams, он расширяет объект params (ручками, не через extende, поэтому только некоторые параметры можно менять через него)
  // нужно когда мы в конструкторе ресайзим виджет (в момент ресайза у нас в модели старые данные по размерам)
  // а в dopParams мы передаем новые актеальные данные по размерам
  // также используется при переходе в режим fullscreen во вьювере
  applyVisualState: function(params, dopParams) {
    // защита от предыдущей версии виджета, просто чтобы не было ошибок и его можно было удалить в конструкторе
    if (!params.theme_data) return;

    var data = {
      w: dopParams ? dopParams.w : params.w,
      h: dopParams ? dopParams.h : params.h,
      images_h: dopParams ? dopParams.images_h : params.images_h,
      captions_h: dopParams ? dopParams.captions_h : params.captions_h,
      thumbnails_h: this.THUMBNAILS_HEIGHT,
      counters_h: this.COUNTERS_HEIGHT,
      left_arrow_noun: params.left_arrow_noun,
      right_arrow_noun: params.right_arrow_noun,
      theme: params.current_theme,
      current_mode: params.current_mode,
      thumbnails: params.theme_data.thumbnails,
      counters: params.theme_data.counters,
      arrows_type: params.theme_data.arrows_type,
      arrows_view_mode: params.theme_data.arrows_view_mode,
      captions: params.theme_data.captions,
      counters_type: params.theme_data.counters_type,
      active_arrows: params.active_arrows,
      pictures: params.pictures,
      radius: params.theme_data.radius,
    };

    data.showThumbnails = data.thumbnails;
    data.showCounters = data.counters;
    data.showCaptions = data.captions;
    data.showFullscreen = params.theme_data.fullscreen;
    data.showArrows = params.theme_data.arrows;
    if (data.showArrows !== undefined) {
      data.showBtmArrows =
        data.showArrows && (data.active_arrows ? data.active_arrows === 'bottom' : data.theme === 'theme_classic');
      data.showMdlArrows =
        data.showArrows && (data.active_arrows ? data.active_arrows === 'middle' : data.theme === 'theme_captions');
    } else {
      data.showBtmArrows = data.theme === 'theme_classic';
      data.showMdlArrows = data.theme === 'theme_captions';
    }

    // устанавливаем размеры отдельных частей слайдшоу и скрываем ненужные
    this.$images.css('height', data.images_h);
    this.$thumbnails.css({ display: data.showThumbnails ? 'block' : 'none', height: data.thumbnails_h });
    this.$counters.css({
      display:
        data.showCounters && (data.counters_type ? data.counters_type === 'dots' : data.theme === 'theme_captions')
          ? 'block'
          : 'none',
      height: data.counters_h,
    });
    this.$captions.css({
      display: data.showCaptions || data.theme === 'theme_captions' ? 'block' : 'none',
      height: data.captions_h,
    });

    // показ/скрытие кнопки фулскрина
    this.$images.find('.fullscreen').toggle(data.showFullscreen);

    this.$middle_arrows.toggleClass(
      'active',
      data.active_arrows ? data.active_arrows === 'middle' : data.theme === 'theme_captions'
    );
    this.$bottom_arrows.toggleClass(
      'active',
      data.active_arrows ? data.active_arrows === 'bottom' : data.theme === 'theme_classic'
    );

    if (
      data.theme &&
      data.theme === 'theme_captions' &&
      !data.active_arrows &&
      (!data.counters_type || data.counters_type === 'dots')
    ) {
      this.setDotsPosition('middle');
    }

    if (this.environment === 'viewer') {
      //  this.$el.toggleClass('mouseover', data.showArrows && data.arrows_view_mode === 'mouseover');
      this.$bottom_arrows.toggle(data.showBtmArrows);
      this.$middle_arrows.toggle(data.showMdlArrows);
      this.$bottom_arrows_container.toggleClass(
        'only-counters',
        data.active_arrows === 'middle' ||
          data.showArrows === false ||
          (data.showBtmArrows && data.arrows_view_mode === 'mouseover')
      );
    } else {
      if (data.showArrows !== undefined) {
        // показ/скрытие кнопки серединных стрелок
        this.$middle_arrows.css({ display: data.showArrows ? 'block' : 'none' });
        // показ/скрытие кнопок нижних стрелок
        this.$bottom_arrows.toggle(data.showArrows);
      } else {
        this.$middle_arrows.css({ display: data.theme === 'theme_captions' ? 'block' : 'none' });
        this.$bottom_arrows.toggle(data.theme === 'theme_classic');
      }
      this.$bottom_arrows_container.toggleClass('only-counters', data.showArrows === false && data.showCounters);
    }
    // показ/скрытие кнопки счетчика между нижними стрелками
    this.$images
      .find('.counters-numbers')
      .toggle(
        data.showCounters && (data.counters_type ? data.counters_type === 'numbers' : data.theme === 'theme_classic')
      );

    if (data.counters_type === 'dots') {
      this.setDotsPosition(data.active_arrows);
    }
    // получаем цвета контролов и фона в формате rgba(x,x,x,x)
    var themeData = params.theme_data,
      controlsColor = this.getRGBA(themeData.controls_color, themeData.controls_opacity),
      backgroundColor = this.getRGBA(themeData.background_color, themeData.background_opacity);

    // раскрашиваем все наши контролы в нужный цвет
    this.colorArrows(themeData.controls_color, themeData.controls_opacity);

    this.$('svg #full-screen').css('fill', controlsColor);

    this.$('.counters-numbers').css('color', controlsColor);

    if (data.counters_type === 'dots' && data.active_arrows === 'bottom' && !this.$counters.hasClass('down-position')) {
      _.defer(
        function() {
          this.$counters.find('.counter').css({ background: controlsColor, 'border-color': controlsColor });
        }.bind(this)
      );
    }
    // применяем фон
    this.$images.css('background', backgroundColor);
    this.$thumbnails.css('background', backgroundColor);

    // создаем стили положения и размера для основных картинок слайдшоу
    // делать это через обычные стили с процентами не стоит
    // гораздо производительнее каждый раз при ресайзе создавать динамические стили с фиксированными размерами-координатами в пикселах, проверено
    this.setNewPicsWidths(data.w);

    // создаем стили транзишенов для картинок слайдшоу
    this.setPicsTransitions();

    // смотрим все  картинки которые сейчас уже отображены в слайдшоу
    // и меняем их размер, background-size и radius(для вьювера радиус задается в applyPictures())
    this.$images_wrapper.children('.image').each(
      _.bind(function(index, item) {
        var $item = $(item);

        $item.css({
          'background-size': this.setImageSize(
            themeData.fill,
            $item.attr('data-width'),
            $item.attr('data-height'),
            data.w,
            data.images_h
          ),
          'border-radius': data.radius,
        });
      }, this)
    );

    if (!this.initialRender) {
      // в applyVisualState() методе нижеследующие действия выполняет только Конструктор
      // но не вслучае первого рендера,
      // т.к. ему иногда надо вызывать метод applyVisualState() отдельно и потом
      // пересчитывать каунтеры или тумбочи и скроллить к картинке.
      // во Вьювере же эти действия запустятся один раз после всех apply... в initialize() для производительности.

      var pictures = params.pictures || [];

      // задаем ширину оберток над превьюшками или кружками
      this.handleCounters && this.$counters_container.css('width', pictures.length * this.COUNTER_WIDTH);
      this.handleThumbnails &&
        this.$thumbnails_container.css(
          'width',
          pictures.length * (this.THUMBNAIL_WIDTH + this.THUMBNAIL_PADDING) - this.THUMBNAIL_PADDING
        );

      // пересчитываем положение блока с кружками и их внутренний скролл
      this.recalcCounters(params, dopParams);

      // пересчитываем положение блока с превьюшками и их внутренний скролл
      this.recalcThumbnails(params, dopParams);

      // скролим без анимации до картинки которая была до вызова функции (вообще эта функция очень много чего делает)
      // потому что при изменении размера виджета у нас меняется и положение картинок в обертке которая их содержит и пр.
      this.moveToImage(this.currentImageID, false, 'change-visual-style');
    }
  },

  // вынес в отдельную функцию, чтобы можно было удобно вызввать из slideshow.js
  colorArrows: function(color, opacity) {
    var controlsColor = this.getRGBA(color, opacity);

    this.$('svg path').css('stroke', controlsColor);
    this.$('svg').css('fill', controlsColor);
  },

  setDotsPosition: function(arrows) {
    var themeData, dotsColor;
    if (arrows === 'middle') {
      this.$counters.remove();
      this.$thumbnails.after(this.$counters);
      this.$counters.addClass('down-position');
      this.$counters.find('.counter').css('background', '#fff');
    }

    if (
      this.$thumbnails.next('.counters-dots').length &&
      this.$thumbnails.next('.counters-dots').length > 0 &&
      arrows === 'bottom'
    ) {
      this.$counters.remove();
      this.$images.find('.counters-numbers').after(this.$counters);
      this.$counters.removeClass('down-position');

      if (this.environment === 'constructor') {
        themeData = this.model.get('theme_data');
        dotsColor = this.getRGBA(themeData.controls_color, themeData.controls_opacity);
        this.$counters.find('.counter').css('background', dotsColor);
      }
    }
  },

  // преобразует цвет в формате #xxxxxx + opacity в формат rgba(x,x,x,x)
  getRGBA: function(color, opacity) {
    var col = '',
      rbg = [
        parseInt(color.substring(0, 2), 16),
        parseInt(color.substring(2, 4), 16),
        parseInt(color.substring(4, 6), 16),
      ];

    if (opacity > 0.99) col = 'rgb(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ')';
    else col = 'rgba(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ', ' + opacity + ')';

    return col;
  },

  getOriginalUrl: function(pic) {
    return pic.rasterUrl || pic.url;
  },

  getScaledUrl: function(picture) {
    if (Modernizr.retina || Scale.isOn()) return picture.scaled2xUrl || picture.rasterUrl || picture.url;
    return picture.scaledUrl || picture.rasterUrl || picture.url;
  },

  // метод вызывается когда у нас поменялся список картинок
  // в конструкторе каждый раз при изменении pictures, во вьювере один раз, при создании виджета
  applyPictures: function(params) {
    // защита от предыдущей версии виджета, просто чтобы не было ошибок и его можно было удалить в конструкторе
    if (!params.theme_data) return;

    var pictures = params.pictures || [];

    this.$el.toggleClass('no-images', !pictures.length);

    this.$bottom_arrows_container.toggleClass('no-images', pictures.length <= 1);
    this.$middle_arrows.toggleClass('no-images', pictures.length <= 1);

    this.$images.find('.bottom-arrows .counters-text-total').text(pictures.length);

    // удаляем все прежние картинки, превьюшки, кружки counters
    this.$images_wrapper.empty();
    this.handleCounters && this.$counters_container.empty();
    this.handleThumbnails && this.$thumbnails_container.empty();

    // сортируем картинки по порядку
    pictures = _.sortBy(pictures, 'num');

    for (var i = 0; i < pictures.length; i++) {
      var $item,
        pic = pictures[i];

      // создаем основную картинку
      $item = $('<div>')
        .addClass('image')
        .attr('data-id', pic.id)
        .toggleClass('error', !!pic.error)
        .css({
          'background-size': this.setImageSize(
            params.theme_data.fill,
            pic.width,
            pic.height,
            params.w,
            params.images_h
          ),
        })
        .appendTo(this.$images_wrapper);

      if (this.environment == 'constructor')
        $item.css({ 'background-image': pic.url ? 'url("' + this.getScaledUrl(pic) + '")' : 'none' });

      // в режиме вьювера картинку не грузим сразу, а потом, по мере надобности, чтобы сберечь трафик и увеличить скорость работы
      if (this.environment == 'viewer' && pic.url) {
        $item.attr('data-src', this.getScaledUrl(pic));
        if (pic.scaledUrl) $item.data('orig-src', this.getOriginalUrl(pic));
      }

      // сохраняем в DOM элементы физические размеры картинки, потом пригодятся в setImageSize
      if (pic.url && pic.width && pic.height) $item.attr('data-width', pic.width).attr('data-height', pic.height);

      if (this.handleThumbnails) {
        // создаем превьюшку картинки
        $item = $('<div>')
          .addClass('thumb')
          .attr('data-id', pic.id)
          .toggleClass('error', !!pic.error)
          .css({ left: (this.THUMBNAIL_WIDTH + this.THUMBNAIL_PADDING) * i - this.THUMBNAIL_PADDING / 2 })
          .appendTo(this.$thumbnails_container);

        if (this.environment == 'constructor')
          $item.css({ 'background-image': pic.thumbUrl ? 'url("' + pic.thumbUrl + '")' : 'none' });

        // в режиме вьювера картинку не грузим сразу, а потом, по мере надобности, чтобы сберечь трафик и увеличить скорость работы
        if (this.environment == 'viewer' && pic.thumbUrl) $item.attr('data-src', pic.thumbUrl);
      }

      if (this.handleCounters) {
        // создаем кружочки counters
        $('<div>')
          .addClass('counter')
          .attr('data-id', pic.id)
          .css({ left: this.COUNTER_WIDTH * i })
          .appendTo(this.$counters_container);
      }
      // в режиме вьювера создаем для каждой картинки блок с подписью
      // обязательно делать проверку на использование подписей через this.params.current_theme,
      // а не через this.handleCounters, т.к. кружочки могубыть отключены, а подписи остаться.
      if (
        (this.params.theme_data.captions || this.params.current_theme === 'theme_captions') &&
        this.environment === 'viewer'
      ) {
        var $caption_wrapper = $('<div>')
          .addClass('caption-wrapper')
          .attr('data-id', pic.id);

        $('<div>')
          .addClass('caption')
          .html(Utils.plainTextToHtml(pic.text, { detectLinks: true }))
          .appendTo($caption_wrapper);

        $caption_wrapper.appendTo(this.$captions);
      }
    }

    if (this.environment === 'viewer') {
      this.$images_wrapper.find('.image').css('border-radius', this.params.theme_data.radius);
    }

    // помечаем первую и последнюю картинку.
    // используется в moveToImage
    this.$images_wrapper.children(':first-child').data('first-child', true);
    this.$images_wrapper.children(':last-child').data('last-child', true);

    // обновляем переменную currentImageID (просто такой картинки уже может не быть)
    this.updateCurrentImageID(params);

    if (!this.initialRender) {
      // в applyPictures() методе нижеследующие действия выполняет только Конструктор
      // но не вслучае первого рендера,
      // т.к. ему иногда надо вызывать метод applyPictures() отдельно и потом
      // пересчитывать каунтеры или тумбочи и скроллить к картинке.
      // во Вьювере же эти действия запустятся один раз после всех apply... в initialize() для производительности.

      // задаем ширину оберток над превьюшками или кружками
      this.handleCounters && this.$counters_container.css('width', pictures.length * this.COUNTER_WIDTH);
      this.handleThumbnails &&
        this.$thumbnails_container.css(
          'width',
          pictures.length * (this.THUMBNAIL_WIDTH + this.THUMBNAIL_PADDING) - this.THUMBNAIL_PADDING
        );

      // пересчитываем положение блока с кружками и их внутренний скролл
      this.recalcCounters(params);

      // пересчитываем положение блока с превьюшками и их внутренний скролл
      this.recalcThumbnails(params);

      // скролим без анимации до картинки которая была до вызова функции (вообще эта функция очень много чего делает)
      // потому что при изменении размера виджета у нас меняется и положение картинок в обертке которая их содержит и пр.
      this.moveToImage(this.currentImageID, false, 'change-pictures');
    }

    if (!pictures.length) this.$images.find('.bottom-arrows .counters-text .counters-text-current').html(0);
  },

  // применяет стили к тексту подписей
  applyTextStyles: function(params) {
    var style = _.omit(params.text_style, 'size-leading-linked', 'size-leading-ratio'); // также важно, что объект при _.omit клонируется

    if (!/px/i.test(style['font-size'])) style['font-size'] += 'px';
    if (!/px/i.test(style['letter-spacing'])) style['letter-spacing'] += 'px';
    if (!/px/i.test(style['line-height'])) style['line-height'] += 'px';
    if (!/#/i.test(style['color'])) style['color'] = '#' + style['color'];

    style['opacity'] /= 100;

    this.$captions.find('.caption').css(style);
  },

  // создаем стили положения и размера для основных картинок слайдшоу
  // проставлять их каждый раз картинкам через инлайн накладно, а использовать проценты нельзя (тупит при анимации)
  // да и вообще не работать с инлайн стилями гуд практис в итоге по понятности и надежности кода
  setNewPicsWidths: function(width) {
    var $style = this.$el.find('#slideshow-images-position-style-' + this.params._id),
      hasOwnLayerInitially = Modernizr.isdesktop ? '' : ' translateZ(0)',
      edgeRatio = Scale.isAllowed() ? Scale.getRatio() : 1,
      style =
        '\
        .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper {\n\
          left: ' +
        0 +
        'px;\n\
          width: ' +
        width +
        'px;\n\
        }\n\
        .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image {\n\
          left: ' +
        0 +
        'px;\n\
          width: ' +
        width +
        'px;\n\
        }\n\
        .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image.prev-image {\n\
          -webkit-transform: translateX(' +
        -width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
          transform: translateX(' +
        -width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
        }\n\
        .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image.center-image {\n\
          -webkit-transform: translateX(0) ' +
        hasOwnLayerInitially +
        ';\n\
          transform: translateX(0) ' +
        hasOwnLayerInitially +
        ';\n\
        }\n\
        .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image.next-image {\n\
          -webkit-transform: translateX(' +
        width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
          transform: translateX(' +
        width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
        }\n\
        \
        .edge .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper {\n\
          left: ' +
        0 +
        'px;\n\
          width: ' +
        width +
        'px;\n\
        }\n\
        .edge .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image {\n\
          left: ' +
        0 +
        'px;\n\
          width: ' +
        width +
        'px;\n\
        }\n\
        .edge .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image.prev-image {\n\
          -webkit-transform: translateX(' +
        Math.ceil(-width * edgeRatio) +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
          transform: translateX(' +
        Math.ceil(-width * edgeRatio) +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
        }\n\
        .edge .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image.center-image {\n\
          -webkit-transform: translateX(0) ' +
        hasOwnLayerInitially +
        ';\n\
          transform: translateX(0) ' +
        hasOwnLayerInitially +
        ';\n\
        }\n\
        .edge .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper .image.next-image {\n\
          -webkit-transform: translateX(' +
        Math.ceil(width * edgeRatio) +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
          transform: translateX(' +
        Math.ceil(width * edgeRatio) +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
        }\n\
        ';

    if (!$style.length) {
      $style = $('<style id="slideshow-images-position-style-' + this.params._id + '" ' + 'type="text/css">');
      $style.appendTo(this.$el);
    }

    $style.text(style);
  },

  // создаем стили транзишенов для картинок слайдшоу
  setPicsTransitions: function() {
    var $style = this.$el.find('#slideshow-images-transition-style-' + this.params._id),
      time = this.getPicsTransitionTime(),
      easing = 'cubic-bezier(0.40, 0.24, 0.40, 1)', // как во Вьювере
      style =
        '\
        .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper.enable-transitions .image {\n\
          -webkit-transition:	all ' +
        time +
        'ms ' +
        easing +
        ';\
          transition: all ' +
        time +
        'ms ' +
        easing +
        ';\
        }\n\
        .common-slideshow[data-id="' +
        this.params._id +
        '"] .images .images-wrapper.enable-transitions .image.center-image {\n\
          -webkit-transition:	all ' +
        (time - 5) +
        'ms ' +
        easing +
        ';\
          transition: all ' +
        (time - 5) +
        'ms ' +
        easing +
        ';\
        }\n\
        ';

    if (!$style.length) {
      $style = $('<style id="slideshow-images-transition-style-' + this.params._id + '" ' + 'type="text/css">');
      $style.appendTo(this.$el);
    }

    $style.text(style);
  },

  // подбираем скорость анимации картинок.
  getPicsTransitionTime: function() {
    if (RM.screenshot) return;

    var time = 450; // дефолт.

    if (!this.mag) return time;

    if (this.isFullscreenMode) {
      // берем такое же время транзишена, как и у страниц во Вьювере.
      return _.isFunction(this.mag.getPageTransitionTime) ? this.mag.getPageTransitionTime() : time;
    } else {
      var scale = 1,
        scaledWidth;

      // на десктопе в режиме дефолтного вьюпорта никаких скейлов!
      // p.s. десктоп может работать и в режиме мобильного вьюпорта в режиме превью.
      if ((this.mag.viewport === 'default' && Modernizr.isdesktop) || this.environment === 'constructor') scale = 1;
      // visibleWidgetsCoords.scale устанавливается в page.js onResize
      else
        scale =
          this.mag.currentPage &&
          this.mag.currentPage.visibleWidgetsCoords &&
          this.mag.currentPage.visibleWidgetsCoords.scale;

      scaledWidth = Math.round(this.params.w * scale);

      // стоит отметить, что Слайдшоу максимум будет 1024px — юзеры не будут его делать
      // больше размера рабочей области Мэга.

      // за переломные размеры ширины взята реальная ширина в пикселях
      // различных устройств Эппл, а от этой ширине уже подобрано подходящее время транзишена.
      // как-будто мы рассуждаем о картинка в Слайдшоу, как о Страницах в Мэге, для простоты абстракции.
      if (scaledWidth >= 768)
        // шириной как вертикальный iPad и больше
        time = 550;
      else if (scaledWidth >= 667)
        // шириной как горизонтальный iPhone 6 и больше
        time = 520;
      else if (scaledWidth >= 568)
        // шириной как горизонтальный iPhone 5 и больше
        time = 490;
      else if (scaledWidth >= 480)
        // шириной как горизонтальный iPhone 4 и больше
        time = 470;
      else time = 400;

      return time;
    }
  },

  // функция которая эмулирует работу background-size: cover || contain
  // во-первых когда сам расчитываешь рендерится быстрее, во-вторых - без глюков в виде паразитных линий по краям
  // к тому же если у нас отключена опция fill нам надо сделать так что если картинка целиком помещается в область слайдшоу
  // ее не надо ресайзить на увеличение а просто расположить по центру в оригинальном размере
  setImageSize: function(fill, imgW, imgH, w, h) {
    if (!imgW || !imgH) return fill ? 'cover' : 'contain';

    var newW, newH;

    if (!fill) {
      if (imgW < w && imgH < h) return imgW + 'px ' + imgH + 'px';
      else {
        if (w / h > imgW / imgH) {
          newH = h + 2; // 2 это чтобы картинка была чуть больше размера области слайдшоу, чтобы не было паразитных линий по краям
          newW = Math.round((newH * imgW) / imgH);
        } else {
          newW = w + 2; // 2 это чтобы картинка была чуть больше размера области слайдшоу, чтобы не было паразитных линий по краям
          newH = Math.round((newW * imgH) / imgW);
        }

        return newW + 'px ' + newH + 'px';
      }
    } else {
      if (w / h > imgW / imgH) {
        newW = w + 2; // 2 это чтобы картинка была чуть больше размера области слайдшоу, чтобы не было паразитных линий по краям
        newH = Math.round((newW * imgH) / imgW);
      } else {
        newH = h + 2; // 2 это чтобы картинка была чуть больше размера области слайдшоу, чтобы не было паразитных линий по краям
        newW = Math.round((newH * imgW) / imgH);
      }

      return newW + 'px ' + newH + 'px';
    }
  },

  // срабатывает только в конструкторе при выходе из редактора подписи под картинкой
  // здесь мы просто смотрим изменил ли пользователь текст под картинкой и если да, тогда сохраняем его
  onCaptionBlur: function() {
    if (this.environment === 'viewer') return;

    if (!this.model) return;

    var text = this.$captions.find('.caption').val(),
      pictures = _.clone(this.model.get('pictures')),
      pic = _.findWhere(pictures, { id: this.$captions.find('.caption').attr('data-id') });

    if (!pic) return;

    if (pic.text === text) return;

    // _.clone скопирует массив, а ссылки на объекты внутри оставит старые,
    // поэтому мы просто подменяем картинку под нужным индексом новым объектом
    var i = pictures.indexOf(pic);
    pictures[i] = _.extend({}, pic, { text: text });

    this.model.save({ pictures: pictures }, { toHistory: true });

    this.block.workspace.trigger('redraw');
  },

  // пересчитывает положение и скрол кружков counters
  recalcCounters: function(params, dopParams) {
    if (!(params.theme_data.counters_type === 'dots' || params.current_theme === 'theme_captions')) return;

    var pictures = params.pictures || [],
      w = dopParams ? dopParams.w : params.w,
      totalW = this.COUNTER_WIDTH * pictures.length,
      cropedW = Math.floor(w / this.COUNTER_WIDTH) * this.COUNTER_WIDTH,
      newW = Math.min(totalW, cropedW),
      needLeft = params.active_arrows ? params.active_arrows === 'middle' : params.current_theme === 'theme_captions',
      left = needLeft ? Math.floor((w - newW) / 2) : 0;

    this.$counters_scroll_wrapper.css({ left: left, width: newW });
    this.$counters_container.css({ width: totalW });
  },

  // пересчитывает положение и скрол превьюшек
  recalcThumbnails: function(params, dopParams) {
    if (!params.theme_data.thumbnails) return;

    var pictures = params.pictures || [],
      w = dopParams ? dopParams.w : params.w,
      totalW = pictures.length * (this.THUMBNAIL_WIDTH + this.THUMBNAIL_PADDING) - this.THUMBNAIL_PADDING,
      newW = Math.min(totalW, w - this.THUMBNAILS_PADDING),
      left = Math.floor((w - newW) / 2);

    this.thumbsWidth = newW;

    this.$thumbnails_scroll_wrapper.css({ left: left, width: newW });
    this.$thumbnails_container.css({ width: totalW });
  },

  // Переключение между картинками должно работать по клику на любую область картинки, левая часть — назад, правая — вперед
  onImageClick: function(e) {
    if (this.environment == 'constructor') return;

    if (!Modernizr.isdesktop) return;

    var $obj = this.$images;

    e && e.stopPropagation();

    var box = Scale.getNormalizedBox($obj.get(0));
    if (e.pageX < box.left + box.width / 2) this.prevImage();
    else this.nextImage();
  },

  // нажали на стрелку - "предыдущая картинка"
  prevImage: function(e) {
    var isClickForUpdateClasses;
    if (this.environment === 'constructor') {
      isClickForUpdateClasses = this.updateArrowsState(e);
    }

    if (isClickForUpdateClasses) return;
    this.imagePrevNext(e, 'prev', 'switch-to-image');
  },

  // обновляет классы, сетит в модель какие стрелки активны;
  // если возвращается true, то фунцкия вызвавшая ее должна перестать выполняться,
  // т.к. это первый клик по не активным стрелкам и он нужен, чтобы их активировать,
  // а не для переключения слайда
  updateArrowsState: function(e) {
    var isClickForUpdateClasses;

    if ($(e.target).closest('.arrow-middle').length > 0) {
      if (this.$middle_arrows.hasClass('active')) {
        isClickForUpdateClasses = false;
      } else {
        this.$middle_arrows.addClass('active');
        this.$bottom_arrows.removeClass('active');
        this.model.save('active_arrows', 'middle');
        this.model.trigger('change:theme_data');
        isClickForUpdateClasses = true;
        this.setDotsPosition('middle');
      }
    } else {
      if (this.$bottom_arrows.hasClass('active')) {
        isClickForUpdateClasses = false;
      } else {
        this.$bottom_arrows.addClass('active');
        this.$middle_arrows.removeClass('active');
        this.model.save('active_arrows', 'bottom');
        this.model.trigger('change:theme_data');
        isClickForUpdateClasses = true;
        this.setDotsPosition('bottom');
      }
    }

    return isClickForUpdateClasses;
  },

  // в случае когда выбран вариант показа стрелок по ховеру и активными
  // стрелками при этом являются нижние, нужно менять классы, чтобы серая область
  // вокруг счетчика сужалась и расширялась в соотвествии с поведением стрелок
  onMouseMove: function() {
    var $btmContainer = this.$('.images').find('.bottom-arrows');
    if (this.active_arrows === 'bottom') {
      $btmContainer.toggleClass('only-counters', !$btmContainer.hasClass('only-counters'));
    }
  },

  // нажали на стрелку - "следующая картинка"
  nextImage: function(e) {
    var isClickForUpdateClasses;

    if (this.environment === 'constructor') {
      isClickForUpdateClasses = this.updateArrowsState(e);
    }

    if (isClickForUpdateClasses) return;

    this.imagePrevNext(e, 'next', 'switch-to-image');
  },

  imagePrevNext: function(e, dir, tp) {
    if (!this.currentImageID) return;

    var neighbourPic = dir == 'next' ? this.currentPic.$el.next() : this.currentPic.$el.prev();

    if (!neighbourPic.length) neighbourPic = this.$images_wrapper.children(':' + (dir == 'next' ? 'first' : 'last'));

    this.moveToImage(neighbourPic.attr('data-id'), true, tp);

    e && e.stopPropagation();
  },

  // клик по превьюшке
  thumbnailClick: function(e) {
    this.moveToImage($(e.currentTarget).attr('data-id'), false, 'switch-to-image');

    e.stopPropagation();
  },

  // клик по кружку counters
  counterClick: function(e) {
    this.moveToImage($(e.currentTarget).attr('data-id'), false, 'switch-to-image');

    e.stopPropagation();
  },

  // функция прокручивает слайдшоу к картинке с id = imageID
  // может делать с анимацией и без (animate)
  // также получает на входе initiator (который ей самой не интересен, но который она передает потом в сообщении о том что произошел переход на картинку)
  // initiator просто содержит информацию о том, кто вызвал moveToImage
  moveToImage: function(imageID, animate, initiator) {
    if (!imageID) return;

    var $pics = this.$images_wrapper.children('.image'),
      $newPic = $pics.filter('[data-id="' + imageID + '"]'),
      newPicIndex = $pics.index($newPic),
      oldPic = _.clone(this.currentPic), // важно клонировать, иначе референс получается.
      newPic = {
        num: newPicIndex,
      },
      self = this;

    if (!$newPic.length || newPicIndex == -1) return;

    this.currentImageID = imageID;
    this.currentPic.$el = $newPic;
    this.currentPic.num = newPicIndex;

    // ставим флаг что юзер начал сам листать картинки,
    // чтобы отключить автоплей (если он был включен в настройках).
    if (initiator == 'switch-to-image') {
      this.disableAutoplayOnUserInteraction = true;
      this.stopAutoplay();
    }

    // включаем или выключаем транзишены в зависимости от того,
    // надо ли анимировать с анимацией или же нет.
    this.$images_wrapper.toggleClass('enable-transitions', animate);

    // сбрасываем ожидание конца анимации для предыдущей картинки
    // это важно, если быстро листаем картинки и текущая страница уже сменилась,
    // но ее колбек еще не выстрелил, а если выстрелит то все испортит.
    this.resetWaitForAnimationEnd();

    // навешивать надо до установки трансформа, колбек окончания анимации текущей картинки.
    animate && oldPic.$el && this.waitForAnimationEnd(oldPic.$el, onPicChange);

    // считаем, что юзер желает двигаться по картинкам вперед, если:
    // если нет предыдущей картинки (первый заход)
    // или
    // если есть предыдущая картинка и она по номеру меньше новой
    this.direction = !oldPic.$el || !oldPic.$el.length || oldPic.num < newPic.num ? 'forward' : 'backward';

    // если перешли с последней картинки на первую, то движемся вперед.
    if ($newPic.data('first-child') && oldPic.$el && oldPic.$el.length && oldPic.$el.data('last-child'))
      this.direction = 'forward';
    // если перешли с первой картинки на последнюю, то движемся вперед.
    else if ($newPic.data('last-child') && oldPic.$el && oldPic.$el.length && oldPic.$el.data('first-child'))
      this.direction = 'backward';

    // смотрим начало и конец блока видимых страниц
    var firstVisible =
        newPic.num -
        (this.direction == 'forward' ? this.PREDISPLAY_COUNT['backward'] : this.PREDISPLAY_COUNT['forward']),
      lastVisible =
        newPic.num +
        (this.direction == 'forward' ? this.PREDISPLAY_COUNT['forward'] : this.PREDISPLAY_COUNT['backward']);

    // позиционируем картинки по новой.
    // всем страницам ставим классы:
    // те что слева текущей .left, те что справа .right, текущей .center
    // (отсчет по номерам страниц)
    $pics.each(function(i) {
      var $pic = $(this);

      if (i < newPic.num) self.setPicPosition($pic, 'prev', i + 1 === newPic.num);
      else if (i > newPic.num) self.setPicPosition($pic, 'next', i - 1 === newPic.num);
      else self.setPicPosition($pic, 'center', i === newPic.num);

      // близлежайшие картинки делаем display:block
      if (i >= firstVisible && i <= lastVisible) {
        $pic.removeClass('hidden');
      }
    });

    self.$images.find('.counters-text-current').html(newPicIndex + 1);

    // если без анимации, тогда сразу вызываем колбэк.
    !animate && onPicChange();

    function onPicChange() {
      $pics.each(function(i) {
        var $pic = $(this);

        // прячем дальнележащие картинки с помощью display:none
        if (i < firstVisible || i > lastVisible) $pic.addClass('hidden');
      });

      // если новая картинка — первая, то не прячем последнюю,
      // чтобы переход через границу картинок был с анимацией.
      if ($newPic.data('first-child'))
        self.$images_wrapper
          .children()
          .last()
          .removeClass('hidden');

      // если новая картинка — последняя, то не прячем первую.
      // чтобы переход через границу картинок был с анимацией.
      if ($newPic.data('last-child'))
        self.$images_wrapper
          .children()
          .first()
          .removeClass('hidden');

      // восстанавливаем транзишены страниц, если они были отключены.
      // во всех случаях, кроме первого запуска-рендера мэга, т.к. этом случае
      // транзишены могут навесится обратно слишком рано и будет видно,
      // как страницы разъезжаются на исходные позиции при заходе в мэг.
      // не возвращать транзишены не страшно, т.к. при переходе на другую страницу
      // они все равно заранее навесятся.
      if (!animate && !self.initialRender) {
        window.requestAnimationFrame(function() {
          // и обязательно при следующем кадре, т.к. при переходе на страницы
          // через мэг-меню мы не хотим анимации, но без попадания в кадр
          // транзишены навешиваются обратно слишком рано и анимации будет.
          self.$images_wrapper.addClass('enable-transitions');
        });
      }

      // сообщаем всем заинтересованным что у нас произошел переход к картинке
      self.trigger('currentImageChanged', imageID, animate, initiator);

      if (self.handleCounters && !self.initialRender) {
        // не при первом рендере т.к. здесь есть offsetLeft и т.п., вызывающее рефлоу,
        // а при первом рендере из всего нижеследующего нам нужно лишь навесить .active на первый кружок.

        // смотрим кружки counters
        // если текущий активный кружок не виден на экране скролим блок так чтобы он стал виден + 1 его сосед
        var $counter = self.$counters_container.children('[data-id="' + imageID + '"]');
        self.$counters_container.children('.active').removeClass('active');
        $counter.addClass('active');

        var left = $counter.get(0).offsetLeft,
          w = self.$counters.find('.items-wrapper').width(),
          newScroll = undefined;

        if (left + self.COUNTER_WIDTH * 2 - self.$counters.find('.items-wrapper').scrollLeft() > w) {
          newScroll = left + self.COUNTER_WIDTH * 2 - w;
        } else if (left - self.COUNTER_WIDTH - self.$counters.find('.items-wrapper').scrollLeft() < 0) {
          newScroll = left - self.COUNTER_WIDTH;
        }

        if (newScroll != undefined) {
          if (animate)
            self.$counters
              .find('.items-wrapper')
              .stop()
              .animate({ scrollLeft: newScroll }, 200);
          else self.$counters.find('.items-wrapper').scrollLeft(newScroll);
        }
      }

      if (self.handleThumbnails && !self.initialRender) {
        // не при первом рендере т.к. здесь есть offsetLeft и т.п., вызывающее рефлоу,
        // а при первом рендере из всего нижеследующего нам нужно лишь навесить .active на первый кружок.

        // смотрим превьюшки
        // если текущая превьюшка не видна на экране скролим блок так чтобы она стала видна + 1 соседняя
        var $thumbnail = self.$thumbnails_container.children('[data-id="' + imageID + '"]');
        self.$thumbnails_container.children('.active').removeClass('active');
        $thumbnail.addClass('active');

        var left = $thumbnail.position().left,
          w = self.$thumbnails.find('.items-wrapper').width(),
          newScroll = undefined;

        if (
          left +
            (self.THUMBNAIL_WIDTH + self.THUMBNAIL_PADDING) * 2 -
            self.THUMBNAIL_PADDING / 2 -
            self.$thumbnails.find('.items-wrapper').scrollLeft() >
          w
        ) {
          newScroll = left + (self.THUMBNAIL_WIDTH + self.THUMBNAIL_PADDING) * 2 - self.THUMBNAIL_PADDING / 2 - w;
        } else if (
          w > (self.THUMBNAIL_WIDTH + self.THUMBNAIL_PADDING) * 2 &&
          left -
            self.THUMBNAIL_WIDTH -
            self.THUMBNAIL_PADDING / 2 -
            self.$thumbnails.find('.items-wrapper').scrollLeft() <
            0
        ) {
          newScroll = left - self.THUMBNAIL_WIDTH - self.THUMBNAIL_PADDING / 2;
        }
      }

      if (newScroll != undefined) {
        if (animate)
          self.$thumbnails
            .find('.items-wrapper')
            .stop()
            .animate({ scrollLeft: newScroll }, 200);
        else self.$thumbnails.find('.items-wrapper').scrollLeft(newScroll);
      }

      if (self.model && self.environment === 'constructor') {
        // если мы в режиме конструктора то меняем текст в текстарии
        var pictures = self.model.get('pictures'),
          pic = _.findWhere(pictures, { id: self.currentImageID });

        if (pic)
          self.$captions
            .find('.caption')
            .val(pic.text)
            .attr('data-id', self.currentImageID);
        else
          self.$captions
            .find('.caption')
            .val('')
            .attr('data-id', '');
      } else if (
        (self.params.theme_data.captions || self.params.current_theme === 'theme_captions') &&
        self.environment === 'viewer'
      ) {
        // если в режиме вьювера то показываем текст текущей картинки (плавно или резко в зависимости от animate)

        if (!animate) {
          self.$captions
            .find('.caption-wrapper')
            .stop()
            .css({ display: 'none' });
          self.$captions
            .find('.caption-wrapper[data-id="' + self.currentImageID + '"]')
            .stop()
            .css({ display: 'block' });
        } else {
          self.$captions
            .find('.caption-wrapper[data-id="' + self.currentImageID + '"]')
            .stop()
            .fadeIn(200)
            .siblings()
            .stop()
            .fadeOut(200);
        }
      }

      // показываем основные картинки и превьюшки если у нас есть такие которые стали видны на экране
      // это только для режива вьювера
      // изначально у нас все создано но урлы картинкам не прописаны чтобы они не грузились
      // эти функции прописывают текущим видимым картинкам урлы чтобы они начали загружаться и показались
      self.preloadImages();
      self.preloadThumbImages();
    }
  },

  // ожидает конца анимации перемещения картинки.
  // навешивать надо до трансформа.
  // $pic —
  waitForAnimationEnd: function($pic, callback) {
    this.resetWaitHook = Utils.waitForTransitionEnd($pic, this.getPicsTransitionTime() + 1000, 'transform', callback);
  },

  // сбросить ожидание окончания анимации без выполнения колбека ожидания.
  resetWaitForAnimationEnd: function() {
    this.resetWaitHook && this.resetWaitHook();
  },

  // устанавливает положение картинки слева от видимой части слайдшоу, справа или же целиком в экране
  setPicPosition: function($pic, pos, neighbour) {
    // оптимизируем обращения к ДОМу. когда много страниц, есть небольшой эффект от этого.
    if ($pic.data('last-position') === pos && $pic.data('last-neighbour') === neighbour) return;

    $pic
      .removeClass('center-image prev-image next-image neighbour')
      .addClass(pos + '-image ' + (neighbour ? 'neighbour' : ''));

    $pic.data('last-position', pos);
    $pic.data('last-neighbour', neighbour);
  },

  // показывает(загружает) основные картинки (текущую + 1 слева + 1 справа)
  preloadImages: function() {
    if (this.environment != 'viewer') return;

    var $pic = this.$images_wrapper.children('[data-id="' + this.currentImageID + '"]');
    if (!$pic.length) return;

    var $prevPic = $pic.prev();
    if (!$prevPic.length) $prevPic = this.$images_wrapper.children(':last');

    var $nextPic = $pic.next();
    if (!$nextPic.length) $nextPic = this.$images_wrapper.children(':first');

    var that = this;

    preloadPic($pic);

    if (!RM.screenshot) {
      // Скриншотеру достаточно одной картинки
      preloadPic($prevPic);
      preloadPic($nextPic);
    }

    function preloadPic($img) {
      if (that.isFullscreenMode) {
        if ($img.data('orig-src')) {
          var img = new Image();
          img.onload = function() {
            $img.css({ 'background-image': 'url("' + img.src + '")' }).removeData('orig-src');
          };
          img.src = $img.data('orig-src');
        }
      }

      if ($img.attr('data-src')) {
        that.preloadImagesList.push({ url: $img.attr('data-src') });
        $img.css({ 'background-image': 'url("' + $img.attr('data-src') + '")' }).removeAttr('data-src');
      }
    }
  },

  // показывает(загружает) превьюшки (все которые сейчас должны быть видны на экране + 1 слева + 1 справа)
  preloadThumbImages: function() {
    if (this.environment !== 'viewer') return;

    if (!this.params.theme_data.thumbnails) return;

    // находим все тумбы которые ейчас видны на экране (с учетом внутреннего скрола)
    var parentWidth = this.initialRender ? this.thumbsWidth : this.$thumbnails_scroll_wrapper.width(),
      parentScroll = this.initialRender ? 0 : this.$thumbnails.find('.items-wrapper').scrollLeft();

    this.$thumbnails.find('.items-wrapper .thumb').each(
      _.bind(function(index, item) {
        var $item = $(item),
          src = $item.attr('data-src');

        if (src) {
          var left = index * this.THUMBNAIL_WIDTH - this.THUMBNAIL_PADDING / 2,
            width = this.THUMBNAIL_WIDTH + this.THUMBNAIL_PADDING;

          if (!(left - parentScroll > parentWidth + width || left + width - parentScroll < 0 - width)) {
            this.preloadImagesList.push({ url: $item.attr('data-src') });
            $item.css({ 'background-image': 'url("' + $item.attr('data-src') + '")' }).removeAttr('data-src');
          }
        }
      }, this)
    );
  },

  // функция обновляет значение currentImageID если оно вдруг некорректно на данный момент
  // (например изначально или при смене объекта pictures в конструкторе)
  updateCurrentImageID: function(params) {
    if (!params.pictures || !params.pictures.length) {
      this.currentImageID = undefined;
      return;
    }

    if (!this.currentImageID) this.currentImageID = this.getFirstPictureID(params);
    else {
      var pic = _.findWhere(params.pictures, { id: this.currentImageID });
      this.currentImageID = pic ? pic.id : this.getFirstPictureID(params);
    }
  },

  // находит айдишник картинки которая идет первая по порядку
  getFirstPictureID: function(params) {
    var pictures = _.sortBy(params.pictures, 'num');

    return pictures[0].id;
  },

  // реакция на кнопку фулскрина
  toggleFullscreen: function(e) {
    if (this.environment == 'constructor') return;

    if (this.isFullscreenMode) this.leaveFullscreen();
    else this.enterFullscreen();

    e.stopPropagation();
  },

  // переводит слайдшоу в полноэкранный режим
  enterFullscreen: function() {
    if (this.environment == 'constructor') return;
    if (this.isFullscreenMode) return;

    this.isFullscreenMode = true;

    this.$parentBeforeFullscreen = this.$el.parent();

    // жуткий-прежуткий костыль, если виджетбар не скрыть когда мы перешли из конструктора в режим превью
    // и на виджете слайдшоу нажали fullscreen то он у нас все уезжает вверх на 180px
    // потому что именно на столько виджетбар уезжает вниз (под экран) при переходе из конструктора в превью
    // вообще это баг, но проявляется во всех браузерах, потому не понятно кто тут идиот
    $('.constructor .widgetbar').addClass('hidden');

    this.$el.addClass('fullscreen-mode');

    // вырываем всю верстку из виджета и добавляем ее к боди, для того чтоюы можно было на весь жкран раскрыть
    this.$el.appendTo('body').css('z-index', 999);

    // навешиваем обработчик ресайза окна (виджет должен всегда занимать все окно в фулскрине)
    $(window).on('resize', this.onFullscreenResize);

    // навешиваем обработчики стрелок и эскейпа
    $('body').on('keyup', this.onFullscreenKeyUp);

    // отключаем глобальные шорткаты конструктора и вьювера
    _.defer(function() {
      RM.common.disableShortcuts['slideshow'] = true;
    });

    // первоначально меняем размер виджета на текушие размеры экрана
    this.onFullscreenResize();

    // убираем фокус отовсюду
    Utils.getFocusBack();

    this.preloadImages();
  },

  onFullscreenKeyUp: function(e) {
    if (e.keyCode == $.keycodes.left) this.prevImage();
    if (e.keyCode == $.keycodes.right) this.nextImage();
    if (e.keyCode == $.keycodes.esc) this.leaveFullscreen();
  },

  // рассчитывает новые размеры виджета и его внутрених частей в зависимости от размера экрана
  // и просит слайдшоу плеер перерисоваться в соответствии с тими размерами
  onFullscreenResize: function() {
    var w = this.$el.width(),
      h = this.$el.height(),
      showBottomDots =
        this.params.theme_data.counters &&
        ((this.params.active_arrows === 'middle' && this.params.theme_data.counters_type === 'dots') ||
          this.params.current_theme === 'theme_captions');

    var images_h =
      h -
      (this.params.theme_data.thumbnails ? this.THUMBNAILS_HEIGHT : 0) -
      (this.params.theme_data.captions || this.params.current_theme === 'theme_captions' ? this.params.captions_h : 0) -
      (showBottomDots ? this.COUNTERS_HEIGHT : 0);

    this.applyVisualState(this.params, {
      w: w,
      h: h,
      images_h: images_h,
      captions_h: this.params.captions_h,
    });
  },

  // переводит слайдшоу в нормалный режим
  leaveFullscreen: function() {
    if (this.environment == 'constructor') return;
    if (!this.isFullscreenMode) return;

    this.isFullscreenMode = false;

    // обратно выбдираем верстку из body и запихиваем обратнов контейнер виджета
    this.$el.appendTo(this.$parentBeforeFullscreen).css('z-index', 'auto');

    $(window).off('resize', this.onFullscreenResize);

    this.$el.removeClass('fullscreen-mode');

    $('.constructor .widgetbar').removeClass('hidden');

    $('body').off('keyup', this.onFullscreenKeyUp);

    // включаем обратно глобальные шорткаты конструктора и вьювера
    _.defer(function() {
      delete RM.common.disableShortcuts['slideshow'];
    });

    // просим виджет перерисоваться в соответствии с исходными размерами
    this.applyVisualState(this.params);

    this.setNewPicsWidths(this.params.w);
  },

  startAutoplay: function() {
    if (this.autoPlayInterval) return;

    if (this.disableAutoplayOnUserInteraction) return;

    this.autoPlayInterval = setInterval(
      _.bind(function() {
        // switch-to-image-autoplay вместо switch-to-image потому,
        // что при switch-to-image происходит автоматическое отключение автоплей, поскольку считается что пользователь начал сам листать картинки
        // а нам в данном случае такая реакци не нужна, иначе автоплей остановиться после первого же срабатывания
        this.imagePrevNext(null, 'next', 'switch-to-image-autoplay');
      }, this),
      this.AUTOPLAY_INTERVAL
    );
  },

  stopAutoplay: function() {
    clearInterval(this.autoPlayInterval);

    // обзательно! иначе заново не сработает startAutoplay когда потребуется
    this.autoPlayInterval = null;
  },

  destroy: function() {
    this.isDestroyed = true; // Флаг для асинхронных операций
    this.stopAutoplay();
    this.leaveFullscreen();
    this.resetWaitForAnimationEnd();
    this.$el && this.$el.remove();
  },

  /* Функции для свайпа картинок на телефонах и планшетах (начало блока)  */

  // навешиваем обработчик свайпа картинок
  setSwipeAction: function() {
    // навешиваем только для девайсов
    if (Modernizr.isdesktop) return;

    // навешиваем наш самописный плагин для вытягивания-утягивания элементов
    this.picsSwipe = this.$images_wrapper
      .RMSwipe({
        $scrollElements: this.mag.$mag_container, // это чтоюы можно было скролить страницу поверх слайдшоу

        maxTransitionSpeed: this.getPicsTransitionTime(),

        // сила пружинки при натяжении первой картинки вправо и наоборот.
        // чем больше значение дроби, тем жесче "пружина", 1/1 нет пружины, 1/<1 обратная "пружина".
        rubberBandForce: 1 / 1.47,

        // функция опрашивается в самом начале свайпа,
        // чтобы знать в каком направлении ожидается жест и его ограничения по сдвигу.
        getCurrentConstraints: function($target) {
          var res = {},
            size = this.isFullscreenMode ? this.$images_wrapper.width() : this.params.w;

          // указываем элементы, которые надо двигать
          res['$moveItems'] = this.currentPic.$el.add(this.currentPic.$el.prev()).add(this.currentPic.$el.next());

          // в качестве референсного объекта берем либо предыдущую, либо следующую картинку,
          // но не центральную, поскольку у нее транзишен меньше остальных.
          // т.е. по ней нельзя ни ожидать транзишенов, ни смотреть, есть сейчас анимация или нету.
          res['$referenceItem'] = res['$moveItems'].filter(':not(.center-image)').first();

          // запрещаем сдвиг всего влево если мы на последней картинке
          if (this.currentPic.$el.is(':last-child')) {
            // noTrigger:true означает что в этом направлении триггерить жест не надо,
            // но сам факт присутствия объекта у данного направления 'up' говорит о том,
            // что в данном направлении будет работать пружинка и движение в этом направлении разрешено для инициации жеста.
            res['left'] = { max: 0, noTrigger: true };
          } else {
            res['left'] = { max: size };
          }

          // запрещаем сдвиг всего вправо если мы на первой картинке
          if (this.currentPic.$el.is(':first-child')) {
            res['right'] = { max: 0, noTrigger: true };
          } else {
            res['right'] = { max: size };
          }

          return res;
        }.bind(this),

        onSwipeStart: function() {
          this.$images_wrapper.removeClass('enable-transitions');

          // ставим флаг что юзер начал сам листать картинки,
          // чтобы отключить автоплей (если он был включен в настройках).
          this.disableAutoplayOnUserInteraction = true;
          this.stopAutoplay();
        }.bind(this),

        customMoveCheck: function($obj, shift) {
          // если сейчас сдвиг отрицательный значит страницы двужутся влево,
          // значит левую страницу двигать не надо, ее все равно не видно,
          // ну и с правой страницей такой же алгоритм.
          if ($obj.hasClass('prev-image') && shift < 0) return false;
          if ($obj.hasClass('next-image') && shift > 0) return false;

          return true;
        }.bind(this),

        callback: function(dir) {
          // в конце анимации мы делаем стандартный moveToImage без анимации,
          // чтобы все работало нормально (moveToImage много чего делает).
          // делать это надо именно в конце анимации иначе у нас будет рваная анимация прокрутки к нужной картинке
          var pic;

          if (dir === 'right') pic = this.currentPic.$el.prev();

          if (dir === 'left') pic = this.currentPic.$el.next();

          if (!pic.length) pic = this.currentPic.$el;

          this.moveToImage(pic.attr('data-id'), false, 'switch-to-image');
        }.bind(this),
      })
      .data('swipe');
  },

  /* Функции для свайпа картинок на телефонах и планшетах  */
});

export default SlideshowPlayer;
