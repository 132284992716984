/**
 * Модуль Backbone модели данных о мэге.
 *@module User Mag
 */

import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Constants, Utils } from '../../common/utils';

/**
 * Модель данных мэга. Содержит только свойства мэга, но не его содержимое
 * @class RM.models.UserMag
 */
export const UserMagModel = Backbone.Model.extend({
  idAttribute: '_id',

  url: function() {
    if (this.get('user') && this.get('uri')) {
      var user = this.get('user');
      if (_.isObject(user)) user = user.get('uri');
      var link = user + '/' + this.get('uri');
    } else {
      var link = this.getId();
    }

    return '/api/readymag/' + link;
  },

  PAGES_URL: '/api/magpages/',
  PUBLISH_URL: '/api/publish/',
  UNPUBLISH_URL: '/api/unpublish/',

  /**
   * Парсит ответ от сервера при загрузке модели и производит доп. действия над данными
   * @method parse
   * @param data {Object} ответ от сервера
   * @return {Object} измененные данные ответа от сервера
   */
  parse: function(data) {
    const { MagList } = require('./mag-list');
    const { UserModel } = require('./user');

    if (data.user) {
      this.user = new UserModel(data.user);
      delete data.user;
    }

    if (!data.title) data.emptyTitle = true;

    // __magterm
    data.title = data.title || 'Project';

    if (!_.isEmpty(data.recentMags)) {
      this.recentMags = new MagList(data.recentMags, { parse: true });
    }
    return data;
  },

  /**
   * Возвращает num_id страницы по ее номеру (или '' если не нашел)
   * @num порядковый номер
   * @return {String} PAGE_ID
   */
  getPageNumId: function(num) {
    if (!this.get('pages')) return '';

    var page = _.find(this.get('pages'), function(page) {
      return page.num == num;
    });

    return page ? page.num_id : '';
  },

  /**
   * Возвращает id страницы по ее номеру (или '' если не нашел)
   * @method getPageId
   * @param pagenum {Number} Номер страницы. Первая страница имеет номер 1
   * @return {String} PAGE_ID
   */
  getPageId: function(pagenum) {
    if (!this.get('pages')) return '';

    var page = _.find(this.get('pages'), function(page) {
      return page.num == pagenum;
    });

    return page ? page._id : '';
  },

  /**
   * Возвращает прямой URL на скриншот заданной страницы мэга
   * @method getPageScreenshot
   * @param size {Number} Горизонтальный размер скриншота в пикселях
   * @param pagenum {Number} Номер страницы. Первая страница имеет номер 1
   * @param editVersion {Boolean} нужен ли скриншот для конструкторской версии страницы
   * @return {String} URL
   */
  getPageScreenshot: function(size, pagedata) {
    size = Utils.screenshotSize(size); // учтет ретину

    if (pagedata.cover) {
      if (!this.get('cover') && this.get('pages')) return this.getPageScreenshot(size, { _id: this.get('coverPid') });
      if (!this.get('cover'))
        return [
          '/api/screenshot/renew',
          this.get('num_id'),
          this.get('coverPid'),
          (this.get('is_published') ? 'ready' : '') + '?redirect=true&size=' + size,
        ].join('/');
      return Utils.addFilenameComponent(this.get('cover'), size);
    }

    if (this.get('pages')) {
      var page = _.findWhere(this.get('pages'), pagedata);
      var scr = page && page.screenshot;
      if (!scr && page)
        return [
          '/api/screenshot/renew',
          this.get('num_id'),
          page._id,
          (this.get('is_published') ? 'ready' : '') + '?redirect=true&size=' + size,
        ].join('/');
      return Utils.addFilenameComponent(scr, size);
    } else {
      console.error('cannot find page screenshot: ', this, pagedata);
    }
  },

  /**
   * Возвращает прямой URL на просмотр мэга
   * @method getLink
   * @return {String} URL
   */
  getLink: function() {
    if (!window.isdesktop) {
      return this.getUnpublishedViewerLink();
    }

    var magUri = (this.get('is_published') && this.get('uri')) || this.getId();

    if (RM.common.customDomainProfile) return '/' + magUri + '/';

    return Constants.readymag_host + '/' + this.user.get('uri') + '/' + magUri + '/';
  },

  /**
   * Возвращает прямой URL на просмотр неопубликованного мэга
   * @method getLink
   * @return {String} URL
   */
  getUnpublishedViewerLink: function() {
    return Constants.readymag_auth_host + '/' + this.user.get('uri') + '/' + this.get('num_id');
  },

  /**
   * Возвращает прямой URL на страницу редактирования мэга
   * @method getMagEditLink
   * @return {String} URL
   */
  getMagEditLink: function() {
    return Constants.readymag_auth_host + '/edit/' + this.getId() + '/';
  },

  getMagEditContentsLink: function() {
    // На мобильниках открываем неопубликованный проект во вьювере вместо редактирования
    if (!window.isdesktop) {
      return this.getUnpublishedViewerLink();
    }

    return this.getMagEditLink() + 'contents/';
  },

  /**
   * Возвращает прямой URL на страницу редактирования свойств мэга
   * @method getMagSettingsLink
   * @return {String} URL
   */
  getMagSettingsLink: function() {
    return this.getMagEditLink() + 'settings/';
  },

  /**
   * Возвращает уникальный порядковый числовой ID (не _id документа в БД). Во всех запросах на сервер используется num_id вместо _id
   * @method getId
   * @return {Number} num_id
   */
  getId: function() {
    return this.get('num_id');
  },

  /**
   * Возвращает количество страниц в мэге
   * @method getPagesCount
   * @return {Number} количество страниц
   */
  getPagesCount: function() {
    return this.get('pages_count') || (this.get('pages') && this.get('pages').length);
  },

  /**
   * Удаляет мэг на сервере
   * @method deleteMag
   * @param opts {Object} Объект с коллбэками на success и always
   * @async
   */
  deleteMag: function(opts) {
    this.deleteXHR && this.deleteXHR.abort();
    this.deleteXHR = $.ajax({
      type: 'DELETE',
      url: '/api/mag/' + this.getId(),
      success: function(data) {
        if (typeof opts.success === 'function') {
          opts.success(data);
        }
      },
      error: function(xhr) {
        console.log('Error deleting mag: ' + xhr.responseText);
        // __magterm
        this.onError('A problem occurred while deleting your project. Please, contact us: ' + Constants.SUPPORT_MAIL);
      },
      context: this,
    }).always(function() {
      if (typeof opts.always === 'function') {
        opts.always();
      }
    });
  },

  /**
   * Меняет состояние мэга на сервере и текущее состояние модели: publish/unpublish, или переопубликовывает измененный мэг
   * @method _changePublishState
   * @param action - 'publish', 'unpublish', 'republish'
   * @param opts {Object} Объект с коллбэками на success и always
   * @async
   * @private
   */
  _changePublishState: function(action, opts) {
    var url = action && (action === 'publish' || action === 'republish') ? this.PUBLISH_URL : this.UNPUBLISH_URL;

    this.publishXHR && this.publishXHR.abort();
    this.publishXHR = $.ajax({
      type: 'POST',
      url: url + this.getId(),
      success: function(res) {
        this.set('is_published', action && action !== 'unpublish');
        if (action === 'publish') {
          this.set('published', res.published || new Date().toISOString());
          this.set('updated', res.updated || new Date().toISOString());
          this.set('major_update', res.major_update || new Date().toISOString());
        }
        if (action === 'republish') this.set(_.extend(_.omit(res, 'user'), { updated: this.get('last_changed') }));

        this.set('coverPid', res.coverPid || this.get('coverPid'));
        this.set('cover', res.cover || this.get('cover'));

        if (typeof opts.success === 'function') opts.success();
      },
      error: function(xhr) {
        console.log('Error changing puplish state: ' + xhr.responseText);
        // __magterm
        this.onError(
          'A problem occurred while ' + action + 'ing ' + 'your project. Please, contact us: ' + Constants.SUPPORT_MAIL
        );
      },
      context: this,
    }).always(function() {
      if (typeof opts.always === 'function') opts.always();
    });
  },

  /**
   * Меняет состояние мэга на опубликовано
   * @method publishMag
   * @param opts {Object} Объект с коллбэками на success и always
   * @async
   */
  publishMag: function(opts) {
    this._changePublishState('publish', opts);
  },

  /**
   * Меняет состояние мэга на неопубликовано
   * @method inpublishMag
   * @param opts {Object} Объект с коллбэками на success и always
   * @async
   */
  unpublishMag: function(opts) {
    this._changePublishState('unpublish', opts);
  },

  /**
   * Переобубликовывает измененный опубликованный мэг
   * @method republishMag
   * @param opts {Object} Объект с коллбэками на success и always
   * @async
   */
  republishMag: function(opts) {
    this._changePublishState('republish', opts);
  },

  /**
   * Дублирует мэг
   * @method duplicateMag
   * @param opts {Object} Объект с коллбэками на success, error, always и т.п.
   * @async
   */
  duplicateMag: function(opts) {
    var self = this,
      duplicate_url = '/api/mag/' + this.get('num_id') + '/duplicate',
      ajax_opts;

    ajax_opts = {
      type: 'POST',
      url: duplicate_url,
      context: this,
    };
    _.extend(ajax_opts, opts);

    this.duplicateXHR && this.duplicateXHR.abort();
    this.duplicateXHR = $.ajax(ajax_opts);
  },

  hasUnsavedChanges: function() {
    return this.get('is_published') && this.get('changed');
  },

  onError: function(message) {
    alert(message);
  },
});
