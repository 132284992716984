/**
 * Виджет для аудио (soundcloud) (используется во вьювере)
 */
import WidgetClass from '../widget';
import { Utils } from '../../common/utils';
import AudioPlayer from '../../common/audio-player';

const AudioWidget = WidgetClass.extend({
  // достаточно версии прототипа
  // initialize: function(data, page) {},

  render: function() {
    this.makeBox('audio');

    // создаем плеер который сам знает как рендерится
    // первый параметр this передает плееру объект в котором находятся все нужные параметры (моделей во вьвере у нас нет)
    // в констркторе например при создании плеера передается this.model.toJSON() вместо this
    this.player = new AudioPlayer(this, this.$el, this.widgetIsLoaded);

    // убираем прозрачность у плеера (изначально он прозрачный, это поведение нужно для конструктора)
    this.player.show();

    this.rendered = true;

    return this;
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // метод должен вернуть true если с виджетом все ок и его надо показать во вьювере,
  // в противном случае виджета даже не будет создан
  isValid: function() {
    // если в виджете указан URL композиции
    return this.parsed_url;
  },

  start: function() {
    this.started = true;

    if (
      !RM.screenshot &&
      !this.destroyed &&
      this.player &&
      this.autoplay &&
      this.rendered &&
      this.loaded &&
      Modernizr.isdesktop &&
      !Utils.PageVisibilityManager.isPageHidden()
    ) {
      this.player.play();
    }

    return this;
  },

  stop: function() {
    this.started = false;

    if (!RM.screenshot && !this.destroyed && this.player && this.rendered && this.loaded) {
      this.player.pause();
    }

    return this;
  },

  destroy: function() {
    this.player && this.player.destroy();

    this.player = null;

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default AudioWidget;
