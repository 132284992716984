/**
 * Виджет для видео
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import $f from 'froogaloop';
import WidgetClass from '../widget';
import VideoUtils from '../../common/videoutils';
import { Utils } from '../../common/utils';

var _yt = function(callback, context) {
  var cb = _.bind(callback, context);

  if (window.youTubeApiIsReady) {
    return cb();
  } else {
    window.ytQueue.push({
      cb: cb,
      context: context,
    });
  }
};

/**
 * Плеер для ютюба.
 * Основан на frameAPI, которое вызывает глобальный метод  onYouTubeIframeAPIReady когда готово.
 * Поэтому почти все методы используют _yt
 */
var YouTubePlayer = function(iframe, options) {
  this.playerIsReady = false;
  this.queue = []; // внутренняя очередь готовности плеера, в отличие от апи эта очередь внутрення для каждого инстанса плеера

  _yt(function() {
    this.yt = new YT.Player(iframe.id, {
      events: {
        onReady: _.bind(function() {
          if (this.destroyed) return;

          this.playerIsReady = true;

          _.each(this.queue, function(item) {
            _.isFunction(item.cb) && item.cb();
          });
        }, this),
      },
    });
  }, this);

  this.start_time = options && options.start_time;
};

YouTubePlayer.prototype = {
  // по аналогии с глобальной очередью апи, локальная очередь готовности самого плеера (своя для каждого инстанса плеера)
  addToQueue: function(callback, context) {
    var cb = _.bind(callback, context);

    if (this.playerIsReady) {
      return cb();
    } else {
      // @TODO: MEMORY LEAK context > queue > context > queue > context > queue....
      this.queue.push({
        cb: cb,
        context: context,
      });
    }
  },

  on: function(event, callback, context) {
    // ready, finish, play, pause

    var cb = _.bind(callback, context || window);

    var ytStatuses = {
      finish: 0,
      play: 1,
      pause: 2,
    };

    if (event == 'ready') {
      this.addToQueue(cb, this);
      return;
    }

    this.addToQueue(function() {
      this.yt.addEventListener('onStateChange', function(status) {
        if (ytStatuses[event] == status.data) {
          cb();
        }
      });
    }, this);
  },

  mute: function() {
    this.addToQueue(function() {
      this.yt.mute();
    }, this);
  },

  unMute: function() {
    this.addToQueue(function() {
      this.yt.unMute();
    }, this);
  },

  pause: function() {
    this.addToQueue(function() {
      this.yt && this.yt.pauseVideo && this.yt.pauseVideo();
    }, this);
  },

  play: function() {
    this.addToQueue(function() {
      this.yt.playVideo();
    }, this);
  },

  rewind: function(time) {
    this.addToQueue(function() {
      this.yt.seekTo(time || 0);
    }, this);
  },

  loop: function() {
    this.rewind(this.start_time || 0.01);
  },

  getDuration: function() {
    if (!this.duration) this.duration = this.yt.getDuration();
    return this.duration;
  },

  getCurrentTime: function() {
    return (this.yt.getCurrentTime && this.yt.getCurrentTime()) || 0;
  },

  checkLoop: function() {
    if (this.getDuration() - this.yt.getCurrentTime() < 0.5) {
      this.loop();
      return true;
    }
  },

  destroy: function() {
    window.ytQueue = _.reject(window.ytQueue, function(item) {
      return item.context == this;
    }); // Вычищаем свои коллбэки из общей очереди
    delete this.yt;
    delete this.queue;
    this.destroyed = true;
  },
};

/**
 *  Плеер для вимео. Основан на Froogaloop
 */
var VimeoPlayer = function(iframe, options) {
  // this.$f = $f(iframe);

  _.bindAll(this);

  this.id = iframe.id;

  this.start_time = options && options.start_time;
};

VimeoPlayer.prototype = {
  on: function(event, callback, context) {
    // ready, finish, play, pause
    // var cb = _.bind(callback, context || window);
    $f(this.id).addEvent(event, callback);
  },

  mute: function() {
    $f(this.id).api('setVolume', 0);
  },

  unMute: function() {
    return false; // ничего не делаем, unmute вроде бы не нужен для vimeo
  },

  pause: function() {
    $f(this.id).api('pause');
  },

  play: function() {
    $f(this.id).api('play');
  },

  rewind: function(time) {
    $f(this.id).api('seekTo', time || 0);
  },

  loop: function() {
    if (this.start_time) {
      this.rewind(this.start_time);
    }
  },

  getCurrentTime: function(cb) {
    $f(this.id).api('getCurrentTime', cb);
  },

  setColor: function(color) {
    $f(this.id).api('setColor', color);
  },

  destroy: function() {
    var $player = $f(this.id);

    if (!$player) return;

    $player.removeEvent('ready');
    $player.removeEvent('play');
    $player.removeEvent('finish');
    $player.removeEvent('pause');
  },
};

var _player_templates = {
  // Чтобы видео могло воспроизводиться в фоне на мобилках, нужно чтобы оно соответствовало нескольким требованиям:
  // - youtube playsinline=1
  // - ? vimeo background=1 - должно быть так, но до сих пор не работает :(
  Vimeo: _.template(
    '<iframe' +
      ' src="https://player.vimeo.com/video/<%=video_id%>?wmode=opaque&api=1&loop=<%=(loop)?1:0%>&muted=<%=(mute)?1:0%>&player_id=video_<%=id%><%=(!real)?"_c":""%>&title=<%=info?1:0%>&byline=<%=info?1:0%>&portrait=<%=info?1:0%>&color=<%=color%>" id="video_<%=id%><%=(!real)?"_c":""%>" width="<%=w%>" height="<%=h%>" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen allow="autoplay; fullscreen; encrypted-media"></iframe>'
  ),

  // Для src нужно обязательно прописывать https:, иначе при работе с не-https серверами (локал, work)
  // будет возникать ошибка Unable to post message to http://www.youtube.com. Recipient has origin https://www.youtube.com.
  // Проблема возникла с 11.12.2013
  //
  // К параметрам ембеда теперь обязательно нужно добавлять origin, равный <протокол+хост> клиента
  // Проблема возникла с 11.12.2013

  // Убран origin, т.к. теперь на не-https работает только без него (30.04.2014)
  YouTube: _.template(
    '<iframe <% if (real) {%>id="yt_<%=video_id%>"<%}%>' +
      ' src="https://www.youtube.com/embed/<%=video_id%>?&wmode=opaque&enablejsapi=1&playlist=&autohide=1&loop=<%=(loop)?1:0%>&showinfo=<%=info?1:0%>&theme=<%=theme%>&controls=<%=controls? 1: 0%><%=html5 ? "&html5=1" : ""%>&rel=0&vq=hd1080&playsinline=<%=(autoplay)?1:0%>" width="<%=w%>" height="<%=h%>" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen allow="autoplay; fullscreen; encrypted-media"></iframe>'
  ),
};

const VideoWidget = WidgetClass.extend({
  RESTART_TIMEOUT: 5000,

  initialize: function(data, page, $container) {
    WidgetClass.prototype.initialize.apply(this, arguments);

    this.$container = $container;

    // real == true - признак того, что плейер работает в режиме вьювера, а не конструктора
    // В результате бага он сохранился в данных некоторых виджетов, поэтому здесь мы его удаляем
    // Реальное его значение передается в вызове функции getEmbed
    delete this.real;

    return this;
  },

  render: function() {
    // наличие контейнера - признак того что этот виджет создан из фонового виджета и используетяв качестве видео фона
    // исторически так сложилось, не буду править
    if (!this.$container) {
      this.makeBox('video');
    } else {
      this.setElement(this.$container);
    }

    this.rendered = true;

    // изначально проставляем постер пока плеера нет
    // именно постер генерирует событие loaded
    // сам плеер в этом не участвует, но для скриншотера будет работать как надо
    // а для самого вьювера будет не очень корректно, но не критично (просто по-другому писать - полный треш)
    this.setPosterInsteadOfVideo();

    return this;
  },

  isValid: function() {
    return this.video_id && this.provider_name;
  },

  _needAutoStart: function() {
    return this.autoplay && !Utils.PageVisibilityManager.isPageHidden();
  },

  start: function() {
    this.started = true;

    this.onPlayFired = false;

    if (RM.screenshot || !this.rendered || this.destroyed) {
      return this;
    }

    // если плеер еще не создавали, то создаем его
    if (!this.playerInitialized) {
      // создаем плеер при старте чтобы не мешать анимациям перехода
      // транзишены сильно тупят если при это рендерится видео (а ютуб не рендерится если виджет в данный момент не виден)
      this.$el.append(this.getEmbed()); // вместо html(..) чтобы не перезатирать постер который теперь не background-image пмо на виджете, а отдельный img внутри перед ифреймом

      this.$iframe = this.$el.find('iframe');
      this.$iframe.attr('id', 'video_' + this.id);

      this.player = this.createPlayer(this.provider_name, this.$iframe, {
        start_time: this.start_time,
        autoplay: this._needAutoStart(),
      });

      this.player && this.player.on('ready', this.onPlayerReady);

      this.playerInitialized = true;
    }

    // для вимео плеер создается мгновенно, поскольку его либа froogaloop интегрирована в РМ
    // для ютуба все команды плееру идут в очередь, пока плеер не будет создан
    // и потом скопом исполняются
    // поэтому с плеером можно работать сразу после его создания (в плане вызова start-stop по крайней мере)
    if (this.player && this._needAutoStart()) {
      this.player.play();
    }

    return this;
  },

  stop: function() {
    this.started = false;

    clearInterval(this.loopInterval);

    if (!RM.screenshot && !this.destroyed && this.player && this.rendered) {
      if (this.provider_name.toLowerCase() === 'youtube') {
        // Паузим только если действительно начали проигрывание,
        // иначе пропадает тумбнейл

        // так делать нельзя, плеер могли запустить, и уйти на другую страницу
        // при этом прогресс будет все еще 0 и видео не запаузится
        // https://trello.com/c/6G75BlU3/173--
        /* if (this.player.getCurrentTime() > 0) { this.player.pause(); }*/
        // upd. и так тоже нельзя, при старте onPlay выстреливает все же несколько позднее
        /* if (this.onPlayFired) { this.player.pause(); }*/

        // если есть автозапуск, тогда точно надо стопить
        if (this._needAutoStart()) {
          this.player.pause();
        }

        // если нет автозапуска, тогда стопить надо если был ручной запуск
        if (!this._needAutoStart() && this.onPlayFired) {
          this.player.pause();
        }
      } else {
        this.player.pause();
      }
    }

    return this;
  },

  destroy: function() {
    clearInterval(this.loopInterval);

    this.player && this.player.destroy();

    this.player = null;

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },

  // Функция устанавливает постер видео до тех пор, пока видео не загрузится.
  // В скриншотере и в мобильной версии видео-фона так там и остается, поскольку плеер не создаетя в дальнейшем
  setPosterInsteadOfVideo: function() {
    if (!this.custom_thumbnail && !this.thumbnail_url) {
      this.widgetIsLoaded();
      return;
    }

    // если задан, то показываем кастомный постер
    if (this.custom_thumbnail && this.custom_thumbnail_url) {
      this.setCustomPosterInsteadOfVideo();
    } else {
      this.setEmbeddedPosterInsteadOfVideo();
    }
  },

  // стандартное превью видео, взятое с видеохостинга
  setEmbeddedPosterInsteadOfVideo: function() {
    this.$poster && this.$poster.remove();
    this.$poster && this.$poster.off();

    this.$poster = $('<img/>')
      .addClass('poster')
      .css('opacity', 0)
      .appendTo(this.$el);

    this.$poster
      .on(
        'load',
        _.bind(function() {
          // сбрасываем стили width и height
          // они могут проставиться в функии реагирующей на ресайз до того как картинка загрузится
          // это происходит например при переключении вьюпортов в превью
          this.$poster.css({ width: '', height: '' }).css('opacity', 1);

          var w = this.$poster[0].width,
            h = this.$poster[0].height;

          // для старых видео сохраняем aspect_poster
          // он нам еще пригодится в onresize бэкграунд виджета
          if (!this.aspect_poster) this.aspect_poster = w / h;

          VideoUtils.setVideoPosition({
            mag: this.page.mag,
            $container: this.$el,
            $media: this.$poster,
            provider: this.provider_name,
            aspect_poster: this.aspect_poster,
            aspect_real: this.aspect_real,
            controls_remove: !!this.$container, // если видео не в режиме БГ тогда нам не надо позиционировать постер так чтобы он совпадал по положению с видео у которого отрезаны контролы и реклама
          });

          setTimeout(this.widgetIsLoaded, 100);
        }, this)
      )
      .on(
        'error',
        _.bind(function() {
          this.oldUrl = this.thumbnail_url;
          this.thumbnail_url = this.thumbnail_url.replace('https://', 'http://');

          // если это была вторая попытка (т.е. ни с https ни с http постер не загрузился)
          if (this.oldUrl === this.thumbnail_url) {
            this.widgetIsLoaded();
            return;
          }

          // пробуем теперь загрузить постер с http
          this.setEmbeddedPosterInsteadOfVideo();
        }, this)
      )
      .attr('src', this.thumbnail_url);
  },

  // кастомное превью для видео, которое загрузил сам пользователь
  // и которое будет оставаться видимым, пока на него не кликнут
  setCustomPosterInsteadOfVideo: function() {
    this.$poster && this.$poster.remove();
    this.$poster && this.$poster.off();

    this.$poster = this.getCustomVideoPoster(this);
    this.$poster.css('cursor', 'pointer');
    this.$poster.on(
      'click',
      _.bind(function() {
        this.onPosterClicked();
      }, this)
    );

    this.$poster.appendTo(this.$el);
    setTimeout(this.widgetIsLoaded, 100);
  },

  // кастомный постер (превью) для видео с иконкой play
  getCustomVideoPoster: function(data) {
    var icon = '/img/common/video-player/play.svg';
    var $poster = $('<div/>').addClass('poster poster-custom');

    $poster.css({ width: data.w, height: data.h });
    $poster.css('background-image', 'url(' + icon + '), url(' + data.custom_thumbnail_url + ')');

    return $poster;
  },

  // в случае кастомного видео превью по клику на него запустим видео
  // и через небольшую паузу уберем постер
  onPosterClicked: function() {
    this.player.play();

    _.delay(
      _.bind(function() {
        if (this.$poster) {
          this.$poster.off();
          this.$poster.remove();
        }
      }, this),
      200
    );
  },

  // Создает Плеер. Плееры для обоих сервисов должны реализовывать одинаковый интерфейс
  createPlayer: function(type, iframe, options) {
    if (type.toLowerCase() === 'vimeo') {
      return new VimeoPlayer(iframe[0], options);
    }

    if (type.toLowerCase() === 'youtube') {
      return new YouTubePlayer(iframe[0], options);
    }

    return null;
  },

  // Метод используется также в конструкторе
  getEmbed: function(data) {
    data = data || this;
    _.defaults(data, {
      real: true,
      w: 640,
      h: 320,
      theme: 'dark',
      color: '3ab9ff',
      info: false,
      controls: true,
      id: _.uniqueId('video_'),
      origin: encodeURIComponent(window.location.protocol + '//' + window.location.hostname), // Обязательно для параметров ембеда youtube
    });

    // Для сафари всегда отдаем html5 плейер, т.к. он стал глушить автостарт флэша (https://trello.com/c/oe1RtMtP/207-bg)
    if (Modernizr.safari) {
      data.html5 = true;
    }

    if (!data.video_id) return null;

    var videoPlayer = _player_templates[data.provider_name](data);

    // если есть кастомный кавер, вернем его для конструктора,
    // а для виджета в режиме просмотра кастомный кавер
    // обрабатывается в setPosterInsteadOfVideo()
    if (!data.real && data.custom_thumbnail && data.custom_thumbnail_url) {
      var $iframe = $(videoPlayer).css('visibility', 'hidden');
      return this.getCustomVideoPoster(data).add($iframe);
    }

    return _player_templates[data.provider_name](data);
  },

  onPlayerReady: function() {
    if (this.destroyed || !this.player) {
      return;
    }

    // If the video's sound is on (mute: false), we need to turn it off
    if (this.$container) {
      this.mute = true;
    }

    if (this.mute) {
      this.player.mute();
    } else {
      this.player.unMute();
    }

    // для обычного (не бг видео) деалем его ифрейм видимым сразу после его загрузки (а до тех пор видим постер)
    if (!this.$container) {
      this.$iframe && this.$iframe.addClass('fade-out');

      // для не кастомного превью видео подгружаем и показываем его
      // до тех пор, пока не загрузится видео в ифрейме
      if (!this.custom_thumbnail || !this.custom_thumbnail_url) {
        // vimeo iframe имеет 125 мс opacity fade-out после прогрузки, дожидаемся его отработки
        // и только тогда скрываем свой постер, чтобы избежать неприятного мигания картинки
        _.delay(
          _.bind(function() {
            if (this.$poster) {
              this.$poster.css('opacity', 0);
            }
          }, this),
          125
        );

        // после этого постер можно совсем удалить
        _.delay(
          _.bind(function() {
            if (this.$poster) {
              this.$poster.off();
              this.$poster.remove();
            }
          }, this),
          250
        );
      }
    }

    this.player.on('pause', this.onPause);
    this.player.on('play', this.onPlay);

    if (this.start_time) {
      this.player.rewind(this.start_time);
      this.player.pause(); // Перемотка на нужное время почему-то автоматически запускает плейер
    }

    if (Modernizr.isdesktop) {
      this.provider_name.toLowerCase() === 'vimeo' && this.player.on('playProgress', this.onProgress);
    }

    if (this.started && this._needAutoStart()) {
      this.player.play();
    }

    this.player.setColor && this.player.setColor(this.color);
  },

  onPlay: function() {
    // Отправляем событие аналитики
    if (!this.$container && RM.analytics) {
      RM.analytics.sendEvent('Video Play', this.title + ' / ' + this.url);
    }

    if (this.$iframe && !this.$iframe.hasClass('fade-out')) {
      this.$iframe.addClass('fade-out');
      this.trigger('playStarted');
    }

    // Обработка loop для Youtube
    clearInterval(this.loopInterval);

    if (this.loop && this.player.checkLoop) {
      this.loopInterval = setInterval(
        _.bind(function() {
          var looped = this.player.checkLoop();
          if (looped) {
            clearInterval(this.loopInterval);

            // В сафари при перемещении на начало файла,
            // ютуб плейер не выстреливает onplay. Из-за этого не очищается
            // и не переинициализируется интервал проверки на конец видео
            // Приходится запускать вручную
            if (Modernizr.safari) {
              _.delay(
                function() {
                  this.onPlay();
                }.bind(this),
                200
              );
            }
          }
        }, this),
        100
      );
    }

    this.onPlayFired = true;
  },

  onPause: function() {
    // Отправляем событие аналитики
    if (this.provider_name === 'Vimeo') {
      this.player.getCurrentTime(
        _.bind(function(ct) {
          // Отправляем событие аналитики
          var ct = Math.round(ct);
          if (!this.$container && RM.analytics)
            RM.analytics.sendEvent('Video Pause', this.title + ' / ' + this.url + ' / ' + ct + ' sec.', ct);
        }, this)
      );
    } else if (this.provider_name === 'YouTube') {
      var ct = Math.round(this.player.getCurrentTime());
      if (!this.$container && RM.analytics)
        RM.analytics.sendEvent('Video Pause', this.title + ' / ' + this.url + ' / ' + ct + ' sec.', ct);
    }
  },

  // Рестарт, если запуск зафейлился непонятно по какой причине
  onProgress: function(progressData, id) {
    // фикс бага в IE, когда прогресс может пойти а onPlay еще не выстрелил
    // https://trello.com/c/SbWYrJ0U/70-2-ie
    if (!this.onPlayFired) {
      this.onPlay();
    }

    // Обработка старта ролика не сначала в комплексе с loop для Vimeo
    if (progressData.percent === 1 && this.loop && this.player.loop) {
      // Если доиграли до конца (сейчас сработает loop на начало), то
      // перематываем на start_time
      this.player.loop();
    }

    if (parseFloat(progressData.seconds) === 0.0) {
      if (!this.restartTimeout) {
        this.restartTimeout = setTimeout(
          _.bind(function() {
            clearInterval(this.loopInterval);

            this.playerInitialized = false;

            this.player && this.player.destroy();
            this.player = null;

            this.$iframe && this.$iframe.remove();

            !this.destroyed && this.started && this.start();

            delete this.restartTimeout;
          }, this),
          this.RESTART_TIMEOUT
        );
      }
    } else {
      clearTimeout(this.restartTimeout);
      delete this.restartTimeout;
    }
  },

  /**
   * Изменение размеров айфрейма с видео
   */
  updateVideoFrameSize: function(w, h) {
    this.$iframe && this.$iframe.attr('width', w).attr('height', h);
    this.$poster && this.$poster.css({ width: w, height: h });
  },
});

export default VideoWidget;
