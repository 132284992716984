import Mag from '../viewer/mag';

const Scale = {
  /**
   * В зависимости от того, включен ли scale и используется ли css zoom, возвращает бокс элемента с поправкой на scale или как есть
   * @param {Object} element
   * @returns {Object}
   */
  getNormalizedBox: function(element) {
    return Scale.isOn() && Scale.isZoom()
      ? Scale.getBox(element, Scale.getRatio())
      : element && element.getBoundingClientRect();
  },
  /**
   * Возвращает результаты getBoundingClientRect с учётом масштаба ratio
   * @param {Object} element
   * @param {Number} [ratio]
   */
  getBox: function(element, ratio) {
    // Результат getBoundingClientRect() нужно корректировать только для зума. При скейле всё возвращается правильно.
    ratio = ratio || 1;
    var dimensions = element ? element.getBoundingClientRect() : {};
    return {
      top: dimensions.top * ratio,
      left: dimensions.left * ratio,
      width: dimensions.width * ratio,
      height: dimensions.height * ratio,
      bottom: dimensions.bottom * ratio,
      right: dimensions.right * ratio,
    };
  },

  /**
   * Возвращает масштаб
   * @param {Number} defaultWidth Ширина, которая принята за 1 (сейчас это 1024)
   * @param {Number} actualWidth Текущая ширина окна
   * @returns {Number}
   */
  getRatio: function(defaultWidth, actualWidth) {
    defaultWidth = defaultWidth || Mag.prototype.getViewportSetting('width', 'default');
    actualWidth = actualWidth || window.innerWidth;
    return actualWidth / defaultWidth;
  },

  /**
   * Возвращает мэг
   * @returns {Mag}
   */
  getMag: function() {
    return (
      (RM.viewerRouter && RM.viewerRouter.mag) ||
      (RM.constructorRouter && RM.constructorRouter.preview && RM.constructorRouter.preview.mag) ||
      {}
    );
  },

  /**
   * Опредляет, разрешён ли scalable
   * @returns {boolean}
   */
  isAllowed: function() {
    var mag = Scale.getMag();
    var opts = mag.opts || {};
    return Scale.isDesktopDefault() && opts.scalableviewer;
  },

  /**
   * Опредляет, включен / активен ли scalable. Scalable может быть разрешён. но он активен только если window.innerWidth >= 1024
   * @returns {boolean}
   */
  isOn: function(ratio) {
    ratio = ratio || Scale.getRatio();
    return Scale.isAllowed() && ratio > 1;
  },

  /**
   * Определяет, используется ли для зума css zoom (для всех браузеров кроме FF)
   * @returns {boolean}
   */
  isZoom: function() {
    return !Modernizr.firefox;
  },

  /**
   * Определяет, используется ли для зума css transform (в FF)
   * @returns {*}
   */
  isTransform: function() {
    return Modernizr.firefox;
  },

  /**
   * Определяет, что это десктоп и дефолтный вьюпорт
   * @returns {boolean}
   */
  isDesktopDefault: function() {
    return Modernizr.isdesktop && Scale.getMag().viewport === 'default';
  },

  /**
   * Нормализует масштаб: возвращает без изменений на мобильных, приводит к 1 в зависимости от того, включен ли scaleable или нет
   * @param scale Масштаб
   * @param {String} method
   * @returns {Number} transform|zomm
   */
  normalize: function(scale, method) {
    // Почти 1
    // проверка с определенной точностью, плавающая запятая как-никак
    if (Math.abs(scale - 1) < 0.000001) {
      return 1;
      // На десктопе в дефолтном режиме проверим, разрешён ли скейл.
      // (десктоп может работать и в режиме мобильного вьюпорта в режиме превью)
    } else if (Scale.isDesktopDefault()) {
      var matchesMethod =
        !method || (method === 'zoom' && Scale.isZoom()) || (method === 'transform' && Scale.isTransform());
      return Scale.isAllowed() && matchesMethod ? Math.max(1, scale) : 1;
      // В остальных случаях (мобильные или превью мобильных на десктопе) — без изменений. scale может быть > 1 и < 1
    } else {
      return scale;
    }
  },
};

export default Scale;
