/**
 * Класс для мэга
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/viewer/mag.tpl';
import { UserMagModel } from '../constructor/models/user-mag';
import { Constants, Utils } from '../common/utils';
import TextUtils from '../common/textutils';
import InitUtils from '../common/init-utils';
import EcommerceManagerClass from '../common/e-commerce-manager';
import CartSidebarClass from './cart-sidebar';
import ShareUtils from '../libs/shareutils';
import GlobalWidgets from '../common/global-widget';
import AnimationUtils from '../common/animationutils';
import Scale from '../common/scale';
import Page from './page';
import Viewports from '../common/viewports';
import Widgets from './widgets';
import Animations from './animations';
import NavigationClass from './navigation';
import ToolbarClass from './toolbar';
import BrandingClass from './branding';
import FinalPageClass from './final-page';
import dateFormat from '../vendor/date.format';

const MagClass = Backbone.View.extend({
  TYPEKIT_PREVIEW_API_LINK: '//use.typekit.net/previewkits/pk-v1.js',

  PRELOAD_COUNT_HORIZONTAL: {
    backward: 1,
    forward: Modernizr.isdesktop ? 5 : Modernizr.isphone ? 3 : 2,
  },

  PRELOAD_COUNT_VERTICAL: {
    backward: Modernizr.isdesktop ? 2000 : Modernizr.isphone ? 1000 : 1000,
    forward: Modernizr.isdesktop ? 3000 : Modernizr.isphone ? 2000 : 2000,
  },

  // на какую высоту контент страницы должен быть больше высоты окна
  // чтобы считаться что у страницы есть "значимый скрол",
  // т.е. не просто "немного не уместилось", а еще остался контент до которого стоит поскролить
  // задается на глазок
  SCROLLABLE_TRESHOLD: 100,

  // это бонус к размеру страницы, чтобы при вертикальном скроле была небольшая пауза когда полностью отскролилась текущая страница и начала скролится следующая
  // использется на десктопе в режиме вертикального вьювера
  // SCROLL_SNAP: 220,
  // UPD: 12.10.2017 фича отключена как вредная, создающая ощущение рваного вертикального скролла
  SCROLL_SNAP: 0,

  template: templates['template-viewer-mag'],

  initialize: function(magData, router) {
    _.bindAll(this);

    if (!magData) magData = {};

    _.defaults(magData, {
      // pages: {}
      pages: [], // ?
    });

    this.hasWidgetsWithVerticalOnscrollAnimation = false;

    var allGlobalWidgets = _(magData.pages)
      .chain()
      .reduce(function(wids, p) {
        return wids.concat(p.wids);
      }, [])
      .each(
        function(w) {
          // Заодно проверим есть ли в проекте виджеты с вертикальной onscroll анимацией
          if (
            w.animation &&
            w.animation.type === 'scroll' &&
            w.animation.steps &&
            _.any(
              w.animation.steps,
              function(step) {
                return step.dy;
              }.bind(this)
            )
          ) {
            this.hasWidgetsWithVerticalOnscrollAnimation = true;
          }
        }.bind(this)
      )
      .filter({ is_global: true })
      .value();
    // Обычные глобальные виджеты. Существуют в рамках страницы.
    this.staticGlobalWidgetsData = _(allGlobalWidgets).filter(function(gw) {
      return !gw.is_above;
    });

    // Отвязанные глобальные виджеты. Существуют вне страниц.
    this.aboveGlobalWidgetsData = _(allGlobalWidgets).filter(function(gw) {
      return gw.is_above;
    });
    var aboveGlobalWidgetIds = _.pluck(this.aboveGlobalWidgetsData, '_id');

    // Добавляем в данные страниц статические глобальные виджеты. Кроме страницы, к которой принадлежит
    // сам глобальный виджет
    // А отвязанные напротив - вычищаем. Будем рендерить их отдельно
    _(magData.pages).each(
      function(p) {
        p.wids = p.wids.concat(
          _.reject(this.staticGlobalWidgetsData, function(gw) {
            return gw.pid === p._id;
          })
        );
        // У некоторых виджетов в старых проектах во вьюере нет _id. Для них вернём случайное число, иначе эти виджеты вообще уберутся из wids
        p.wids = _.unique(p.wids, function(w) {
          return w._id || Math.random();
        });
        p.wids = _(p.wids).filter(function(w) {
          return !_.contains(aboveGlobalWidgetIds, w._id);
        });
      }.bind(this)
    );

    // this.aboveGlobalWidgets = _.map(aboveGlobalWidgets, function(widgetData) { return new Widgets[widgetViewportData.type](widgetViewportData, page); })

    // Взять параметры мэга
    _.extend(this, magData);

    this.model = new UserMagModel(magData, { parse: true });

    this.router = router;

    this.eCommerceManager = new EcommerceManagerClass(this.router, this, this.router.environment);

    this.cartSidebar = new CartSidebarClass(this);

    this.isPreview = router.isPreview;

    this.$container = router.$mags_container;

    // самая черная магия, да простят меня те кто будет поддерживать этот код после меня
    // был баг что некоторые мэги на бою у Антона очень плохо скролятся,
    // но не все мэги, и не везде, а только при определенных условиях:
    // 1. меню отключено
    // 2. вертикальный режим вьювера
    // 3. скрол страница
    // путем долгих поисков было установлено, что все становится хорошо если меню создано,
    // копая глубже был найден "виновник" того что все работает как надо:
    // это плагин jquery.rmmousewheel.js (указал его здесь чтобы потом, если надумают его выкашивать, нашли поиском этот коммент)
    // если он навешен хоть где-либо, хоть на что-либо и даже если они никогда не вызывается
    // то все становится хорошо, разбираться дальше не стал, сделал так чтобы работало, а после нас хоть потоп
    // https://trello.com/c/TadMOIPL/263--
    if (Modernizr.isdesktop) {
      $('<div>').bind('mousewheel', function() {});
    }

    this.setViewerOptions(this.getMagViewport());

    this.continueLoading.__debounced = _.debounce(this.continueLoading, 200);

    this.loadPages.__debounced = _.debounce(this.loadPages, 700);

    // лишний раз вызывать смысла нет,
    // не и потому что на айфоне неправильно выдается window.innerWidth сразу по окончанию тача
    // и во время пинч-зума, он выдает неправильные значения. Только на iPhone. iPad - все ок.
    // 300 это время анимации из самого маленького скейла в исходны когда отскейлили в мелкую картинку а потом отпустили пальцы
    this.onResize.__debounced = _.debounce(this.onResize, 300);

    this.redrawAboveGlobalWidgets.__debounced = _.debounce(this.redrawAboveGlobalWidgets, 500, true);

    // Счётчик загруженных пачек виджетов
    this.widgetPackCount = 0;

    // Сделаем мемуаизацию для функции, которая часто вызывается при скролле
    this.getAboveGlobalBoxMemoized = _.memoize(this.getAboveGlobalBox, function(widgetId, page, containerBox) {
      return (
        widgetId +
        ' ' +
        (page && page.num) +
        ' ' +
        (page && page.pageViewport) +
        ' ' +
        (containerBox && containerBox.width) +
        ' ' +
        (containerBox && containerBox.height)
      );
    });

    return this;
  },

  setViewerOptions: function(viewport) {
    var viewOpts = {
      arrows: true,
      menubutton: true,
      projectinfo: true,
      sharebutton: true,
      pagecounter: true,
      viewertype: 'vertical',
      slidein: false,
      scalewidth: 3600,
      endpage: true,
      endpagetype: 'join',
    };

    // Переопределяем дефолты реальными настройками мэга
    viewOpts = _.extend(viewOpts, this.model.get('opts'));

    // для скринотера всегда режим горизонтального вьювера
    // чтобы не думать то там может быть не так
    // под горизонтальным вьювером все работает как надо, пусть так и будет, проверять все заново под вертикальным видом не охота
    viewOpts.viewertype = RM.screenshot ? 'horizontal' : viewOpts.viewertype;

    // режим с наложением страниц доступен только для десктопа (а для режима превью только для дефолтного вьюпорта)
    if (!Modernizr.isdesktop || (this.isPreview && viewport !== 'default')) {
      viewOpts.slidein = false;
    }

    if (viewOpts.viewertype === 'vertical') {
      // Считаем, что десктоп всегда поддерживает стики. Для IE, Edge подключится полифил
      // Если включен слайд-ин - показываем старый вьювер, если выключен - стики вьювер
      if (Modernizr.isdesktop) {
        this.isStickyVerticalViewer =
          !viewOpts.slidein &&
          !this.isPreview && // TODO: В превью из-за сложностей верстки пока всегда показываем старый вьювер. Исправить.
          !this.hasWidgetsWithVerticalOnscrollAnimation; // Если в проекте есть вертикальные онскролл анимации, вырубаем стики виьювер, иначе у анимации будут скачки
      } else {
        this.isStickyVerticalViewer = Modernizr.csspositionsticky;

        // если девайсы не поддерживают стики, тогда показываем обычный горизонтальный вьювер (это ios5, и андроид на хроме)
        if (!this.isStickyVerticalViewer) {
          viewOpts.viewertype = 'horizontal';
          // а также обязательно включаем навигационные стрелки (и соответственно свайп страниц), иначе мы никак не сможем менять страницы
          // https://trello.com/c/19WkLAym/168--
          if (this.pages.length > 1) {
            viewOpts.arrows = true;
          }
        }
      }
    }

    // Допольнительные ограничения на настройки в зависимости от ситуации
    viewOpts.sharebutton = viewOpts.sharebutton && !this.isPreview && !!this.published && !this.is_private; // с изначальным получением залогиненого юзера проблемы, пусть вообще никто не видит для приватного
    // (!this.is_private || this.user.isMe); //если мэг приватный то только автор увидит панельку шера

    viewOpts.pagecounter = viewOpts.pagecounter && !this.isPreview && this.pages.length > 1;

    viewOpts.endpage = viewOpts.endpage && !this.isPreview && !!this.published;

    // для юзера hello (под которым идут все рекламные лэндинги) убираем меню, мини-меню и энд пэйдж (стрелки оставляем)
    if (this.model.user && this.model.user.get && this.model.user.get('_id') == '540dc7a3a5c9259a383b910b') {
      viewOpts = _.extend(viewOpts, {
        arrows: true,
        menubutton: this.isPreview,
        sharebutton: false,
        pagecounter: false,
        endpage: false,
      });
    }

    if (RM.common.isDomainViewer) {
      viewOpts.endpage = false;
    }

    this.viewOpts = viewOpts;

    this.PRELOAD_COUNT =
      this.viewOpts.viewertype == 'horizontal' ? this.PRELOAD_COUNT_HORIZONTAL : this.PRELOAD_COUNT_VERTICAL;
  },

  setViewerClasses: function() {
    this.$el
      .removeClass('viewer-type-horizontal viewer-type-vertical')
      .addClass('viewer-type-' + this.viewOpts.viewertype)
      .removeClass('pages-pos-normal pages-pos-overlap')
      .addClass('pages-pos-' + (this.viewOpts.slidein ? 'overlap' : 'normal'));

    if (this.viewOpts.viewertype == 'vertical') {
      $('html').addClass('overflow-on-vertical-view');

      if (this.isStickyVerticalViewer) {
        this.$el.addClass('viewer-type-vertical-sticky');
        $('body').addClass('overflow-on-vertical-view');
      }
    }
  },

  unsetViewerClasses: function() {
    this.$el
      .removeClass('viewer-type-horizontal viewer-type-vertical')
      .removeClass('pages-pos-normal pages-pos-overlap');

    if (this.viewOpts.viewertype == 'vertical') {
      $('html').removeClass('overflow-on-vertical-view');

      this.$magScrollSizer.css('height', '');

      if (this.isStickyVerticalViewer) {
        this.$el.removeClass('viewer-type-vertical-sticky');
        $('body').removeClass('overflow-on-vertical-view');
      }
    }
  },

  render: function() {
    if (this.active) return;

    // __magterm
    this.title = this.title || 'Project';

    this.magOpenTime = new Date();

    // создаем вьюху стандартым методом бекбона:
    // устанавливаем el, $el и делегацию событий из списка events
    this.setElement(
      this.template({
        hasStandaloneBottomArrow: !this.isPreview && this.viewOpts.viewertype == 'vertical' && this.viewOpts.arrows,
        isPreview: !!this.isPreview,
      })
    );

    this.$el.appendTo(this.$container);

    this.setViewerClasses();

    // случаем события по линкам на боди, а не врнутри контейнера мэга
    // потому что у нас есть попапы (например в хотспоте ифона)
    // которые рендерятся снаружи контейнера
    $('body')
      .on('click', 'a.external-link', this.toExternalLink)
      .on('click', '.maglink.back-to-top', this.backToTopCurrentPage)
      .on('click', '.maglink', this.maglink)
      .on('click', '.goback-link', this.onGobackLinkClick)
      .on('click', '.mailchimp-link', this.onMailchimpLinkClick)
      .on('click', '.anchor-link', this.onAnchorLinkClick)
      .on('click', '.share-link', this.onShareLinkClick);

    this.$mag_container = this.$('.mag-pages-container');

    // Защита от смещения браузером контента страницы
    // при фокусе на какой-нибудь элемент
    // Например, внутри ифрейма может установиться фокус на инпут.
    // https://trello.com/c/1ewvfB8I/175--
    this.$mag_container.on('scroll', this.fixFocusScroll);

    this.$mag_viewport_device = this.$('.mag-pages-viewport-device'); // картинка айфона или ипада вокруг облати страниц, только для режима превью конструктора

    this.$pages_container = this.$('.mag-pages-container .container');

    this.$pages_blackout = this.$pages_container.find('.blackout');

    this.$aboveWidgetsContainer = this.$('.above-pages-container');

    if (this.viewOpts.viewertype == 'vertical') {
      // объект который является скрол-контейнером, т.е. у которого можно задачать и считывать ScrollTop
      this.$magScrollContainer = $(window);

      // объект который задает высоту скрола
      this.$magScrollSizer = $('body');

      // для стики режима важно чтобюы и сам .mag был размеров в сумму всех страниц
      // потому что в нем есть mag-pages-container который имеет черный фон и он должен быть высотой с весь мэг
      if (this.isStickyVerticalViewer) this.$magScrollSizer = $('body').add(this.$el);

      // объект на который надо навешивать обработчик скрола
      this.$magScrollHandler = $(window);

      // обхект через который моэно анимировать скролл
      this.$magScrollAnimate = $('body, html'); // http://stackoverflow.com/questions/8149155/animate-scrolltop-not-working-in-firefox
    }

    // только для вертикального режима
    // отдельная нижняя стрелка прокрутки
    if (this.viewOpts.viewertype == 'vertical') {
      this.$bottomArrow = this.$('.navigation-arrow.bottom');
      this.$bottomArrow.one('click', this.scrollMagALittle);
    }

    this.active = true;

    // какого-то хрена данные для страниц назвали также как и сами вьюхи эти страниц
    // из-за этого нижеслудующий switchToViewport падает (а вместе с ним и onResize)
    // поэтому тут такой костыль
    var pagesData = this.pages;
    this.pages = null;

    // сразу определяем с каким вьпортом хотим показывать мэг, еще до создания страниц
    this.viewport = null; // сбрасываем на всякий случай, если вдруг в данных мэга будет проставлен это поле
    this.switchToViewport(this.getMagViewport());

    // обязательно просим мэг пересчитать свои новые размеры c учетом нового вьюпорта
    this.onResize();

    // фикс бага когда иногда при загрузке мэга сразу в лэндскейп режиме
    // высота контейнера была слишком большой (только ифон 6+ когда видны табы)
    if (!Modernizr.isdesktop) {
      setTimeout(this.onResize, 1500);
    }

    this.pages = _(pagesData)
      .chain()
      .sortBy(function(pageData) {
        return pageData.num;
      })
      .map(
        _.bind(function(pageData) {
          var page = '';
          try {
            page = new Page({
              mag: this,
              router: this.router,
              totalPages: pagesData.length,
              pageData: pageData,
              viewerType: this.viewOpts.viewertype,
              isStickyVerticalViewer: this.isStickyVerticalViewer,
              $container: this.$pages_container,
            }).render();
          } catch (e) {
            console.log('Error page rendering.', e);
          }
          return page;
        }, this)
      )
      .filter(function(page) {
        return !!page;
      })
      .value();

    var lastPage = this.getPage(this.getPagesCount());

    lastPage && lastPage.markAsLast(); // просим последнюю страницу пометить себя как последнюю

    if (this.viewOpts.viewertype == 'horizontal') {
      this.setPagesTransitions();
    }

    if (!RM.screenshot) {
      // создаем стрелки навигации, также обслуживает свайп страниц
      if (this.viewOpts.viewertype == 'horizontal' && this.viewOpts.arrows) {
        this.navigation = new NavigationClass({
          mag: this,
          router: this.router,
          $container: this.$el,
          isPreview: this.isPreview,
          hasFinalPage: this.viewOpts.endpage,
          viewerType: this.viewOpts.viewertype,
        }).render();
      }

      // создаем final page
      if (this.viewOpts.endpage) {
        this.finalPage = new FinalPageClass({
          me: this.router.me,
          mag: this,
          router: this.router,
          $container: this.$pages_container,
          isPreview: this.isPreview,
          isPrivate: this.is_private,
          viewerType: this.viewOpts.viewertype,
          finalPageType: this.viewOpts.endpagetype,
          isStickyVerticalViewer: this.isStickyVerticalViewer,
          recentMags: this.model.recentMags || _([]), // оборачиваем пустой массив в underscore, чтобы можно было применять к нему .each
        }).render();
      }

      // создает панель с кнопками меню, шер и пр. (внутри себя создает меню, панельку шера и пейдж каунтер)
      // надо создавать после навигации, потому что он в нее лазает
      this.toolbar = new ToolbarClass({
        mag: this,
        pages: this.pages,
        router: this.router,
        $container: this.$el,
        isPreview: this.isPreview,
        hasMenu: this.viewOpts.menubutton,
        hasProjectInfo: this.viewOpts.projectinfo,
        hasShare: this.viewOpts.sharebutton && !Constants.IS_FILE_PROTOCOL,
        hasPageCounter: this.viewOpts.pagecounter,
        viewerType: this.viewOpts.viewertype,
        publishDate: this.published ? dateFormat(new Date(this.published), 'mmm dd, yyyy') : 'unpublished',
      }).render();

      // Не показываем брендинг на девайсах в режиме десктопа (на этом месте там кнопка Close)
      if (this.must_show_branding && !(RM.common.embedMode && !Modernizr.isdesktop)) {
        this.branding = new BrandingClass({
          mag: this,
          $container: this.$el,
        }).render();
      }
    }

    // важно пересчитать размер глобального скрола
    // причем сделать это после создания файнал пейджа
    // ведь он тоже вносит свой вклад
    if (this.viewOpts.viewertype == 'vertical') {
      this.onResize();

      // в горизонтальном режиме за это отвечает focusPage() в page.js
      // а тут нам надо один раз сфокусировать на основном скрол контейнере (у самих страниц скрола нет)
      // важно для управления скролом с клавиатуры стрелками и пробелом (pgup, pgdown почему-то работают и так)
      // https://trello.com/c/m9zwV7xK/253-pgdn-up
      if (Modernizr.isdesktop) {
        this.getPage(1) && this.getPage(1).$content.focus();
      }

      // Пролистывание страниц вперёд и назад в вертикальном режиме:
      // вешаемся на keydown, потому что на keydown можно отменить действие по умолчанию (небольшой скролл по клавишам-стрелкам),
      // а на keyup — уже нельзя (поздно).
      $('body').on('keydown', this.onVerticalViewKeyDown);
    }

    if (!Modernizr.isdesktop) {
      $(window).on('touchstart', this.onDragStart);
      $(window).on('touchmove', this.onDragMove);
      $(window).on('touchend touchcancel', this.onDragEnd);
      $(window).on('pageshow', this.onResize); // костыль для девайсов, чтобы пересчитать размер видимой части экрана с учетом всех навигационных и прочих поганых табов
    } else {
      $(window).on('mousedown', this.onMouseDown);
      $(window).on('mouseup', this.onMouseUp);
      $(window).on('mousewheel', this.onMouseWheel);
    }

    Utils.PageVisibilityManager.addEventListener(this.onPageVisibilityChange);

    this.listenTo(this, 'finalPageShown', this.onFinalPageShow);
    this.listenTo(this, 'finalPageHidden', this.onFinalPageHide);

    this.listenTo(Backbone, 'form:focus', this.onFormFocus);
    this.listenTo(Backbone, 'form:blur', this.onFormBlur);

    $(window).on('resize', this.onResize);

    // Для Chrome for iOS: при повороте в вертикальную ориентацию не всегда выстреливается
    // событие resize у окна.
    // Хром может просто визуально сжать текущее окно, а реальный ресайз не произойдет
    if (!Modernizr.isdesktop) {
      $(window).on('orientationchange', this.onResize);
    }

    $(window).on('gesturestart gestureend', this.onGestureChange);

    if (this.viewOpts.viewertype == 'vertical') {
      this.$magScrollHandler.on('scroll', this.onMagScroll);
    }

    $('body').on('keyup', this.onKeyup);

    // создаем стили для текстового виджета
    TextUtils.generateCSS('paragraph', 'viewer', document, this.edit_params && this.edit_params.paragraph_styles);
    TextUtils.generateCSS('link', 'viewer', document, this.edit_params && this.edit_params.link_styles);
    // Сформируем очень маленький шортлист только со шрифтами, которые используются в проекте.
    // Нужен в текстовом в методе seamlessFontsShow для устранения fout (и, возможно, других виджетах типа кнопки, в которых тоже есть текст).
    // Только для вьюера (в превью и при экспорте в pdf — полный шортлист как в конструкторе)
    var exportPDFMode = RM.screenshot && Utils.queryUrlGetParam('pdf') == 'true';
    if (!exportPDFMode && !this.isPreview) {
      TextUtils.setShortList(TextUtils.getVeryShortList(this.edit_params && this.edit_params.fonts));
    }

    // эдит версия мэга во вьювере (когда во вьювере используется библиотека typekit preview api)
    // достаточно редкий случай - это когда автор заходит в свой мэг который ни разу не публиковался (по прямому урлу)
    // поэтому здесь допустимо (с натяжечкой) использовать кривой костыль
    // с ожиданием загрузки библиотеки прямо тут, а потому уже вызывать appendFontsCssToDocument
    // это единственное место на данный момент которое надо отлавливать по поводу эдит версии тайпкита во вьювере
    var fontsVersion = !this.isPreview && this.model.get('is_published') ? 'published' : 'edit',
      self = this;

    // если нужна эдит версия, но при этом библиотека еще не загружена
    // ставим ее на загрузку и по окончании оной добавляем шрифты к мэгу
    // тут есть три слабых места:
    // 1. если выйти из такого мэга раньше чем загрузится либа то колбек загрузки либы все равно останется
    // и потом когда-нибудь выстрелит, не очень гуд, но ситуация маловероятная
    // 2. если при этом зайти в другой не опубликованный авторский мэг, (пока либа еще грузится  от первого мэга)
    // то либу начнут грузить повторно, можно вместо !window.TypekitPreview сделать проверку на наличие вообще тэга этого скрипта и подвешиваться на ожидание его загрузки, но смысла особого нет
    // ситуация еще более маловероятная чем первая
    // 3. это костыль который стоит не на своем месте, если потом у нас еще появятся места откуда вьювер может грузить
    // шрифты, то мы там конечно же забудем о том, что либы может еще не быть и ее надо подгружать
    if (fontsVersion == 'edit' && !window.TypekitPreview && !Modernizr.isboxversion) {
      $.getScript(this.TYPEKIT_PREVIEW_API_LINK, function(data, textStatus, jqxhr) {
        appendFontsCssToDocument();
      });
    } else {
      appendFontsCssToDocument();
    }

    function appendFontsCssToDocument() {
      TextUtils.appendFontsCssToDocument({
        // если мы не в режиме превью и мэг опубликован, тогда нам надо показать published версию шрифтов (актуально для тайпкита, где вместо Preview API надо использовать Instant Kit Api)
        version: fontsVersion,
        fonts: self.edit_params && self.edit_params.fonts,
        typekit_url: self.typekit_url,
      });
    }

    return this;
  },

  destroy: function() {
    _.each(this.pages, function(page) {
      page.destroy();
    });

    this.pages = null;

    $('body')
      .off('click', 'a.external-link', this.toExternalLink)
      .off('click', '.maglink.back-to-top', this.backToTopCurrentPage)
      .off('click', '.maglink', this.maglink)
      .off('click', '.goback-link', this.onGobackLinkClick)
      .off('click', '.mailchimp-link', this.onMailchimpLinkClick)
      .off('click', '.anchor-link', this.onAnchorLinkClick)
      .off('keydown', this.onVerticalViewKeyDown)
      .off('click', '.share-link', this.onShareLinkClick);

    this.toolbar && this.toolbar.destroy();
    this.navigation && this.navigation.destroy();
    this.finalPage && this.finalPage.destroy();

    if (!Modernizr.isdesktop) {
      $(window).off('touchstart', this.onDragStart);
      $(window).off('touchmove', this.onDragMove);
      $(window).off('touchend touchcancel', this.onDragEnd);
      $(window).off('pageshow');
    } else {
      $(window).off('mousedown', this.onMouseDown);
      $(window).off('mouseup', this.onMouseUp);
      $(window).off('mousewheel', this.onMouseWheel);
    }

    this.unsetViewerClasses();

    Utils.PageVisibilityManager.removeEventListener(this.onPageVisibilityChange);

    $(window).off('resize', this.onResize);
    $(window).off('orientationchange', this.onResize);
    $(window).off('gesturestart gestureend', this.onGestureChange);

    if (this.viewOpts.viewertype == 'vertical') {
      this.$magScrollHandler.off('scroll', this.onMagScroll);
    }

    $('body').off('keyup', this.onKeyup);

    this.active = false;

    // удаляем вьюху стандратным методом бекбона:
    // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
    return this.remove(); // return this для вызова по цепочке
  },

  getUrl: function() {
    // в режиме кастомного домена работаем по другому
    if (RM.common.isDomainViewer) {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        '/' +
        (RM.common.customDomainProfile ? this.num_id + '/' : '')
      );
    }

    return window.location.protocol + '//' + window.location.hostname + '/' + this.num_id + '/';
  },

  // Находит страницу по номеру
  getPage: function(uri, parent) {
    if (parseInt(uri, 10) == uri) uri = parseInt(uri, 10);

    if (_.isNumber(uri)) return this.pages[uri - 1];

    return _(this.pages).find(function(page) {
      return page.uri == uri;
    });
  },

  // Возвращает кол-во страниц в мэге
  getPagesCount: function() {
    return this.pages.length;
  },

  // проверка что текущая страница первая страница мэга
  isFirstPage: function() {
    return this.currentPage && !this.getPage(this.currentPage.num - 1);
  },

  // проверка что текущая страница последняя страница мэга
  isLastPage: function() {
    return this.currentPage && !this.getPage(this.currentPage.num + 1);
  },

  // Функция получения номера страницы по ее айдишнику
  getPageNum: function(page_id) {
    var page = _.find(this.pages, function(p) {
      return p._id == page_id;
    });

    return page && page.num;
  },

  // Функция получения урла страницы по ее айдишнику
  getPageUri: function(page_id) {
    var page = _.find(this.pages, function(p) {
      return p._id == page_id;
    });

    if (!page) return null;

    var pageuri = page.uri ? page.uri + '/' : page.num == 1 ? '' : page.num + '/';

    return this.router.getMagUri(this) + pageuri;
  },

  // скорость анимации страниц
  getPageTransitionTime: function() {
    var time = 400,
      w = Math.min(this.viewOpts.scalewidth, this.getContainerSizeCached().width),
      h = this.getContainerSizeCached().height;

    if (Modernizr.isdesktop) {
      time = 550;
    } else if (Modernizr.istablet) {
      if (w > h) {
        // landscape
        time = 550;
      } else {
        time = 470;
      }
    } else {
      // phone
      if (w > h) {
        // landscape
        time = 520;
      } else {
        time = 370;
      }
    }

    return time;
  },

  // вызывается когда текущая вкладка в браузере стала неактивной-активной (или вообще браузер свернули-развернули)
  onPageVisibilityChange: function() {
    if (!this.currentPage) return;

    if (Utils.PageVisibilityManager.isPageHidden()) {
      // Не стопим аудио, потому что решили, что правильней не стопить.
      // https://trello.com/c/ooUlgZ6S/210-1
      this.currentPage.stop({ widgetTypes: ['video', 'background', 'iframe'] });

      var nextPage = this.getPage(this.currentPage.num + 1);

      // если запущена следующая за текущей страница (в вертикальном вьювере такое возможно) останавливаем и ее
      // и помечаем что она была запущена, для того, чтобы когда вкладка получит фокус запустить ее снова
      if (nextPage && nextPage.started) {
        nextPage.stop({ widgetTypes: ['video', 'background', 'iframe'] });
        this.restartNextPage = true;
      }
    } else {
      // ...но _стартуем_ в том числе и аудио, т.к. оно могло не стартовать ранее,
      // сесли вкладка изначально была открыта как фоновая. Но если у аудио есть autoplay,
      // то нужно его стартовать, когда вкладка станет активной
      this.currentPage.start({ widgetTypes: ['audio', 'video', 'background', 'iframe'], forceStart: true });

      var nextPage = this.getPage(this.currentPage.num + 1);

      if (nextPage && this.restartNextPage) {
        nextPage.start({ widgetTypes: ['audio', 'video', 'background', 'iframe'], forceStart: true });
        delete this.restartNextPage;
      }
    }
  },

  updateArrowsColor: function(page) {
    if (!page) return;

    var color = page.arrows_color || 'white';

    this.$el
      .removeClass('navigation-white-arrows')
      .removeClass('navigation-black-arrows')
      .addClass('navigation-' + color + '-arrows');
  },

  onFinalPageShow: function() {
    // тут нет нужды проверять стоп соседней страницы с текущей как в onPageVisibilityChange
    // потому что onFinalPageShow происходит только по скролу для вертикального вьювера
    // а при скроле все само правильно пересчитывается в плане старта/паузы следующей за текущей страницы
    this.currentPage && this.currentPage.stop({ widgetTypes: ['audio', 'video', 'background', 'iframe'] });

    if (this.viewOpts.viewertype == 'horizontal') {
      // включаем транзишены для Страниц, т.к. они еще могут быть отключены,
      // и эффект скейла и затемнения на Последнюю Страницу .last ляжет без транзишена.
      // транзишены для Страниц все еще могут быть отключены, например,
      // если первый заход в Мэг был на Последнюю Страницу и еще юзер не сделал переходы
      // (те, которые с анимацией проходят)
      // на другие страницы (не .final-page). любой из этих переходов прямо перед анимацией
      // включает транзишены Страниц по логике showPage, а у .final-page другая
      // логика и мы включаем транзишены для Страниц тут для подстраховки.
      // почему при первом рендере транзишены не включаем, а только перед переходом
      // на Страницы — см. камменты в onPageChange
      this.$pages_container.removeClass('disable-transitions');

      this.$pages_container.addClass('fade-last-page-on-final-page');
    }
  },

  onFinalPageHide: function() {
    // тут нет нужды проверять старт соседней страницы с текущей как в onPageVisibilityChange
    // потому что onFinalPageShow происходит только по скролу для вертикального вьювера
    // а при скроле все само правильно пересчитывается в плане старта/паузы следующей за текущей страницы
    this.currentPage && this.currentPage.start({ widgetTypes: ['audio', 'video', 'background', 'iframe'] });

    if (this.viewOpts.viewertype == 'horizontal') {
      this.$pages_container.removeClass('fade-last-page-on-final-page');
    }
  },

  onFormFocus: function() {
    var isIosSafari = Modernizr.safari && !Modernizr.isdesktop;
    if (isIosSafari && this.viewOpts.viewertype === 'vertical') {
      // В вертикальном вьюере все страницы должны знать о фокусе и скролле, а не только та, которая содержит виджет,
      // потому что в случае скролла при форме в фокусе все страницы должны обновлять top своего фона
      var scrollTop = this.getScroll();
      _.each(this.pages, function(page) {
        page.trigger('form:focus', scrollTop);
      });
      // Навесим обработчик скролла, на случай если кто-то будет скроллить мэг, пока форма в фокусе
      this.$magScrollContainer && this.$magScrollContainer.on('scroll', this.onScrollWithFormInFocus);
    }
  },

  onFormBlur: function() {
    var isIosSafari = Modernizr.safari || !Modernizr.isdesktop;
    if (isIosSafari && this.viewOpts.viewertype === 'vertical') {
      // В вертикальном вьюере оповестим все страницы. В горизонтальном не используется sticky, поэтому не нужно оповещать
      _.each(this.pages, function(page) {
        page.trigger('form:blur');
      });
      this.$magScrollContainer && this.$magScrollContainer.off('scroll', this.onScrollWithFormInFocus);
    }
  },

  onScrollWithFormInFocus: function() {
    var isIosSafari = Modernizr.safari || !Modernizr.isdesktop;
    if (isIosSafari && this.viewOpts.viewertype === 'vertical') {
      // В вертикальном вьюере оповестим все страницы. В горизонтальном не используется sticky, поэтому не нужно оповещать
      var scrollTop = this.getScroll();
      _.each(this.pages, function(page) {
        page.trigger('page:scrollWithFormInFocus', scrollTop);
      });
    }
  },

  onDragStart: function(e) {
    clearTimeout(this.touchInProgressTimeout);
    // флаг что юзер что-то начал делать пальцем
    // это чтобы приостановить асинхронный рендеринг виджетов
    this.pauseLoading('touch');

    //			if (this.viewOpts.viewertype == 'vertical') return;

    var touch = e.originalEvent.targetTouches[0];

    this.globalTouchObject = {
      $target: $(e.target).closest('.has-horizontal-scroll, .has-vertical-scroll'),
      x: touch.screenX,
      y: touch.screenY,
      startTime: +new Date(),
    };
  },

  onDragMove: function(e) {
    // if (this.viewOpts.viewertype == 'vertical') return;

    var data = this.globalTouchObject;

    // детектим мультитач жест
    if (e.originalEvent.touches.length > 1) return;

    if (!data) return;

    if (this.viewOpts.viewertype == 'horizontal') {
      // если мы НЕ находимся внутри элемента который помечен как имеющий внутренний скролл
      // тогда можно смело превентить все дефолтное поведение
      // но за одним исключением: если мы на странице с фикседами, тогда там тач может приходить
      // не изнутри контейнера со скролом, а вообще изнутри z-index-wrapper
      // эту ситуацию надо обруливать отдельно
      var scrollOnFixedsPage =
        this.currentPage && this.currentPage.hasFixedWidgets && $(e.target).closest(this.$mag_container).length;

      if (scrollOnFixedsPage && !data.$target.length) data.$target = this.currentPage.$scrollWrapper;
    } else {
      if (!data.$target.length) data.$target = this.$magScrollContainer;
    }

    if (!data.$target.length) {
      e.preventDefault();
      return;
    }

    if (Utils.isPageNativelyScaled()) return;

    var touch = e.originalEvent.targetTouches[0],
      deltaX = touch.screenX - data.x,
      deltaY = touch.screenY - data.y,
      tolerance = 5; // сколько надо сдвинуть пальцем в нужном направлении, чтобы понять что мы скроллим. меньше этого значения — считаем случайным сдвигом пальца.

    if (Math.abs(deltaY) > tolerance && data.$target.hasClass('has-vertical-scroll')) {
      var scrollHeight = data.$target[0].scrollHeight,
        outerHeight = data.$target.outerHeight();

      if (deltaY > 0 && data.$target.scrollTop() <= 0) e.preventDefault();

      if (deltaY < 0 && data.$target.scrollTop() >= scrollHeight - outerHeight) e.preventDefault();
    }

    if (data.$target.hasClass('has-horizontal-scroll')) {
      // а ничего не делаем, нафиг
    }
  },

  onDragEnd: function(e) {
    this.checkDoubleTap(e);

    // флаг что юзер что-то закончил делать пальцем
    // это чтобы продолжить асинхронный рендеринг виджетов
    // задежку в 400 делаем специально
    // у нас есть много анимаций которые продолжаются после отпускания пальца, в эти моменты тоже нужно подождать
    this.touchInProgressTimeout = setTimeout(
      _.bind(function() {
        this.continueLoading('touch');
      }, this),
      400
    );

    var data = this.globalTouchObject;

    // определяем что был короткий клик без сдвига - закрыть меню
    // и если клик произошел изнутри контейнера страниц (а не из меню, тулбара и пр.)
    if (data) {
      var touch = e.originalEvent.changedTouches[0],
        deltaX = touch.screenX - data.x,
        deltaY = touch.screenY - data.y;

      if (
        this.toolbar &&
        this.toolbar.isMenuOpened() &&
        Math.abs(deltaX) < 3 &&
        Math.abs(deltaY) < 3 &&
        +new Date() - data.startTime < 500 &&
        !Utils.isPageNativelyScaled() &&
        $(e.target).closest(this.$mag_container).length
      ) {
        this.toolbar.closeMenu();
      }
    }

    delete this.globalTouchObject;
  },

  checkDoubleTap: function(e) {
    // проверяем что  каждый тап это отпусание последнего пальца (т.е. что масиив touches пустой)
    // иначе он начнет реаировать на жесты того же самого скейла, когда двумя пальцами скейлят, а потом оба отпускают
    if (e.originalEvent.touches.length > 0) return;

    var now = +new Date(),
      delta = now - (this.lastTouchEndTime || now + 1);

    this.lastTouchEndTime = now;

    // проверяем что мы в режиме зума, что между тапами прошло не более 500 мс
    if (delta < 500 && delta > 0 && Utils.isPageNativelyScaled()) {
      // задетектили двойной тап - выход из режима зума
      var $viewport = $('meta[name="viewport"]');

      if ($viewport.length) {
        var content = $viewport.attr('content'),
          newContent = content.replace(/maximum\-scale\=[0-9\.]*/, 'maximum-scale=1.0');

        $viewport.attr('content', newContent);

        if (Modernizr.android) {
          // спец хак, без этого изменения меты вьюпорта к исходному состоянию не применялось
          var $tmp = $('<div>').appendTo('body');
          _.delay(function() {
            $tmp.remove();
          }, 500);

          setTimeout(
            _.bind(function() {
              $viewport.attr('content', content);

              // спец хак, без этого изменения меты вьюпорта к исходному состоянию не применялось
              var $tmp = $('<div>').appendTo('body');
              _.delay(function() {
                $tmp.remove();
              }, 500);
            }, this),
            1000
          );
        } else {
          $viewport.attr('content', content);
        }

        this.lastTouchEndTime = 0;

        _.delay(function() {
          $(window).trigger('gesturechange'); // это чтобы навигация отработала показ стрелок
        }, 300);

        // а это чтобы навигация ТОЧНО отработала показ стрелок
        // а то если быстро отзумить и два раза тапнуть бывает так, что зум еще не вернулся в норму и стрелки не покажутся
        // https://trello.com/c/byLdNrxj/185-iphone
        _.delay(function() {
          $(window).trigger('gesturechange');
        }, 1000);
      }
    }
  },

  onMouseDown: function(e) {
    this.globalMouseObject = {
      x: e.screenX,
      y: e.screenY,
      startTime: +new Date(),
    };
  },

  onMouseUp: function(e) {
    var data = this.globalMouseObject;

    // определяем что был короткий клик без сдвига - закрыть меню
    // и если клик произошел изнутри контейнера страниц (а не из меню, тулбара и пр.)
    if (data) {
      var deltaX = e.screenX - data.x,
        deltaY = e.screenY - data.y;

      if (
        this.toolbar &&
        this.toolbar.isMenuOpened() &&
        Math.abs(deltaX) < 3 &&
        Math.abs(deltaY) < 3 &&
        +new Date() - data.startTime < 500 &&
        $(e.target).closest(this.$mag_container).length
      ) {
        this.toolbar.closeMenu();
      }
    }

    delete this.globalMouseObject;
  },

  onMouseWheel: function(e) {
    // Если это свайп двумя пальцами влево по трекпаду или magick mouse - отменяем,
    // чтобы не сработал history back gesture у браузера
    // При этом еще допускается отклонение по Y на 1px. Если задать условие deltaY === 0,
    // то возможны срабатывания back
    if (e.originalEvent.deltaX < 0 && Math.abs(e.originalEvent.deltaY) < 2) {
      e.cancelable && e.preventDefault();
    }
  },

  // Нажатия клавиш
  onKeyup: function(event) {
    if (
      event.target.tagName.toLowerCase() == 'input' ||
      (event.target.tagName.toLowerCase() == 'textarea' && event.target.className != 'copyhack')
    )
      return;

    if (!_.isEmpty(RM.common.disableShortcuts)) return;

    if (this.isInPresentationMode) return;

    // смотрим код клавиши среди шорткатов вьюпортов
    var viewport = _.chain(Viewports.viewports)
      .filter(function(vp) {
        return vp.name == 'tablet_portrait' ? (this.currentPage['viewport_' + vp.name] || {}).enabled : true;
      }, this)
      .map(function(vp, idx) {
        var x = _.clone(vp);
        x.shortcut = '' + (idx + 1);
        return x;
      })
      .findWhere({ shortcut: String.fromCharCode(event.keyCode) })
      .value();

    // переключение между вьюпортами по 1-4 работает только в превью режиме вьювера (в конструкторе)
    if (viewport && this.isPreview) {
      this.switchToViewport(viewport.name);

      // Для горизонтального вьюера ре-рендерим above-all-виджеты.
      // Для вертикального и так ре-рендерятся, потому что вызывается mag.onResize, а в нём — mag.onScroll и mag.showPage
      if (!this.aboveGlobalWidgetsRendered && this.viewOpts.viewertype === 'horizontal') {
        this.renderAboveGlobalWidgets(this.currentPage);
      }

      // важно заново пересчитать все настройки и классы заново с учетом нового вьюпорта
      // все дело втом, что для мобильных вьюпортов в превью запрещен режим slidein для обоих видов вьювера
      // поскольку его нет и на девайсах
      this.setViewerOptions(viewport.name);
      this.setViewerClasses();

      // обязательно просим мэг пересчитать свои новые размеры c учетом нового вьюпорта
      // onResize также попросит все страницы обработать ресайз (его обработают только видимые)
      this.onResize();

      this.loadPages();
    }

    var keyPressed = 'unknown';

    var validKey = _.find($.keycodes, function(i, field) {
      var result = $.keycodes[field] === event.keyCode;
      if (result) keyPressed = field;
      return result;
    });

    if (validKey) {
      this.trigger('keypress-' + event.keyCode, event);
    }
  },

  // Нажатия клавиш, для режима презентаций
  onKeydown: function(event) {
    if (
      event.target.tagName.toLowerCase() == 'input' ||
      (event.target.tagName.toLowerCase() == 'textarea' && event.target.className != 'copyhack')
    )
      return;

    if (!_.isEmpty(RM.common.disableShortcuts)) return;

    if (!this.isInPresentationMode) return;

    if (event.altKey || event.ctrlKey || event.metaKey) return;

    switch (event.which) {
      case 8: // backspace
      case 33: // pgup
      case 80: // p
      case 37: // left
      case 72: // h
      case 38: // up
      case 75: // k
        event.preventDefault();
        this.goPrevPage({ animation: true });
        break;

      case 13: // enter
      case 34: // pgdown
      case 78: // n
      case 39: // right
      case 76: // l
      case 40: // down
      case 74: // j
        event.preventDefault();
        this.goNextPage({ animation: true });
        break;

      case 36: // home
        event.preventDefault();
        this.goFirstPage();
        break;

      case 35: // end
        event.preventDefault();
        this.goLastPage();
        break;

      case 9: // tab
      case 32: // space
        event.preventDefault();
        event.shiftKey ? this.goPrevPage({ animation: true }) : this.goNextPage({ animation: true });
        break;

      case 77: // menu, оставляем возможность открыть меню если оно есть, потому и триггером, а не прямыым вызовом, так правильнее
        event.preventDefault();
        this.trigger('keypress-' + event.keyCode, event);
        break;
    }
  },

  /**
   * Пролистывает страницы вперёд и назад по клавишам вверх / вниз (для вертикального вьюера)
   * @param {Event} event
   */
  onVerticalViewKeyDown: function(event) {
    // В режиме презентаций не пролистывать клавишами вверх / вниз
    if (this.isInPresentationMode || this.viewOpts.viewertype !== 'vertical') {
      return;
    }
    if (event.which === $.keycodes.down || event.which === $.keycodes.up) {
      event.preventDefault();
      // Если предыдущий скролл по клавишам-стрелкам был только что, не анимировать
      // (Если дожидаться полного окончания анимации с Utils.waitForTransitionEnd — скролл будет не гладкий)
      // (Сделать через _.debounce тоже нельзя, потому что нужно всегда превентить событие)
      if (this.arrowKeyLastScrollAt && new Date() - this.arrowKeyLastScrollAt < 400) {
        return;
      }
      var isForward = event.which === $.keycodes.down;
      this.arrowKeyLastScrollAt = +new Date();
      // Скроллить на высоту экрана
      var windowHeight = window.innerHeight;
      var globalScrollTop = this.getScroll();
      // Локальный скролл для текущей страницы
      var pageScroll = this.getScrollPosition();
      // Порог "докрутки"
      var threshold = Math.round(windowHeight / 4);
      var isBelowThreshold = function(value) {
        return value && value <= threshold;
      };
      var isBelowWindowHeight = function(value) {
        return value > threshold && value < windowHeight;
      };
      var getRemainingSnapHeight = _.bind(function() {
        var coordinates = this.magPagesPos[this.currentPage.num];
        return Math.abs(globalScrollTop - coordinates.y);
      }, this);
      var currentPageHeight = this.currentPage.pageHeight;
      var atPageTop = pageScroll === 0 && !this.isFirstPage();
      // Высота которая останется после этого скролла
      var remainingHeight;
      var delta;

      // Прокрутка в прямом направлении
      if (isForward) {
        remainingHeight = currentPageHeight - pageScroll - windowHeight;
        // Если после скролла останется небольшой кусок текущей страницы, проскроллить сразу до конца страницы
        if (isBelowThreshold(remainingHeight)) {
          delta = currentPageHeight - pageScroll;
          // Если останется большой но не полный кусок текущей страницы, скроллить сейчас на это расстояние (а потом — на высоту экрана)
        } else if (isBelowWindowHeight(remainingHeight)) {
          delta = remainingHeight;
        } else {
          delta = windowHeight;
        }

        // Если мы сейчас на верху страницы, учитывать SCROLL_SNAP
        // (который добавляется при включенной опции slidein для любой страницы кроме первой, где этого снапа нет)
        // (иначе будет заметно, как страница высотой в экран проскроллилась не полностью)
        if (atPageTop && this.viewOpts.slidein) {
          delta += getRemainingSnapHeight();
        }

        // Прокрутка в обратном направлении
      } else {
        // При скролле в обратном направлении, если мы в начале страницы, за скролл-расстояние берём высоту предыдущей страницы
        if (atPageTop) {
          var previousPageCoordinates = this.magPagesPos[this.currentPage.num - 1];
          pageScroll = previousPageCoordinates ? previousPageCoordinates.h : 0;
        }
        remainingHeight = pageScroll - windowHeight;

        if (isBelowThreshold(remainingHeight) || pageScroll < windowHeight) {
          delta = -pageScroll;
        } else {
          delta = -windowHeight;
        }

        if (atPageTop && this.viewOpts.slidein) {
          delta -= this.SCROLL_SNAP - getRemainingSnapHeight();
        }
      }

      this.setScrollPosition({ delta: delta, animate: true });
    }
  },

  // включает/выключает режим презентации
  // на данный момент включается при заходе в фулскрин, выключается при выходе
  // особенности:
  // -прячем все кнопки тулбара (но плашку брандинга оставляем, если она есть)
  // -отключаем обычные шорткаты проекта и навешиваем свои, для кликеров презентаций (исключение: шорткат m для меню, его оставляем)
  // -шорткаты смены слайдов работают и в вертикальном виде и даже при отключенных Navigation Arrows
  // -только десктоп
  // -в превью нельзя
  togglePresentationMode: function(state) {
    if (!!this.isInPresentationMode == state) return;

    if (!Modernizr.isdesktop) return;

    if (this.isPreview) return;

    if (state) {
      $('body').on('keydown', this.onKeydown);
      this.trigger('presentationModeOn');
    } else {
      $('body').off('keydown', this.onKeydown);
      this.trigger('presentationModeOff');
    }

    this.isInPresentationMode = state;
  },

  recalcBottomArrowState: function(page) {
    if (!this.currentPage || page !== this.currentPage) return;

    this.navigation && this.navigation.recalcBottomArrowState(page);
  },

  // устанавлвает скрол для текущей страницы
  // используется в конструкторе при переходе в превью
  // если в подарочных картах используется
  setScrollPosition: function(params) {
    if (!this.currentPage) return;

    var offset;

    if (this.viewOpts.viewertype == 'vertical') {
      if (typeof params.offset !== 'undefined') {
        offset = this.magPagesPos[this.currentPage.num].y + params.offset;
      } else {
        // если не задан offset значит должна быть задана дельта
        offset = this.getScroll() + params.delta;
      }

      if (params.animate) {
        this.$magScrollAnimate.animate({ scrollTop: offset }, 500);
      } else {
        this.setScroll(offset);
      }
    } else {
      if (typeof params.offset !== 'undefined') {
        offset = params.offset;
      } else {
        // если не задан offset значит должна быть задана дельта
        offset = this.currentPage.$scrollWrapper.scrollTop() + params.delta;
      }

      if (params.animate) {
        this.currentPage.$scrollWrapper.animate({ scrollTop: offset }, 500);
      } else {
        this.currentPage.$scrollWrapper.scrollTop(offset);
      }
    }
  },

  // вохвращает скрол для текущей страницы
  // используется в конструкторе при возврате из превью
  getScrollPosition: function() {
    if (!this.currentPage) return 0;

    var offset = 0;

    if (this.viewOpts.viewertype == 'vertical') {
      offset = Math.max(this.getScroll() - this.magPagesPos[this.currentPage.num].y, 0);
    } else {
      offset = this.currentPage.$scrollWrapper.scrollTop();
    }

    return offset;
  },

  getScroll: function() {
    return this.$magScrollContainer && this.$magScrollContainer.scrollTop();
  },

  setScroll: function(scroll) {
    this.$magScrollContainer && this.$magScrollContainer.scrollTop(scroll);
  },

  // для режима вертикального вьювера
  onMagScroll: function() {
    // Проверяем, есть ли у нас какой-нибудь элемент в фуллскрине
    // проблема возникает при открытии видео во весь экран
    // в этот момент страница прыгает вверх и соответственно триггерит событие скролла
    // нам это не надо, т.к. возможно изменение dom'a и тогда будет черный экран вместо видео в фуллскрине
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.webkitCurrentFullScreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      return;
    }

    _.isFunction(this._resetUrlStringTimeoutFunc) && this._resetUrlStringTimeoutFunc();

    this.pauseWidgetsLoadingOnScroll();

    // обработка перерасположения всех страниц и вызов showpage
    var pageData = this.findPageOnCurrentMagScroll({
      pageStartsOnBottom: this.isStickyVerticalViewer && !Modernizr.isdesktop,
    });
    var params = this.getScrollParams(pageData);

    this.lastPageScroll = params.pageScroll;
    this.lastPageScrollPercent = params.pageScrollPercent;

    // показываем текущую страницу
    // и при это говорим на сколько надо прокрутить текущую
    this.showPage(pageData.page.num, params);

    if (this.finalPage && !this.isStickyVerticalViewer) {
      if (pageData.finalPageScroll != undefined) {
        this.finalPage.scrollOnVerticalMode(pageData.finalPageScroll);
      } else {
        this.finalPage.resetScrollOnVerticalMode();
      }
    }
    // revert forceApply: true from this commit https://bitbucket.org/readymag_admin/rmcode-frontend/commits/fbb81d3d9c4d21c3a507e3244c1657d75529fc01
    // since that broked vertical scroll animations in global widgets
    this.aboveGlobalAnimations && this.aboveGlobalAnimations.onScroll({ forceApply: true, scroll: this.getScroll() });

    // триггерим события видимости/невидимости файнал пейджа сразу как только она показалась при скролле/при загрузке
    if (this.finalPage && this.isStickyVerticalViewer) {
      var scrollPos = this.getScroll(),
        pageBeforeFinalPage = this.magPagesPos[this.pages.length],
        beginningOfFinalPage = pageBeforeFinalPage.y + pageBeforeFinalPage.h - this.getContainerSizeCached().height;

      this.trigger(scrollPos > beginningOfFinalPage ? 'finalPageShown' : 'finalPageHidden');
    }

    // прятать нижнюю стрелку
    // но реагировать только на те скролы которые произошли через 2 секунды после открытия мэга
    if (this.$bottomArrow && +this.magOpenTime + 2000 < +new Date()) {
      this.$bottomArrow.addClass('offscreen');

      var $bottomArrow = this.$bottomArrow;
      delete this.$bottomArrow;

      setTimeout(function() {
        $bottomArrow.remove();
      }, 400);
    }
  },

  // для режима вертикального вьювера
  scrollMagALittle: function() {
    this.$magScrollAnimate.animate({ scrollTop: this.getScroll() + this.getContainerSizeCached().height }, 500);
  },

  findPageOnCurrentMagScroll: function(params) {
    params = params || {};

    // обработка перерасположения всех страниц и вызов showpage
    var scroll = this.getScroll(),
      containerSizeCached = this.getContainerSizeCached(),
      scroll = Math.min(Math.max(scroll, 0), this.magHeight - containerSizeCached.height), // убираем скрол вне границ, оттяжка на афари
      len = this.pages.length,
      // если передан параметр pageStartsOnBottom
      // тогда добавлем к текущему скролу (только для расчетов) высоту контейнера минус высота тулбара и отступов от него до низа экрана
      // это важно для вертикалного виде телефона и ланшщета у которых есть стики
      // дл того чтоюы визуально переключение на новую страницу (старт-стоп страниц, пейдж-каунтр, заголовок, урл и пр.)
      // происходило не когжа страница полностью подъедет к верху экрана
      // а когда она будет видна только-только заехав за верху тулбара (который на мобильных устройствах снизу)
      barier = scroll + (params.pageStartsOnBottom ? containerSizeCached.height - 50 - 16 - 16 : 0),
      i,
      page,
      pos,
      prevPos;

    // пробегаемся по всем страницам
    for (i = 0; i < len; i++) {
      page = this.pages[i];

      pos = this.magPagesPos[page.num];

      // если есть предыдущая страница то начало смотрим от ее конца
      // а не от начала текущей, потому что у нас между страницами могут быть пробелы в 220 пикселей
      // (на десктопе в режиме слайд-ин)
      var st = prevPos ? prevPos.y + prevPos.h : pos.y,
        finalPageScroll;

      // current page or final page
      // i == len - 1 это последняя страница всегда, даже когда она не видна а виден файнал пейдж
      if ((barier >= st && barier < pos.y + pos.h) || i == len - 1) {
        if (i == len - 1) {
          finalPageScroll = Math.max(scroll - pos.y - pos.h - pos.dh, 0);
        }

        return {
          page: page,
          scroll: scroll,
          pos: pos,
          // это тот скрол который надо применить к файнал пейджу если он есть
          finalPageScroll: finalPageScroll,
          // это флаг что файнал пейдж виден полностью, т.е. последняя страница полностью проскролена
          finalPageShown: this.finalPage && !(barier >= st && barier < pos.y + pos.h),
        };
      }

      prevPos = pos;
    }
  },

  /**
   * @param {Object} pageData Объект, который возвращает findPageOnCurrentMagScroll
   * @return {Object} параметры, которые передаются в showPage
   */
  getScrollParams: function(pageData) {
    var pageScroll = Math.max(pageData.scroll - pageData.pos.y, 0);
    var pageScrollPercent = pageScroll / pageData.page.pageHeight;

    var params = {
      initiator: 'scroll',
      pageScroll: pageScroll,
      pageScrollPercent: pageScrollPercent,
    };

    // если режим слайд-ин отключен
    // тогда нам надо двигать следующую страницу вслед за текущей, вплотную
    if (!this.viewOpts.slidein) {
      params.nextPageScroll = params.pageScroll - pageData.pos.h;
    } else {
      // иначе следующая страница должна начинаться под текущей
      params.nextPageScroll = 0;
      params.slideInNextPageScroll = pageData.pos.h - params.pageScroll;
    }

    // смотрим видна ли часть следующей страницы (независимо от this.viewOpts.slidein)
    // нужно для того, чтобы стартовать следующую страницу тоже, когда становиться виден ее кусочек
    params.nextPageVisible = pageData.pos.h - params.pageScroll < this.getContainerSizeCached().height;

    if (this.viewOpts.slidein) {
      var percent = (pageData.pos.h - params.pageScroll) / this.getContainerSizeCached().height;
      params.nextPageBlackout = Math.min(Math.max(percent, 0), 1);
    }

    return params;
  },

  // для режима горизонтального вьювера
  onCurrentPageScroll: function(page, scroll) {
    this.pauseWidgetsLoadingOnScroll();
    this.aboveGlobalAnimations &&
      this.aboveGlobalAnimations.onScroll({ forceApply: !this.isStickyVerticalViewer, scroll: scroll });

    this.lastPageScroll = scroll;
    this.lastPageScrollPercent = this.lastPageScroll / page.pageHeight;

    // при скроле заново пересматриваем приоритеты чтобы начали грузиться те виджеты которые сейчас видны на отскроленом экране
    this.loadPages.__debounced();
  },

  // для режима горизонтального вьювера
  scrollCurrentPageALittle: function() {
    this.currentPage && this.currentPage.scrollPageALittle();
  },

  focusCurrentPage: function() {
    this.currentPage && this.currentPage.focusPage();
  },

  backToTopCurrentPage: function() {
    // для вертикального режима другая логика
    if (this.viewOpts.viewertype == 'vertical') {
      var pageData = this.findPageOnCurrentMagScroll();
      this.$magScrollAnimate.animate({ scrollTop: pageData.pos.y }, 500);
    } else {
      this.currentPage && this.currentPage.backToTop();
    }
    RM.analytics && RM.analytics.sendEvent('Scroll Top Link');
  },

  // Проскролливает указанную страницу так, чтобы указанная точка
  //  оказалась сверху экрана. y - в координатах рабочей области (как виджеты).
  //  Скроллим с учетом скейла текущего вьюпорта
  scrollPageToPos: function(uri, y) {
    var page = this.getPage(uri),
      pos,
      container = this.getContainerSizeCached(),
      scale = page.scale,
      scroll;

    y = y || 0;
    y = y * scale;
    y = y + page.contentPosition.top;

    if (this.viewOpts.viewertype == 'vertical') {
      pos = this.magPagesPos[page.num];
      scroll = Math.max(0, pos.y + y);

      // Не позволяем усролливать дальше конца страницы,
      // чтобы не вылезала следующая
      scroll = Math.min(scroll, pos.y + pos.h - container.height);

      this.$magScrollAnimate.animate({ scrollTop: scroll }, 500);
    } else {
      if (this.currentPage != page) this.showPage(page.uri || page.num);

      scroll = Math.max(0, y);
      page.scrollTo(scroll);
    }
  },

  pauseWidgetsLoadingOnScroll: function() {
    // 15.02.2016
    // временно для пробы решили заблокировать это поведение (останов загрузки виджетов и старта страниц при скроле)
    return;

    // флаг что юзер скролит страницу
    // это чтобы приостановить асинхронный рендеринг виджетов
    this.pauseLoading('page-scrolling');

    // флаг что закончился скрол
    // это чтобы продолжить асинхронный рендеринг виджетов
    this.continueLoading.__debounced('page-scrolling');
  },

  // Отправка события о переходе по внешней ссылке
  toExternalLink: function(ev) {
    var url = $(ev.target)
      .closest('a')
      .attr('href');
    RM.analytics && RM.analytics.sendEvent('External Link', url);
  },

  maglink: function(event) {
    if (event.which == 2 || event.metaKey || event.ctrlKey) return; // оставляем дефолтное поведение браузера

    var mag_url = $(event.currentTarget).data('link') || $(event.currentTarget).attr('href');

    if (!mag_url) return false;

    event.preventDefault();

    // back-to-top ссылки не должны менять текущую страницу
    if ($(event.currentTarget).hasClass('back-to-top')) {
      return this.backToTopCurrentPage();
    }

    this.router.navigate(mag_url, { trigger: true });
  },

  onGobackLinkClick: function(e) {
    this.router.goBack();
  },

  onMailchimpLinkClick: function(e) {
    var $link = $(e.currentTarget),
      data;

    if ($link.hasClass('disabled')) {
      return;
    }

    $link.flashClass('disabled', 3000); // Временно дизаблим ссылку, чтобы предотвратить множественные клики

    try {
      data = JSON.parse($link.attr('data-mailchimp'));
    } catch (e) {
      data = null;
    }

    if (!data) {
      return;
    }

    InitUtils.initMailchimpAPI(function(mailchimp) {
      Utils.deleteCookie('MCEvilPopupClosed'); // Удаляем куку, которую вешает майлчимп, чтобы не показывать попап повторно
      mailchimp.start(data);
    });
  },

  onAnchorLinkClick: function(e) {
    var $link = $(e.currentTarget),
      y = parseInt($link.attr('data-anchor-link-pos'), 10) || 0,
      uri = $link.attr('data-page-uri');

    if (!y || !uri) {
      return;
    }

    this.scrollPageToPos(uri, y);

    RM.analytics && RM.analytics.sendEvent('Anchor Link', y);
  },

  onShareLinkClick: function(e) {
    ShareUtils.share({
      host: (RM.common.embedDomainName || window.location.protocol + '//' + window.location.hostname) + '/',
      tp: $(e.currentTarget).attr('data-share-provider'), // facebook, twitter, pinterest ...
      source: 'mag links', // для аналитики
      page: this.currentPage || this.pages[0],
      mag: _.extend(this.model.toJSON(), { user: this.user }),
      forProject: $(e.currentTarget).attr('data-share-type') == 'mag',
      customDomain: !!(RM.common.isDomainViewer || RM.common.embedDomainName),
      customDomainProfile: !!(RM.common.customDomainProfile || RM.common.embedDomainType == 'User'),
      analytics: this.router.analytics,
    });
  },

  // обновляем пейдж каунтер в процессе свайпа страниц (до вызова showPage и this.trigger('pageChanged'getPage)
  showPageCounter: function(dir) {
    if (!this.toolbar) return;

    var pageNum = 1;

    if (this.currentPage) {
      pageNum = this.currentPage.num + (dir ? (dir == 'left' ? 1 : -1) : 0);
      pageNum = Math.max(Math.min(pageNum, this.getPagesCount()), 1);
    }

    this.toolbar.showPageCounter(this.getPage(pageNum), { showPageCounter: true });
  },

  // Показываем переданную страницу (с анимацией сдвига, либо без)
  // Передаётся номер той страницы, в которой находится верхняя граница экрана
  showPage: function(pageuri, params) {
    params = params || {};

    if (!this.active) this.render(); // в превью вызывается

    var newPage = this.getPage(pageuri),
      oldPage = this.currentPage,
      nextPage = newPage && this.getPage(newPage.num + 1),
      self = this;

    // Рендер глобальных виджетов при первом запуске
    if (newPage && !this.aboveGlobalWidgetsRendered) {
      this.renderAboveGlobalWidgets(newPage);
    }

    // Для вертикального вьюера на мобильных видимость виджетов нужно разруливать всегда
    // (из-за того, что там страница начинается внизу, а не вверху, как на десктопе, и на nextPageVisible полагаться нельзя)
    if (this.isStickyVerticalViewer) {
      // Вызовем без параметров — в этом случае параметры вычислятся внутри метода на основе текущего значения скролла
      this.redrawAboveGlobalWidgets();
      // На десктопе если видна другая страница, нужно разруливать видимость above-all-виджетов
    } else if (params.nextPageVisible || this.viewOpts.viewertype === 'horizontal') {
      // В вертикальном вьюере без слайд-ина, когда две страницы видны на экране, то задан nextPageScroll
      // В вертикальном вьюере со слайд-ином, когда две страницы видны на экране, задан slideInNextPageScroll
      // В горизонтальном вьюере не задано ни то ни другое, зато выставлен флаг animation
      this.redrawAboveGlobalWidgets(
        newPage,
        nextPage,
        params.slideInNextPageScroll || params.nextPageScroll,
        params.animation
      );
      // Переход по ссылке на страницу внутри мэга https://trello.com/c/35kZKgWB/138-current
    } else if (newPage) {
      this.redrawAboveGlobalWidgets(newPage);
      // Debounce для всех остальных случаев, кроме первого запуска (иначе на десктопе после быстрого скролла, когда nextPageVisible = false виджеты иногда не перерисовываются)
      // Тоже без параметров, потому что параметры нужно рассчитывать на момент выполнения, а не первого вызова метода (первого за период дебаунса)
    } else if (oldPage) {
      this.redrawAboveGlobalWidgets.__debounced();
    }

    // в вертикальном виде нет никаких анимаций смены страниц
    if (this.viewOpts.viewertype == 'vertical') {
      params.animation = false;

      // скролим текущую страницу
      newPage && newPage.scrollOnVerticalMode(params.pageScroll || 0);

      if (newPage && nextPage && params.nextPageScroll != undefined) {
        nextPage.scrollOnVerticalMode(params.nextPageScroll);

        // запускаем помимо текущей страницы еще и следующую если мы видим хоть ее часть
        // для вертикального вьювера со стики позиционированием оставляем старую логику, это для девайсов там нет нужды запускать две страницы одновременно, да и думаю я что что-нибудь сломаю
        if (!this.isStickyVerticalViewer) {
          if (params.nextPageVisible) {
            nextPage.start();
          } else {
            nextPage.stop();
          }
        }
      }

      if (newPage && params.nextPageBlackout != undefined) {
        this.$pages_blackout.css({
          display: nextPage ? 'block' : 'none',
          opacity: params.nextPageBlackout,
          'z-index': newPage.getPageZIndex(),
        });
      }
    }

    var first_run = !this.currentPage;

    // если просят открыть текущую страницу (проверяем и по номеру и по кастомному урлу) тогда ничего не делаем
    // особенно важно для вертикального, поскольку shoPage там выстреливает при любом скроле
    // и когда текущая страница не меняется дальше идти не нужно и я бы даже сказал нельзя
    if (!first_run && (pageuri == this.currentPage.num || pageuri == this.currentPage.uri)) {
      return false;
    }

    if (!newPage) {
      if (pageuri != 1) {
        this.router.onError();
      }
      return false;
    }

    if (oldPage) {
      // в горизонтальном вьювере у нас всегда стартована только одна страница
      // поэтому мы точно знаем какую страницу надо остановить при смене страниц
      // для вертикального вьювера со стики позиционированием оставляем старую логику, это для девайсов там нет нужды запускать две страницы одновременно, да и думаю я что что-нибудь сломаю
      if (this.viewOpts.viewertype == 'horizontal' || this.isStickyVerticalViewer) {
        oldPage.stop();
      } else {
        // для вертикального вьювера, помимо страницы с которой мы ушли, надо еще остановить и следующую за ней
        // если она была запущена ранее (у нас может быть две одновременно стартованных страницы, в случае когда на экране видны обе)
        // но тут надо аккуратно стопить, во-первых не стоит стопить если это получается та старница на которую мы толко что пришли
        // ничего страшного не произойдет, но получиться что мы ее застопим, а дальше сразу снова запустим, абсолютно лишнее действие, да и лаг будет
        // во-вторых надо проверить, что мы не остановим страницу которую только что чуть выше не просили ее запустить (params.nextPageVisible && nextPage.start();)
        // такое случиться если мы стоя на второй странице скрольнули вверх и перешли на первую, но вторая осталась видима, так вот вторая в данном случае будет oldPage и одновременно nextPage для текущей
        // поэтому мы не должны ее паузить так как она должна быть стартована потому что она идет после текущей первой и она частично видима
        if (!(nextPage == oldPage && params.nextPageVisible)) {
          oldPage.stop();
        }

        // также надо проверить страницу следующую за старой и остановить ее тоже, если это не страница которая стала новой и не страница которая стала следующей за новой
        // например мы стоим на первой странице и частично видим вторую - работают обе
        // после чего махом перепрыгиваем на 3ю страницу и частично видим 4ю
        // в таком случае oldPage это первая страница и мы ее остановим, также проверим следующую за ней и также остановим ее
        // поскольку она не является ни текущей 3й, ни следующей за ней 4й
        var nextPageToOld = oldPage && this.getPage(oldPage.num + 1);

        if (nextPageToOld && !(nextPageToOld == newPage || (nextPageToOld == nextPage && params.nextPageVisible))) {
          nextPageToOld.stop();
        }
      }
    } else {
      params.animation = false; // первый раз страница показывается без анимации в любом случае
    }

    // флаг что началась анимация смены страниц
    // это чтобы приостановить асинхронный рендеринг виджетов
    this.pauseLoading('page-change-animation');

    this.currentPage = newPage;

    // сбрасываем ожидание конца анимации для предыдущей страницы (это важно если быстро листаем страницы и текущая страница уже сменилась, но ее колбек еще не выстрелил, а если выстрелит то все испортит)
    oldPage && oldPage.resetWaitForAnimationEnd();

    // останавливаем прежнее ожидании когда юзер ничего не делает, чтобы не запустилась предыдущая страница
    clearInterval(this.showPageCompleteInterval);

    // включаем или выключаем транзишены в зависимости от того надо ли анимировать с анимацией или же нет
    this.$pages_container.toggleClass('disable-transitions', !params.animation);

    // навешивать надо до установки трансформа, колбек окончания анимации текущей страницы
    if (params.animation) {
      // в режиме слайдина текущая страница двигается только при движениии по страницам вперед
      // при движении назад она стоит на месте, поэтому на нее нельзя навешивать
      if (this.viewOpts.slidein && oldPage) {
        if (oldPage.num > newPage.num) {
          oldPage.waitForAnimationEnd(onPageChange);
        } else {
          newPage.waitForAnimationEnd(onPageChange);
        }
      } else {
        newPage.waitForAnimationEnd(onPageChange);
      }
    }

    // считаем что юзер желает двигаться по страницам вперед, если:
    // переход на странице был без анимации или
    // если есть предыдущая страница и она по номеру меньше новой
    // если нет предыдущей страницы (первый заход)
    this.direction = !oldPage || oldPage.num < newPage.num ? 'forward' : 'backward';

    // смотрим начало и конец блока видимых страниц

    var firstVisible =
        newPage.num - this.getPreloadPageCount(newPage.num - 1, this.direction === 'forward' ? 'backward' : 'forward'),
      lastVisible = newPage.num + this.getPreloadPageCount(newPage.num - 1, this.direction);

    // Для горизонтального вьювера без навигации стрелками не нужен прелоад соседних страниц
    if (this.opts.viewertype == 'horizontal' && !this.opts.arrows) {
      firstVisible = lastVisible = newPage.num;
    }

    // позиционируем страницы по новой
    // также показываем близлежащие страницы
    // всем страницам ставим классы: те что слева текущей .prev, те что справа .next, текущей .center (отсчет по номерам страниц)
    // для вертикаьлного вида этот код тоже нужен, но там по факту ничего не скрывается (в css есть оверрайды для случая вертикального вьювера)
    for (var i = 0; i < this.pages.length; i++) {
      var page = this.pages[i];
      if (page.num < newPage.num) page.setPosition('prev', page.num + 1 == newPage.num);
      else if (page.num > newPage.num) page.setPosition('next', page.num - 1 == newPage.num);
      else page.setPosition('center', page.num == newPage.num);

      // близлежайшие страницы делаем display:block
      if (page.num >= firstVisible && page.num <= lastVisible) {
        page.show();
      }

      // для всех страниц кроме текущей и следующей за ней сбрасываем все трансформы (и наружный и внутренний)
      // чтобы их положение на экране управлялось классами prev-page и next-page
      if (this.viewOpts.viewertype == 'vertical' && (page.num < newPage.num || page.num > newPage.num + 1)) {
        // сбрасываем все трансформы у страницы (и наружный и внутренний)
        page.resetScrollOnVerticalMode();
      }
    }

    // если без анимации, тогда сразу вызываем колбэк
    !params.animation && onPageChange();

    // Записываем в историю только при первом запуске роутера. skipInternalHistory нужен для переходов по ссылкам виджетов Go Back
    this.scheduleSetUrlString({ replace: !first_run, skipInternalHistory: params.skipInternalHistory });

    this.router.setTitle();

    // просто так не менять!
    // используется еще и в сторонних приблудах, типа GTM для хаффингтона
    this.trigger('pageChanged', newPage, {
      showPageCounter: params.animation || params.bySwipe || first_run || params.initiator == 'scroll',
      initial: first_run,
    });

    newPage.trigger('changedTo');

    if (this.viewOpts.viewertype == 'vertical') {
      this.updateArrowsColor(newPage);
    }

    // новая страница показана на своем месте по центру экрана (с анимацией либо без)
    function onPageChange() {
      // восстанавливаем транзишены страниц, если они были отключены.
      // во всех случаях, кроме первого запуска-рендера мэга, т.к. этом случае
      // транзишены могут навесится обратно слишком рано и будет видно,
      // как страницы разъезжаются на исходные позиции при заходе в мэг.
      // не возвращать транзишены не страшно, т.к. при переходе на другую страницу
      // они все равно заранее навесятся.
      if (!params.animation && !first_run)
        window.requestAnimationFrame(function() {
          // и обязательно при следующем кадре, т.к. при переходе на страницы
          // через мэг-меню мы не хотим анимации, но без попадания в кадр
          // транзишены навешиваются обратно слишком рано и анимации будет.
          self.$pages_container.removeClass('disable-transitions');
        });

      // флаг что закончилась анимация смены страниц
      // это чтобы продолжить асинхронный рендеринг виджетов
      self.continueLoading('page-change-animation');

      if (self.viewOpts.viewertype == 'vertical' && params.initiator != 'scroll') {
        // просто тупо установить скрол бар у нужное положение
        // но только в том случае, если showPage вызыван НЕ в результате скрола
        self.setScroll(self.magPagesPos[newPage.num].y);
      }

      // Спецхак для webkit. Если изображение вьюпорт-девайса в превью не умещалось по высоте в экран,
      // то при переходе на скролл-страницу происходил сдвиг вверх, хотя везде стоит overflow:hidden
      // Похожий код есть в constructor/router.js в goPreview
      // https://trello.com/c/T2HGM3id/139--
      self.isPreview && self.$el.scrollTop(0);

      // останавливаем прежнее ожидании когда юзер ничего не делает, чтобы не запустилась предыдущая страница
      clearInterval(self.showPageCompleteInterval);

      // если юзер cейчас ничего не делает, тогда можно сразу запускать страницу, загружать новые и прятать старые
      if (!self.loadingPaused) {
        innerCallback();
      } else {
        // в противном случае запускаем таймер по которому будем ждать момента когда юзер ничего не делает
        // чтобы можно было запустить текущую страницу, начать загружать новые и прятать старые
        self.showPageCompleteInterval = setInterval(function() {
          if (!self.loadingPaused) {
            clearInterval(self.showPageCompleteInterval);
            innerCallback();
          }
        }, 17);
      }

      function innerCallback() {
        // прячем все страницы кроме текущей, левой и правой
        for (var i = 0; i < self.pages.length; i++) {
          var page = self.pages[i];

          if (page.num < firstVisible || page.num > lastVisible) {
            page.hide();
          }
        }

        // сразу запускаем виджеты на странице
        // важно это сделать до self.loadPages
        // для виджетов фб, ифрейма и видео
        // иначе те из них что находтся на текущей видимой странице
        // начнут рендерится до старта, и подумают что они на неактивной странице
        // и тогда стриггерят лоад событие сразу же (но не для скриншотера!)
        // поскольку фактически они начинают рендерится только при старте
        // upd. проверка на FinalPage это для вертикального вьювера
        // может быть так, что мы нажали end с разу отскролили в самый низ
        // в таком случае сначала сработает триггер показа файнал пейджа и все страницы остановятся
        // а потом сработает старт последней, что нам тут не надо
        !self.lastFinalPageState && newPage.start();

        // надо заново пересмотреть какие виджеты надо загрузить сейчас, а какие попозже
        // заодно задаем порядок загрузки если идем по страницам вперед, тогжа начинаем предгрузить страницы вперед, и наоборот
        self.loadPages();
      }
    }
  },

  /**
   * Откладывает для хрома обновление урла страницы до окончания скролла
   * Сделано из-за бага в хроме: он фризит весь интерфейс при history.pushState и replaceState,
   * что вызывает разрывы в плавном скролле вертикального вьювера
   */
  scheduleSetUrlString: function() {
    if (!Modernizr.chrome) {
      this.router.setUrlString.apply(this.router, arguments);
      this.router.trackPage(); // аналитика

      this._resetUrlStringTimeoutFunc = false;
      return;
    }

    var args = arguments;

    this._resetUrlStringTimeoutFunc = function() {
      clearTimeout(this._urlStrigScheduleTimeout);
      this._urlStrigScheduleTimeout = setTimeout(
        function() {
          this._resetUrlStringTimeoutFunc = null;
          this.router.setUrlString.apply(this.router, args);
          this.router.trackPage(); // аналитика
        }.bind(this),
        100
      );
    }.bind(this);

    this._resetUrlStringTimeoutFunc();
  },

  // Найти следующую страницу и перейти на нее
  // не вызывается в режиме вертикального вьювера
  goNextPage: function(params) {
    var finalPageShown = this.finalPage && this.finalPage.shown;

    // если мы находимся на последней странице
    // тогда просим показать final page если он есть и еще не показан
    if (this.isLastPage()) {
      if (!finalPageShown) {
        this.finalPage && this.finalPage.show();
        this.finalPage && this.redrawAboveGlobalWidgets(this.finalPage);
      }
      return;
    }

    this.currentPage && this.router.go(this.currentPage.num + 1, params);
  },

  // Найти предыдущюю страницу и перейти на нее
  // не вызывается в режиме вертикального вьювера
  goPrevPage: function(params) {
    var finalPageShown = this.finalPage && this.finalPage.shown;

    // если мы находимся на последней странице
    // и у нас показан final page, то просим его скрыть
    if (this.isLastPage() && finalPageShown) {
      this.finalPage && this.finalPage.hide();
      this.finalPage && this.redrawAboveGlobalWidgets(this.currentPage);
      return;
    }

    // если мы на первой странице - выходим
    if (this.isFirstPage()) {
      return;
    }

    this.currentPage && this.router.go(this.currentPage.num - 1, params);
  },

  goFirstPage: function() {
    this.router.go(1);
  },

  goLastPage: function() {
    this.router.go(this.getPagesCount());
  },

  // функция вызывается если надо приостановить загрузку виджетов по какой-либо причине (идет анимация или тач начался, например)
  // при этом передается причина reason
  // виджеты продолжают грузиться до тех пор пока нет ни одной причины не грузится
  // как только появляется хотя бы одна - останов
  pauseLoading: function(reason) {
    this.pauseReasons = this.pauseReasons || {};

    this.pauseReasons[reason] = true;

    this.loadingPaused = true;
  },

  // функция вызывается если надо продолжить загрузку виджетов по причине снятия предыдущего ограничения (анимация или тач закончились, например)
  // при этом передается причина reason
  // виджеты продолжают грузиться только если все причины false (а пока есть true причины, лоадер гоняет пустой цикл ожидания)
  continueLoading: function(reason) {
    this.pauseReasons = this.pauseReasons || {};

    this.pauseReasons[reason] = false;

    // отменяем паузу лоадинга если все свойства объекта pauseReasons равны false (т.е. если не осталось причин для останова)
    this.loadingPaused = !_.every(_.values(this.pauseReasons), function(reason) {
      return !reason;
    });
  },

  // загрузка текущей страницы и прелоадинг соседних видимых
  loadPages: function() {
    if (!this.pages || !this.pages.length) return;

    // получаем порядок страниц в порядке приоритета загрузки их контента на данный момент
    // не возвращает уже загруженные или невидимые
    var pagesLoadOrder = this.getPagesLoadOrder();

    // все уже загружено в данной видимой области
    if (!pagesLoadOrder || !pagesLoadOrder.length) return;

    // пачки виджетов по странично
    var pagesWidgetsPacks = [],
      i,
      j,
      upcomingPage;

    // Пачка виджетов со всех страниц, которая рендерится сразу (все страницы)
    var immediatePack = [];

    // получаем с каждой страницы пачки виджетов которые попадают во фрейм высотой в экран в таком порядке:
    // фон, фикседы, текущие видимые виджеты на экране (учитываем скрол и скейл),
    // потом пачки в порядке: одна вниз, одна вверх, все вниз (по одной), все вверх (по одной)
    for (i = 0; i < pagesLoadOrder.length; i++) {
      upcomingPage = this.pages[pagesLoadOrder[i]];
      var packs = upcomingPage.getWidgetsPacks();
      // если есть хоть одна пачка виджетов которую надо загрузить
      if (packs.length) {
        pagesWidgetsPacks.push(packs);
      }
      immediatePack = immediatePack.concat(upcomingPage.getImmediatePack());
    }

    // все уже загружено в данной видимой области (или уже загружается)
    if (!pagesWidgetsPacks.length) return;

    var widgetsPacksOrder = [];

    // сортируем пачки виджетов в порядке приоритета загрузки
    // сначала первая пачка первой страницы pagesLoadOrder
    // потом первая пачка второй страницы pagesLoadOrder
    // потом по очереди все пачки первой страницы pagesLoadOrder
    // потом повторяем все для второй страницы (но минуя ее первую пачку) и так по циклу
    for (i = 0; i < pagesWidgetsPacks.length; i++) {
      // добавляем самую первую пачку виджетов текущей в списке страницы
      // но только для перой страницы в списке, для остальных страниц первая пачка добавляется на предыдыщуем шаге
      i == 0 && addPack(i, 0);

      // добавляем самую первую пачку виджетов следующей от текущей в списке страницы
      addPack(i + 1, 0);

      // добавляем по очереди все пачки текущей страницы, без первой пачки
      for (var j = 1; j < pagesWidgetsPacks[i].length; j++) {
        addPack(i, j);
      }
    }

    widgetsPacksOrder.unshift(immediatePack);
    this.widgetsPacksLoadQueue = widgetsPacksOrder;

    // останавливаем прежнюю очередь загрузки
    // но текущую свою пачку она все равно доделает, тут без вариантов
    // но благо пачки виджетов идут в размер экрана, т.е. оптимального размера
    // возможно потом не будем сбрасывать этот таймер, просто менять очередь и функция сама будет подцеплять ее
    // но пока сбрасываем, скорость реакции загрузчика на смену обстановки так будет быстрее
    // вопрос только не будет ли проблем если loadPages вызывать слишком часто, каждый его вызов в таком случае добавляет асинхронную загрузку еще одной пачки
    this.stopPagesLoad();

    // запускаем цикл загрузки пачек из очереди
    this.loadNextWidgetsPack();

    function addPack(pageInd, packInd) {
      if (pageInd < 0 || pageInd >= pagesWidgetsPacks.length) return;
      if (packInd < 0 || packInd >= pagesWidgetsPacks[pageInd].length) return;
      widgetsPacksOrder.push(pagesWidgetsPacks[pageInd][packInd]);
    }
  },

  stopPagesLoad: function() {
    clearTimeout(this.loadNextWidgetsTimer);
  },

  // берет из this.widgetsPacksLoadQueue первую пачку виджетов
  // удаляет ее оттуда
  // запускает лоадинг всех виджетов в пачке
  loadNextWidgetsPack: function() {
    // в очереди загрузки не осталось виджетов, выходим
    if (!this.widgetsPacksLoadQueue.length) {
      // Сообщим о том, что очередь загрузки пустая — используется, например, при логировании таймингов
      Backbone.trigger('widgetsQueue:empty', this.num_id);
      return;
    }

    // если запаузили рендеринг виджетов по какой-либо причине
    // запускаем пустой цикл
    if (this.loadingPaused) {
      this.loadNextWidgetsTimer = setTimeout(this.loadNextWidgetsPack, 50);
      return;
    }

    // забираем первый элемент из очереди к себе в обработку
    var pack = this.widgetsPacksLoadQueue.shift();

    // если в пачке нет виджетов (такого быть не должно правда)
    if (!pack.length) {
      this.loadNextWidgetsTimer = setTimeout(this.loadNextWidgetsPack, 17);
      return;
    }

    // находим какой странице принадлежат виджеты этой пачки
    // В одной пачке могут быть виджеты с нескольких страниц. Например, в самой первой пачке виджетов, которым не нужна загрузка и они рендерятся сразу
    var pages = _.uniq(_.map(pack, 'page'));
    var page = pages[0];

    // если страница одна (потому что для первой пачки виджетов, которые грузятся сразу, страниц может быть несколько)
    // не видна или загружена уже вся тогда пропускаем пачку (по сути все виджеты в ней уже должны быть загружены)
    if (pages.length === 1 && (!page.shown || page.loaded)) {
      this.loadNextWidgetsTimer = setTimeout(this.loadNextWidgetsPack, 17);
      return;
    }

    // Отметим время начала загрузки пачки виджетов
    var packNumber = this.widgetPackCount + 1;

    page.loadWidgetsPack(
      pack,
      _.bind(function() {
        // Отметим время окончания загрузки пачки виджетов

        this.widgetPackCount++;
        this.loadNextWidgetsTimer = setTimeout(this.loadNextWidgetsPack, 17);
      }, this)
    );
  },

  getPreloadPageCount: function(ind, direction) {
    if (this.viewOpts.viewertype == 'horizontal') {
      return this.PRELOAD_COUNT[direction];
    }
    var count = 1,
      countPx = 0,
      dir = direction == 'forward' ? 1 : -1;
    while (countPx < this.PRELOAD_COUNT[direction] && count * dir + ind < this.pages.length && count * dir + ind >= 0) {
      countPx += this.pages[count * dir + ind].height;
      count++;
    }
    return count;
  },

  // получаем порядок страниц в порядке приоритета загрузки их контента на данный момент
  // не возвращает уже загруженные или невидимые
  getPagesLoadOrder: function() {
    var page = this.currentPage;

    if (!page) return;

    var curInd = page.num - 1,
      len = this.pages.length,
      pagesOrder = [],
      self = this,
      i;

    // смотрим начало и конец блока видимых страниц (могу выходить за пределы области страниц, там дальше проверки)
    var firstVisible =
        page.num - this.getPreloadPageCount(curInd, this.direction == 'forward' ? 'backward' : 'forward'),
      lastVisible = page.num + this.getPreloadPageCount(curInd, this.direction);

    // порядок обработки страниц (cur номер текущей страницы, first-last область видимых страниц)
    // direction == 'forward'  ->  cur, cur + 1, cur - 1, cur + 2 ... last,  cur - 2 ... first
    // direction == 'backward' ->  cur, cur - 1, cur + 1, cur - 2 ... first, cur + 2 ... last

    addPage(curInd);

    if (this.direction == 'backward') {
      addPage(curInd - 1);
      addPage(curInd + 1);
      for (i = curInd - 2; i >= firstVisible; i--) addPage(i);
      for (i = curInd + 2; i <= lastVisible; i++) addPage(i);
    } else {
      // forward
      addPage(curInd + 1);
      addPage(curInd - 1);
      for (i = curInd + 2; i <= lastVisible; i++) addPage(i);
      for (i = curInd - 2; i >= firstVisible; i--) addPage(i);
    }

    return pagesOrder;

    function addPage(ind) {
      if (ind < 0 || ind >= len || !self.pages[ind].shown || self.pages[ind].loaded) {
        return;
      }

      pagesOrder.push(ind);
    }
  },

  onGestureChange: function() {
    // важно вызывать например при пинче, когда меняется скейл
    // потому что если нативный скейл есть, надо указывать высоту контента 100%
    // а если нет, тогда window.innerHeight
    this.onResize.__debounced();
  },

  // запускает проверку на режим фулскрина вкл он или выкл
  // делает 5 попыток с интервалом 500мс
  // почему так через одно место? почему не через fullscreen API
  // 1. стандартные события fullscreen API не стреляют при F11, это просто пиздец, нахуй это апи нужно если оно само в себе и не связано с дефолтным браузерным фулскрином
  // 2. также через это апи нельзя определить фулскрин режим сейчас или нет, если вы зашли в него через F11, facepalm
  // 3. почему делаем несколько попыток? потому что некоторые упоротые браузеры типа ФФ анимируют переход в фулскрин, и нам надо сделать несколько попыток,
  //   можно конечно сделать одну через 3 сек, но лучше очередью, чтобы как можно быстрее поймать смену состояния
  // 4. IE очень плохо поддерживает fullscreen API, что в свете перечисленных выще проблем уже не кажется самой большой проблемой
  // зы: метод не сработает если, к примеру, открыта консоль разработчика
  checkFullscreen: function() {
    this.fullscreenCheckCounter = 5;
    clearInterval(this.fullscreenCheckTimer);

    this.fullscreenCheckTimer = setInterval(
      _.bind(function() {
        // проверяем на то, что у нас внутренности браузера совпадают по размеру с окном браузера (т.е. chromeless state по факту)
        // в любимом ФФ все конечно по другому и проверка на размеры не проканает, зато проканает проприетарный window.fullScreen
        // window.innerHeight >= screen.height - 1 это для обожаемого IE у которого фулскрин на 1px меньше экрана (к слову у ФФ тоже, похоже они из одного инкубатора)
        var state = window.fullScreen || (window.innerWidth == screen.width && window.innerHeight >= screen.height - 1);

        this.togglePresentationMode(state);

        this.fullscreenCheckCounter--;
        if (!this.fullscreenCheckCounter) clearInterval(this.fullscreenCheckTimer);
      }, this),
      500
    );
  },

  onResize: function(e) {
    this.checkFullscreen();

    // на девайсах принудительно правим высоту html и body
    // потому что например на ифонах высота 100% у html и у body возвращает высоту окна без учета того что там есть навигационный бар и нижний бар
    // и у нас весь блок html сдвигается вниз на высоту этого бара и никак его обратно не отскролить
    // это происходит на всех ифонах в горизонтальном режиме а также на 6+ в вертикальном (иногда)
    if (
      (!Modernizr.isdesktop && this.viewOpts.viewertype == 'horizontal') ||
      (this.viewOpts.viewertype == 'vertical' && !this.isStickyVerticalViewer)
    ) {
      var $items = this.viewOpts.viewertype == 'horizontal' ? $('html, body').add(this.$el) : this.$el,
        scaled = Utils.isPageNativelyScaled(),
        height = window.innerHeight;
      // костыль для 6+ ифона с его гребаным масштабированием: 1 пиксел css -> 3 виртуальных пиксела -> 2.60869... физических пиксела
      // в горизонтальном виде вместе с верхней навигационной панелью и блоком табов
      // свободной области остается 1012 виртуальных пикселей: 1242(весь экран) - 132(навигационный блок) - 98(вкладка табов)
      // итого имеем 337.33333 css писксела для свободной области (1012 / 3)
      // поскольку css не умеет работать с дробными значениями для высоты окна
      // он возвращает нам 337 которые мы ему и устанавливаем
      // ну а браузер потом рендерит это в виртуальные пикселы как 1011 (3 * 337)
      // т.е. свободного места 1012, а мы заняли 1011, оставшийся один пиксел будет белым цветом фона боди,
      // на экране эта нижняя полоска в 1 виртуальный пиксел будет отрендерена в виде 0.8695652... физического пиксела
      if (height == 337 && $(window).height() == 414) height++;

      // в режиме нативного скейла надо указывать 100% высоту
      // потому что window.innerHeight возвращает отскейленое значение высоты окна
      $items.height(scaled ? '100%' : height);

      // скролим все к верху для фиксинга багов при поворотах и пр.
      // но только не в режиме скейла!
      !scaled && $items.scrollTop(0);
    }

    var containerSize = this.getContainerSize(),
      orientation = containerSize.width > containerSize.height ? 'landscape' : 'portrait',
      orientationChanged = false;

    // если ресайз произошел в связи со сменой ориентации
    // тогда обрабатываем смену вьюпорта если надо
    // просто навешивать на собтие смены ориентации не стоит, потому что там непонятно в каком порядке
    // выстреливают события ресайза и смены ориентации
    if (!Modernizr.isdesktop && this.prevOrientation && this.prevOrientation != orientation) {
      this.switchToViewport(this.getMagViewport());

      this.setPagesTransitions();

      this.stopPagesLoad();

      orientationChanged = true;

      // вызываем ресайз по таймеру еще раз, потому что на 6+ сразу после смены ориентации данные по высоте экрана не корректные
      setTimeout(this.onResize, 300);

      // фикс бага когда иногда при смене ориентации в лэндскейп режиме
      // высота контейнера была слишком большой (только ифон 6+ когда видны табы)
      // https://trello.com/c/HS3UiwgN/62-1
      setTimeout(this.onResize, 1500);
    }

    this.prevOrientation = orientation;

    // если выбран мобильный вьюпорт и мы в режиме превью конструктора
    // тогда окно просмотра мега надо вписать внутрь виртуального устройства
    if (this.isPreview && this.viewport != 'default') {
      var left = Math.round((this.$el.width() - containerSize.width) / 2),
        top = Math.round((this.$el.height() - containerSize.height) / 2);

      this.$mag_container.add(this.$mag_viewport_device).css({
        top: Math.max(top, 0),
        left: Math.max(left, 0),
        width: containerSize.width,
        height: containerSize.height,
      });
    } else {
      // иначе удаляем все размеры-положения из инлайн стиля
      this.$mag_container.add(this.$mag_viewport_device).css({
        top: '',
        left: '',
        width: '',
        height: '',
      });
    }

    this.$mag_viewport_device.attr('data-viewport', this.viewport);

    // создаем стили положения и размера для текущих страниц мэга
    // делать это через обычные стили с процентами не стоит
    // горазд производительнее каждый раз при ресайзе создавать динамические стили с фиксированными размерами-координатами в пикселах, проверено
    this.setNewPagesWidths(containerSize.width, containerSize.height);

    // это для вертикального вида
    this.magHeight = 0;
    this.magPagesPos = [];

    var pagesCount = this.pages && this.pages.length;
    // просим все страницы применить ресайз (невидимые его просто проигнорят)
    _.each(
      this.pages,
      _.bind(function(page, ind) {
        var pageHeight = page.onResize({ absolutePosition: this.magHeight }),
          dopHeight = 0;
        // 150 это бонус к размеру страницы, чтобы при вертикальном скроле была небольшая пауза когда полностью отскролилась текущая страница и начала скролится следующая
        // для последней страницы бонус добавляем только в том случае, если есть файнал пейдж
        // но только для режима когда страницы идут с наложением друг на друга
        if ((ind < pagesCount - 1 || this.finalPage) && this.viewOpts.slidein) dopHeight = this.SCROLL_SNAP;

        this.magPagesPos[page.num] = { y: this.magHeight, h: pageHeight, dh: dopHeight };
        this.magHeight += pageHeight + dopHeight;
      }, this)
    );

    if (this.finalPage) {
      if (this.isStickyVerticalViewer) {
        this.finalPage.setSizeAndPosForStickyVersion({ absolutePosition: this.magHeight });
      }

      this.magHeight += this.finalPage.getHeight();
    }

    if (this.viewOpts.viewertype == 'vertical' && this.magHeight) {
      var bodyWidth = this.$magScrollSizer.width(),
        previewDifference = 0;

      // для превью режима мобильного вьюпорта надо учесть что скрол глобальный
      // но расчет его произвожится для высоты контейнера внутри девайса
      // т.е. нам надо принудительно нивелировать эту разницу
      if (this.isPreview && this.viewport != 'default') {
        previewDifference = Math.max(this.$el.height() - this.getViewportSetting('min_height'), 0);
      }

      this.$magScrollSizer.css('height', this.magHeight + previewDifference);

      // появился скролбар - вызываем пересчет ресайза
      // если исчез - тоже вызываем
      // вроде зациклиться не должно (на десктопе, потому что у нас нет зависимости высоты контента от ширины, из-за чего может появиться цикл)
      // а вот на девайсах очень даже может, но на них не надо этих проверок вообще, потому что там скрол не отжирает место
      if (bodyWidth != this.$magScrollSizer.width() && Modernizr.isdesktop) {
        this.onResize();
      }

      if (this.currentPage) {
        // восстанавливаем позицию скрола для вертикального режима вьювера при ресайзе
        if (!this.isStickyVerticalViewer) {
          this.setScroll(this.getScrollPosForNewSize());
          this.onMagScroll();
        } else if (orientationChanged) {
          // стики вьюера и смена ориентации (стики только для мобильных и только iOS пока)
          var preserveScrollPos = this.getScrollPosForNewSize();
          setTimeout(
            _.bind(function() {
              this.setScroll(preserveScrollPos);
            }, this),
            0
          );
        }
      }

      this.$bottomArrow &&
        this.$bottomArrow.toggleClass(
          'offscreen',
          this.getScroll() + containerSize.height + this.SCROLLABLE_TRESHOLD > this.magHeight
        );
    }

    // восстанавливаем позицию скрола при смене ориентации для горизонтального режима вьювера
    if (this.viewOpts.viewertype == 'horizontal' && this.currentPage && orientationChanged) {
      var preserveScrollPos = this.getScrollPosForNewSize();
      setTimeout(
        _.bind(function() {
          this.currentPage.$scrollWrapper.scrollTop(preserveScrollPos);
        }, this),
        0
      );
    }

    // после ресайза заново смотрим какие виджеты надо грузить в первую очередь
    // проверка this.currentPage - первоначально не запускаем
    if (this.currentPage) this.loadPages.__debounced();
  },

  getScrollPosForNewSize: function() {
    if (!this.currentPage) return 0;

    if (this.viewOpts.viewertype == 'vertical') {
      return this.magPagesPos[this.currentPage.num].y + (this.lastPageScrollPercent || 0) * this.currentPage.pageHeight;
    } else {
      return (this.lastPageScrollPercent || 0) * this.currentPage.pageHeight;
    }
  },

  // создаем стили положения и размера для текущих страниц мэга
  // а также для final page!
  // проставлять их каждый раз страницам через инлайн накладно, а использовать проценты нельзя (тупит при анимации)
  // да и вообще не работать с инлайн стилями гуд практис в итоге по понятности и надежности кода
  setNewPagesWidths: function(width, height) {
    var $style = this.$el.find('#page-position-style'),
      hasOwnLayerInitially = Modernizr.isdesktop ? '' : ' translateZ(0)',
      styleSize =
        '\
        .mag .mag-pages-container .container {\n\
          left: ' +
        0 +
        'px;\n\
          width: ' +
        width +
        'px;\n\
        }\n\
        .mag .mag-pages-container .container .page {\n\
          left: ' +
        0 +
        'px;\n\
          width: ' +
        width +
        'px;\n\
        }\n\
        .mag .mag-pages-container .container .final-page {\n\
          left: ' +
        0 +
        'px;\n\
          width: ' +
        width +
        'px;\n\
        }\n',
      stylePos = '';

    if (this.viewOpts.viewertype == 'horizontal') {
      stylePos =
        '\
          .mag .mag-pages-container .container .final-page.offscreen {\n\
            -webkit-transform: translateX(' +
        width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
            transform: translateX(' +
        width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
          }\n\
          .mag .mag-pages-container .container .page.prev-page {\n\
            -webkit-transform: translateX(' +
        (this.viewOpts.slidein ? 0 : -width) +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
            transform: translateX(' +
        (this.viewOpts.slidein ? 0 : -width) +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
          }\n\
          .mag .mag-pages-container .container .page.center-page {\n\
            -webkit-transform: translateX(0) ' +
        hasOwnLayerInitially +
        ';\n\
            transform: translateX(0) ' +
        hasOwnLayerInitially +
        ';\n\
          }\n\
          .mag .mag-pages-container .container .page.next-page {\n\
            -webkit-transform: translateX(' +
        width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
            transform: translateX(' +
        width +
        'px) ' +
        hasOwnLayerInitially +
        ';\n\
          }';
    } else {
      // vertical
      // очень важно раскидать ве страницы за пределы экрана
      // иначе бразуер будет их рендерить, даже несмотря на то что они перекрываются другими страницами
      // +1 тоже обязательно, иначе часть страницы каким-то образом все равно оказывается в области рендеринга
      // "Enable continuous page repainting" в помощь
      if (!this.isStickyVerticalViewer) {
        stylePos =
          '\
            .mag .mag-pages-container .container .final-page {\n\
              top: ' +
          (height + 1) +
          'px;\
            }\n\
            .mag .mag-pages-container .container .page.prev-page {\n\
              top: ' +
          (-height - 1) +
          'px;\
            }\n\
            .mag .mag-pages-container .container .page.next-page {\n\
              top: ' +
          (height + 1) +
          'px;\
            }\n';
      } else {
        var containerHeight = this.getContainerSizeCached().height;
        stylePos =
          '\
            .mag .mag-pages-container .container .page .page-fixed-bg-container,\
            .mag .mag-pages-container .container .page .fixed-position-container,\
            .mag .mag-pages-container .container .page .fixed-position-container-top {\
              height: ' +
          containerHeight +
          'px;\
            }\n\
            .mag .mag-pages-container .container .page .fixed-position-container,\
            .mag .mag-pages-container .container .page .fixed-position-container-top,\
            .mag .mag-pages-container .container .page:not(.hide-sticky) .content-scroll-wrapper {\
              margin-top: ' +
          -containerHeight +
          'px;\
            }\n';
      }
    }

    if (!$style.length) {
      $style = $('<style id="page-position-style" type="text/css">');
      $style.appendTo(this.$el);
    }

    if (this.viewOpts.viewertype == 'horizontal') {
      this.$pages_container.addClass('disable-transitions-on-resize');
      $style.text(styleSize + stylePos);
      this.$pages_container.height(); // заставляем элемент перерисоваться до начала animation frame, чтобы не сработал последующий транзишен
      this.$pages_container.removeClass('disable-transitions-on-resize');
    } else {
      // vertical
      $style.text(styleSize + stylePos);
    }
  },

  // создаем стили транзишенов для страниц мэга и файнал пейджа
  // делать это через wcc не удобно и не шибко очевидно
  setPagesTransitions: function() {
    var $style = this.$el.find('#page-transition-style'),
      time = this.getPageTransitionTime(),
      easing = 'cubic-bezier(0.40, 0.24, 0.40, 1)',
      style =
        '\
        .mag .mag-pages-container .container .page {\n\
          -webkit-transition:	all ' +
        time +
        'ms ' +
        easing +
        ';\
          transition: all ' +
        time +
        'ms ' +
        easing +
        ';\
        }\n\
        .mag .mag-pages-container .container .page.center-page {\n\
          -webkit-transition:	all ' +
        (time - 5) +
        'ms ' +
        easing +
        ';\
          transition: all ' +
        (time - 5) +
        'ms ' +
        easing +
        ';\
        }\n\
        .mag .mag-pages-container .container .final-page {\n\
          -webkit-transition:	all ' +
        time +
        'ms ' +
        easing +
        ';\
          transition: all ' +
        time +
        'ms ' +
        easing +
        ';\
        }\n\
        ';

    if (!$style.length) {
      $style = $('<style id="page-transition-style" type="text/css">');
      $style.appendTo(this.$el);
    }

    $style.text(style);
  },

  // смена текущего вьюпорта во всем мэге
  // страницы сами разберутся какой вьюпорт им показывать из тех что в них были включены в конструкторе (эти настройки индивидуальны для страниц)
  switchToViewport: function(viewport) {
    if (this.viewport == viewport) return;

    var oldViewport = this.viewport;

    this.containerSize = null;
    // сохраняем значение нового вьюпорта
    this.viewport = viewport;
    this.trigger('change:viewport', { viewport: this.viewport, oldViewport: oldViewport });

    this.aboveGlobalWidgetsRendered = false;
    this.aboveGlobalWidgets &&
      this.aboveGlobalWidgets.forEach(function(w) {
        w.destroy();
        w.off();
      });

    this.aboveGlobalAnimations && this.aboveGlobalAnimations.destroy();

    this.aboveGlobalWidgets = [];
    // просим все страницы проверить поменялся ли у них вьюпорт в связи со сменой вьюпорта в мэге
    // если поменялся, тогда они удалят все виджеты
    // если не поменялся, тогда ничего делать не надо (свои размеры и скейл (если есть) они изменят по событию onResize, которое сработает до смены вьюпорта)
    _.each(this.pages, function(page) {
      page.updateViewport();
    });
  },

  getContainerSize: function() {
    var w,
      h,
      self = this;

    // ширина и высота будут отличаться от ширины и высоты окна только в случае
    // если выбран мобильный вьюпорт и мы в режиме превью конструктора
    if (this.isPreview && this.viewport != 'default') {
      w = this.getViewportSetting('width');
      // тут Math.min чтобы при очень маленьком окне скрол внутри устройста скролил бы весь контент
      // долго объяснять, в общем без этого Math.min скролбар вылезает вниз за границу экрана так же как и часть контента снизу
      h = Math.min(this.getViewportSetting('min_height'), this.$el.height());
    } else {
      w = RM.screenshot ? $(window).width() : this.$el.width(); //от окна брать нельзя, у него нет ограничений на минимальный размер, а у контейнера мэгов есть, и мы должны учитывать это

      if (RM.screenshot) {
        h = window.innerHeight;
        // } else if (Modernizr.isdesktop && this.isStickyVerticalViewer) {
      } else if (this.isStickyVerticalViewer && Modernizr.safari && !Modernizr.isdesktop) {
        h = getAvailableSreenHeight();
      } else if (this.isStickyVerticalViewer) {
        h = window.innerHeight;
      } else {
        h = this.$el.height();
      }
    }

    // кешируем
    this.containerSize = { width: w, height: h };

    return this.containerSize;

    // из-за исчезающих статус и навигейшн баров у нас постоянно меняется размер высоты экрана
    // а у нас на высоту завязаны фоны
    // и чтобы не делать лишних пересчетов в моменты когда эти бары появляются-исчезают (да и определить этот моммент весьма сложно)
    // мы просто по дефолту говорим что у нас высота  экрана равна
    // высоте экрана устройства при landscape
    // и высоте экрана - 20 - 20 при portrait (вычитаем высоту системного верхнего бара и сколлапсированного навигационного)
    function getAvailableSreenHeight() {
      // portrait
      if (self.isPortrait()) {
        return Math.max(window.innerHeight, screen.height - 20 - 20 + 1); // +1 важно, иногда белая полуписельная полоска снизу
      } else {
        // landscape
        // В мобильном сафари и на андройде screen.width и screen.height ведут себя по разному
        // в сафари - не зависят от ориентации
        // в хроме - зафисят
        return Math.min(screen.width, screen.height);
      }
    }
  },

  // возвращает кешированные данные, для скорости
  getContainerSizeCached: function() {
    return this.containerSize || this.getContainerSize();
  },

  isPortrait: function() {
    if (!window.screen) {
      return window.innerWidth < window.innerHeight;
    }

    if (window.screen.orientation && window.screen.orientation.type) {
      return !!window.screen.orientation.type.match(/portrait/i);
    }

    if (window.orientation === 0) {
      return true;
    }

    if (window.orientation === 90 || window.orientation === -90) {
      return false;
    }

    if (window.innerWidth < window.innerHeight) {
      return true;
    }
    return false;
  },

  // функция определяет с каким вьюпорте мы хотим показывать мэг
  // это не финальная инстанция, каждая из страниц потом сама будет подбирать
  // из какого вьюпорта брать данные если юзер в конструкторе не включит для страницы нужный вьюпорт (тот который мы тут определим для отображения всего мэга)
  getMagViewport: function() {
    // если выбран мобильный вьюпорт и мы в режиме превью конструктора
    // тогда возвращаем в качестве исходного вьюпорта для мэга тот, который указан сейчас для текущего воркспейса конструктора
    var isPortrait = this.isPortrait();
    if (this.isPreview) {
      return this.constructorViewport || 'default';
    } else {
      // если мы на десктопе - без вариантов, дефолтный десктоп
      var viewport = Viewports.viewports_constants.default;

      // Современные устройства с высоким разрешением и мобильные устройства
      if (Modernizr.retina || !Modernizr.isdesktop || Modernizr.ismobile) {
        // Устройства в портретном режиме 99% это мобильные устройства
        var windowWidth = window.innerWidth;

        // в айфонах во встроенном в приложения (не все, проблемы с инстаграмом) браузером
        // не верно определяется window.innerWidth и из-за этого выбирается не правильные вьюпорт

        if (screen && screen.width) {
          windowWidth = Math.min(screen.width, window.innerWidth);
        }

        if (isPortrait) {
          // Телефоны
          if (windowWidth < 600) {
            return Viewports.viewports_constants.phone_portrait;
            // Планшеты включая iPad pro
          } else if (windowWidth >= 600 && windowWidth <= 1024) {
            return Viewports.viewports_constants.tablet_portrait;
          }
          return Viewports.viewports_constants.default;
        } else if (windowWidth > 980) {
          // В альбомном режиме для планшетов показываем десктоп вью
          return Viewports.viewports_constants.default;
        }
      }

      // Хак для ipad-ов на всякий случай (вдруг появятся планшеты еще больше)
      if (Modernizr.isipad) {
        return isPortrait ? Viewports.viewports_constants.tablet_portrait : Viewports.viewports_constants.default;
      }

      // для мобильных устройств подбираем наиболее подходящий вьюпорт
      // сейчас алгоритм простой: выбираем среди них ближайший по ширине к ширине девайса (похожий код также в page.js getPageViewport)
      // потом возможно усложним алго
      if (!Modernizr.isdesktop) {
        var deviceWidth = this.$el.width(),
          closestViewportIndex = _.reduce(
            Viewports.viewports,
            function(memo, obj, ind) {
              if (Math.abs(obj.width - deviceWidth) <= Math.abs(Viewports.viewports[memo].width - deviceWidth))
                return ind;
              else return memo;
            },
            0
          );

        viewport = Viewports.viewports[closestViewportIndex].name;
      }

      return viewport;
    }
  },

  // метод возвратит настройки вьюпорта (width, min_height...) (по умолчанию для текущего вьюпорта)
  // значения берутся из настроек вьюпорта в VIEWPORTS.JS
  getViewportSetting: function(settingName, viewport) {
    viewport = viewport || this.viewport;

    var data = _.findWhere(Viewports.viewports, { name: viewport }) || {};

    return data[settingName];
  },

  log: function() {
    var a = '';
    for (var i = 0; i < arguments.length; i++) {
      a += arguments[i] + ' ';
    }

    var $b = $('#log');

    if (!$b.length) {
      $b = $('<div id="log">')
        .css({
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
          width: 200,
          height: 300,
          left: 10,
          top: 10,
          'z-index': 999999,
          overflow: 'auto',
        })
        .appendTo('body');
    }
    var old = $b.html(),
      splited = old.split('<br>');

    $b.html(splited.slice(-17).join('<br/>') + '<br/>' + a);
    $b.scrollTop(999999);
  },

  fixFocusScroll: function() {
    this.$mag_container.scrollTop(0);
    this.$mag_container.scrollLeft(0);
  },

  // Проверяем что ссылка указана на этот же проект но абсолютным урлом
  matchSameDomainLink: function(link) {
    var match = link.match(new RegExp('//' + Utils.escapeSpecial(window.location.hostname) + '/(.*)'));
    var pageUri = match && match[1];

    if (pageUri) {
      var page = this.getPage(pageUri);
      if (page) return page._id;
    }

    return link;
  },

  getScale: function(pageViewport) {
    if (RM.screenshot) {
      return 1;
    } // Для скриншотера всегда возвращаем оригинальнй скейл

    var containerWidth = Math.min(this.viewOpts.scalewidth, this.getContainerSizeCached().width),
      // ширина вьюпорта для pageViewport
      width = this.getViewportSetting('width', pageViewport),
      // ширина девайса или в режиме превью констрктора - области виртуального девайса

      // причем не для pageViewport, а для magViewport - т.е. вьюпорта-устройства выбранного для отображения всего мэга,
      // и не важно что страница может использовать дефолтный отмасштабированный, важно в каком виртуальном устройстве мы кажем весь мег
      scale = containerWidth / width;
    //при ширине окна большем, чем ширина скейла, проект прижимается к левому краю,
    //чтобы этого избежать навешиваем класс для центрирования контейнера
    if (containerWidth < this.getContainerSizeCached().width && this.viewOpts.scalableviewer && !Modernizr.firefox) {
      this.$el.find('.page-content-container').addClass('scale-centring');
    }

    return Scale.normalize(scale);
  },

  /**
   * Возвращает виджет. Если страница с виджетом ещё не загрузилась — ждёт, потом возвращает.
   * @param {String} id
   * @param {Page} [preferredPage] Страница, на которой предпочтительно найти виджет
   * (сделано из-за глобальных виджетов, которых может быть несколько с одним id в одном и том же мэге: по одному на каждой странице)
   * @returns {Promise<RM.classes.Widget>}
   */
  getWidgetById: function(id, preferredPage) {
    var promise;
    var widget;
    var widgetData;
    // Поищем страницу, на которой есть этот виджет. Если приоритетная страница задана, начнём с неё.
    // Ищем данные виджета, а не сам вью виджета, потому что если страница не догрузилась, вью виджета ещё не будет
    var page =
      preferredPage && preferredPage.getWidgetDataById(id)
        ? preferredPage
        : _.find(this.pages, function(page) {
            // В скриншотере страницы мэга — это простые объекты, и метода getWidgetDataById у них нет
            return page.getWidgetDataById && page.getWidgetDataById(id);
          });
    if (page) {
      widget = page.getWidgetById(id);
      // Виджет есть и вью отрисован
      if (widget) {
        promise = window.Promise.resolve(widget);
        // Виджет есть, но вью не отрисован — подождём загрузки страницы и снова поищем
      } else if (!page.loaded) {
        promise = new window.Promise(
          function(resolve, reject) {
            this.listenToOnce(page, 'loaded' + id, function() {
              var widget = page.getWidgetById(id);
              widget ? resolve(widget) : reject();
            });
          }.bind(this)
        );
      }
      // Поищем в above-all виджетах
    } else {
      // FIXME Нужно было заводить объект класса page для инкапсуляции всей логики above-all,
      // FIXME чтобы эта логика не оказывалась непосредственно в мэге.
      // FIXME К тому же часто логика такая же, как для страницы (как в этом if-else)
      // Поищем в данных о above-all виджетах, потому что виджеты могут быть ещё не созданы

      widgetData = _.find(this.aboveGlobalWidgetsData, { _id: id });
      // Если данные есть, значит виджет можно нати
      if (widgetData) {
        widget = _.find(this.aboveGlobalWidgets, { _id: id });
        // Если виджет уже создан — резолвим
        if (widget) {
          promise = window.Promise.resolve(widget);
          // Если ещё не создан — дождёмся создания и поищем снова
        } else if (!this.aboveGlobalWidgetsRendered) {
          promise = new window.Promise(
            function(resolve, reject) {
              this.listenToOnce(this, 'aboveGlobalWidgetsRendered', function() {
                var widget = _.find(this.aboveGlobalWidgets, { _id: id });
                widget ? resolve(widget) : reject();
              });
            }.bind(this)
          );
        }
      }
    }
    return promise || window.Promise.reject();
  },

  /**
   * Рендерит все above-all виджеты. Прячет или показывает в зависимости от видимости на заданной странице
   * @param {Object} page
   */
  renderAboveGlobalWidgets: function(page) {
    var widgets = [];
    var viewport = page.pageViewport;

    _.each(
      this.aboveGlobalWidgetsData,
      function(widgetData) {
        var oldWidget = _.find(this.aboveGlobalWidgets, { _id: widgetData._id });
        if (oldWidget) {
          oldWidget.destroy();
          // .off() обязательно после destroy,
          // потому что на destroy виджет пошлет всем кто его слушает прощальный привет в виде события destroyed, чтобы не ждали его лоада, если кто-то надеялся
          oldWidget.off();
        }

        var widget;
        if (widgetData) {
          var widgetViewportData = page.getWidgetViewportData(widgetData, viewport);
          // Если это анимация, которая должна проигрываться один раз одному пользователю, и она уже была проиграна, то спрячем этот виджет
          var animationExpired = !this.isPreview && AnimationUtils.hasExpired(widgetViewportData);
          if (Widgets[widgetViewportData.type] && !animationExpired) {
            // Создается виджет заданного типа с заданными параметрами
            widget = new Widgets[widgetViewportData.type](widgetViewportData, page);

            // В качестве фиксед контейнера задаем спец контейнер,
            // который существует вне страниц
            widget.$fixedContainer = this.$aboveWidgetsContainer;

            // Пока не рендерим виджеты — перед рендером нужно инициализировать анимацию для всех above-all-виджетов сразу
            if (widget.isValid()) {
              widgets.push(widget);
            }
          }
        }
      }.bind(this)
    );

    // Анимацию нужно инициализировать для всех глобальных виджетов сразу, но до рендера каждого виджета
    this.aboveGlobalAnimations = this.initializeAboveGlobalAnimation(widgets, page);

    _.each(widgets, function(widget) {
      widget.render();
      if (widget.hidden) {
        GlobalWidgets.hide(widget, true);
      } else {
        // Виджет и так видимый, но нужно выставить флаг wasHidden = false
        GlobalWidgets.show(widget, true);
      }
    });

    this.aboveGlobalWidgets = _.sortBy(widgets, 'y');
    this.aboveGlobalWidgetsRendered = true;
    this.trigger('aboveGlobalWidgetsRendered');
  },

  /**
   * Инициализирует анимацию для above-all-виджета
   * Для каждого above-all-виджета — отдельный объект анимаций, а не общий, как для всех обычных виджетов на одной странице,
   * Иначе их нельзя будет по отдельности уничтожать / создавать при скролле
   * @param {Object|Array} widgets
   * @param {Object} page
   * @return page
   */
  initializeAboveGlobalAnimation: function(widgets, page) {
    widgets = _.isArray(widgets) ? widgets : [widgets];

    // Придётся выставить масштаб страницы сейчас — он нужен для правильного рассчёта шагов скролл-анимации
    // Иначе не будет работать в горизонтальном вьюере
    page.scale = this.getScale(page.pageViewport);

    var animations = new Animations({
      page: page,
      widgets: widgets,
    });

    var loadPromises = [];
    _.each(widgets, function(widget) {
      var promise = new window.Promise(function(resolve) {
        if (widget.loaded) {
          resolve(widget);
        } else {
          widget.on('loaded', resolve.bind(null, widget));
        }
      });
      loadPromises.push(promise);
    });

    window.Promise.all(loadPromises).then(
      function() {
        this.aboveAnimationsReady = true;
        this.trigger('aboveAnimationsReady');
      }.bind(this)
    );

    // Подпишемся на ресайз этой страницы
    // (особенно важно для горизонтального вьюера, иначе в нём анимированные виджеты отрендерятся неправильно)
    this.listenTo(page, 'resize', function(page) {
      if (!_.isUndefined(page.prevScale) && page.prevScale !== page.scale) {
        animations.updateTimelines();
      }
      animations.onResize({ updateAll: true });
    });

    return animations;
  },

  updateAboveGlobalViewport: function(widget, newPage) {
    var widgetData = _.find(this.aboveGlobalWidgetsData, { _id: widget._id });
    var viewportWidgetData = newPage.getWidgetViewportData(widgetData, newPage.pageViewport);
    // Дестроить, заново инициализировать и рендерить внутри самого виджета не получится: нужно инициализировать анимацию
    if (widget.animationObj) {
      this.aboveGlobalAnimations.removeAnimation(widget.animationObj);
    }

    widget.destroy();
    widget.initialize(viewportWidgetData, newPage);

    if (widget.hasAnimation()) {
      var animation = this.aboveGlobalAnimations.addAnimation(widget);
      animation && animation.start();
    }

    widget.render();
    GlobalWidgets.hide(widget, true);
  },

  /**
   * Возвращает координаты бокса для above-all-виджета, на заданной странице / вьюпорте
   * @param {String} widgetId
   * @param {Object} page
   * @param {Object} containerBox {width: <Number>, height: <Number>}
   * @returns {{left: number, top: number, width: number, height: number}}
   */
  getAboveGlobalBox: function(widgetId, page, containerBox) {
    var widgetData = _.find(this.aboveGlobalWidgetsData, { _id: widgetId });
    var viewportWidgetData = page.getWidgetViewportData(widgetData, page.pageViewport);
    return Utils.getFixedPositionBox(
      viewportWidgetData.fixed_position,
      _.pick(viewportWidgetData, ['x', 'y', 'w', 'h']),
      page.scale,
      containerBox
    );
  },

  /**
   * Показывает или прячет каждый above-all-виджет в зависимости от его видимости и от позиции страниц под ним
   * @param {Object} currentPage Текущая страница
   * @param {Object} nextPage
   * @param {Number} [nextPageScroll] сколько пикселов осталось нижнему краю текущей страницы до верхнего края экрана.
   * @param {Boolean} [animation] Происходит ли анимация. Только в горизонтальном вьюере при перелистывании страниц.
   * При загрузке / инициализации и в горизонтальном, и в вертикальном вьюере анимации нет
   */
  redrawAboveGlobalWidgets: function(currentPage, nextPage, nextPageScroll, animation) {
    // Если параметры не переданы (как для вертикального вьюера в мобильном вьюпорте), вычислим их из текущего значения скролла
    if (!currentPage) {
      // Посчитаем параметры без флага pageStartsOnBottom, как если бы страница начиналась, когда она в верху экрана (а не внизу, как на мобильных)
      var currentPageData = !_.isUndefined(this.getScroll()) ? this.findPageOnCurrentMagScroll() : undefined;
      var currentPageNumber = currentPageData && currentPageData.page.num;
      var scrollParams = currentPageData && this.getScrollParams(currentPageData);
      currentPage = currentPageNumber && this.getPage(currentPageNumber);
      nextPage = currentPage && this.getPage(currentPage.num + 1);
      nextPageScroll = scrollParams && (scrollParams.slideInNextPageScroll || scrollParams.nextPageScroll);
      animation = scrollParams && scrollParams.animation;
    }

    nextPageScroll = !_.isUndefined(nextPageScroll) ? Math.abs(nextPageScroll) : undefined;

    var containerBox = {
      width: Math.min(this.viewOpts.scalewidth, this.getContainerSizeCached().width),
      height: this.getContainerSizeCached().height,
    };

    var isViewportDifferent = function(widget, secondPage) {
      return !widget.page || (secondPage && widget.page.pageViewport !== secondPage.pageViewport);
    };

    // Если это скролл и частично видна другая страница, вычислим показывать или спрятать каждый виджет в зависимости от позиции страниц и виджета
    if (!_.isUndefined(nextPageScroll)) {
      // nextPageScroll всегда отрицательный, но для операций сравнения удобнее использовать положительный
      var nextPageScrollAbs = Math.abs(nextPageScroll);
      // Проверяем свойство hidden для текущих страницы и вьюпорта и прячем/показываем виджет

      _.each(
        this.aboveGlobalWidgets,
        function(widget) {
          var visibleOnCurrentPage = !GlobalWidgets.isHidden(widget, currentPage._id, this.isPreview);
          var visibleOnNextPage = nextPage && !GlobalWidgets.isHidden(widget, nextPage._id, this.isPreview);
          var visibleOnBoth =
            visibleOnCurrentPage && visibleOnNextPage && currentPage.pageViewport === nextPage.pageViewport;

          var currentBox = this.getAboveGlobalBoxMemoized(widget._id, currentPage, containerBox);
          var nextBox = this.getAboveGlobalBoxMemoized(widget._id, nextPage || currentPage, containerBox);

          var isMobileFinalPage =
            !Modernizr.isdesktop &&
            currentPageData &&
            currentPageData.finalPageScroll &&
            currentPageData.finalPageScroll > 0;

          // Будет показан на верхней странице
          var showOnCurrentPage =
            visibleOnCurrentPage && nextPageScrollAbs > currentBox.top + currentBox.height && !isMobileFinalPage;
          // Будет показан на нижней странице
          var showOnNextPage = nextPage && visibleOnNextPage && nextBox.top > nextPageScrollAbs;
          // На последней странице при отсутствии страницы с брендингом не скрываем виджет, если он на ней виден, но выходит за границы страницы
          var visibleOnLast =
            !nextPage &&
            visibleOnCurrentPage &&
            (!currentPage.mag.viewOpts.endpage ||
              (currentPageData ? currentPageData.finalPageScroll === undefined : false));

          // Будет показан, потому что виден или на верхней или на нижней странице, или находится между страницами, но виден на обеих
          var willShow = showOnCurrentPage || showOnNextPage || visibleOnBoth || visibleOnLast;

          if (willShow) {
            // У верхней страницы всегда приоритет вне зависимости от направления скролла
            var newPage = showOnCurrentPage ? currentPage : showOnNextPage ? nextPage : widget.page;
            var willChangeViewport = isViewportDifferent(widget, newPage);

            // Сменим страницу сразу, а транзишен с ре-рендером под нужный вьюпорт — после этого.
            widget.updatePage(newPage);

            // Виджет виден, поэтому при смене вьюпорта нужно сделать транзишен
            if (willChangeViewport) {
              // Важно убрать виджету rendered при смени вьюпорта, т.к. анимации глобал виджетов могут иметь триггерами
              // другие глобал виджеты, и при смене вьюпорта анимация привяжется к уже отрендеренному виджету в другом вьюпорте
              // вместо того чтобы дождаться рендера виджета для нового вьюпорта

              widget.rendered = false;
              GlobalWidgets.hide(widget).then(
                function() {
                  this.updateAboveGlobalViewport(widget, newPage);
                  GlobalWidgets.show(widget);
                }.bind(this)
              );
            } else {
              widget.wasHidden && GlobalWidgets.show(widget);
            }
          } else {
            !widget.wasHidden && GlobalWidgets.hide(widget);
          }
        }.bind(this)
      );
      // Если это не скролл, а загрузка / инициализация или перелистывание в горизонтальном вьюере,
      // просто посмотрим, видны ли виджеты на этой странице, и покажем / спрячем их
    } else {
      var pageTransitionTime = this.getPageTransitionTime();
      _.each(
        this.aboveGlobalWidgets,
        function(widget) {
          // У финальной псевдо-страницы нет id, поэтому проверим его. Если нет id — не показываем
          var showOnCurrentPage = currentPage._id && !GlobalWidgets.isHidden(widget, currentPage._id, this.isPreview);
          var willChangeViewport = currentPage._id && isViewportDifferent(widget, currentPage);
          if (currentPage._id) {
            widget.updatePage(currentPage);
          }
          if (willChangeViewport) {
            this.updateAboveGlobalViewport(widget, currentPage);
          }
          if (showOnCurrentPage) {
            // В горизонтальном вьюере со слайд-ин, если перелистываем со стр 3 (виджет скрыт) на стр 2 (виджет показан),
            // и используем метод waitForAnimationToEnd — событие ontransitionend по окончании анимации долго не вызывается,
            // а таймаут там больше на 1 с
            if (animation) {
              widget.wasHidden &&
                Utils.waitForTransitionEnd(
                  currentPage.$el,
                  pageTransitionTime,
                  'transform',
                  GlobalWidgets.show.bind(null, widget)
                );
            } else {
              widget.wasHidden && GlobalWidgets.show(widget);
            }
          } else {
            !widget.wasHidden && GlobalWidgets.hide(widget);
          }
        }.bind(this)
      );
    }
  },
});

export default MagClass;
