// Модуль для Google universal analytics
// https://developers.google.com/analytics/devguides/collection/analyticsjs/advanced
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from './utils';
import dateFormat from '../vendor/date.format';

var defaultDimensions = {
  // page type
  dimension1: 'other',

  // user id
  dimension2: 'anonymous',

  // subscription
  dimension3: 'free',

  // first touch points first source|medium|campaign|referrer|landing page
  dimension4: 'none|direct|none|none|none',

  // first campaign
  dimension5: '',

  // first medium
  dimension6: '',
};

const extendAnalyticsUsers = {
  WIDGET_THRESHOLD: 10,

  initialize: function(options) {
    _.bindAll(this);
    _.extend(this, options);

    // общий объект со всеми данными, собранными для отправки события
    // перезаписывает объект для начала нового сбора данных
    this.currentData = this._initCurrentData();

    // сохраняем текущий роутер
    if (!this.router) this.router = RM.collectorRouter || RM.constructorRouter;

    // добавляем user-id
    if (this.currentData.userId && !RM.common.isDownloadedSource) {
      var _userId = this.currentData.userId;
      ga('readymag.set', '&uid', _userId);
      ga('readymag.set', '&cd9', _userId);
    }

    // Спец измерение по партнерке с Blurb
    if (this.currentData.blurbUserCreationDate) {
      ga('readymag.set', '&cd7', this.currentData.blurbUserCreationDate);
    }

    if (!RM.common.isDownloadedSource) {
      $(this._saveLoginData);
    }

    this.lastTrackedUrl = '';

    // вместо того, чтобы отправлять события на каждое создание виджета, отправляем на каждые 10 созданных виджетов
    this._createWidgetBuf = 0;

    $(
      _.bind(function() {
        this.trackPage();
      }, this)
    );
  },

  sendEvent: function(action, params, _value, _cb) {
    if (!window.ga) return;
    if (RM.constructorRouter && RM.constructorRouter.previewMode) return;

    // сборка параметров действия
    var functionParams = {
      action: action,
    };

    if (_.isObject(params)) {
      functionParams = _.extend(functionParams, params);
    } else {
      functionParams.label = params || undefined;
      functionParams.value = _value || undefined;
    }

    if (action === 'Create Widget') {
      this._createWidgetBuf += 1;
      this._createWidgetBuf %= this.WIDGET_THRESHOLD;

      if (this._createWidgetBuf !== 0) return;
      else {
        functionParams.value = this.WIDGET_THRESHOLD;
      }
    }

    var actionParams = this._extendParams(functionParams);

    if (actionParams.label && actionParams.label.match(/enterprise/)) {
      actionParams.label = actionParams.label.replace(/enterprise/, 'custom');
    }

    // все параметры берем в явном виде и описываем перед отправкой из this.actionParams
    // этот же объект передается дальше другим триггерам аналитики
    var sendObj = {
      hitType: 'event',
      eventCategory: actionParams.category,
      eventAction: actionParams.action,
      eventLabel: actionParams.label,
      eventValue: actionParams.value,
      page: actionParams.page,
      dimension1: actionParams.dims.pageType,
      dimension2: actionParams.dims.userId,
      dimension3: actionParams.dims.subscription,
      screenName: actionParams.screenName,
      location: actionParams.href,
      hitCallback: _cb || actionParams.cb || undefined,
    };

    if (this._rmdata) {
      sendObj.dimension4 = this._rmdata.dimension;
      sendObj.dimension5 = this._rmdata.first_campaign;
      sendObj.dimension6 = this._rmdata.first_medium;
    }

    var dimension10 = this.getABTestingValue();
    if (dimension10) {
      sendObj.dimension10 = dimension10;
    }

    window.ga('readymag.send', sendObj);

    this.sendFacebookPixelEvent(actionParams.action, actionParams);
  },

  trackPage: function(params) {
    // если у нас нет созданной аналитики, значит здесь ничего отслеживать не надо
    if (!window.ga) return;
    if (RM.constructorRouter && RM.constructorRouter.previewMode) return;

    var actionParams = this._extendParams(params);
    actionParams.hitType = 'pageview';

    if (actionParams.page === this.lastTrackedUrl) return;

    this.lastTrackedUrl = actionParams.page;

    var sendObj = {
      page: actionParams.page,
      location: actionParams.href,
      dimension1: actionParams.dims.pageType,
      dimension2: actionParams.dims.userId,
      dimension3: actionParams.dims.subscription,
      screenName: actionParams.screenName,
      hitCallback: actionParams.cb || undefined,
    };

    if (this._rmdata) {
      sendObj.dimension4 = this._rmdata.dimension;
      sendObj.dimension5 = this._rmdata.first_campaign;
      sendObj.dimension6 = this._rmdata.first_medium;
    }

    // Спец измерение по партнерке Blurb
    var blurb_reference_date = this._getUserParam('blurb_reference_date');
    var stripe_data = this._getUserParam('stripe');
    if (blurb_reference_date) {
      if (!stripe_data) {
        sendObj.dimension8 = 'New user';
      } else if (stripe_data.subscription_status === 'canceled') {
        sendObj.dimension8 = 'Cancelled';
      } else if (/_/g.test(stripe_data.subscription_id)) {
        sendObj.dimension8 = 'Customer-' + stripe_data.subscription_id.split('_')[1];
      }
    }

    var dimension10 = this.getABTestingValue();
    if (dimension10) {
      sendObj.dimension10 = dimension10;
    }

    window.ga('readymag.send', 'pageview', sendObj);

    this.sendFacebookPixelEvent('PageView');
  },

  /*
    ================================
    Вспомогательные функции
    ================================
  */

  _initCurrentData: function() {
    var result = {};

    result.userId = null;

    // так как в публичной части user грузится асинхронно, сюда с домена readymag.com мы попадать не должны
    if (window.ServerData.me) {
      result.userId = ServerData.me._id || (ServerData.me.user && ServerData.me.user._id);
      result.userEmail = ServerData.me.email || (ServerData.me.user && ServerData.me.user.email);
      result.userFullName = ServerData.me.name || (ServerData.me.user && ServerData.me.user.name);
      result.subscription = this._getSubscription();
    }

    var hasCookie =
      Utils.getCookie('_rmdata') ||
      (Modernizr.localstorage && window.localStorage && window.localStorage.getItem('_rmdata'));

    if (hasCookie) {
      // if we have cookie, return the existing data
      this._rmdata = JSON.parse(atob(hasCookie));
    }

    // Данные по партнерке Blurb
    var blurb_reference_date = this._getUserParam('blurb_reference_date');
    var createDate = this._getUserParam('createDate');
    if (blurb_reference_date && createDate) {
      result.blurbUserCreationDate = 'blurb-' + dateFormat(new Date(createDate), 'yyyymmdd');
    }

    return result;
  },

  _getSubscription: function() {
    var ret =
      (ServerData.me.stripe && ServerData.me.stripe.subscription_id) ||
      (ServerData.me.user && ServerData.me.user.stripe && ServerData.me.user.stripe.subscription_id);

    var type = '',
      level = '',
      cycle = '';

    if (!ret || ret.toLowerCase() === 'none') {
      type = 'free';
      level = '';
    } else {
      ret = ret.toLowerCase();

      // ищем тип подписки
      if (ret && /yearly/g.test(ret)) {
        cycle = 'yearly';
      } else if (ret && /monthly/g.test(ret)) {
        cycle = 'monthly';
      }

      level = ret;
      level = level.replace(/ /g, '');
      level = level.replace(/_/g, '');
      level = level.replace(/(yearly|monthly)/g, '');
      level = level.replace(/plan/g, '');
      level = level.trim();
      type = 'paid';
    }

    return (type + ' ' + level + ' ' + cycle).trim();
  },

  _parseDimensions: function(data) {
    var dims = {
      magId: data.magId || defaultDimensions.dimension1,
      magCreatorId: data.magCreatorId || defaultDimensions.dimension2,
      pageType: data.pageType || defaultDimensions.dimension3,
      userId: data.userId || defaultDimensions.dimension4,
    };

    if (RM.constructorRouter && !RM.constructorRouter.isOwner) {
      dims.pageType = 'constructor shared';
    }

    dims.subscription = this.currentData.subscription || 'free';

    return dims;
  },

  _correctUrl: function(url) {
    if (url === '/') return url;
    if (url[url.length - 1] === '/') return url.substr(0, url.length - 1);
    return url;
  },

  // Общая функция сбора параметров перед отправкой.
  _extendParams: function(params) {
    var params = params || {},
      result = params;

    // добавляем информацию о dimensions
    result.dims = this.router.getAnalyticsDimensions && this._parseDimensions(this.router.getAnalyticsDimensions());

    if (!result.page) result.page = '/' + Backbone.history.getFragment();

    // удаляем query
    if (result.page.indexOf('?') !== -1) {
      result.page = result.page.substr(0, result.page.indexOf('?'));
    }

    // убираем завершающий слэш
    result.page = this._correctUrl(result.page);

    result.href = window.location.href;

    // если есть action -> ищем категорию
    if (result.action) {
      result.category = this.getActionCategory(result.action);
    }

    if (result.label) {
      result.label = result.label.toString();
    }

    result.screenName = this._setScreenName();

    return result;
  },

  _saveLoginData: function() {
    var join_data = Utils.getCookie('send_join_event');
    if (!join_data) return;
    join_data = JSON.parse(atob(join_data));

    var curr_timestamp = new Date().valueOf();
    var referrer_data =
      Utils.getCookie('_rmdata') ||
      (Modernizr.localstorage && window.localStorage && window.localStorage.getItem('_rmdata'));
    if (!referrer_data) {
      referrer_data = {};
    } else {
      referrer_data = JSON.parse(atob(referrer_data));
    }

    var updObj = {
      timestamp: curr_timestamp,
    };

    // Хардкод стоимости и валюты - по просьбе аналитиков. Фэйсбуку так больше нравится %)
    Utils.sendFacebookPixelEvent('Joined', { value: 2, currency: 'USD' });

    _.extend(updObj, join_data, referrer_data);
    this._updateAnalyticsData(updObj);

    Utils.deleteCookie('send_join_event');
  },

  _updateAnalyticsData: function(data) {
    $.post('/api/me/analytics', { analytics_data: data });
  },

  _setScreenName: function() {
    var result;
    // так как у нас веб-приложение, воспользуемся механизмом наименования экранов
    // будем считать, что экран – это веб-страница со всеми состояниями, которые появляются без перезагрузки

    if (RM.constructorRouter && RM.constructorRouter.previewMode) {
      result = 'Preview ' + RM.constructorRouter.mag.get('num_id');
    }

    if (RM.constructorRouter && !RM.constructorRouter.previewMode) {
      result = 'Constructor ' + RM.constructorRouter.mag.get('num_id');
    }

    if (RM.collectorRouter && RM.collectorRouter.currentView.mode !== 'shared-folder') {
      result = 'Profile ‘' + RM.collectorRouter.currentView.user.get('name') + '’';
    }

    if (RM.collectorRouter && RM.collectorRouter.currentView.mode === 'shared-folder') {
      result = 'Profile (shared folder) ‘' + RM.collectorRouter.currentView.user.get('name') + '’';
    }

    return result;
  },

  _getUserParam: function(param_name) {
    var user = (this.router && this.router.me && this.router.me.attributes) || {};
    return user[param_name];
  },

  /*
    ================================
    Категории
    ================================
  */

  // таблица категорий для событий: задается один раз, чтобы не путаться и не запоминать, иначе все сломается
  getActionCategory: function(action) {
    var result = '';

    switch (action) {
      // User Conversions
      case 'Joined':
      case 'Subscribed':
      case 'Subscribe Error':
      case 'Resumed Subscription':
      case 'Changed Subscription':
      case 'Coupon Applied':
      case 'Cancelled Subscription':
      case 'Cancelled Autorenew':
        result = 'Basic User Actions';
        break;
      // User Subscriptions
      case 'Customer New':
      case 'Customer':
      case 'User (free)':
        result = 'Status of the day';
        break;
      // Homepage Actions
      case 'Manage Subscription':
      case 'Submit To Explore':
      case 'Explore Tab Click':
        result = 'Homepage Actions';
        break;
      //Process Actions
      case 'Animation Type Change':
      case 'Animation Use Preset':
      case 'Animation Choose Effect':
      case 'Animation Trigger Click':
      case 'Animation Trigger Remove':
      case 'Font Selector Show':
      case 'Font Selector Switch to Category':
      case 'Font Selector Show Custom Font Panel':
      case 'Font Explorer Add/Remove from Library':
      case 'Font Selector Edit List':
      case 'Font Selector Custom Font Panel Select Weight':
      case 'Font Selector Custom Font Panel Upload Weight':
      case 'Font Selector Edit Custom Font':
      case 'Create Mag Request':
      case 'Create Mag':
      case 'Publish Mag':
      case 'Republish Mag':
      case 'Unpublish Mag':
      case 'Create Widget':
      case 'Create Page':
      case 'Page SEO Open':
      case 'Page Sharing Open':
      case 'Page Restored':
      case 'Customize Page Sharing':
      case 'Restore Defaults Sharing':
      case 'Master Page Sharing':
      case 'Help Tour':
      case 'Help Open':
      case 'Constructor Help Open':
      case 'Code Editor Open':
      case 'Code Editor Switch Tab':
      case 'Code Editor Update':
      case 'Code Editor Clear Switcher':
      case 'Code Editor Use Iframe Switcher':
      case 'Add Team Member':
      case 'Delete Team Member':
      case 'Picture Scale':
      case 'Background Widget Type':
      case 'Viewer Type Toggle':
      case 'Viewer Options Toggle':
      case 'Viewer Settings Learn More Click':
      case 'Project Visible to Search Engines':
      case 'Password Toggle':
      case 'Set Project Password':
      case 'Project Visibile to Visitors':
      case 'Form Storage Connect Click':
      case 'Form Storage Set Endpoint Param':
      case 'Form Storage Successful OAuth Result':
      case 'Form Storage Delete Endpoint Param':
      case 'Map Domain':
      case 'SSL Toggle':
      case 'Bing Image Search':
      case 'PDF Export':
      case 'HTML Export':
      case 'Set Project GA':
      case 'Set Project GTM':
      case 'GA Upgrade':
      case 'Key Press':
      case 'Restore to published':
        result = 'Process Actions';
        break;
      default:
        result = 'Process Actions';
        break;
    }

    return result;
  },
};

export default extendAnalyticsUsers;
