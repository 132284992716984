/**
 * классы дл работы с анимациями во вьювере
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from '../common/utils';
import AnimationUtils from '../common/animationutils';
import Scale from '../common/scale';
import MathUtils from '../common/mathutils';

const Animations = Backbone.View.extend({
  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);

    var groups = {};

    // бежим по всем виджетам и если находим тот, который относитья к какой-либо группе анимаций
    // то заносим к себе в список что такая-то группа и изменяем ее размер
    // изначально размер группы и положение не определены, первый найденый виджет группы устанавливает размеры и положение группы
    // в свои собственные, каждый последующий блок расширяет рамку и положение группы своими размерами и положением
    _.each(
      this.widgets,
      _.bind(function(w) {
        if (w.hasAnimation()) {
          // фикседы и фулвидхи/фулхайты не могут быть в группах, для них анимации только по одному
          // но могут быть ситуации когда группу анимаций уже сделали, а потом внутри нее виджетам присвоили фулвидхи или фикседы
          // поэтому во вьювере мы все группы для фулвидхов/фулхайтов и фикседов убиваем, просто тупо перегенерируя идишник
          if (w.fixed_position || w.is_full_width || w.is_full_height || w.sticked) {
            w.animation.UUID = Utils.generateUUID();
          }

          groups[w.animation.UUID] = groups[w.animation.UUID] || [];

          groups[w.animation.UUID].push(w);
        }
      }, this)
    );

    this.animations = [];

    // Разрешаем аппапатное ускорение в целом на мобильных, на десктопе кроме сафари,
    // и в сафари только если все анимации на странице могут быть ускорены.
    // Ускорение в отдельных виджетах можно отключить, но нельзя включить, если allowAccelerate: false
    // Баг https://readymag.monday.com/boards/55520426/pulses/77776979
    var allowAccelerate =
      !Modernizr.isdesktop ||
      !Modernizr.safari ||
      _.all(groups, function(widgets) {
        return AnimationUtils.canBeAccelerated((_.isArray(widgets) ? widgets : [widgets])[0].animation);
      });

    _.each(
      groups,
      function(widgets) {
        this.addAnimation(widgets, { allowAccelerate: allowAccelerate });
      }.bind(this)
    );

    this.start.__debounced = _.debounce(this.start, 300);
  },

  /**
   * Создаёт анимацию для виджета / группы виджетов и добавляет её в список анимаций
   * @param {Array|Object} widgets
   * @param {Object} params
   */
  addAnimation: function(widgets, params) {
    params = params || {};
    widgets = _.isArray(widgets) ? widgets : [widgets];
    var animationType = widgets[0].animation.type,
      mag = this.page.mag;
    var animation;

    // Делаем все анимации, кроме ховеров на мобильных. Если у ховера на мобильных есть триггер, тоже делаем анимацию:
    // она не будет срабатывать, но присвоится начальное состояние (opacity: 0, например)
    // https://trello.com/c/QIItCPyk/344-onhover
    if (Modernizr.isdesktop || animationType !== 'hover' || widgets[0].getAnimationTriggers().length) {
      // скрол анимации оставляем пока только на десктопе, на девайсах он пока жутко тупит
      // в будущем можно будет просто отключить во вьюпортах, но пока по-быстрому так
      // в превью тоже не показываем, кстати
      // отключаем так чтобы даже начальное состояние анимации не проставлялось
      // if (animationType == 'scroll' && (!Modernizr.isdesktop || (mag.isPreview && mag.viewport != 'default'))) return;

      animation = new Animation[animationType]({
        page: this.page,
        widgets: widgets,
        allowAccelerate: params.allowAccelerate,
      });

      this.animations.push(animation);
    }
    return animation;
  },

  /**
   * Удаляет анимацию из списка анимаций и уничтожает её
   * @param {Backbone.View} animation
   */
  removeAnimation: function(animation) {
    this.animations = _.without(this.animations, animation);
    animation && animation.destroy();
  },

  start: function() {
    _.each(this.animations, function(animation) {
      animation.start();
    });
  },

  stop: function() {
    _.each(this.animations, function(animation) {
      animation.stop();
    });
  },

  resetStarted: function() {
    _.each(this.animations, function(animation) {
      animation.started = false;
    });
  },

  onScroll: function(params) {
    params = params || {};

    // на started у страницы проверять нельзя
    // поскольку она может быть показана, а запущена чуть позже (например в процессе загрузки)
    // а нам такое поведение не подходит для анимаций типа on scroll
    if (this.page.mag.currentPage != this.page && !params.forceApply) return;

    _.each(this.animations, function(animation) {
      animation.onScroll && animation.onScroll(params);
    });
  },

  onResize: function(params) {
    _.each(this.animations, function(animation) {
      animation.onResize(params);
    });
  },

  // пересоздаем анимации, например при смене скейла страницы (там с фикседами есть завязки на скейл)
  updateTimelines: function() {
    _.each(this.animations, function(animation) {
      animation.createAnimationTimeline();
    });
  },

  hasScaleUp: function() {
    return Boolean(
      _.find(this.animations, function(animationView) {
        return AnimationUtils.isScaleUp(animationView.animation);
      })
    );
  },

  destroy: function() {
    _.each(this.animations, function(animation) {
      animation.destroy();
    });

    delete this.animations;
  },
});

var Animation = {};

//* ******************************************************************************************************
// базовый класс анимаций, напрямую не используется, просто от него наследуются 4 подкласса
//* ******************************************************************************************************
var BaseAnimation = Backbone.View.extend({
  className: 'animation-container',

  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);

    // передаем всем виджетам объект анимаций которому тот принадлежит
    // ничего особенного, но виджету надо знать что он в контейнере для анимаций, чтобы правилно себя там расположить
    // ну и потом наверное буду механизмы ожидания объектом анимаций загрузки всех виджетов внутри себя, но пока его нет
    _.each(
      this.widgets,
      _.bind(function(wid) {
        wid.animationObj = this;
      }, this)
    );

    this.isFixed = this.widgets[0].fixed_position;
    this.isFullwidth = this.widgets[0].is_full_width;
    this.isFullheight = this.widgets[0].is_full_height;
    this.isSticked = this.widgets[0].sticked;
    this.isAbove = this.widgets[0].is_above;
    this.playOnce = !this.page.mag.isPreview && this.playOnce;

    // контейнер в котором мы должны отрендерить свой контейнер анимаций $el, а в $el уже будут рендериться
    this.$container = this.isFixed || this.isAbove ? this.widgets[0].$fixedContainer : this.page.$content;

    // Фикс бага когда изображения, увеличенные трансформом, размывается, если находится поверх фона с position: -webkit-sticky
    // https://readymag.monday.com/boards/55520426/pulses/77776979
    if (
      !this.isFixed &&
      !this.isAbove &&
      Modernizr.safari &&
      Modernizr.isdesktop &&
      this.page.mag.isStickyVerticalViewer &&
      AnimationUtils.isScaleUp(this.widgets[0].animation)
    ) {
      this.$wrapper = $('<div class="animation-wrapper force3d"/>').appendTo(this.$container);
    }

    this.$el.appendTo(this.$wrapper ? this.$wrapper : this.$container);

    this.createAnimationTimeline(params);

    this.onResize({ updateAll: true });
  },

  createAnimationTimeline: function(params) {
    params = params || {};
    this.timeline && this.timeline.destroy();

    this.animation = AnimationUtils.getNormalizedAnimation(
      this.widgets[0].animation,
      this.isFixed,
      this.isFullwidth,
      this.isFullheight,
      this.page.scale
    );

    this.timeline = AnimationUtils.createAnimationTimeline({
      el: this.$el,
      steps: this.animation.steps,
      type: this.animation.type,
      force3d: params.allowAccelerate && AnimationUtils.canBeAccelerated(this.animation),
      loop: AnimationUtils.normalizeLoopValue(this.animation.loop),
      // Конструктор таймлайна экстендит себя параметрами, а свойство trigger (от backbone.events) у него уже есть
      animationTrigger: this.animation.trigger,
      screenshotMode: !!RM.screenshot,
    });
  },

  start: function() {
    if (!this.timeline || !this.paused) return;

    this.paused = false;

    this.timeline.resume();
  },

  stop: function() {
    // паузим только если анимация сейчас работает
    // важно потому что мы ставим флаг паузы по причине ухода со страницы
    // чтобы по приходу запустить анимацию доделываться
    if (!this.timeline || !this.timeline.active) return;

    // Анимацию для above-all не нужно ставить на паузу при уходе со страницы.
    // Если виджет виден на другой странице, анимация доиграет, потому что виджет не ре-рендерится.
    if (!this.isAbove) {
      this.paused = true;
      this.timeline.pause();
    }
  },

  // это виджеты которые имеют анимации спрашивают где им себя рендерить, в каком контейнере
  getAnimationContainer: function() {
    return this.$el;
  },

  // вызывается из виджета каждый раз когда он хочет изменить свое положение
  modifyWidgetPosition: function(css) {
    // фикседы располагаем всегда по центру контейнера баундинг бокса
    // в нулях нельзя ведь могут быть повороты и повернутые части вылезут за пределы
    // потому как в нулях будет расположен исходный блок, без трансофрмаций
    if (this.isFixed) {
      css.dimensions.left = '50%';
      css.dimensions.top = '50%';
      css.dimensions.right = '';
      css.dimensions.bottom = '';
      css.dimensions['margin-left'] = -css.dimensions.width / 2;
      css.dimensions['margin-top'] = -css.dimensions.height / 2;
    } else {
      css.dimensions.left -= this.bbox_css.left;
      css.dimensions.top -= this.bbox_css.top;
    }

    return css;
  },

  onResize: function(params) {
    params = params || {};

    // обновляем размеры контейнеров анимаций только если:
    // 1. нет ключа updateAll (он передается при первоначальном создании, чтобы проставить размеры изначально)
    // 2. если мы фиксед и скейл страницы поменялся
    // 3. если мы фулвидх
    // 4. если мы фулхайт
    // 5. если мы стики (прибиты к левому или правому краю экрана)
    if (
      params.updateAll ||
      (this.isFixed && this.prevPageScale != this.page.scale) ||
      this.isFullwidth ||
      this.isFullheight ||
      this.isSticked
    ) {
      var bbox_tmp = {
          t: Number.POSITIVE_INFINITY,
          l: Number.POSITIVE_INFINITY,
          b: Number.NEGATIVE_INFINITY,
          r: Number.NEGATIVE_INFINITY,
          z: 0,
        },
        bbox;

      // фикседы и фулвидхи/фулхайты не могут быть в группах, для них анимации только по одному
      if (this.isFixed || this.isFullwidth || this.isFullheight || this.isSticked) {
        // формируем контейнер анимаций
        this.bbox_css = this.widgets[0].calcBoxStyle({ calcBBox: true }).dimensions;
      } else {
        // для обычных виджетов бежим по всем виджетам в группе и изменяем ее размер
        // изначально размер группы и положение не определены, первый  виджет группы устанавливает размеры и положение группы
        // в свои собственные, каждый последующий расширяет рамку и положение группы своими размерами и положением
        _.each(
          this.widgets,
          function(wid) {
            bbox = wid.calcBoxStyle({ calcBBox: true }).dimensions;

            bbox_tmp.t = Math.min(bbox_tmp.t, bbox.top);
            bbox_tmp.l = Math.min(bbox_tmp.l, bbox.left);
            bbox_tmp.b = Math.max(bbox_tmp.b, bbox.top + bbox.height);
            bbox_tmp.r = Math.max(bbox_tmp.r, bbox.left + bbox.width);
            bbox_tmp.z = Math.max(bbox_tmp.z, bbox['z-index']);
          },
          this
        );

        // формируем контейнер анимаций
        // для обычных виджетов нам надо собрать css для bbox зная крайние точки
        this.bbox_css = {
          left: bbox_tmp.l,
          top: bbox_tmp.t,
          width: bbox_tmp.r - bbox_tmp.l,
          height: bbox_tmp.b - bbox_tmp.t,
          'z-index': bbox_tmp.z,
        };
      }

      this.$el.css(this.bbox_css);
    }

    this.innerContentTop = this.page.contentPosition.top / this.page.scale;
    this.innerContainerHeight = this.page.mag.getContainerSizeCached().height / this.page.scale;

    this.prevPageScale = this.page.scale;
  },

  /**
   * Выполняет действие на html-элементе триггера, если триггер есть, или на html-элементе самого виджета, если триггера нет
   * @param {Function} callback Функция, которую нужно выполнить. Получает html-элемент в качестве агрумента
   * Например, функция привязывающая обработчик события.
   */
  withTriggerOrElement: function(callback) {
    // Если у элемента есть внешний триггер, поищем его во всех виджетах мэга — и навешаем обработчик
    var triggerIds = this.animation.trigger;
    if (triggerIds.length) {
      window.Promise.all(
        _.map(
          triggerIds,
          function(triggerId) {
            // Поищем триггер на всех страницах мэга, начиная со страницы анимируемого элемента
            // Если триггер найден, навешаем обработчик на него
            return this.page.mag.getWidgetById(triggerId, this.page);
          }.bind(this)
        )
      )
        .then(
          function(triggers) {
            _.each(
              triggers,
              function(trigger) {
                // Если триггер отрисован — отлично, навешаем обработчик сразу
                if (trigger.rendered) {
                  callback(trigger.$el);
                  // Если триггер не отрисован, дождёмся отрисовки и тогда навешаем обработчик
                } else {
                  this.listenToOnce(trigger, 'rendered', function() {
                    callback(trigger.$el);
                  });
                }
              }.bind(this)
            );
          }.bind(this)
        )
        // Если триггер не найден, навешаем обработчик на сам виджет
        .catch(
          function() {
            callback(this.$el);
          }.bind(this)
        );
    } else {
      // Если триггер не указан или не найден, триггерим действия при событии на самом объекте.
      // Если триггер в конструкторе спрятан, то во вьюере его не будет, поэтому не делаем дополнительную проверку на hidden
      callback(this.$el);
    }
  },

  destroy: function() {
    this.stop();
    this.timeline && this.timeline.destroy();
    this.remove();
  },
});

//* ******************************************************************************************************
// класс для скрол анимаций
//* ******************************************************************************************************
Animation['scroll'] = BaseAnimation.extend({
  onScroll: function(params) {
    params = params || {};

    if (!this.timeline) return;

    // на started у страницы проверять нельзя
    // поскольку она может быть показана, а запущена чуть позже (например в процессе загрузки, а нам такое поведение здесь не подходит)
    if ((this.page.mag.currentPage != this.page || this.isAbove) && !params.forceApply) return;

    var currentScroll = this.isAbove ? params.scroll : Math.max(0, this.page.visibleWidgetsCoords.scrollTop || 0); // оттяжка на маках может давать отрицательный скрол

    // фикседы скролятся сразу и без скейла
    if (this.isFixed) {
      this.timeline.seek(currentScroll);
      return;
    }

    var percentageMap = {
      top: 1,
      center: 0.5,
      bottom: 0,
    };

    var per100 = this.bbox_css.top + this.innerContentTop,
      per0 = per100 - this.innerContainerHeight,
      perStart = percentageMap[this.animation.steps[0].start_point || 'bottom'],
      scrollStart = perStart * (per100 - per0) + per0 + this.animation.steps[0].start_offset || 0;

    if (scrollStart <= 0) {
      this.timeline.seek(currentScroll / this.page.scale);
    } else {
      this.timeline.seek(Math.max(0, currentScroll - scrollStart * this.page.scale) / this.page.scale);
    }
  },

  onResize: function() {
    BaseAnimation.prototype.onResize.apply(this, arguments);

    this.onScroll();
  },
});

//* ******************************************************************************************************
// класс для лоад анимаций
//* ******************************************************************************************************
Animation['load'] = BaseAnimation.extend({
  /**
   * Доля высоты, которая должна быть видна. 0.2 значит 20%.
   */
  visibleHeightPortion: 0.2,

  initialize: function() {
    BaseAnimation.prototype.initialize.apply(this, arguments);

    var steps = this.animation.steps;
    this.startWhenInView = steps && steps.length && steps[0].startWhenInView;

    this.checkBounds.__throttled = _.throttle(this.checkBounds, 100);
  },

  start: function() {
    BaseAnimation.prototype.start.apply(this, arguments);
    this.isReady()
      .then(
        function() {
          this.timeline.play();
          this.started = true; // лоад анимации стартовать только один раз
        }.bind(this)
      )
      .catch(function(error) {
        error && console.log(error && error.stack);
      });
  },

  checkBounds: function() {
    if (!this.scrolledToBounds) {
      window.requestAnimationFrame(
        function() {
          if (this.isInBounds()) {
            this.scrolledToBounds = true;
            this.trigger('scrolledToBounds');
          }
        }.bind(this)
      );
    }
  },

  onScroll: function() {
    // Иногда нужно дождаться фактического перемещения вью после изменения свойства scrollTop,
    // иначе проверка isInBounds может быть неадекватной
    this.checkBounds.__throttled();
  },

  /**
   * Возвращает промис, когда можно запускать onload-анимации
   * @return {Promise}
   */
  isReady: function() {
    var promise;
    // В скриншотере не запускаем onload-анимации. Если они уже запущены — тоже
    // АХТУНГ! Safari может довольно долгое время после загрузки держать document.hidden == true, не смотря
    // на то, что страница уже рендерится. Из-за этого промис здесь сразу может зарежектиться.
    // Но у нас есть еще onPageVisibilityChange в mag.js, который должен стартовать анимации, когда сафари
    // "покажет" документ
    if (RM.screenshot || this.started || Utils.PageVisibilityManager.isPageHidden()) {
      promise = window.Promise.reject();
      // Для остальных случаев ждём выполнения разных условий, в зависимости от флага startWhenInView и isAbove
    } else {
      var widget = this.widgets[0];
      // Условия начала анимаций
      var conditions = [];

      var widgetLoaded = widget.loaded
        ? window.Promise.resolve()
        : new window.Promise(
            function(resolve) {
              this.listenToOnce(widget, 'loaded', resolve);
            }.bind(this)
          );

      // above-all
      if (this.isAbove) {
        var aboveAnimationsReady = this.page.mag.aboveAnimationsReady
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                this.listenToOnce(this.page.mag, 'aboveAnimationsReady', resolve);
              }.bind(this)
            );
        var widgetRendered = widget.rendered
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                this.listenToOnce(widget, 'rendered', resolve);
              }.bind(this)
            );
        var widgetShown = !widget.wasHidden
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                this.listenToOnce(widget, 'shown', resolve);
              }.bind(this)
            );

        // Above-all, как и фикседы, будем анимировать только когда они фактически появляются
        // Например, если above-all с onload-анимацией скрыт на первой странице, но виден на второй,
        // начнём анимацию только когда виджет покажется на второй странице
        // startWhenInView не ждёт готовности остальных above-all-виджетов
        conditions = this.startWhenInView
          ? [widgetRendered, widgetShown, widgetLoaded]
          : [aboveAnimationsReady, widgetRendered, widgetShown, widgetLoaded];
        // Остальные, не above-all, в том числе просто глобальные
      } else {
        var pageStarted = this.page.started
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                this.listenToOnce(this.page, 'started', resolve);
              }.bind(this)
            );

        // запускаем лоад анимации только если все виджеты на странице имеющие лоад анимации загружены
        // это важно чтобы выстраивать цепочки последовательных анимаций виджетов
        // (start when in view не ждут этого события)
        var animationsReady = this.page.loadAnimationsReady
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                this.listenToOnce(this.page, 'loadAnimationsReady', resolve);
              }.bind(this)
            );

        var changedToOwningPage = this.page.isCurrent()
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                this.listenToOnce(this.page, 'changedTo', resolve);
              }.bind(this)
            );
        var scrolledToBounds = this.isInBounds()
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                this.listenToOnce(this, 'scrolledToBounds', resolve);
              }.bind(this)
            );
        var isInBoundsAfterWidgetLoad = this.isInBounds()
          ? window.Promise.resolve()
          : new window.Promise(
              function(resolve) {
                // После загрузки виджета нужно проверить попадание в окно ещё раз
                widgetLoaded.then(
                  function() {
                    if (this.isInBounds()) {
                      resolve();
                    }
                  }.bind(this)
                );
              }.bind(this)
            );

        var isInBounds = window.Promise.race([scrolledToBounds, isInBoundsAfterWidgetLoad]);

        // startWhenInView, кроме очевидного, не ждёт готовности остальных виджетов на этой странице
        conditions = this.startWhenInView
          ? [pageStarted, changedToOwningPage, isInBounds]
          : [animationsReady, pageStarted];
      }

      promise = window.Promise.all(conditions);
    }

    return promise;
  },

  isInBounds: function() {
    var pageScale = this.page.scale;
    var boxes = _.map(this.widgets, function(widget) {
      var element = widget.$el[0];
      // Баг IE: когда у элемента нет parentElelement, getBoundingClientRect бросает unspecified error
      if (element.parentElement) {
        return Scale.isOn(pageScale) && Scale.isZoom()
          ? Scale.getBox(element, pageScale)
          : element.getBoundingClientRect();
      } else {
        return {
          bottom: 0,
          height: 0,
          left: 0,
          right: 0,
          top: 0,
          width: 0,
        };
      }
    });
    var box = MathUtils.getBoundingBoxOfMany(boxes);
    var windowHeight = $(window).height();
    var allowedVisibleHeight = this.visibleHeightPortion * box.height;

    return (
      (box.height && box.bottom >= allowedVisibleHeight && box.bottom <= windowHeight) ||
      (box.height && box.top <= windowHeight - allowedVisibleHeight && box.top > 0)
    );
  },
});

//* ******************************************************************************************************
// класс для клик анимаций
//* ******************************************************************************************************
Animation['click'] = BaseAnimation.extend({
  initialize: function() {
    BaseAnimation.prototype.initialize.apply(this, arguments);

    var bindClick = function($trigger) {
      $trigger.on('click', this.onClick);
      $trigger.css('cursor', 'pointer');
    }.bind(this);

    this.waitingForEnd = false;

    this.lastTimeClick = 0;

    this.withTriggerOrElement(bindClick);
  },

  onClick: function() {
    if (!this.timeline) return;

    // на вакомах очень часто происходят двойные клики
    if (+new Date() - this.lastTimeClick < 300) return;

    this.lastTimeClick = +new Date();

    var t = this.timeline;

    // Анимация неактивна — при клике запускаем
    if (!t.active) {
      this.waitingForEnd = false;
      t.reversed ? t.reverse() : t.play(); // reverse для лупа не сработает, но такой ситуации и не может быть что лупп анимация не активна и при этом состояние reversed
      this.setHasPlayed();
      _.each(this.widgets, function(widget) {
        if (widget.type === 'video' && widget.player) {
          t.reversed ? widget.player.pause() : widget.player.play();
        }
      });
      // Анимация активна — при клике останавливаем или отменяем остановку, если уже кликали до этого
    } else {
      this.waitingForEnd ? t.off('full-cycle-end', this.onCycleEnd) : t.on('full-cycle-end', this.onCycleEnd);
      this.waitingForEnd = !this.waitingForEnd;
    }
  },

  onCycleEnd: function() {
    var t = this.timeline;

    t.off('full-cycle-end', this.onCycleEnd);

    this.waitingForEnd = false;

    if (t.loop) {
      t.stop();
    } else {
      // resersed в конце цикла уже cменил свое значение на обратное, поэтому утем это
      t.reversed ? t.reverse() : t.play();
      this.setHasPlayed();
    }
  },

  setHasPlayed: function() {
    var permanentId = AnimationUtils.getPermanentAnimationId(this.widgets[0]);
    this.animation.playOnce && !AnimationUtils.hasPlayed(permanentId) && AnimationUtils.persistHasPlayed(permanentId);
  },

  destroy: function() {
    var resetCursor = function($el) {
      $el.css('cursor', '');
    };
    this.withTriggerOrElement(resetCursor);

    BaseAnimation.prototype.destroy.apply(this, arguments);
  },
});

//* ******************************************************************************************************
// класс для ховер анимаций
//* ******************************************************************************************************
Animation['hover'] = BaseAnimation.extend({
  initialize: function() {
    BaseAnimation.prototype.initialize.apply(this, arguments);

    // Слушаем mouseenter / mouseleave только на десктопе. Этих событий и так нет на мобильном, но мало ли.
    if (Modernizr.isdesktop) {
      var bindHover = function($trigger) {
        $trigger.on('mouseenter', this.onMouseEnter);
        $trigger.on('mouseleave', this.onMouseLeave);
      }.bind(this);

      // сообщаем виджетам, у которых уже есть ховер-эффекты, что теперь добавилась еще и анимация
      _.each(
        this.widgets,
        _.bind(function(wid) {
          if (['text', 'shape', 'picture', 'button'].indexOf(wid.type) != -1) {
            wid.hasHoverAnimation = true;
          }
        }, this)
      );

      this.withTriggerOrElement(bindHover);
    }
  },

  onMouseEnter: function(e) {
    if (!this.timeline) return;

    var t = this.timeline;

    if (!t.active) {
      !t.reversed && t.play();
    } else {
      if (!t.loop && t.reversed) {
        t.on('full-cycle-end', this.onCycleEnd);
      } else {
        t.off('full-cycle-end', this.onCycleEnd);
      }
    }
  },

  onMouseLeave: function(e) {
    if (!this.timeline) return;

    var t = this.timeline;

    if (!t.active) {
      !t.loop && t.reversed && t.reverse();
    } else {
      if (!t.loop && t.reversed) {
        t.off('full-cycle-end', this.onCycleEnd);
      } else {
        t.on('full-cycle-end', this.onCycleEnd);
      }
    }
  },

  onCycleEnd: function() {
    var t = this.timeline;

    t.off('full-cycle-end', this.onCycleEnd);

    if (t.loop) {
      t.stop();
    } else {
      // resersed в конце цикла уже cменил свое значение на обратное, поэтому утем это
      t.reversed ? t.reverse() : t.play();
    }
  },
});

export default Animations;
