import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import ShapeSVG from '../../common/shape-svg';
import Scale from '../../common/scale';
import { Utils } from '../../common/utils';

/**
 * Виджет Shape
 */

const ShapeWidget = WidgetClass.extend(ShapeSVG.prototype).extend({
  // достаточно версии прототипа
  // initialize: function(data, page) {},

  mailchimpMatcher: /__rm_mailchimp_(.+)/i,
  anchorRegexp: /__rm_anchor/i,
  shareRegexp: /^share\.(facebook|twitter|pinterest|gplus|linkedin|email)\.(mag|page)$/i,

  render: function() {
    this.makeBox('widget-shape');

    if (this.hasHoverAnimation) {
      this.$el.addClass('has-onhover-animation');
    }

    this.rendered = true;

    if (this.requiresImageLoad()) {
      // Произошла ошибка - мы не смогли растеризовать svg
      if (!this.rasterUrl) {
        this.widgetIsLoaded();
        return this;
      }

      this.$el.addClass('icon');

      // если тип шейпа иконка (картинка т.е.), тогда повороты с антиалиасингом
      this.antialiasRotation = true;

      var $img = $('<img>');

      $img
        .on(
          'load',
          _.bind(function() {
            this.$el.html($img);
            this.widgetIsLoaded();
          }, this)
        )
        .on('error', this.widgetIsLoaded);

      $img.attr(
        'src',
        Modernizr.retina || Scale.isOn(this.page.scale)
          ? this.raster2xUrl || this.rasterUrl || ''
          : this.rasterUrl || ''
      );
    } else {
      this.redrawSVG();
      this.widgetIsLoaded();
    }

    this.checkLink();

    return this;
  },

  // Здесь этот метод не нужен. Добавлен только из-за бага рендеринга Firefox
  // Он неверно отрисовывает clip-path и mask на невидимой странице. Приходится еще
  // раз перерисовывать
  // https://trello.com/c/GjsRi2m9/178-ff
  start: function() {
    if (Modernizr.firefox && !this.requiresImageLoad() && this.borders > 0) {
      this.redrawSVG();
    }
  },

  redrawSVG: function() {
    var self = this,
      fakeModel = {
        id: self._id,
        get: function(param) {
          return self[param];
        },
      };

    // переделываем svg в div, в мобильных все с непрозрачным бордером, для десктопа только те, что без бордера
    if (this.tp == 'rectangle' && this.opacity == 1 && (!Modernizr.isdesktop || this.borders == 0)) {
      var bg_color = Utils.hex2rgb(this.bg_color),
        color = Utils.hex2rgb(this.color);

      if (bg_color) {
        bg_color.push(this.bg_opacity);
      }

      this.$el.css({
        'background-color': bg_color ? 'rgba(' + bg_color.join(',') + ')' : 'transparent',
        'border-color': color ? 'rgb(' + color.join(',') + ')' : 'transparent',
        'border-radius': this.radius || 0,
        'border-style': 'solid',
        'border-width': this.borders,
        'box-sizing': 'border-box',
      });

      this.$el.addClass('div-instead-of-svg');
    } else {
      // вызываем метод генерации svg
      // он описан в /common/shape-svg.js, потому что он общий и для конструктора и для вьювера
      // var svg = this.generateShapeSVG('viewer', fakeModel, fakeModel.get('w'), fakeModel.get('h'));
      var svg = this.generateShapeSVG('viewer-' + Utils.generateUUID(), fakeModel, this.$el.width(), this.$el.height());

      this.$el.html(svg);
    }
  },

  /**
   * Определяет, нужна ли шейпу загрузка растрового изображения
   * @returns {boolean}
   */
  requiresImageLoad: function() {
    return this.tp === 'icon';
  },

  canRenderImmediately: function() {
    // Если шейпу не нужна загрузка растрового изображения, значит, его можно рендерить сразу
    return !this.requiresImageLoad();
  },

  onPageResize: function(opts) {
    WidgetClass.prototype.onPageResize.apply(this, arguments);

    if (!this.requiresImageLoad()) {
      this.redrawSVG();
    }
  },

  //достаточно версии прототипа
  //widgetIsLoaded: function() {},

  //достаточно версии прототипа
  //isValid: function() {},

  //достаточно версии прототипа
  //start: function() {},

  //достаточно версии прототипа
  //stop: function() {},

  //достаточно версии прототипа
  //destroy: function() {},
});

export default ShapeWidget;
