/**
 * Модальное меню мега со списком страниц
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/viewer/final-page.tpl';
import ShareUtils from '../libs/shareutils';
import { Utils, Constants } from '../common/utils';
import Login from '../common/login';

const FinalPageClass = Backbone.View.extend({
  template: templates['template-viewer-final-page'],

  events: {
    'click .recover .go': 'onClickRecoverSubmit',
    'click .join.ljr-panel .go': 'onJoinButtonClick',
  },

  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);
    return this; // для вызова по цепочке
  },

  render: function() {
    /*
			finalPageType	is_logged	recent_mags	result
			empty			x			x			empty
			recent			0			0			empty
			recent			1			0			empty
			join			1			>0			empty //upd: заменили на empty для того чтобы на бэке было проще определять нужны или нет рисенты, достаточно посмотреть на finalPageType
			join			1			0			empty

			recent			0			>0			recent
			recent			1			>0			recent
			join			0			0			join
			join			0			>0			join
			*/
    var tp = 'empty';

    if (this.finalPageType == 'join' && !this.me) tp = 'join';
    if (this.finalPageType == 'join') tp = 'join'; // с изначальным получением залогиненого юзера проблемы, пусть они тоже видят плашку join, прятать ее для них не стоит, на телефоне они включены в высоту страницы и для стики в расчеты всего скрола

    // создаем вьюху стандартым методом бекбона:
    // устанавливаем el, $el и делегацию событий из списка events
    this.setElement(
      this.template({
        mag: this.mag,
        // disableSocials: this.isPrivate && !this.mag.user.isMe, //дезейблим социалку если приватный мэг и смотрит не автор
        disableSocials: this.isPrivate, // с изначальным получением залогиненого юзера проблемы, пусть вообще никто не видит для приватного
        recentMags: this.recentMags,
        tp: tp,
        magLink: this.mag.getUrl(),
        isStickyVerticalViewer: this.isStickyVerticalViewer,
        tweetText: ShareUtils.getFinalShare(null, _.extend(this.mag.model.toJSON(), { user: this.mag.user }), {
          forProject: true,
          customDomain: RM.common.isDomainViewer,
        }).tweet,
        specialRecentView: tp == 'recent' && this.recentMags.length == 1 && !Modernizr.isphone,
        Constants,
      })
    );

    this.$el.appendTo(this.$container);

    if (tp == 'join') {
      new Login({
        useNavigate: false,
        $node: this.$('.dop-container-join'),
        router: this.router,
      }).render();
    }

    if (this.viewerType == 'horizontal') {
      this.listenTo(this.mag, 'pageChanged', this.onPageChange);
      this.listenTo(this.mag, 'keypress-' + $.keycodes.esc, this.hide);
    } else {
      // vertical
      this.show();
    }

    this.setRecoverPanelTop();
    return this; // для вызова по цепочке
  },

  onJoinButtonClick: function() {
    if (this.$('.join.ljr-panel input').hasClass('err') || !this.$('.join.ljr-panel .go').hasClass('active')) return;
  },

  onClickRecoverSubmit: function() {
    var email = this.$('.recover.ljr-panel .email-input').val();

    $('.recover.ljr-panel .send-message .recover-email').text(email);
  },

  //задает top позицию для окна восстановления пароля вровень с окном логина
  //в случае с мобильной версии задаем конкретное число,
  //потому что для мобильной версии высота final page захордкодена
  setRecoverPanelTop: function() {
    var smallScreen = window.matchMedia('(max-width: 768px)').matches,
      topPosition = (this.getHeight() - $('.join.ljr-panel').outerHeight()) / 2 + 'px';

    $('.recover.ljr-panel').css({ top: smallScreen ? '0' : topPosition });
  },

  // для режима вертикального вьювера вызывается один раз при рендере
  show: function() {
    if (!this.shown) {
      this.$el.removeClass('offscreen hidden'); // hidden На вский случай , по идее его тут уже не должно быть
      this.shown = true;

      if (this.viewerType == 'horizontal') {
        this.mag.trigger('finalPageShown');
      }
    }

    return this; // для вызова по цепочке
  },

  // для режима вертикального вьювера не вызывается никогда
  hide: function() {
    if (this.shown) {
      this.$el.addClass('offscreen');
      this.shown = false;
      this.mag.trigger('finalPageHidden');
    }

    return this; // для вызова по цепочке
  },

  destroy: function() {
    // удаляем вьюху стандратным методом бекбона:
    // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
    return this.remove(); // return this для вызова по цепочке
  },

  setSizeAndPosForStickyVersion: function(params) {
    var smallScreen = window.matchMedia('(max-width: 768px)').matches;
    params = params || {};

    if (this.$('.recover.ljr-panel').hasClass('hidden')) {
      this.$('.dop-container-text').removeClass('hidden');
      this.$el.css({
        height: smallScreen ? this.$('.final-page-container').height() : 100 + 'vh',
        top: params.absolutePosition,
      });
    } else {
      this.$('.dop-container-text').addClass('hidden');
      this.$el.css({
        height: 100 + 'vh',
        top: params.absolutePosition,
      });
    }
  },

  scrollOnVerticalMode: function(offset) {
    this.hasScrollOnVerticalMode = true;

    this.$el.css('top', 0); // чтобы перекрыть динамические классы для final-page в setNewPagesWidth

    Utils.applyTransform(this.$('.final-page-wrapper'), 'translateY(' + -offset + 'px)');
  },

  resetScrollOnVerticalMode: function(offset) {
    if (!this.hasScrollOnVerticalMode) return;

    this.hasScrollOnVerticalMode = false;

    this.$el.css('top', '');

    Utils.applyTransform(this.$('.final-page-wrapper'), '');
  },

  getHeight: function() {
    return this.$('.final-page-wrapper').height();
  },

  onPageChange: function(page, params) {
    // оптимизация, подготавливаем файнал пейдж к показу
    // как только оказались на последней странице мэга сразу делаем файнал пейдж видимым, но пока за пределами экрана
    this.$el.toggleClass('hidden', !this.mag.isLastPage());

    this.hide();
  },

  onKeyUp: function(e) {
    e.stopPropagation();
  },
});

export default FinalPageClass;
