/**
 * Виджет Cart
 */

import Features from '../../common/features';
import ButtonWidget from './button';

var ecommerceCartWidget = ButtonWidget.extend({
  render: function() {
    ButtonWidget.prototype.render.apply(this, arguments);
    this.renderSidebar();
    this.mag.eCommerceManager.events.on('ecommerce:cartdata:changed', this.renderTotalPrice);
  },

  renderSidebar: function() {
    if (this.mag.eCommerceManager.isMagConnectedToStripe() && this.mag.cartSidebar) {
      this.mag.cartSidebar.render();
    }
  },

  // Добавляет общий прайс содержимого корзины к тексту кнопки
  renderTotalPrice: function(cartData) {
    if (!cartData || !cartData.skus) {
      return;
    }
    var totalPrice = 0;

    for (var id in cartData.skus) {
      if (cartData.skus.hasOwnProperty(id)) {
        var item = cartData.skus[id];
        totalPrice += item.price * item.cart_count;
      }
    }

    if (Math.round(totalPrice) !== 0) {
      var btnText = this.text + ' (' + (totalPrice / 100).toFixed(2) + ')';
      this.$el.find('.text').css('width', 'auto'); // Необходимо тк ширина текста жестко задана
    } else {
      var btnText = this.text;
      this.$el.find('.text').css('width', this.text_w + 'px');
    }

    this.renderButtonText(btnText);
  },

  onButtonClick: function() {
    this.mag.eCommerceManager.changeCartSidebarVisibility();
  },

  destroy: function() {
    this.mag.eCommerceManager.events.off('ecommerce:cartdata:changed', this.renderTotalPrice);
    ButtonWidget.prototype.render.apply(this, arguments);
  },
});

export default Features.get('eCommerce') ? ecommerceCartWidget : undefined;
