/**
 *
 * виджет Hotspot во Вьювере.
 *
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import Widgets from './';
import { Utils } from '../../common/utils';
import templates from '../../../templates/common/hotspot-widget.tpl';
import ShapeSVG from '../../common/shape-svg';
import HotspotWidgetClass from '../../common/hotspot-widget';

const hotspotWidget = WidgetClass.extend(ShapeSVG.prototype).extend({
  // флаг, что при прелоадинге виджетов ждать его загрузки не надо
  // (но не для скриншотера).
  DO_NOT_WAIT_FOR_PRELOAD: true,
  PHONE_TIP_WIDTH: 280, // Эта же константа есть в hotspot-widget.less
  MOUSE_LEAVE_TIMEOUT: 100,
  EDGE_GAP: 16, // Мин расстояние от подсказки до края экрана

  events: {
    'click .pin': 'onPinClick',
    mouseenter: 'onMouseenter',
    mouseleave: 'onMouseleave',
  },

  render: function() {
    var params;

    this.makeBox('widget-hotspot');

    this.rendered = true;
    this.phoneTipWrapperTemplate = templates['template-common-hotspot-widget-mobile-wrapper'];
    this.isPreview = !!(RM.constructorRouter && RM.constructorRouter.preview);

    params = {
      // первый параметр this передает хотспоту объект, в котором находятся
      // все нужные параметры (моделей во Вьювере у нас нет).
      // в Конструкторе например при создании хотспота
      // передается _.clone(this.model.attributes) вместо this.
      model: this,
      $container: this.$el,
      environment: 'viewer',
      mag: this.page && this.page.mag,
      block: null,
    };
    this.hotspotWidget = new HotspotWidgetClass(params);
    this.$pin = this.$('.pin').addClass('viewer');

    if (this.started) {
      this.renderTip();
    }

    if (this.pin_type == 'icon') {
      this.$el.addClass('icon');

      // Произошла ошибка - мы не смогли растеризовать svg
      if (!this.rasterUrl) {
        this.widgetIsLoaded();
        return this;
      }

      var $img = $('<img>');

      $img
        .on(
          'load',
          _.bind(function() {
            this.$('.pin').html($img);
            this.widgetIsLoaded();
          }, this)
        )
        .on('error', this.widgetIsLoaded);

      $img.attr('src', Modernizr.retina ? this.raster2xUrl || this.rasterUrl || '' : this.rasterUrl || '');
    } else {
      this.renderPinShape();
      this.widgetIsLoaded();
    }

    return this;
  },

  renderPinShape: function() {
    // вызываем метод генерации svg.
    // он описан в /common/shape-svg.js, потому что он общий и для конструктора и для вьювера.
    var model = new Backbone.Model(this),
      svg;

    model.id = this._id;
    svg = this.generateShapeSVG('viewer', model, this.w, this.h);

    this.$pin.html(svg);
  },

  renderTip: function() {
    if (this.isTipRendered) {
      return;
    }

    this.$tip = $('<div class="tip viewer invisible"></div>');

    this.$tip.toggleClass('box-shadow', this['tip_box-shadow']);

    this.$tip.css({ 'border-radius': this['tip_border-radius'] });

    this.$blocksWrapper = $('<div class="blocks-wrapper"></div>');

    this.$blocksWrapper.appendTo(this.$tip);

    this.$blocksWrapper.css({
      'background-color': Utils.getRGBA(this['tip_background-color'], this['tip_background-color-opacity'] / 100),
      'border-radius': this['tip_border-radius'],
    });

    this.$('.common-hotspot').append(this.$tip);

    this.pictureData = _.findWhere(this.wids || [], { type: 'picture' });
    this.textData = _.findWhere(this.wids || [], { type: 'text' });

    this.hasPictureWidget = !!(this.pictureData && !this.pictureData.hidden);
    this.hasTextWidget = !!(this.textData && !this.textData.hidden);

    if (this.hasPictureWidget) {
      this.pictureData.insideHotspot = true;
      this.pictureWidget = new Widgets.picture(this.pictureData, this.page, this.$blocksWrapper);
      if (this.pictureWidget.isValid()) {
        this.pictureData.w = Math.min(this.tip_w, this.pictureData.w); // На всякий случай, чтобы виджет не выпирал за пределы подсказки
        this.pictureWidget.render();
      } else {
        // Если фактически нет картинки, не отображаем виджет,
        // пересчитываем высоту подсказки и положение текста
        this.tip_h -= this.pictureWidget.h || 0;
        this.textData && (this.textData.y = 0);

        this.pictureWidget = null;
        this.hasPictureWidget = false;
      }
    }

    if (this.hasTextWidget) {
      this.textData.insideHotspot = true;
      this.textData.hasGlobalParent = this.is_global;
      this.textWidget = new Widgets.text(this.textData, this.page, this.$blocksWrapper);
      this.textWidget.w = Math.min(this.tip_w, this.textWidget.w); // На всякий случай, чтобы виджет не выпирал за пределы подсказки
      this.textWidget.render();
    }

    // Если не отрендерились внутренние блоки, то не рендерим саму подсказку
    if (!this.hasPictureWidget && !this.hasTextWidget) {
      this.$tip.remove();
      return;
    }

    this.$closeButton = $('<div class="hotspot-phone-close"></div>');
    this.$tip.append(this.$closeButton);

    this.isTipRendered = true;
  },

  /**
   * Обновляет родительскую страницу у виджета
   * Нужно для глобальных виджетов, т.к. они могут иметь разные свойства на разных страницах
   * @param {RM.classes.Page} newPage
   */
  updatePage: function(newPage) {
    WidgetClass.prototype.updatePage.apply(this, arguments);
    this.textWidget && this.textWidget.updatePage(newPage);
  },

  start: function() {
    this.started = true;

    if (this.rendered && !this.destroyed) {
      this.renderTip();
    }

    return this;
  },

  stop: function() {
    this.started = false;

    this.isTipVisible && this.hideTip();

    return this;
  },

  applyTipPosition: function() {
    var pinDists = this.calcDistsToPageVisibleBounds(), // Расстояния от центра пина до краев вьюпорта
      tipDists, // Расстояния от центра пина до краев вьюпорта
      pinSize = {
        w: this.w,
        h: this.h,
      },
      tipSize = {
        w: this.$tip.width(),
        h: this.$tip.height(),
      },
      gap = this.hotspotWidget.TIP_GAP,
      newPos = this.tip_pos,
      m = _.pick(this, 'w', 'h', 'tip_w', 'tip_h', 'tip_pos'), // Псевдомодель. Скормим ее общей функции позиционирования
      newCoords,
      topFixed = false,
      leftFixed = false;

    newPos = getBestPos(this.tip_pos);
    m.tip_pos = newPos;

    // Общий алгоритм размещение
    newCoords = this.hotspotWidget.apply_tip_position(m);

    // Проверяем и корректируем то, что вылезло за границы вьюпорта
    tipDists = calcTipDistsToPageVisibleBounds(newPos);
    _.each(
      tipDists,
      function(dist, key) {
        if (dist < 0) {
          switch (key) {
            case 'top':
              newCoords.top -= dist - this.EDGE_GAP;
              topFixed = true;
              break;
            case 'bottom':
              // Если еще не корректировали сверху
              if (!topFixed) {
                newCoords.top += dist - this.EDGE_GAP;
              }
              break;
            case 'left':
              newCoords.left -= dist - this.EDGE_GAP;
              leftFixed = true;
              break;
            case 'right':
              // Если еще не корректировали слева
              if (!leftFixed) {
                newCoords.left += dist - this.EDGE_GAP;
              }
              break;
          }
        }
      }.bind(this)
    );
    this.$tip.css(newCoords);

    // Возвращает кол-во свободного места от подсказки до краев вьюпорта
    // для выбранной позиции
    function calcTipDistsToPageVisibleBounds(position) {
      var top, right, bottom, left;
      switch (position) {
        case 'top':
          top = pinDists.top - pinSize.h / 2 - gap - tipSize.h;
          bottom = pinDists.bottom + pinSize.h / 2 + gap;
          left = pinDists.left - tipSize.w / 2;
          right = pinDists.right - tipSize.w / 2;
          break;
        case 'right':
          top = pinDists.top - tipSize.h / 2;
          bottom = pinDists.bottom - tipSize.h / 2;
          left = pinDists.left + pinSize.w / 2 + gap;
          right = pinDists.right - pinSize.w / 2 - gap - tipSize.w;
          break;
        case 'bottom':
          top = pinDists.bottom + pinSize.h / 2 + gap;
          bottom = pinDists.bottom - pinSize.h / 2 - gap - tipSize.h;
          left = pinDists.left - tipSize.w / 2;
          right = pinDists.right - tipSize.w / 2;
          break;
        case 'left':
          top = pinDists.top - tipSize.h / 2;
          bottom = pinDists.bottom - tipSize.h / 2;
          left = pinDists.left - pinSize.w / 2 - gap - tipSize.w;
          right = pinDists.right + pinSize.w / 2 + gap;
          break;
      }

      return { top: top, left: left, bottom: bottom, right: right };
    }

    function getBestPos(defPos) {
      var bestPos = defPos,
        min = -Infinity,
        curMin,
        stop = false;

      _.each(['top', 'right', 'bottom', 'left'], function(position) {
        if (stop) {
          return;
        }
        var tipDists = calcTipDistsToPageVisibleBounds(position);
        curMin = _.min(tipDists);

        // Песли вписываемся в заданную позицию, то больше ничего не делаем
        if (position == defPos && tipDists[position] > 0) {
          bestPos = position;
          stop = true;

          // Иначе ищем позицию, чтобы минимальное свободное место до краев было максимальным :)
          // При этом, например при больших размерах, подсказка все равно может отрезаться.
          // Такой алгоритм позволит свести обрезку к минимуму.
          // Так же важно, чтобы в дальнейшем при корректировке позиции,
          // вылезшей за экран подсказки, она не каладывалась на пин.
        } else if (curMin > min && tipDists[position] >= 0) {
          min = curMin;
          bestPos = position;
        }
      });

      return bestPos;
    }
  },

  adjustPictureSize: function() {
    var phoneScale = this.tip_w / this.PHONE_TIP_WIDTH,
      size;

    if (this.pictureWidget) {
      if (this.isPhone()) {
        size = {
          width: this.PHONE_TIP_WIDTH,
          height: this.pictureData.h / phoneScale,
        };
      } else {
        size = {
          width: this.pictureData.w,
          height: this.pictureData.h,
        };
      }

      this.pictureWidget.$el.css(size);
    }
  },

  onPinClick: function() {
    if (this.isHoverable()) {
      return;
    }
    if (this.isTipVisible) {
      this.hideTip();
    } else if (this.isTipRendered) {
      this.showTip();
    }

    RM.analytics && this.isTipVisible && RM.analytics.sendEvent('Hotspot Click');
  },

  onMouseenter: function() {
    if (!this.isHoverable() || !this.isTipRendered) {
      return;
    }
    clearTimeout(this.mouseLeaveTimeout);
    if (!this.isTipVisible) {
      this.showTip();
    }

    RM.analytics && RM.analytics.sendEvent('Hotspot Hover');
  },

  onMouseleave: function() {
    if (!this.isHoverable()) {
      return;
    }
    clearTimeout(this.mouseLeaveTimeout);
    this.mouseLeaveTimeout = setTimeout(
      function() {
        this.hideTip();
      }.bind(this),
      this.MOUSE_LEAVE_TIMEOUT
    );
  },

  showTip: function() {
    if (!this.isTipRendered) {
      return;
    }

    this.adjustPictureSize();

    if (this.isPhone()) {
      this.$phoneTipWrapper = this.$phoneTipWrapper || $(this.phoneTipWrapperTemplate());

      // Чтобы задать позицию absoulute, а не fixed, т.к. будем показывать в превью
      this.$phoneTipWrapper.toggleClass('preview', this.isPreview);

      // В превью аттачим внутрь изображения телефона
      this.$phoneTipWrapper.detach().appendTo(this.isPreview ? '.mag-pages-container' : 'body');

      this.$phoneTipWrapper.on('touchstart', this.onTipTouchStart);
      this.$phoneTipWrapper.on('touchmove', this.onTipTouchMove);

      this.$tipParent = this.$tip.parent();
      this.$tip.detach().appendTo(this.$phoneTipWrapper.find('.center-cell'));

      _.defer(
        function() {
          this.$phoneTipWrapper.removeClass('invisible');
        }.bind(this)
      );

      // Закрываем по клику на бэке
      this.$phoneTipWrapper.on(
        'click',
        function(e) {
          var $closestTip = $(e.target).closest('.tip');
          if ($closestTip.is(this.$tip)) {
            return;
          } // Не закрываем по клику на самом типе
          this.hideTip();
          this.$phoneTipWrapper.off();
        }.bind(this)
      );

      this.$closeButton.on('click', this.onCloseButtonClick);
    } else {
      this.hotspotWidget.apply_tip_container_size({
        width: this.tip_w,
        height: this.tip_h,
      });
      this.applyTipPosition();

      this.$tip.removeClass('invisible');

      this.BringToTopZ();

      // Будем скрывать по неблокирующему клику в любое место
      this.page.mag.router.$mags_container.on('mousedown touchstart', this.onGlobalMouseDown);

      if (!this.isHoverable()) {
        $('body').on('keydown', this.onBodyKeyDown);
      }
    }

    this.listenTo(this.page.mag, 'change:viewport', this.onMagViewportBeforeChange);

    this.isTipVisible = true;
  },

  hideTip: function() {
    if (!this.isTipVisible) {
      return;
    }

    this.isTipVisible = false;

    clearTimeout(this.mouseLeaveTimeout);

    if (this.isPhone()) {
      this.$phoneTipWrapper.addClass('invisible');

      this.$phoneTipWrapper.off('touchstart', this.onTipTouchStart);
      this.$phoneTipWrapper.off('touchmove', this.onTipTouchMove);

      _.delay(
        function() {
          this.$tip.detach().appendTo(this.$tipParent);
          this.$phoneTipWrapper && this.$phoneTipWrapper.detach();
        }.bind(this),
        200
      );
    } else {
      this.$tip.addClass('invisible');

      this.BackToNormalZ();

      $('body').off('keydown', this.onBodyKeyDown);
    }

    this.stopListening(this.page.mag, 'change:viewport');
  },

  onTipTouchStart: function(e) {
    this.tipScrollY = e.originalEvent.touches.item(0).clientY;
  },

  onTipTouchMove: function(e) {
    e.stopPropagation();

    var $target = $(e.currentTarget),
      delta = this.tipScrollY - e.originalEvent.touches.item(0).clientY;

    if (
      (delta < 0 && $target.scrollTop() <= 0) ||
      (delta > 0 && $target.scrollTop() + $target.height() >= $target[0].scrollHeight)
    ) {
      e.preventDefault();
    }

    this.tipScrollY = e.originalEvent.touches.item(0).clientY;
  },

  onCloseButtonClick: function() {
    this.$closeButton.off('click', this.onCloseButtonClick);
    this.hideTip();
  },

  onMagViewportBeforeChange: function(e) {
    this.hideTip();
  },

  onGlobalMouseDown: function(e) {
    var $target = $(e.target),
      $closestPin = $target.closest('.pin'),
      $closestTip = $target.closest('.tip');

    // Чтобы не скырвался по mousedown по самому пину
    if ($closestPin.is(this.$pin) || $closestTip.is(this.$tip)) {
      return;
    }

    this.page.mag.router.$mags_container.off('mousedown touchstart', this.onGlobalMouseDown);
    this.hideTip();
  },

  isPhone: function() {
    return Modernizr.isphone || (this.isPreview && this.page.mag.viewport == 'phone_portrait');
  },

  isHoverable: function() {
    return (
      this.tip_show_on == 'hover' &&
      Modernizr.isdesktop &&
      !this.isPhone() && // Для превью
      this.page.mag.viewport !== 'tablet_portrait'
    ); // Для превью
  },

  onBodyKeyDown: function(e) {
    if (e.keyCode == $.keycodes.esc) {
      this.hideTip();
    }
  },

  destroy: function() {
    this.hotspotWidget && this.hotspotWidget.destroy();
    this.hotspotWidget = null;

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default hotspotWidget;
