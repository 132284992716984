/**
 * Класс для панели логина-регистрации-восстановления пароля
 */

// ВНИМАНИЕ!!!, этот же файл обслуживает ифон версию!!!!
// также этот файл обслуживает форму джойна в лэндингах (в ифрейме с landing_signup.html)
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import md5 from 'md5';
import { Utils, Constants } from './utils';
import templates from '../../templates/common/login.tpl';
import CreateMag from './create-mag';

const Login = Backbone.View.extend({
  events: {
    'click .link': 'link',
    'click .close-popup': 'closePopup',

    'input .login.ljr-panel input.password-input': 'onPasswordInput',
    'keydown .login.ljr-panel input': 'loginInputKey',
    'click .login.ljr-panel .go': 'tryLogin',
    'submit .login.ljr-panel #login_form': 'onLoginFormSubmit',
    'click .login.ljr-panel .social-auth': 'socialAuth',

    'input .join.ljr-panel input.password-input': 'onPasswordInput',
    'keydown .join.ljr-panel input': 'joinInputKey',
    'click .join.ljr-panel .go': 'createAccount',
    'click .join.ljr-panel .social-auth': 'socialAuth',
    'click .join.ljr-panel label .checkmark': 'changeCheckboxState',
    'keyup .join.ljr-panel input.for-validate': 'validateForm',

    'click .recover.ljr-panel .go': 'sendRecoverRequest',
    'click .recover.ljr-panel .go-back': 'backFromRecover',

    'input .reset.ljr-panel input': 'onPasswordInput',
    'click .reset.ljr-panel .go': 'resetPassword',

    'click .login-popup-wrapper': 'closeOutsideClick',

    touchstart: 'onTouchStart',
    touchmove: 'onTouchMove',
  },

  ERROR_MESSAGES: {
    invalid_email: 'Not a valid email',
    short_password: 'At least 6 characters long',
    password_dont_match: "Passwords don't match",
  },

  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);

    // Форма логина может передать свои локализованные сообщения
    _.extend(this.ERROR_MESSAGES, this.errorMessages);

    this.template = templates['template-common-login'];
    this.template_button_preloader = templates['template-common-login-preloader'];

    this.logged = false; // спец поле чтобы опера при автоподстановке не пыталась сама отправить форму

    this.animationSpeed = 0;

    if (!this.disableAnimations) {
      _.delay(
        _.bind(function() {
          this.animationSpeed = 300;
        }, this),
        100
      );
    }

    this.enableCors();

    $(window).on('message', this.processLoginMessages);
  },

  render: function() {
    // если не задан $node тогда создаем его по шаблону
    // но он может быть задан, это бывает тогда, когда код логина используется в отдельной форме джойна в лэндингах
    // либо в файнал пейдже
    // код просто натягивается на существующую верстку внутри ифрейма
    this.setElement(this.$node || $(this.template({})).appendTo(this.$container));

    // если форма логина навешивается на существующую верстку в файнал пейдже или в форме в лендингах
    // тогда запрещаем отслеживание и блокировку оттяжки глобального скрола
    this.disableScrollWatcher = !!this.$node;

    this.setElement(this.$el);

    this.rendered = true;

    this.$nameInput = this.$('.join.ljr-panel .fullname-input');

    this.$emailInput = this.$('.join.ljr-panel .email-input');

    this.$pswInput = this.$('.join.ljr-panel .password-input');

    this.$termsCheckbox = this.$('.join.ljr-panel .terms-checkbox');

    this.$joinButton = this.$('.join.ljr-panel .go');

    var email = Utils.queryUrlGetParam('email');
    if (email) {
      this.$('.email-input').val(email);
    }
  },

  /**
   * Обработчик клика по навигационной ссылке
   * @method link
   */
  link: function(e) {
    // Добавил, потому что у Homepage тоже есть событие для a.link
    // Из-за этого функция отрабатывала дважды, и дважды отсылала данные в аналитику
    e.stopPropagation();
    if (e.which == 2 || e.metaKey || e.ctrlKey) return; // оставляем дефолтное поведение браузера

    var link = $(e.currentTarget).attr('href');

    if (!link) return false;

    // если стоит флаг useNavigate, это значит что переключения между логин-джои-форгот и пр.
    // происходят через роутер путем смены урла
    // если флага нет, считаем что роутер нам не нужен и мы просто переключаем разделы без смены урла
    // (с флагом используем на хоумпэйдже, без флага в коллекторе, фронте и конструкторе если надо)
    if (this.useNavigate) {
      this.router.navigate(link, { trigger: true });
    } else {
      if (/login/i.test(link)) this.switchTo('login');
      if (/join/i.test(link)) this.switchTo('join');
      if (/recover/i.test(link)) this.switchTo('recover');

      return false;
    }

    e.preventDefault();
  },

  closePopup: function() {
    if (Modernizr.isboxversion) return;

    if (this.useNavigate) {
      this.router.navigate(this.urlToBack || '/', { trigger: false });
      this.router.updateWindowCaptions();
    }

    this.hide(true);
  },

  changeCheckboxState: function(e) {
    var checkBox = $(e.target).siblings("input[type='checkbox']");

    if (checkBox.is(':checked')) {
      checkBox.prop('checked', false);
    } else {
      checkBox.prop('checked', true);
    }

    $(e.target).toggleClass('checked');
    $(e.target)
      .parent()
      .siblings('p')
      .toggleClass('checked');

    this.validateForm();
    e.preventDefault();
  },

  validateForm: function() {
    if (
      this.$nameInput.val() !== '' &&
      this.$pswInput.is(':valid') &&
      this.$emailInput.is(':valid') &&
      this.$termsCheckbox.is(':checked')
    ) {
      this.$joinButton.addClass('active');
    } else {
      this.$joinButton.removeClass('active');
    }
  },

  closeOutsideClick: function(e) {
    if (!Modernizr.isdesktop) return;
    if ($(e.target).hasClass('login-popup-wrapper')) this.closePopup();
  },

  show: function(urlToBack) {
    if (this.shown) return;

    $('body').addClass('disable-scroll');

    this.urlToBack = urlToBack;

    // если форму логина открываем по прямому урлу
    // просто первые 100мс при открыти сайта this.animationSpeed == 0, а потом уже 300
    if (this.animationSpeed == 0) this.$el.flashClass('no-animation', 15);

    this.$el.removeClass('hidden');

    this.trigger('shown');

    this.shown = true;

    $('body').on('keyup', this.onBodyKeyUp);

    this.onResize();
  },

  // isSimplyClosed это флаг что попап юзер закрыл сам, т.е. например кликнул в пустое место
  // а если попап был закрыт роутером, например при переходе по другому линку, тогда этот флаг будет сброшен
  hide: function(isSimplyClosed) {
    if (!this.shown) return;

    this.$el.addClass('hidden');

    _.delay(function() {
      $('body').removeClass('disable-scroll');
    }, this.animationSpeed);

    this.trigger('hidden', isSimplyClosed);

    this.shown = false;

    $('body').off('keyup', this.onBodyKeyUp);

    this.onResize();
  },

  onBodyKeyUp: function(e) {
    if (e.keyCode == 27) this.closePopup();
  },

  switchTo: function(tp) {
    var $item = this.$('.ljr-panel.' + tp),
      smallScreen = window.matchMedia('(max-width: 768px)').matches,
      $other = $item.siblings('.ljr-panel'),
      isOnFinalPage = (tp == 'recover' || tp == 'login') && this.$el.selector === '.dop-container-join' && smallScreen,
      self = this;

    if (tp == 'recover') this.$('.recover.ljr-panel .email-input').val(this.$('.login.ljr-panel .email-input').val());

    $item.removeClass('hidden');

    if (Modernizr.isdesktop) {
      setTimeout(function() {
        $item
          .find('input')
          .eq(0)
          .focus();
      }, 0);
    }

    if (!this.disableAnimations) {
      _.delay(function() {
        $other.addClass('hidden');
        if (isOnFinalPage) {
          RM.viewerRouter.mag.onResize();
        }
      }, this.animationSpeed / 4);
    } else {
      $other.addClass('hidden');
      if (isOnFinalPage) {
        RM.viewerRouter.mag.onResize();
      }
    }

    this.onResize();
  },

  onResize: function() {
    if ($(window).width() <= 768 && this.$('.login').hasClass('hidden')) {
      this.$('.login-popup-wrapper').css('height', 652);
    }

    if ($(window).width() <= 768 && this.$('.join').hasClass('hidden')) {
      this.$('.login-popup-wrapper').css('height', 466);
    }
  },

  // Показывает прелоадер в середине заданной кнопки
  showButtonPreloader: function($button) {
    var w = $button.width(),
      h = $button.height(),
      $preloader,
      pw,
      ph;

    $preloader = $(this.template_button_preloader());
    $button.append($preloader);
    $button.css({ color: 'transparent', 'background-image': 'none' });
    $button.addClass('processing');
    pw = $preloader.width();
    ph = $preloader.height();

    $preloader.css({
      left: Math.floor((w - pw) / 2),
      top: Math.floor((h - ph) / 2),
    });
  },

  hideButtonPreloader: function($button) {
    $button.find('.rmpreloader').remove();
    $button.css({ color: '', 'background-image': '' });
    $button.removeClass('processing');
  },

  loginInputKey: function(e) {
    if (e.keyCode == 13) this.tryLogin();
  },

  tryLogin: function() {
    var email = $.trim(this.$('.login.ljr-panel .email-input').val()),
      password = this.$('.login.ljr-panel .password-input').val(),
      $goButton = this.$('.login.ljr-panel .go'),
      redirect = Utils.queryUrlGetParam('redirect');

    if ($goButton.hasClass('processing')) return;

    if (email == '' || password == '') return;

    this.showButtonPreloader($goButton);

    this.requestLogin(
      email,
      password,
      _.bind(function(data) {
        if (data && data.redirect) {
          return (window.location.href = data.redirect);
        }

        if (data) {
          // чтобы был запрос на сохранение пароля в браузере, форму нужно отправить
          // щас мы ее просто отправляем на /discover
          // но в будущем, надо будет отправлять на спец адрес, который будет чистить POST, и пересылать куда надо
          // TO FIX
          if (redirect) return (window.location.href = Constants.readymag_auth_host + redirect);

          this.logged = true;

          // Залогинили юзера, пытавшегося создать проект из темплейта
          if (this.createMagParams) {
            return this.redirectAfterJoin(data.uri);
          }

          var returnUrl =
            Modernizr.sessionstorage && window.sessionStorage.getItem('rm.loginReturnUrl', window.location.pathname);
          Modernizr.sessionstorage && window.sessionStorage.removeItem('rm.loginReturnUrl');
          this.$('.login.ljr-panel #login_form input[name="url"]').val(
            Constants.readymag_auth_host + (returnUrl || '/' + data.uri)
          );
          this.$('.login.ljr-panel #login_form').submit();
        } else {
          this.hideButtonPreloader($goButton);
          clearTimeout(this.wrongTimeout);
          this.$('.login.ljr-panel').addClass('wrong-login');
          this.$('.login.ljr-panel .error').html(
            'Please check that you have entered your login and password correctly.'
          );
          this.wrongTimeout = setTimeout(
            _.bind(function() {
              this.$('.login.ljr-panel').removeClass('wrong-login');
            }, this),
            3500
          );
          // чтобы на девайсах стала видна ошибка
          if (!this.isdesktop) {
            this.$el.scrollTop(9999);
          }
        }
      }, this)
    );
  },

  requestLogin: function(email, password, cb) {
    var data = { pass: md5(password) };
    data[email.match(/@/) ? 'email' : 'uri'] = email;

    $.post(Constants.readymag_auth_host + '/api/user/?timestamp=' + new Date().getTime(), data, function(data) {
      cb && cb(data);
    }).error(function(xhr) {
      if (xhr.status == 404) cb && cb();
      else alert('Login problem. Contact tech support, please: ' + Constants.SUPPORT_MAIL);
    });

    return false;
  },

  onLoginFormSubmit: function() {
    // спец поле чтобы опера при автоподстановке не пыталась сама отправить форму
    return this.logged;
  },

  showErrors: function(errs) {
    // errs - массив
    clearTimeout(this.wrongTimeout);
    this.$('.join.ljr-panel').addClass('wrong-join');

    this.$('.join.ljr-panel .error').html(errs.join(' <br/>'));

    this.wrongTimeout = setTimeout(
      _.bind(function() {
        this.$('.join.ljr-panel').removeClass('wrong-join');
      }, this),
      2000
    );
  },

  redirectHandler: function(redirect, data, $goButton) {
    if (redirect) {
      window.location.href = redirect;
      return true;
    }
    this.redirectAfterJoin(data.uri, $goButton);
  },

  createAccount: function() {
    if (this.$('.join.ljr-panel input').hasClass('err') || !this.$joinButton.hasClass('active')) return;

    var userData = {
        email: this.$emailInput.val(),
        name: $.trim(this.$('.join.ljr-panel .fullname-input').val()),
        pass: this.$pswInput.val(),
        page: window.location.pathname,
        giftCode: '',
        subscribe: this.$('.join.ljr-panel .subscribe-checkbox').is(':checked'),
      },
      errors = [],
      $goButton = this.$joinButton,
      redirect = Utils.queryUrlGetParam('redirect'),
      incorrectEmail,
      incorrectPassword;

    if ($goButton.hasClass('processing')) return;

    if (redirect) {
      userData.giftCode = Utils.queryUrlGetParam('giftCode', decodeURIComponent(redirect));
    }

    if (userData.name == '' || userData.email == '' || userData.pass == '') {
      if (this.router.analytics) {
        this.router.analytics.sendEvent('Join Button Click', {
          label: 'native',
          value: 1,
          _join_type: 'native',
          _errors: 'Some fields are empty',
        });
      }
      return;
    }

    if (!Utils.isValidEmail(userData.email)) {
      incorrectEmail = true;
      var currentEmail = userData.email;
      this.$emailInput
        .prop('type', 'text')
        .addClass('err')
        .val(this.ERROR_MESSAGES['invalid_email']);
    }

    if (userData.pass.length < 6) {
      incorrectPassword = true;
      var currentPassword = userData.pass;
      this.$pswInput
        .prop('type', 'text')
        .addClass('err')
        .val(this.ERROR_MESSAGES['short_password']);
    }

    if (incorrectEmail || incorrectPassword) {
      clearTimeout(this.showErrorTimeout);
      this.showErrorTimeout = setTimeout(
        _.bind(function() {
          if (incorrectEmail) {
            this.$emailInput
              .removeClass('err')
              .prop('type', 'email')
              .val(currentEmail);
          }
          if (incorrectPassword) {
            this.$pswInput
              .removeClass('err')
              .prop('type', 'pass')
              .val(currentPassword);
          }
        }, this),
        3000
      );
    }

    if (!Utils.isValidEmail(userData.email)) errors.push(this.ERROR_MESSAGES['invalid_email']);
    if (userData.pass.length < 6) errors.push(this.ERROR_MESSAGES['short_password']);

    if (errors.length) return;

    if (this.router.analytics) {
      this.router.analytics.sendEvent('Join Button Click', {
        label: 'native',
        value: errors.length,
        _join_type: 'native',
        _errors: errors.join(', '),
      });
    }

    this.showButtonPreloader($goButton);

    userData.pass = md5(userData.pass);

    // создаем аккаунт и создаем новый мэг (если мы не в мобильной версии и не в ИЕ)
    $.ajax({
      type: 'POST',
      data: userData,
      url: Constants.readymag_auth_host + '/api/register',

      success: _.bind(function(data) {
        this.putStorageData('native');
        this.redirectHandler(redirect, data, $goButton);
      }, this),

      error: _.bind(function(jqXHR) {
        this.hideButtonPreloader($goButton);
        console.log(jqXHR);
        if (jqXHR.status == 403) return alert('User with email ' + userData.email + ' exists. Forgot password?');
        if (jqXHR.status === 406) return alert('Name must not contain links.');
        alert('Registration problem. Contact tech support, please:' + Constants.SUPPORT_MAIL);
      }, this),
    });
  },

  joinInputKey: function(e) {
    if (e.keyCode == 13) this.createAccount();
  },

  sendRecoverRequest: function() {
    var email = this.$('.recover.ljr-panel .email-input').val(),
      $goButton = this.$('.recover.ljr-panel .go');

    if ($goButton.hasClass('processing')) return;

    if (!Utils.isValidEmail(email)) return;

    this.showButtonPreloader($goButton);

    // запрос восстановления пароля
    this.requestRecover(
      email,
      _.bind(function(data) {
        // $('body').toggleClass('wrong-recover-email', !data);
        // if (data) $('body').addClass('recover-sended');

        this.hideButtonPreloader($goButton);

        // пишем что письмо отправили независимо от того существует мыло или нет
        this.$('.recover.ljr-panel').addClass('request-sended');
      }, this)
    );
  },

  requestRecover: function(email, cb) {
    $.post(Constants.readymag_auth_host + '/api/recoverypass', { email: email }, cb).error(function(xhr) {
      if (xhr.status == 404) cb && cb();
      else alert('Recovery problem. Contact tech support, please:' + Constants.SUPPORT_MAIL);
    });
  },

  backFromRecover: function() {
    if (this.useNavigate) {
      this.router.navigate('login/', { trigger: true });
    } else {
      this.switchTo('login');
    }
  },

  resetPassword: function() {
    var newPassword = $.trim(this.$('.reset.ljr-panel .new-password-input').val()),
      verifyPassword = $.trim(this.$('.reset.ljr-panel .verify-password-input').val()),
      recoverData = window.ServerData.recover,
      errors = [],
      $goButton = this.$('.reset.ljr-panel .go');

    if ($goButton.hasClass('processing')) return;

    if (newPassword == '' || verifyPassword == '') return;

    if (newPassword != verifyPassword) errors.push(this.ERROR_MESSAGES['password_dont_match']);
    if (newPassword.length < 6 || verifyPassword.length < 6) errors.push(this.ERROR_MESSAGES['short_password']);

    if (errors.length) {
      clearTimeout(this.wrongTimeout);
      this.$('.reset.ljr-panel').addClass('wrong-reset');

      this.$('.reset.ljr-panel .error').html(errors.join(' <br/>'));

      this.wrongTimeout = setTimeout(
        _.bind(function() {
          this.$('.reset.ljr-panel').removeClass('wrong-reset');
        }, this),
        2000
      );

      return;
    }

    // такого быть не должно, только если кто-то вручную показал форму сброса пароля в думе
    // в любом случае аякс запрос не отработает, валидного кода-то нету
    if (!recoverData || !recoverData.recover_code || !recoverData.email) return;

    this.showButtonPreloader($goButton);

    // меняем пароль и редиректимся на логин
    // оттуда автоматом перейдем в профиль, поскольку мы уже будем залогинены
    $.ajax({
      type: 'POST',
      data: { email: recoverData.email, pass: md5(newPassword), code: recoverData.recover_code },
      url: Constants.readymag_auth_host + '/api/changepass',
      success: function() {
        window.location.href = '/login/';
      },
      error: _.bind(function() {
        this.hideButtonPreloader($goButton);
        alert('Change password problem. Contact tech support, please:' + Constants.SUPPORT_MAIL);
      }, this),
    });
  },

  onPasswordInput: function(e) {
    var $input = $(e.currentTarget);

    if (Utils.filterNonAscii($input)) {
      $input.addClass('error-input');
      _.defer(
        _.bind(function() {
          $input.removeClass('error-input');
        }, this)
      );
    }
  },

  socialAuth: function(e) {
    this.$button = $(e.currentTarget);

    var provider = this.$button.attr('data-provider'),
      authUrl = Constants.readymag_auth_host + '/api/auth/' + provider;

    if (this.$button.hasClass('processing')) return;

    this.showButtonPreloader(this.$button);

    if (this.$button.closest('.join.ljr-panel').length && this.router.analytics)
      this.router.analytics.sendEvent('Join Button Click', { _join_type: provider, label: provider });

    var sw = screen.width,
      sh = screen.height,
      ww = Math.min(1000, sw),
      wh = Math.min(700, sh - 40),
      wl = Math.ceil((sw - ww) / 2),
      wt = Math.ceil((sh - wh) / 2);

    this.socialAuthWindow = window.open(
      authUrl,
      '_blank',
      [
        'toolbar=no',
        'location=' + (window.opera ? 'no' : 'yes'),
        'directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no',
        'width=' + ww,
        'height=' + wh,
        'top=' + wt,
        'left=' + wl,
      ].join()
    );

    this.authInProgress = true;

    // детектим, что попап окно закрылось
    this.popupCheckInterval = setInterval(
      _.bind(function() {
        try {
          if (this.socialAuthWindow == null || this.socialAuthWindow.closed) {
            this.authInProgress = false;
            clearInterval(this.popupCheckInterval);
            this.hideButtonPreloader(this.$button);
          }
        } catch (e) {}
      }, this),
      100
    );
  },

  // Хэндлер, который вызывается открытым окном аутентификации через социалки,
  // при получении коллбэка этим окном
  handleSocialAuthResult: function(result) {
    this.authInProgress = false;

    if (!result || result.error || !result.user || !result.user.uri) {
      this.hideButtonPreloader(this.$button);

      if (result.error) {
        this.$el.scrollTop(this.$el[0].scrollHeight - this.$el.outerHeight());
        this.showErrors(['Something went wrong']);

        clearTimeout(this.showSocialErrorTimeout);
        this.showSocialErrorTimeout = setTimeout(
          _.bind(function() {
            this.$('.join.ljr-panel .error').html('');
          }, this),
          4000
        );
      }

      if (result.user && result.user.name) {
        this.switchTo('join');
        this.$('.join.ljr-panel .fullname-input').val(result.user.name);
        this.$('.join.ljr-panel .email-input').focus();
      }
      return;
    }

    var signedUpWithProvider;
    if (result.user.createdViaFacebook) signedUpWithProvider = 'facebook';
    if (result.user.createdViaGoogle) signedUpWithProvider = 'google';

    if (signedUpWithProvider) {
      this.putStorageData(signedUpWithProvider);
      this.redirectAfterJoin(result.user.uri, this.$button);
    } else {
      var redirect = Utils.queryUrlGetParam('redirect');
      if (redirect) return (window.location.href = Constants.readymag_auth_host + redirect);
      window.location.href = Constants.readymag_auth_host + '/' + result.user.uri;
    }
  },

  processLoginMessages: function(e) {
    // слушаем событие postMessage от окна которое даст данные о залогиненом пользователе

    if (!this.authInProgress) {
      return;
    }

    e = e.originalEvent || {};

    if (e.origin == Constants.readymag_auth_host) {
      var result = JSON.parse(decodeURIComponent(e.data));
      if (result && result.event == 'social-auth') {
        clearInterval(this.popupCheckInterval); // обязательно до this.socialAuthWindow.close иначе у нас прелоадер исчезнет раньше чем надо
        this.socialAuthWindow && this.socialAuthWindow.close();
        this.handleSocialAuthResult(result.message);
      }
    }
  },

  redirectAfterJoin: function(userUri) {
    // Если передан коллбэк из кастомной формы логина, даем время ему отработать
    var timeout = _.isFunction(this.joinCallback) ? 500 : 0;
    _.isFunction(this.joinCallback) && this.joinCallback();

    var redirect = Utils.queryUrlGetParam('redirect');
    if (redirect) return (window.location.href = Constants.readymag_auth_host + redirect);

    setTimeout(
      _.bind(function() {
        var profileUrl = Constants.readymag_auth_host + '/' + userUri + '/';
        // конструктор не работает на девайсах и в IE поэтому просто перекидываем в профиль
        if (!Modernizr.isdesktop || Modernizr.msie || Modernizr.msie11) {
          window.location.href = profileUrl;
        } else {
          // Если пытаемся регистироваться, например, из affiliate, то отправляем сразу на страницу настроек affiliate
          var returnUrl =
            Modernizr.sessionstorage && window.sessionStorage.getItem('rm.loginReturnUrl', window.location.pathname);
          Modernizr.sessionstorage && window.sessionStorage.removeItem('rm.loginReturnUrl');
          if (returnUrl) return (window.location.href = Constants.readymag_auth_host + returnUrl);

          // сразу создаем новый мэг
          var cmp = this.createMagParams,
            templateID = cmp && cmp.templateID,
            tp = cmp && cmp.tp ? cmp.tp + '-join-form' : 'join-form'; // приписываем инициаора который вызвал join form из-за которого потом будет создан мэг

          new CreateMag({
            router: this.router,
            me: {
              permissions: { can_create_mag: true },
              uri: userUri,
            }, // юзера у нас пока нет, поэтому эмулируем для него разрешение на создание мэга
            tp: tp,
            templateID: templateID,
            noCreateRequestTracking: cmp && cmp.noCreateRequestTracking,
            error: _.bind(function() {
              this.hideButtonPreloader(this.$button);
            }, this),
          });
        }
      }, this),
      timeout
    );
  },

  enableCors: function() {
    $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
      if (options.url.indexOf(Constants.readymag_auth_host) == -1) return;

      options.xhrFields = {
        withCredentials: true,
      };
    });
  },

  onTouchStart: function(e) {
    if (this.disableScrollWatcher) return;

    this.scrollY = e.originalEvent.touches.item(0).clientY;
  },

  onTouchMove: function(e) {
    if (this.disableScrollWatcher) return;

    var $target = $(e.currentTarget),
      delta = this.scrollY - e.originalEvent.touches.item(0).clientY;

    if (
      (delta < 0 && $target.scrollTop() <= 0) ||
      (delta > 0 && $target.scrollTop() + $target.height() >= $target[0].scrollHeight)
    ) {
      e.preventDefault();
    }

    this.scrollY = e.originalEvent.touches.item(0).clientY;
  },

  destroy: function() {
    this.hide();
    $(window).off('message', this.processLoginMessages);

    setTimeout(
      _.bind(function() {
        this.$el.remove();
      }, this),
      400
    );
  },

  putStorageData: function(type) {
    var data = {};

    var timestamp = new Date();

    // сохраняем данные текущей сессии при join
    data.type = type;
    data.utm_campaign = Utils.queryUrlGetParam('utm_campaign');
    data.utm_source = Utils.queryUrlGetParam('utm_source');
    data.utm_medium = Utils.queryUrlGetParam('utm_medium');
    data.rm_url = encodeURI(window.location.href);
    data.rm_referrer = document.referrer;
    data.timestamp = timestamp.valueOf();
    data._ga = Utils.getCookie('_ga');

    var value = btoa(JSON.stringify(data));
    Utils.createCookie('send_join_event', value, 10);
  },
});

export default Login;
