/**
 * Аудио плеер для виджета аудио. Общий для конструктора и для вьювера!!!
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { SC, soundcloud } from '@rm/soundcloud';
import { Constants } from '../common/utils';
import Scale from '../common/scale';
import templates from '../../templates/common/audio-player.tpl';

// это просто обертка (фактически интерфейс), которая создает внутри себя один из инстансов плеера:
// StandardPlayer или MinimalPlayer в зависимости от настроек
const AudioPlayer = Backbone.View.extend({
  initialize: function(params, $container, readyCallback) {
    _.bindAll(this);

    this.params = params;
    this.$container = $container;
    this.readyCallback = readyCallback || function() {};

    if ((_(['standard', 'visual']).contains(params.current_type) || Modernizr.opera) && !Modernizr.msie)
      this.player = new StandardPlayer(params, $container, readyCallback, this);
    else this.player = new MinimalPlayer(params, $container, readyCallback, this);

    this.render();
  },

  render: function() {
    this.player && this.player.render();
  },

  show: function() {
    this.player && this.player.show();
  },

  play: function() {
    this.player && this.player.play();
  },

  pause: function() {
    this.player && this.player.pause();
  },

  applyPlayerStyle: function(params) {
    this.player && this.player.applyPlayerStyle && this.player.applyPlayerStyle(params);
  },

  destroy: function() {
    if (this.player) {
      this.player.isDestroyed = true; // Флаг для асинхронных операций внутри плейера
      this.player && this.player.destroy();
      this.player = null;
    }
  },
});

/**
 * Класс для стандартного плеера
 */
var StandardPlayer = Backbone.View.extend({
  initialize: function(params, $container, readyCallback, parent) {
    _.bindAll(this);

    this.inner_template = templates['template-common-audio-player-standard'];

    this.parent = parent;
    this.params = params;
    this.$container = $container;
    this.readyCallback = readyCallback || function() {};
  },

  render: function() {
    // формируем строку с параметрами для ифрейма
    var params = [];
    params.push('auto_play=false');
    params.push('buying=' + this.params.socials);
    params.push('liking=' + this.params.socials);
    params.push('download=' + this.params.socials);
    params.push('sharing=' + this.params.socials);
    params.push('show_artwork=' + this.params.artwork);
    params.push('show_comments=' + this.params.comments);
    params.push('show_playcount=' + this.params.playcount);
    params.push('color=' + this.params.color);
    params.push('auto_advance=true');
    params.push('show_user=true');
    if (this.params.current_type == 'visual') {
      params.push('visual=true');
    }

    // Заменяем на https, если по какой-то причине в урле оказалось http.
    // Иначе браузер заблокирует обращение к плейеру с https сайта
    // Проблема обнаружилась 13.12.2013 (https://trello.com/c/LAcoPDXv/105-soundcloud)
    this.params.parsed_url = this.params.parsed_url.replace(/^http:/, 'https:');

    var src = this.params.parsed_url + '&' + params.join('&');

    this.setElement($(this.inner_template({ src: src })));

    this.$container.append(this.$el);

    // используя libs/soundcloud.api.js создаем объект через который моно управлять soundcloud iframe плеером
    // файл расположен в libs потому что я его немного подредактировал (в основном это файл каоторый предоставлет сам soundcloud)
    this.player = SC.Widget(this.$el[0]);

    this.player.bind(
      'ready',
      _.bind(function() {
        setTimeout(
          _.bind(function() {
            this.readyCallback(this.parent);
          }, this),
          500
        );
      }, this)
    );

    setTimeout(
      _.bind(function() {
        this.readyCallback(this.parent);
      }, this),
      2000
    ); // если вдруг плеер не сказал что ready
  },

  // нужно в конструкторе
  // там у нас одновременно внитри виджета могут быть создано много плееров, но только один из них будет виден, последний
  // подробности лучше почитать в blocks/audio.js
  show: function() {
    this.$el && this.$el.css('opacity', 1);
  },

  play: function() {
    this.player && this.player.play(); // используется вызов методов которые предоставляет SC.Widget (объект который обеспечивает связь между нашим кодом и ифреймом soundcloud)
  },

  pause: function() {
    this.player && this.player.pause(); // используется вызов методов которые предоставляет SC.Widget (объект который обеспечивает связь между нашим кодом и ифреймом soundcloud)
  },

  destroy: function() {
    this.player.unbind('ready');
    this.$el && this.$el.remove();
    this.player = null;
  },
});

/**
 * Класс для кастомного (минимального) плеера
 */
var MinimalPlayer = Backbone.View.extend({
  apiKey: Constants.SOUNDCLOUD_API, // также и в adio-panel.js
  defaultCover: '/img/common/audio-player/cover.png', // картинка которую вы показываем как обложку если других обложек не обнаружено

  initialize: function(params, $container, readyCallback, parent) {
    _.bindAll(this);

    this.inner_template = templates['template-common-audio-player-minimal'];

    this.parent = parent;
    this.params = params;
    this.$container = $container;
    this.readyCallback = readyCallback || function() {};
    this.isHTHML5 = this.html5AudioAvailable(); // проверяем поддерживает у нас браузер html5 audio или нет
    // this.isHTHML5 = false;
  },

  render: function() {
    this.setElement($(this.inner_template({ src: this.params.url })));

    this.$container.append(this.$el);

    this.applyPlayerStyle(this.params);

    // смотрим какой движок использовать для проигрывания аудио (html5 audio или flash)
    if (this.isHTHML5) this.audioEngine = new AudioEngineHTML5(this.$container, this);
    else this.audioEngine = new AudioEngineFlash(this.$container, this);

    // загружаем данные по трекам
    this.loadTracksData([{ url: this.params.url }]);
  },

  // изменяет внешний вид плеера в зависимости от настроек виджета
  // используется в конструкторе для смены вида при изменении параметров панельки
  // на фронте во вьювере просто вызывается ожин раз при создании виджета (в render())
  applyPlayerStyle: function(params) {
    if (!this.$el) return;

    this.$el.toggleClass('no-artwork', !params.artwork);
    this.$el.toggleClass('no-info', !params.info);
    // Если включено масштабирование, транзишены на всех элементах плеера.
    // При включеных транзишенах и масштабировании, движущийся ползунок дёргается при наведении на него,
    // даже если транзишен не на самом ползунке, а на других элементах.
    // Наверное, дело в комбинации трансформа, зума и транзишенов.
    this.$el.toggleClass('no-transitions', Scale.isOn());

    this.$('.sc-info').css('color', params.artwork ? '#fff' : '#' + params.color);
    this.$('.sc-left').css('color', '#' + params.color);
    this.$('.sc-button svg path').css('fill', '#' + params.color); // разначаем цвет треугольничку play (он у нас svg)
  },

  // вызывается из loadTracksData когда данные по трекам загружены
  tracksIsLoaded: function(data) {
    this.tracks = null;

    if (!this.audioEngine) return;

    var tracks = data.playerObj.tracks;

    if (!tracks.length) {
      this.$el.remove();
      this.ready = true;
      this.readyCallback(this.parent);
      return;
    }

    this.tracks = tracks;
    this.currentTrackNum = 0;

    // announce the succesful initialization
    this.$el.removeClass('loading');

    // listen to audio engine events
    this.audioEngine
      .on('scPlayer:onAudioReady', this.onAudioReady)
      .on('scPlayer:onMediaPlay', this.onMediaPlay)
      .on('scPlayer:onMediaPause', this.onMediaPause)
      .on('scPlayer:onMediaEnd', this.onMediaEnd)
      .on('scPlayer:onMediaBuffering', this.onMediaBuffering);

    // caching used elements
    this.$elements = {};
    this.$elements.scrubber = this.$('.sc-scrubber');
    this.$elements.play_button = $(this.$('.sc-button'));
    this.$elements.play_wrapper = this.$('.sc-button-wrapper');
    this.$elements.buffer = this.$('.sc-buffer');
    this.$elements.played = this.$('.sc-played');
    this.$elements.time = this.$('.sc-left');

    var firstArtworkUrl = this.updateTrackInfo(this.currentTrackNum);

    // toggling play/pause
    this.$elements.play_button.on('click', this.onPlayClick);

    if (Modernizr.isdesktop) {
      // hover over timeline
      this.$elements.scrubber.on('mousemove', this.onScrubberMouseMove);

      // seeking in the loaded track buffer
      this.$elements.scrubber.on('click', this.onScrubberClick);
    }

    // click on track cover (artwork)
    this.$('.sc-artwork-list').on('click', this.onPlayClick);

    if (Modernizr.isdesktop) {
      // play button dragging
      this.$elements.play_button.RMDrag({
        move: this.moveHandleDrag,
        end: this.endHandleDrag,
        silent: false,
        preventDefault: true,
      });
    }

    if (RM.screenshot) {
      // в режиме скриншотера просто ждем когда загрузится картинка обложки (если обложки нет, тогда будет загружена дефолная this.defaultCover)
      var $imgLoader = $('<img/>')
        .load(
          _.bind(function() {
            setTimeout(
              _.bind(function() {
                this.ready = true;
                !this.isDestroyed && this.readyCallback(this.parent);
                $imgLoader.remove();
              }, this),
              100
            );
          }, this)
        )
        .attr('src', firstArtworkUrl);
    } else {
      this.ready = true;
      this.readyCallback(this.parent);
    }
  },

  // когда мы тянем за кнопку play
  moveHandleDrag: function(e) {
    // stops play
    if (this.playing) this.pause();

    // ставим признак того что начали тянуть, нужен в endHandleDrag
    this.isDragging = true;

    this.$el.addClass('dragging');

    this.scrub(e.pageX);
  },

  // закончили тянуть за Play
  endHandleDrag: function(e) {
    if (e.moved) this.play();

    this.$el.removeClass('dragging');

    // надо с таймаутом иначе сработает play_button.on('click', this.onPlayClick);
    // а нам не надо чтобы он срабатывал
    // (техгнически он все равно сработает, но в нашем случае он не выполнится поскольку this.isDragging еше не встанет в false)
    setTimeout(
      _.bind(function() {
        this.isDragging = false;
      }, this),
      0
    );
  },

  // отображает полоску под курсором когда мы проводим мышой над областью плеера где кнопка play и время
  // только для десктопов
  onScrubberMouseMove: function(event) {
    var $scrubber = $(event.currentTarget),
      $indicator = $scrubber.find('.sc-hover-indicator'),
      box = Scale.getNormalizedBox($scrubber.get(0)),
      relative = event.pageX - box.left;

    $indicator.css('left', relative / Scale.getRatio() + 'px');
  },

  // обрабатываем клик по области где кнопка play и время
  // перематываем трек в то место куда ткнули мышой
  // только для десктопов
  onScrubberClick: function(event) {
    if (!this.playing) this.play();

    this.scrub(event.pageX);
    return false;
  },

  // обработчик нажатия на кнопку play или на обложку
  onPlayClick: function(event) {
    // если клик произошел в результате таскания кнопки play, тогда игнорируем
    // т.е. если не просто кликнули, а сдвинули мыши после нажатия кнопки (это другое действие обрабатывается в moveHandleDrag и endHandleDrag)
    if (this.isDragging) return;

    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }

    event.stopPropagation();
    return false;
  },

  // это событие выстреливает толко для flash версии
  // все дело в том, что когда мы нажимаем play первоначально у нас еще не создан flash объект (об этом рассказывается ниже во флеш движке)
  // т.е. первоначальный play просто создает swf и грузит в него композицию
  // а вот когда композиция загружена мы уже и должны сами ее запустить
  onAudioReady: function() {
    this.audioEngine && this.audioEngine.play();
    this.audioEngine && this.audioEngine.setVolume(100);
  },

  // when the loaded track started to play
  onMediaPlay: function() {
    // set the player state
    this.$el.addClass('playing');
    this.playing = true;

    // таймер одновления данных по текущему времени и пр.
    // так было сделано в их кастомном плеере, они почему-то решинли не полагаться на timeupdate, я решил не переделывать
    clearInterval(this.positionPoll);
    this.positionPoll = setInterval(this.updatePlayState, 500);
  },

  // when the loaded track is was paused
  onMediaPause: function() {
    // reset the player state
    this.$el.removeClass('playing');
    this.playing = false;

    clearInterval(this.positionPoll);
    this.positionPoll = null;
  },

  onMediaEnd: function() {
    // update the scrubber width
    this.$elements.played.css('width', '0%');
    this.$elements.play_button.css('left', '0%');

    // reset the player state
    this.$el.removeClass('playing');
    this.playing = false;

    // stop the audio
    this.audioEngine && this.audioEngine.stop();

    // находи индекс следующего трека, если трек последний, то nextTrackNum будет указывать на первый трек (=0)
    var nextTrackNum = (this.currentTrackNum + 1) % this.tracks.length;

    // continue playing next track (if any)
    // в любом случае запускаем следующий трек
    this.play(nextTrackNum);

    // если следующий трек = первый трек
    // значит мы прошлись по всем композициям и вернулись в начало
    // стопим воспроизведение
    // надо именно так: сначала запустить а потом остановить, все дело в том что после последней композиции
    // плеер должен перейти к первой и остановиться, сделать это проще именно так
    if (nextTrackNum == 0) {
      this.pause();
      // обновляем обложку и время
      // обычно это делает this.play() который выше, но в случае с одним треком такой фокус не прокатит
      // поскольку play поймет что мы запускаем один и тот же трек и не будет его заново load, а load как раз и вызывает updateTrackInfo
      this.updateTrackInfo(0);
    }
  },

  // сообщение о кол-ве загруженных медиа данных по треку
  onMediaBuffering: function(percent) {
    this.$elements.buffer.css('width', percent + '%');
  },

  // используем эту функцию чтобы начать проигрывание трека
  play: function(trackNum) {
    if (!this.tracks) return;

    // если номер треку не передан, считаем что трек текущий
    if (trackNum == undefined) trackNum = this.currentTrackNum;

    if (!this.audioEngine) return;

    // если мы уже и так играем и трек тот же самый тогда ничего не делаем
    if (this.playing && trackNum == this.currentTrackNum) return;

    var track = this.tracks[trackNum];

    this.$el.addClass('playing');
    this.playing = true;

    // если трек текущий и до этого вызова this.play уже выполнялась загружка трека в движок (this.audioEngine.firstLoad)
    // тогда просто продолжаем воспроизведение,
    // иначе если трек другой (не текущий) или если в первый раз запускаем вопсроизведение тогда грузим трек (он сам начнет играться)
    if (trackNum == this.currentTrackNum && this.audioEngine.firstLoad) {
      this.audioEngine && this.audioEngine.play();
    } else {
      this.updateTrackInfo(trackNum);
      this.currentTrackNum = trackNum;
      this.audioEngine && this.audioEngine.load(track, this.apiKey);
    }
  },

  // используем эту функцию чтобы запаузить проигрывание трека
  pause: function() {
    if (!this.audioEngine) return;

    if (!this.playing) return;

    this.$el.removeClass('playing');
    this.playing = false;

    this.audioEngine && this.audioEngine.pause();
  },

  // а эту чтобы перемотать текущий трек (не важно играет он или нет)
  // xPos это просто координаты мыши на экране, он сам посчитает что они означают касательно области внутри плеера (там где play и время)
  // немного нелогично, но пофиг
  scrub: function(xPos) {
    if (!this.audioEngine) return;

    // 0.999 это для того, чтобы мы никаким образом не могли проскролить до конца песни, иначе будут глюки:
    // как только мы проскролим до конца начнется другая композиция и она тоже проскролится до конца при малейшем движении мыши (поскольку кнопку мыши мы не отпускали)
    var playWrapperBox = Scale.getNormalizedBox(this.$elements.play_wrapper.get(0));
    var playButtonBox = Scale.getNormalizedBox(this.$elements.play_button.get(0));
    var relative = (xPos - playWrapperBox.left - playButtonBox.width / 2) / playWrapperBox.width;
    relative = Math.min(
      Math.max(Math.min(relative, 0.999), 0),
      this.$elements.buffer.width() / this.$elements.scrubber.width()
    );

    this.$elements.play_button.css('left', Math.floor(this.$elements.play_wrapper.width() * relative) + 'px');

    this.audioEngine && this.audioEngine.seek(relative);
  },

  // обновляем оставшееся время и положение кнопки play
  // функция вызывается по таймеру
  updatePlayState: function() {
    if (!this.audioEngine) return;

    var duration = this.audioEngine.getDuration(),
      position = this.audioEngine.getPosition(),
      relative = position / duration;

    // update the scrubber width
    this.$elements.played.css('width', 100 * relative + '%');
    this.$elements.play_button.css('left', Math.floor(this.$elements.play_wrapper.width() * relative) + 'px');

    if (duration) this.$elements.time.html(this.timecode(duration - position));
  },

  // показываем информацию о текущем треке: обложка, название, автор и пр.
  // вызывается в основном при смене трека, ну и еще в паре спец. случаев
  updateTrackInfo: function(trackNum) {
    if (!this.tracks) return;

    var track = this.tracks[trackNum];

    // update the current track info in the player
    this.$('.sc-info h3').html('<a target="_blank" href="' + track.permalink_url + '">' + track.title + '</a>');
    this.$('.sc-info h4').html(
      'by <a target="_blank" href="' + track.user.permalink_url + '">' + track.user.username + '</a>'
    );

    var img;
    // update the artwork
    if (track.artwork_url) img = track.artwork_url.replace('-large', '-t300x300');
    else if (track.user && track.user.avatar_url) img = track.user.avatar_url.replace('-large', '-t300x300');
    else img = this.defaultCover;

    this.$('.sc-artwork-list .sc-loaded-artwork').css('background-image', 'url("' + img + '")');

    // update the track duration in the progress bar
    this.$elements.time.html(this.timecode(track.duration));

    // prev-next tracks artwork preloading
    var nextTrackNum = (trackNum + 1) % this.tracks.length,
      prevTrackNum = (trackNum - 1 + this.tracks.length) % this.tracks.length,
      nextTrack = this.tracks[nextTrackNum],
      prevTrack = this.tracks[prevTrackNum];

    if (!nextTrack.preload && nextTrack.artwork_url) {
      nextTrack.preload = new Image();
      nextTrack.preload.src = nextTrack.artwork_url.replace('-large', '-t300x300');
    }

    if (!prevTrack.preload && prevTrack.artwork_url) {
      prevTrack.preload = new Image();
      prevTrack.preload.src = prevTrack.artwork_url.replace('-large', '-t300x300');
    }

    return img;
  },

  // загружает список треков ресурса
  // код выпилен из их собственного кастомного плеера
  loadTracksData: function(links) {
    var index = 0,
      playerObj = { tracks: [] },
      self = this;

    var loadUrl = function(link) {
      if (self.isDestroyed) {
        return;
      } // У виджета может быть вызыван destroy во время отработки этой асинхронной функции

      var apiUrl = self.scApiUrl(link.url);

      $.getJSON(apiUrl, function(data) {
        if (self.isDestroyed) {
          return;
        }

        index += 1;
        if (data.tracks) {
          playerObj.tracks = playerObj.tracks.concat(data.tracks);
        } else if (data.duration) {
          // a secret link fix, till the SC API returns permalink with secret on secret response
          data.permalink_url = link.url;
          // if track, add to player
          playerObj.tracks.push(data);
        } else if (data.creator) {
          // it's a group!
          links.push({ url: data.uri + '/tracks' });
        } else if (data.username) {
          // if user, get his tracks or favorites
          if (/favorites/.test(link.url)) {
            links.push({ url: data.uri + '/favorites' });
          } else {
            links.push({ url: data.uri + '/tracks' });
          }
        } else if ($.isArray(data)) {
          playerObj.tracks = playerObj.tracks.concat(data);
        }

        if (links[index]) {
          // if there are more track to load, get them from the api
          loadUrl(links[index]);
        } else {
          // if loading finishes, anounce it to the GUI
          self.tracksIsLoaded({ playerObj: playerObj, url: apiUrl });
        }
      }).fail(function() {
        // Все пропало, если трек был например удален из Soundcloud или еще что-то.
        !self.isDestroyed && self.readyCallback(self.parent);
      });
    };

    // load first tracks
    loadUrl(links[index]);
  },

  // convert a SoundCloud resource URL to an API URL
  // код выдернут из их кастомного плеера
  scApiUrl: function(url) {
    var secureDocument = document.location.protocol === 'https:',
      resolver = (secureDocument || /^https/i.test(url) ? 'https' : 'http') + '://api.soundcloud.com/resolve?url=',
      params = 'format=json&client_id=' + this.apiKey + '&callback=?';

    // force the secure url in the secure environment
    if (secureDocument) {
      url = url.replace(/^http:/, 'https:');
    }

    // check if it's already a resolved api url
    if (/api\./.test(url)) {
      return url + '?' + params;
    } else {
      return resolver + url + '&' + params;
    }
  },

  // проверка поддерживает ли браузер html5 audio
  html5AudioAvailable: function() {
    var state = false;
    try {
      var a = new Audio();
      state = a.canPlayType && /maybe|probably/.test(a.canPlayType('audio/mpeg'));
    } catch (e) {}

    return state;
  },

  // Convert milliseconds into Hours (h), Minutes (m), and Seconds (s)
  timecode: function(ms) {
    var hms = {
      h: Math.floor(ms / (60 * 60 * 1000)),
      m: Math.floor((ms / 60000) % 60),
      s: Math.floor((ms / 1000) % 60),
    };

    var tc = []; // Timecode array to be joined with ':'

    if (hms.h > 0) tc.push(hms.h);
    tc.push(hms.m < 10 && hms.h > 0 ? '0' + hms.m : hms.m);
    tc.push(hms.s < 10 ? '0' + hms.s : hms.s);

    return tc.join(':');
  },

  // нужно в конструкторе
  // там у нас одновременно внитри виджета могут быть создано много плееров, но только один из них будет виден, последний
  // подробности лучше почитать в blocks/audio.js
  show: function() {
    this.$el && this.$el.css('opacity', 1);
  },

  destroy: function() {
    this.pause();
    this.audioEngine && this.audioEngine.off();
    this.audioEngine && this.audioEngine.destroy();
    this.audioEngine = null;
    this.$el && this.$el.remove();
  },
});

/**
 * Класс для работы с аудио (html5) для кастомного (минимального) плеера
 */
var AudioEngineHTML5 = Backbone.View.extend({
  initialize: function($container, parent) {
    _.bindAll(this);

    // темплейт с тегом html5 audio
    this.inner_template = templates['template-common-audio-player-engine-html5'];

    this.parent = parent;
    this.$container = $container;
    this.firstLoad = false;

    var self = this;
    this.callbacks = {
      onReady: function() {
        self.player && self.trigger('scPlayer:onAudioReady');
      },
      onPlay: function() {
        self.player && self.trigger('scPlayer:onMediaPlay');
      },
      onPause: function() {
        self.player && self.trigger('scPlayer:onMediaPause');
      },
      onEnd: function() {
        self.player && self.trigger('scPlayer:onMediaEnd');
      },
      onBuffer: function(percent) {
        self.player && self.trigger('scPlayer:onMediaBuffering', percent);
      },
    };

    this.render();
  },

  render: function() {
    this.setElement($(this.inner_template({})));

    this.$container.append(this.$el);

    this.player = this.$el.find('audio')[0];

    this.player.addEventListener('play', this.callbacks.onPlay, false);
    this.player.addEventListener('pause', this.callbacks.onPause, false);

    // handled in the onTimeUpdate for now untill all the browsers support 'ended' event
    // this.player.addEventListener('ended', callbacks.onEnd, false);
    this.player.addEventListener('timeupdate', this.onTimeUpdate, false);
    this.player.addEventListener('progress', this.onProgress, false);
  },

  onTimeUpdate: function(event) {
    if (!this.player) return;
    var buffer = ((this.player.buffered.length && this.player.buffered.end(0)) / this.player.duration) * 100;
    // ipad has no progress events implemented yet
    this.callbacks.onBuffer(buffer);
    // anounce if it's finished for the clients without 'ended' events implementation
    if (this.player.currentTime === this.player.duration) {
      this.callbacks.onEnd();
    }
  },

  onProgress: function(event) {
    if (!this.player) return;
    var buffer = ((this.player.buffered.length && this.player.buffered.end(0)) / this.player.duration) * 100;
    this.callbacks.onBuffer(buffer);
  },

  load: function(track, apiKey) {
    if (!this.player) return;
    this.firstLoad = true;
    this.player.pause();
    this.player.src = track.stream_url + (/\?/.test(track.stream_url) ? '&' : '?') + 'client_id=' + apiKey;
    this.player.load();
    this.player.play();
  },

  play: function() {
    this.player && this.player.play();
  },

  pause: function() {
    this.player && this.player.pause();
  },

  stop: function() {
    if (this.player && this.player.currentTime) {
      this.player.currentTime = 0;
      this.player.pause();
    }
  },

  seek: function(relative) {
    if (!this.player) return;
    if (!this.player.src) return;

    try {
      // чтобы консоль не засирать, иногда бывают ошибки которые ни на чем не сказываются
      this.player.currentTime = this.player.duration * relative;
    } catch (e) {}
  },

  getDuration: function() {
    if (!this.player) return 0;
    return this.player.duration * 1000;
  },

  getPosition: function() {
    if (!this.player) return 0;
    return this.player.currentTime * 1000;
  },

  setVolume: function(val) {
    if (!this.player) return;
    this.player.volume = val / 100;
  },

  destroy: function() {
    this.$el.remove();
    this.player = null;
  },
});

/**
 * Класс для работы с аудио (flash) для кастомного (минимального) плеера
 */
var AudioEngineFlash = Backbone.View.extend({
  initialize: function($container, parent) {
    _.bindAll(this);

    // темплейты с флеш объектами
    this.inner_template_flash = templates['template-common-audio-player-engine-flash'];
    this.inner_template_flash_ie = templates['template-common-audio-player-engine-flash-ie'];

    this.parent = parent;
    this.$container = $container;
    this.firstLoad = false;

    var self = this;
    this.callbacks = {
      onReady: function() {
        self.player && self.trigger('scPlayer:onAudioReady');
      },
      onPlay: function() {
        self.player && self.trigger('scPlayer:onMediaPlay');
      },
      onPause: function() {
        self.player && self.trigger('scPlayer:onMediaPause');
      },
      onEnd: function() {
        self.player && self.trigger('scPlayer:onMediaEnd');
      },
      onBuffer: function(percent) {
        self.player && self.trigger('scPlayer:onMediaBuffering', percent);
      },
    };
  },

  // вызовется только при первой попытке play
  // до этого момента рендерить флеш объект нельзя, поскольку он не может работать без первоначально заданного трека
  // а его мы узнаем только когда нажмут play
  render: function(url) {
    var engineID = 'soundcloud_' + this.parent.parent.params._id,
      secureDocument = document.location.protocol === 'https:',
      swf =
        (secureDocument ? 'https' : 'http') +
        '://player.soundcloud.com/player.swf?url=' +
        url +
        '&amp;enable_api=true&amp;player_type=engine&amp;object_id=' +
        engineID;

    if (Modernizr.msie) {
      this.setElement($(this.inner_template_flash_ie({ id: engineID, swf: swf })));
    } else {
      this.setElement($(this.inner_template_flash({ id: engineID, swf: swf })));
    }

    this.$container.append(this.$el);

    // these events is triggered manualy throught soundcloud.player.api.js (proxy between js and flash)
    this.$el
      .find('object')
      // when the loaded track is ready to play
      .on(
        'soundcloud:onPlayerReady',
        _.bind(function(flashId, data) {
          this.player = soundcloud.getPlayer(engineID);
          this.callbacks.onReady();
        }, this)
      )
      // when the loaded track is still buffering
      .on(
        'soundcloud:onMediaBuffering',
        _.bind(function(flashId, data) {
          this.callbacks.onBuffer(data.percent);
        }, this)
      )
      // when the loaded track finished playing
      .on('soundcloud:onMediaEnd', this.callbacks.onEnd)
      // when the loaded track started to play
      .on('soundcloud:onMediaPlay', this.callbacks.onPlay)
      // when the loaded track is was paused
      .on('soundcloud:onMediaPause', this.callbacks.onPause);
  },

  // вызывается при попытке проиграть трек который еще не был загружен
  // у нас плеер может проигрвать как конкретный трек так и набор треков
  load: function(track) {
    // если до того какой либо трек был загружен, тогда просто грузим новый трек
    if (this.firstLoad) {
      this.player && this.player.api_load(track.uri);
    } else {
      // если вообще до этого ничего не было загружено тогда рендерим флеш объект и указываем ему трек который надо загрузить и запустить после загрузки
      this.firstLoad = true;
      this.render(track.uri);
    }
  },

  play: function() {
    this.player && this.firstLoad && this.player.api_play();
  },

  pause: function() {
    this.player && this.firstLoad && this.player.api_pause();
  },

  stop: function() {
    this.player && this.firstLoad && this.player.api_stop();
  },

  seek: function(relative) {
    this.player && this.firstLoad && this.player.api_seekTo(this.player.api_getTrackDuration() * relative);
  },

  getDuration: function() {
    return (
      this.player && this.firstLoad && this.player.api_getTrackDuration && this.player.api_getTrackDuration() * 1000
    );
  },

  getPosition: function() {
    return (
      this.player && this.firstLoad && this.player.api_getTrackPosition && this.player.api_getTrackPosition() * 1000
    );
  },

  setVolume: function(val) {
    this.player && this.player.api_setVolume && this.player.api_setVolume(val);
  },

  destroy: function() {
    this.player = null;
    this.$el && this.$el.remove();
  },
});

export default AudioPlayer;
