/*
 * Как работать с флагами:
 * 1. Перед началом работы меняем значение на true
 * 2. Работаем
 * 3. Перед отправкой PR меняем значение обратно
 * 4. Когда надоест
 *    ├ a. придумываем как засунуть сюда значения из CI
 *    └ b. деплоим Unleash для управления флагами
 */
const features = {
  eCommerce: false,
};

export default {
  get: function(feature) {
    return features[feature] || false;
  },
};
