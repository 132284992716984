/**
 * Класс для работы с данными e-commerce
 *
 * методы которые используют workspace или block
 * должны вызываться только в конструкторе !
 *
 * В конструкторе и вьюэре создается в роутерах, в вьюэре передается в mag.js
 */
import Backbone from 'backbone';
import _ from '@rm/underscore';
import $ from '@rm/jquery';
import Events from './events';
import Features from './features';
import { Constants } from './utils';

const EcommerceManagerClass = function(router, mag, environment) {
  this.isMagLoaded = false;
  this.haveEcommerceCartWidget = false;
  this.isCartSidebarHidden = true;
  this.productsLoading = false;
  this.productsLoaded = false;
  this.productsLoadingError = false;
  this.products = null;

  this.environment = environment;
  this.mag = mag;
  this.router = router;
  this.events = _.extend({}, Backbone.Events);
  this.cartData = { skus: {}, uniqSkusCount: 0, changed: new Date(), order: null };
  this.cartItemsLimit = 25; // у stripe ограничение на 25 уникальных товаров в одном заказе

  if (!Features.get('eCommerce')) {
    return;
  }

  /**
   * У нас уже может быть мэг инициализированный данными
   * иначе подписываемся на эвент (работает в конструкторе)
   */
  if (this.isConstructor()) {
    if (this.mag.has('_id')) {
      this.onMagFullLoaded();
    } else {
      Events.once('mag:loadfull:complete', this.onMagFullLoaded, this);
    }
  } else {
    if (this.mag.model.has('_id')) {
      this.onMagFullLoaded();
    }
  }

  return this;
};

_.extend(EcommerceManagerClass.prototype, {
  CONNECTION_TIMEOUT: 5000,
  stripeJsSrc: 'https://js.stripe.com/v3/',

  isConstructor: function() {
    return this.environment === Constants.environment.constructor;
  },

  isViewer: function() {
    return this.environment === Constants.environment.viewer;
  },

  isPreview: function() {
    return this.environment === Constants.environment.preview;
  },

  // Выполняется, когда загрузились данные мэга
  onMagFullLoaded: function() {
    if (this.isMagLoaded) {
      return;
    }
    this.isMagLoaded = true;
    this.checkEcommerceCart();

    if (this.isMagConnectedToStripe() && !this.productsLoading) {
      this.addStripeLibrary();
      this.getProducts();
    }
  },

  checkEcommerceCart: function() {
    if (this.isConstructor() && this.mag.globalWidgets) {
      var cartWidget = this.mag.globalWidgets.find(function(widget) {
        return widget.get('type') === Constants.ecommerceCartBlockName;
      });

      if (cartWidget) {
        this.haveEcommerceCartWidget = true;
      }
    } else {
      var cartWidget = this.mag.staticGlobalWidgetsData.find(function(widget) {
        return widget.type === Constants.ecommerceCartBlockName;
      });

      if (cartWidget) {
        this.haveEcommerceCartWidget = true;
        return;
      }

      cartWidget = this.mag.aboveGlobalWidgetsData.find(function(widget) {
        return widget.type === Constants.ecommerceCartBlockName;
      });

      if (cartWidget) {
        this.haveEcommerceCartWidget = true;
      }
    }
  },

  getEcommerceData: function() {
    var ecommerceData = null;

    if (this.isConstructor()) {
      if (this.mag.has('ecommerce_data')) {
        ecommerceData = this.mag.get('ecommerce_data');
      }
    } else {
      if (this.mag.model.has('ecommerce_data')) {
        ecommerceData = this.mag.model.get('ecommerce_data');
      }
    }

    return ecommerceData;
  },

  getStripeConnectedUserId: function() {
    if (!this.isMagLoaded) {
      return null;
    }

    var ecommerceData = this.getEcommerceData();
    if (!ecommerceData) {
      return null;
    }

    if (ecommerceData.stripe && ecommerceData.stripe.stripe_data && ecommerceData.stripe.stripe_data.stripe_user_id) {
      return ecommerceData.stripe.stripe_data.stripe_user_id;
    }
    return null;
  },

  getProducts: function() {
    this.productsLoading = true;
    var num_id = this.isConstructor() ? this.mag.get('num_id') : this.mag.model.get('num_id');
    var apiType = this.isViewer() ? 'published_projects' : 'projects';

    $.ajax({
      type: 'GET',
      url: '/api/' + apiType + '/' + num_id + '/ecommerce/stripe/products/',
      success: function(result) {
        if (result && result.data) {
          this.products = result.data;
        }
        this.productsLoading = false;
        this.productsLoaded = true;
        this.events.trigger('ecommerce:loadproducts:complete');
      },
      error: function(jqXHR) {
        console.log('getProducts Error', jqXHR.responseText);
        this.productsLoading = false;
        this.productsLoadingError = true;
      },
      context: this,
    });
  },

  addStripeLibrary: function() {
    if (this.isConstructor()) {
      return;
    }

    const stripeScript = document.createElement('script');
    const body = document.getElementsByTagName('body')[0];
    stripeScript.setAttribute('async', 'async');
    stripeScript.setAttribute('src', this.stripeJsSrc);
    body.appendChild(stripeScript);
  },

  // Возвращает boolean подключен ли stripe к проекту
  isMagConnectedToStripe: function() {
    if (!this.isMagLoaded) {
      return false;
    }

    var ecommerceData = this.getEcommerceData();
    if (!ecommerceData) {
      return false;
    }

    return (
      ecommerceData.stripe &&
      ecommerceData.stripe.status &&
      ecommerceData.stripe.status === 'connected' &&
      ecommerceData.stripe.stripe_data &&
      ecommerceData.stripe.stripe_data.stripe_user_id
    );
  },

  connectToStripe: function() {
    return new Promise(
      function(resolve, reject) {
        window.open('/api/connect/ecommerce/stripe/connect?num_id=' + this.mag.get('num_id'), '_blank');
        this.initSocket()
          .then(
            function(data) {
              this.mag.set({ ecommerce_data: data }, { silent: true });
              resolve();
            }.bind(this)
          )
          .catch(function(error) {
            console.warn('stripe-auth-state error:', error);
            reject(error);
          });
      }.bind(this)
    );
  },

  disconnectFromStripe: function() {
    return new Promise(
      function(resolve, reject) {
        $.ajax({
          type: 'GET',
          url: '/api/connect/ecommerce/stripe/disconnect?num_id=' + this.mag.get('num_id'),
          success: function(data) {
            // Обновляем значения в модели не сохраняя тк в базе уже обновлено
            if (data && data.status) {
              this.products = null;
              this.mag.set({ ecommerce_data: data }, { silent: true });
            }
            resolve(data);
          },
          error: function(jqXHR) {
            console.warn('stripe disconnect error', jqXHR);
            reject(jqXHR);
          },
          context: this,
        });
      }.bind(this)
    );
  },

  initSocket: function() {
    return new Promise(
      function(resolve, reject) {
        var host =
          window.location.origin === Constants.readymag_auth_host && Constants.stripe_live
            ? 'sockets.readymag.com/stripe-auth'
            : '/stripe-auth';

        const socket = io(host, {
          timeout: this.CONNECTION_TIMEOUT,
        });

        socket.on(
          'connect',
          function() {
            if (RM.DEBUG) {
              console.log('socket connected');
            }

            socket.emit('wainting for mag stripe auth update', {
              num_id: this.mag.get('num_id'),
              user: this.router.me.pick('name', 'email', '_id'),
              hash: this.mag.get('socket_hash'),
            });

            socket.on('state-update', function(data) {
              socket.disconnect();
              if (data.error) {
                reject(data.error);
              } else {
                resolve(data.ecommerce_data);
              }
            });
          }.bind(this)
        );

        socket.on('error', function(error) {
          console.warn('socket on error', error);
        });
      }.bind(this)
    );
  },

  checkCartDisabledControls: function() {
    if (this.haveEcommerceCartWidget) {
      var block = this.getEcommerceCartBlock();
      var ecommerceData = this.getEcommerceData();
      if (block) {
        block.checkDisabledControls(
          ecommerceData.stripe && ecommerceData.stripe.status && ecommerceData.stripe.status === 'connected'
        );
      }
    }
  },

  // Возвращает блок e-commerce корзины, если он есть
  getEcommerceCartBlock() {
    if (!this.haveEcommerceCartWidget) {
      return null;
    }
    return this.router.workspace.findBlockByFunction(function(block) {
      return block.attributes.type === Constants.ecommerceCartBlockName;
    });
  },

  // Вовзвращает список продуктов в виде опций для селекта
  getProductsLikeOptionsList: function(productId) {
    var defaultItem = { value: 'default', caption: 'None' };
    var result = [];
    if (this.products && Array.isArray(this.products)) {
      result = this.products
        .filter(function(product) {
          return (
            // Продукт активный и у него есть хотя бы один артикул
            // Если продукт уже выбран, он должен попасть в список
            (product.active && product.articles.length) || (productId && productId === product.id)
          );
        })
        .map(function(product) {
          return {
            value: product.id,
            caption: product.name,
          };
        });
    }
    result.unshift(defaultItem);
    return result;
  },

  isProductActive: function(productId) {
    if (!productId) {
      return false;
    } else if (productId === 'default') {
      return true;
    }
    var product = this.getProductById(productId);
    if (product) {
      return product.active && this.productHasActiveArticle(product);
    }
    return false;
  },

  productHasActiveArticle: function(product) {
    return product && product.articles.length;
  },

  // Определяет доступность для покупки артикула
  isSkuAvailable: function(sku) {
    if (!sku.active) return false;
    if (sku.inventory.type === 'infinite') return true;
    if (sku.inventory.type === 'finite' && sku.inventory.quantity > 0) return true;
    if (sku.inventory.type === 'bucket' && (sku.inventory.value === 'in_stock' || sku.inventory.value === 'limited')) {
      return true;
    }
    return false;
  },

  getProductById: function(productId) {
    if (this.products && Array.isArray(this.products)) {
      return this.products.find(function(product) {
        return product.id === productId;
      });
    }
    return null;
  },

  getProductsStatus: function() {
    return this.productsLoading ? 'loading' : this.productsLoaded ? 'loaded' : 'failed';
  },

  // Возвращает артикулы продукта по его id
  getSkusByProductId: function(productId) {
    var product = this.getProductById(productId);
    if (product) {
      return product.articles;
    }
    return null;
  },

  getFirstAvailableSku: function(skus) {
    if (!skus) {
      return null;
    }
    return skus.find(
      function(sku) {
        return this.isSkuAvailable(sku);
      }.bind(this)
    );
  },

  getFirstAvailableSkuForProductId: function(productId) {
    return this.getFirstAvailableSku(this.getSkusByProductId(productId));
  },

  // Методы для работы с корзиной

  addToCart: function(sku) {
    if (!sku || !sku.id) {
      return new Error("Sku data isn't valid or doesn’t have an id");
    }
    // Если такой артикул уже есть в корзине
    if (this.cartData.skus[sku.id]) {
      this.cartData.skus[sku.id] = Object.assign({}, sku, { cart_count: this.cartData.skus[sku.id].cart_count + 1 });
    } else {
      if (this.cartData.uniqSkusCount + 1 > this.cartItemsLimit) {
        return new Error('You can’t add more than ' + this.cartItemsLimit + ' items to the cart');
      }
      this.cartData.skus[sku.id] = Object.assign({}, sku, { cart_count: 1 });
      this.cartData.uniqSkusCount += 1;
    }
    this.events.trigger('ecommerce:cartdata:changed', this.cartData);
    return this.cartData;
  },

  removeFromCart: function(skuId) {
    if (this.cartData.skus[skuId]) {
      delete this.cartData.skus[skuId];
      this.cartData.uniqSkusCount -= 1;
      this.events.trigger('ecommerce:cartdata:changed', this.cartData);
      return this.cartData;
    }
  },

  getCartData: function() {
    return this.cartData;
  },

  updateCartSkus: function(skus) {
    skus.forEach(
      function(sku) {
        if (this.cartData.skus[sku.id]) {
          this.cartData.skus[sku.id] = Object.assign({}, this.cartData.skus[sku.id], sku);
        }
      }.bind(this)
    );
  },

  updateCartData: function() {
    return new Promise(
      function(resolve, reject) {
        if (!this.cartData || !this.cartData.skus) {
          reject(new Error('Empty cart'));
        }

        var skus_ids = [];

        for (var sku_id in this.cartData.skus) {
          if (!this.cartData.skus.hasOwnProperty(sku_id)) {
            continue;
          }
          skus_ids.push(sku_id);
        }

        var skus_ids_param = skus_ids.length ? _.uniq(skus_ids).join(',') : null;

        $.ajax({
          type: 'GET',
          url:
            '/api/projects/' +
            this.mag.num_id +
            '/ecommerce/stripe/skus/' +
            (skus_ids_param ? '?skus_ids=' + skus_ids_param : ''),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          success: function(result) {
            if (result && result.data) {
              this.updateCartSkus(result.data);
              resolve(this.cartData);
            }
          }.bind(this),
          error: function(jqXHR) {
            console.warn('get sku error:', jqXHR.responseText);
            reject(new Error('Error occurred while updating the skus:', skus_ids));
          },
        });
      }.bind(this)
    );
  },

  changeCartSidebarVisibility: function() {
    this.isCartSidebarHidden = !this.isCartSidebarHidden;
    this.events.trigger('ecommerce:cartsidebar:visibility:changed', this.isCartSidebarHidden);
    return this.isCartSidebarHidden;
  },

  setOrder: function(orderData) {
    this.cartData.order = orderData;
  },

  getOrder: function() {
    return this.cartData.order;
  },

  deleteOrder: function() {
    this.cartData.order = null;
  },
});

export default EcommerceManagerClass;
