/**
 * Класс для навигации по мэгу стрелками, клавиатурой и свайпом
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from '../common/utils';
import templates from '../../templates/viewer/navigation.tpl';

const NavigationClass = Backbone.View.extend({
  ARROWS_SHOW_DELAY: 1000,
  BOTTOM_SHOW_DELAY: 1000,
  BOTTOM_HIDE_DELAY: 0,

  template: templates['template-viewer-navigation'],

  events: {
    'click .navigation-arrow.left': 'goPrevPage',
    'click .navigation-arrow.right': 'goNextPage',
    'click .navigation-arrow.bottom': 'scrollCurrentPage',
  },

  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);

    // лишний раз вызывать смысла нет,
    // не и потому что на айфоне неправильно выдается window.innerWidth сразу по окончанию тача
    // и во время пинч-зума, он выдает неправильные значения. Только на iPhone. iPad - все ок.
    this.updateScaleState.__debounced = _.debounce(this.updateScaleState, 100);

    return this; // для вызова по цепочке
  },

  render: function() {
    // создаем вьюху стандартым методом бекбона:
    // устанавливаем el, $el и делегацию событий из списка events
    this.setElement(this.template({}));

    this.$el.appendTo(this.$container);

    this.$prev = this.$('.navigation-arrow.left');
    this.$next = this.$('.navigation-arrow.right');
    this.$bottom = this.$('.navigation-arrow.bottom');

    _.delay(
      _.bind(function() {
        this.$prev.removeClass('offscreen');
        this.$next.removeClass('offscreen');
      }, this),
      this.ARROWS_SHOW_DELAY
    );

    this.listenTo(this.mag, 'pageChanged', this.onPageChange);
    this.listenTo(this.mag, 'finalPageShown', this.onFinalPageShow);
    this.listenTo(this.mag, 'finalPageHidden', this.onFinalPageHide);
    this.listenTo(this.mag, 'keypress-' + $.keycodes.left, this.goPrevPage);
    this.listenTo(this.mag, 'keypress-' + $.keycodes.right, this.goNextPage);

    // это для трекпада, отлавливаем только сдвиги влево-вправо
    if (Modernizr.isdesktop) $(window).bind('mousewheel', this.onMouseWheel);

    if (!Modernizr.isdesktop) {
      $(window).bind('gesturechange', this.updateScaleState.__debounced);
      this.updateScaleState();

      // навешиваем обработчик свайпа страниц только если у нас неодностраничный мэг
      // либо если есть файнал пейдж (он может быть и на одностраничном на домене)
      if (this.mag.getPagesCount() > 1 || this.hasFinalPage) this.setSwipeAction();
    }

    return this; // для вызова по цепочке
  },

  destroy: function() {
    // это для трекпада, отлавливаем только сдвиги влево-вправо
    if (Modernizr.isdesktop) $(window).unbind('mousewheel', this.onMouseWheel);

    if (!Modernizr.isdesktop) {
      $(window).unbind('gesturechange', this.updateScaleState.__debounced);
      this.pagesSwipe && this.pagesSwipe.destroy();
    }

    // удаляем вьюху стандратным методом бекбона:
    // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
    return this.remove(); // return this для вызова по цепочке
  },

  // для трекпада - листание страниц свайпом влево-вправо
  onMouseWheel: function(e, delta, dx, dy) {
    if (this.preventTrackpadSwipeTill && +new Date() < this.preventTrackpadSwipeTill) return;

    if (Math.abs(dx) > 40 && Math.abs(dx) > Math.abs(dy)) {
      if (dx > 0) this.goPrevPage();
      if (dx < 0) this.goNextPage();
      this.preventTrackpadSwipeTill = +new Date() + 1000;
    }
  },

  setSwipeAction: function() {
    // навешиваем наш самописный плагин для вытягивания-утягивания элементов
    this.pagesSwipe = this.mag.$mag_container
      .RMSwipe({
        $scrollElements: this.mag.$mag_container,

        getMaxTransitionSpeed: _.bind(function() {
          return this.mag.getPageTransitionTime();
        }, this),

        customMoveCheck: _.bind(function($obj, shift) {
          // если мы сейчас на последней странице, и у нас есть в мэге файнал пейдж, тогда
          // смотрим открыт он или закрыт
          if (this.hasFinalPage && this.mag.isLastPage()) {
            // если открыт, тогда запрещаем сдвигать любые элементы кроме самого файнал пейджа
            if (this.finalPageShown && !$obj.hasClass('final-page')) return false;

            // если он скрыт, тогда влево можно двигать только файнал пейдж, вправо только страницы
            if (!this.finalPageShown) {
              if (!$obj.hasClass('final-page') && shift < 0) return false;
              if ($obj.hasClass('final-page') && shift > 0) return false;
            }
          }

          // если сейчас сдвиг отрицательный значит страницы двужутся влево
          // значит левую страницу двигать не надо, ее все равно не видно, ну и с правой страницей такой же алгоритм
          if ($obj.hasClass('prev-page') && shift < 0) return false;

          if ($obj.hasClass('next-page') && shift > 0) return false;

          return true;
        }, this),

        // опрашиваеся после отпускания пальца если мы задетектили что жест был достаточен для триггера действия
        // от резальтата зависит будет ли плагин сам доводить анимацию объектов до нового состояния и потом вызовет колбек
        // или же сразу вызовет колбек
        beforeAutoAnimation: _.bind(function(dir, shift) {
          // если мы на последней странице, файнал пейдж не показан и двигаем палец влево (чтобы он показался)
          // или если показан и двигаем вправо
          // тогда говорим плагиу, что выолнять доводку надо самому, но пусть сразу сработает колбек
          // это нужно для более оператичной реакции фейда последней страницы на показ/скрытие файнал пейджа (он там масштабируется и фейдится)
          // можно этого и не делать, но тогда когда мы свапом уберем файнал пейдж, последняя страница отфейдится только в конце анимации файнла пейджа, выглядит не айс
          if (
            this.mag.isLastPage() &&
            this.hasFinalPage &&
            ((dir == 'left' && !this.finalPageShown) || (dir == 'right' && this.finalPageShown))
          ) {
            return { autoAnimate: true, immediateCallback: true };
          }

          this.mag.showPageCounter(dir);

          return { autoAnimate: true, immediateCallback: false };
        }, this),

        onSwipeStart: _.bind(function() {
          !this.finalPageShown && this.mag.showPageCounter();
        }, this),

        getCurrentConstraints: _.bind(function($target) {
          // функция опрашивается в самом начале свайпа чтобы знать в каком направлении ожидается жест и его ограничения по сдвигу
          var res = {},
            size = this.mag.$mag_container.width(),
            $page = $target.closest('.page, .final-page');

          if (!$page.length) {
            // если таргет above pages виджет, то текущую страницу не определить по верстке
            // т.к. above pages виджеты лежат не внутри .page
            $page = this.mag.currentPage.$el;
          }

          // говорим о то, что двигать контейнер, не надо, а надо только эти элементы
          if (this.mag.isLastPage() && this.hasFinalPage && this.finalPageShown) {
            // при открытом файнал пейдже таскаем только его
            res['$moveItems'] = $page;
          } else {
            // поскольку final page идет в конце, то $page.next() захватит его тоже (а нам это и надо)
            res['$moveItems'] = $page.add($page.prev()).add($page.next());
          }

          // в качестве референсного объекта берем любой кроме центральной страницы (а такой всегда будет, либо левая, либо правая, либо файнал пейдж)
          // поскольку у нее транзишен меньше остальных да и не двигается она когда достаем файнал пейдж
          // т.е. по ней нельзя ни ожидаться транзишенов, ни смотерть есть сейчас анимация или нету
          res['$referenceItem'] = res['$moveItems'].filter(':not(.center-page)').first();

          // запрещаем сдвиг всего влево если мы на последней странице и при этом нет файнал пейджа в мэге или он есть и показан
          if (this.mag.isLastPage() && (!this.hasFinalPage || this.finalPageShown)) {
            res['left'] = { max: 0, noTrigger: true }; // noTrigger:true означает что в этом направлении триггерить жест не надо, но сам факт присутствия объекта у данного направления 'up' говорит о том, что в данном направлении будет работать пружинка и движение в этом направлении разрешено для инициации жеста
          } else {
            res['left'] = { max: size };
          }

          // запрещаем сдвиг всего вправо если мы на первой странице и при этомфайнал пейдж не показан
          if (this.mag.isFirstPage() && !this.finalPageShown) {
            res['right'] = { max: 0, noTrigger: true };
          } else {
            res['right'] = { max: size };
          }

          return res;
        }, this),

        callback: _.bind(function(dir) {
          // this.mag.go.. правильно отработает и появление-скртытие файнал пейджа
          if (dir == 'right') this.mag.goPrevPage({ animation: false, bySwipe: true });
          if (dir == 'left') this.mag.goNextPage({ animation: false, bySwipe: true });
        }, this),
      })
      .data('swipe');
  },

  onFinalPageShow: function() {
    this.finalPageShown = true;

    this.$next.addClass('hidden');

    this.$bottom.addClass('final-page-offscreen');

    // на десктопе всегда кажем, на остальных всегда нет
    this.$prev.toggleClass('hidden', !Modernizr.isdesktop);
  },

  onFinalPageHide: function() {
    this.finalPageShown = false;

    this.$next.removeClass('hidden');

    this.$bottom.removeClass('final-page-offscreen');

    this.$prev.toggleClass('hidden', this.mag.isFirstPage()); // файнал пейдж может быть и на одностраничном мэге (на кастомных доменах)
  },

  onPageChange: function(page, params) {
    // если мы находимся на последней странице,
    // тогда покажем стрелку вправо, даже если больше нет страниц
    // чтобы открыть плашку final page (но только если включена опция)
    this.$next.toggleClass(
      'hidden',
      !!(this.mag.isLastPage() && !this.mag.finalPage) || !!(this.mag.finalPage && this.mag.finalPage.shown)
    );

    this.recalcBottomArrowState(page);

    this.$prev.toggleClass('hidden', this.mag.isFirstPage());

    this.mag.updateArrowsColor(page);
  },

  // функция решает показывать нижнюю стрелку или нет
  // пресчет видимости стрелки вызываеться: после скрола, после ресайза, при смене страницы
  recalcBottomArrowState: function(page) {
    // стрелка появляется тогда когда у текущей страницы есть скрол и эту страницу еще не скролили
    clearTimeout(this.bottomArrowTimeout);

    var visible = page.isBottomArrowVisible();
    // для горизонтального вьювера, когда есть бэкграунд и включен скейл,
    // отключаем нижнюю стрелку, ссылка на задачу:
    // https://www.notion.so/readymag/1d22b21531334647ae71c1561db94eca
    if (this.viewerType === 'horizontal' && this.mag.viewOpts.scalableviewer) {
      visible = false;
    }

    // если выбран мобильный вьюпорт и мы в режиме превью конструктора
    // тогда не показываем никакой стрелки вниз
    if (this.isPreview && this.mag.viewport != 'default') visible = false;

    this.bottomArrowTimeout = setTimeout(
      _.bind(function() {
        this.$bottom.toggleClass('offscreen', !visible);
      }, this),
      visible ? this.BOTTOM_SHOW_DELAY : this.BOTTOM_HIDE_DELAY
    );
  },

  goPrevPage: function(e) {
    this.mag.goPrevPage({ animation: true });
  },

  goNextPage: function(e) {
    this.mag.goNextPage({ animation: true });
  },

  scrollCurrentPage: function(e) {
    this.mag.scrollCurrentPageALittle();
  },

  // Если страница отскейлена пинчем, скрываем стрелки навигации
  updateScaleState: function() {
    // на андроиде gesturechange не работает и понять что мы зумим достаточно сложно, лучше вообще не будем пытаться
    if (Modernizr.android) return;

    this.$prev
      .add(this.$next)
      .add(this.$bottom)
      .toggleClass('scaled-offscreen', Utils.isPageNativelyScaled());
  },
});

export default NavigationClass;
