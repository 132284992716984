/**
 * Виджет Forms
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import { Utils } from '../../common/utils';
import FormWidgetClass from '../../common/form-widget';

const FormWidget = WidgetClass.extend({
  DO_NOT_WAIT_FOR_PRELOAD: true, // флаг что при прелоадинге виджетов ждать его загрузки не надо (но не для скриншотера)
  NUMBER_TEST_REGEX: /^[0-9.,`']+$/, // Цифры, точки, запятые, обратные апострофы, апострофы

  events: {
    'focus :input': 'onFocus',
    'blur :input': 'onBlur',
  },

  render: function() {
    this.makeBox('widget-form');

    this.rendered = true;

    this.seamlessFontsShow(
      function() {
        this.$el.removeClass('fonts-pending');
      }.bind(this),
      function() {
        this.$el.addClass('fonts-pending');
      }.bind(this)
    );

    this.formWidget = new FormWidgetClass({
      // первый параметр this передает Кнопке объект в котором находятся
      // все нужные параметры (моделей во Вьювере у нас нет).
      // в Конструкторе например при создании передается _.clone(this.model.attributes) вместо this.
      data: this,
      environment: 'viewer',
      $container: this.$el,
    });

    this.formWidget.render();

    this.$button = this.$('.button');

    this.$button.on('click', this.onButtonClick);
    this.$('.js-input').on('keydown', this.onInputKeyDown);
    this.$('.js-input').on('input', this.onInput);
    this.$('select').on('change', this.onInput);

    // ожидаем загрузку шрифта
    // только в режиме Cкриншотера
    // для Вьювера этого делать не надо из соображений скорости,
    // да и там не важно этого знать, считаем что виджет грузится мгновенно.
    if (RM.screenshot) {
      this.waitForUsedFontsLoad();
    } else {
      this.widgetIsLoaded();
    }

    return this;
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // достаточно версии прототипа
  // isValid: function() {}

  hasFontsToLoad: function() {
    return true;
  },

  // достаточно версии прототипа
  // hasLoadAnimation: function() {},

  // достаточно версии прототипа
  // start: function() {},

  // достаточно версии прототипа
  // stop: function() {},

  destroy: function() {
    this.formWidget && this.formWidget.destroy();
    this.formWidget = null;

    this.$('.button').off('click', this.onButtonClick);

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },

  // ожидает загрузки шрифта кнопки
  waitForUsedFontsLoad: function() {
    if (this.hasFontsToLoad()) {
      this.page.addFontsToLoad(this, this.widgetIsLoaded);
    } else {
      this.widgetIsLoaded();
    }
  },

  onFocus: function() {
    Backbone.trigger('form:focus', this._id);
  },

  onBlur: function() {
    Backbone.trigger('form:blur', this._id);
  },

  onButtonClick: function(e) {
    var isValid;

    if (this.$button.hasClass('submitted')) {
      return;
    }

    isValid = this.validateForm();

    if (!isValid) {
      return;
    }

    this.setButtonState('submitted');

    if (
      this.button_caption_after_submit === 'Text after submit' ||
      !this.button_caption_after_submit ||
      (this.submit_mode && this.submit_mode !== 'textAfterSubmit')
    ) {
      this.$button.addClass('empty-submitted-text');
    } else {
      this.$button.find('.caption').text(this.button_caption_after_submit);
    }

    if (RM.common.isDownloadedSource) {
      // Экспортированный проект?
      this.submitToIframe(function(err) {
        if (err) {
          console.log('err : ', err);
          this.setButtonState('error');
          RM.analytics && RM.analytics.sendEvent('Form Submit Error', err);
        }
      });
    } else {
      this.submitForm(
        function(err) {
          if (err) {
            console.log('err : ', err);
            this.setButtonState('error');

            if (err.responseJSON && err.responseJSON.name == 'email_not_confirmed') {
              _.delay(function() {
                alert(err.responseJSON.message);
              }, 100);
            }

            RM.analytics && RM.analytics.sendEvent('Form Submit Error', err);
          }
        }.bind(this)
      );
    }
    if (this.link_after_submit && this.submit_mode && this.submit_mode !== 'textAfterSubmit') {
      this.applyLink();
    }
  },

  applyLink: function() {
    var pageUrl;

    if (this.submit_mode === 'toUrlAfterSubmit') {
      this.openInNewTab(this.link_after_submit);
    } else {
      pageUrl = this.page.mag.getPageUri
        ? '/' + this.page.mag.getPageUri(this.link_after_submit)
        : 'javascript:void(0)';
      this.mag.router.navigate(pageUrl, { trigger: true });
    }
  },

  openInNewTab: function(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener',
      href,
    }).click();
  },

  getDataBySort: function(sort) {
    sort = parseInt(sort, 10);
    return _.findWhere(this.fields, { sort: sort });
  },

  validateForm: function() {
    var $inputs = this.$('.js-input'),
      isFormValid = true;

    _.each(
      $inputs,
      function(input) {
        var $input = $(input),
          val = $input.val().trim(),
          data = this.getDataBySort($input.attr('data-sort')),
          isInputValid = true;

        if (!val) {
          isInputValid = data.optional;
        } else {
          switch (data.tp) {
            case 'name':
            case 'text':
            case 'dropdown':
              break;
            case 'email':
              if (!Utils.isValidEmail(val)) {
                isInputValid = false;
              }
              break;
            case 'phone':
              // Разрешаем только цифры, пробел, +, -
              if (!/^[0123456789 +\-]+$/.test(val)) {
                isInputValid = false;
              }
              break;
            case 'number':
              if (!this.NUMBER_TEST_REGEX.test(val) || isNaN(parseFloat(val))) {
                isInputValid = false;
              }
              break;
            case 'checkbox':
              if (!data.optional && !$input.prop('checked')) {
                isInputValid = false;
              }
              break;
          }
        }

        if (!isInputValid) {
          this.formWidget.applyInputsStyle($input.closest('.input-wrapper'), { invalid: true });
          isFormValid = false;
        }
      }.bind(this)
    );

    return isFormValid;
  },

  onInput: function(e) {
    var $input = $(e.currentTarget)
      .parent()
      .find('.js-input');
    this.formWidget.applyInputsStyle($input.closest('.input-wrapper')); // Сбрасываем инвалидную подсветку
    this.setButtonState();
  },

  onInputKeyDown: function(e) {
    // При нажатии на enter, только если не внутри textarea — сабмитить
    if (e.keyCode === $.keycodes.enter && !$(e.target).is('textarea')) {
      this.onButtonClick();
    }
  },

  getFormData: function() {
    var data = {
      wid: this._id,
      mag_num_id: this.page.mag.num_id,
      is_preview: !!(RM.constructorRouter && RM.constructorRouter.preview),
      fields: [],
    };

    _.each(
      this.fields,
      function(f) {
        const $el = this.$('.js-input[data-sort=' + f.sort + ']'); // Ключ сортировки является своебразным айдишником для поиска поля
        var val = $el.val().trim();

        if (f.tp == 'checkbox') {
          val = $el.prop('checked') ? 'On' : 'Off';
        }

        if (f.tp == 'number') {
          const _val = parseFloat(val);
          val = isNaN(_val) ? val : _val;
        }

        data.fields.push({
          caption: f.caption,
          type: f.tp,
          value: val,
        });
      }.bind(this)
    );

    return data;
  },

  submitForm: function(callback) {
    this.xhr && this.xhr.abort();

    this.xhr = $.ajax({
      dataType: 'json',
      contentType: 'application/json',
      url: '/api/connect/forms/submit',
      data: JSON.stringify(this.getFormData()),
      method: 'POST',
      success: function(data) {
        callback();
      },
      error: function(xhr) {
        callback({ status: xhr.status, responseJSON: xhr.responseJSON, responseText: xhr.responseText });
      },
    });

    RM.analytics && RM.analytics.sendEvent('Form Submit Click');
  },

  // Делает настоящий сабмит формы на кастомный урл
  submitToIframe: function(callback) {
    var url = this.endpoint_url && this.endpoint_url.custom_url,
      $iframe,
      $form;

    if (!url) {
      return callback('No custom URL specified for submit');
    }

    $iframe = $('#form_submit_iframe');
    if (!$iframe.length) {
      $iframe = $('<iframe id="form_submit_iframe" name="form_submit_iframe" style="display:none;"></iframe>').appendTo(
        'body'
      );

      // $iframe[0].onload = function(e) {
      // 	console.log('load e : ', e)
      // }
    }

    $form = this.$el.find('form');
    if (!$form.length) {
      // Оборачиваем контент виджета настоящей формой
      $form = this.$el
        .find('.form-wrapper')
        .wrap('<form method="post" target="form_submit_iframe"></form>')
        .parent()
        .attr('action', url);
    }

    $form.get(0).submit();
    callback(null); // Пока не знаю, как поймать ошибку загрузки ифрейма или сабмита формы

    RM.analytics && RM.analytics.sendEvent('Form Submit Click');
  },

  setButtonState: function(state) {
    state = state || '';
    var STATES = ['error', 'submitted'];
    this.$button.removeClass(STATES.join(' ')).addClass(state);
  },
});

export default FormWidget;
