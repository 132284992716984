import _ from '@rm/underscore';
import $ from '@rm/jquery';
import templates from '../../templates/viewer/cart-sidebar.tpl';
import { Constants } from '../common/utils';
import SVGInjector from 'svg-injector';

const CartSidebarClass = Backbone.View.extend({
  template: templates['template-viewer-cart-sidebar'],
  // Custom styling can be passed to options when creating an Element.
  cardStyle: {
    base: {
      color: '#0080FF',
      fontSize: '16px',
      fontFamily:
        "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu, 'Fira Sans', Roboto, 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },

  getRenderStatus: function() {
    return this.rendered;
  },

  initialize: function(mag) {
    _.bindAll(this);
    this.mag = mag;
    this.rendered = false;
    this.haveSkus = false;
    this.checkoutCompete = false;
    return this;
  },

  render: function() {
    if (this.rendered) return;
    this.rendered = true;

    this.setElement(this.template({}));

    this.$el.appendTo($('#mags .mag'));
    this.$toolbar = $('.toolbar.for-viewer');
    this.$tabs = this.$el.find('.cart-sidebar-tabs');
    this.$items = this.$el.find('.cart-items-list');

    this.$orderDetails = this.$tabs.find('.order-details');
    this.$orderItems = this.$tabs.find('.order-items-list');
    this.$orderError = this.$tabs.find('.order-error');
    this.$orderShippingAddress = this.$tabs.find('.order-shipping-address');
    this.$orderShippingMethod = this.$tabs.find('.order-shipping-method');
    this.$orderShippingEmail = this.$tabs.find('.order-shipping-email');
    this.$orderTax = this.$tabs.find('.order-tax');
    this.$orderTotalPrice = this.$tabs.find('.order-total-price');
    this.$orderButtons = this.$tabs.find('.order-btns');
    this.$paymentSuccess = this.$tabs.find('#payment-success-msg');
    this.$paymentFailed = this.$tabs.find('#payment-failed-msg');

    var svgs = this.$el.find('.svg');
    SVGInjector(svgs);

    this.renderCartItems();
    this.initNavigation();

    this.mag.eCommerceManager.events.on('ecommerce:cartsidebar:visibility:changed', this.toggleSidebar);
    this.mag.eCommerceManager.events.on('ecommerce:cartdata:changed', this.renderCartItems);
  },

  renderCartItems: function() {
    this.$items.empty();
    this.haveSkus = false;
    var cartData = this.mag.eCommerceManager.getCartData();
    if (!cartData || !cartData.skus) {
      return;
    }

    for (var sku_id in cartData.skus) {
      if (!cartData.skus[sku_id].active || !cartData.skus.hasOwnProperty(sku_id)) {
        continue;
      }
      if (!this.haveSkus) {
        this.haveSkus = true;
      }
      var product = this.mag.eCommerceManager.getProductById(cartData.skus[sku_id].product);
      /**
       * Элемент корзины
       */
      var item = $('<div/>', { class: 'cart-item-block' });
      var itemTitle = $('<div/>', { class: 'cart-item-title' }).html(product.caption);
      var oneItemPrice = (cartData.skus[sku_id].price / 100).toFixed(2);
      var itemPrice = $('<div/>', { class: 'cart-item-price' }).html(
        cartData.skus[sku_id].currency +
          ' ' +
          oneItemPrice +
          ' x ' +
          cartData.skus[sku_id].cart_count +
          ' = ' +
          cartData.skus[sku_id].currency +
          oneItemPrice * cartData.skus[sku_id].cart_count
      );
      if (product.images && product.images.length) {
        var itemImage = $('<img/>', { class: 'cart-item-image', src: product.images[0] });
        item.append(itemImage);
      }
      var itemRemoveBtn = $('<span/>', { class: 'cart-item-remove-btn', title: 'remove' })
        .text('x')
        .attr('data-sku-id', sku_id);
      item
        .append(itemTitle)
        .append(itemPrice)
        .append(itemRemoveBtn);
      this.$items.append(item);

      itemRemoveBtn.on('click', this.onRemoveItem);
    }
  },

  initNavigation: function() {
    this.$tabs.find("[data-tab^='tab-']").each(
      function(i, btn) {
        $(btn).on(
          'click',
          function() {
            if (this.haveSkus) {
              // Дополнительная логика при переходе на определенные табы
              if (btn.dataset.tab === 'tab-order') {
                this.renderOrderTab();
              }
              if (btn.dataset.tab === 'tab-checkout') {
                this.renderCheckoutForm();
              }
              this.goToTabByClassname('.' + btn.dataset.tab);
            }
          }.bind(this)
        );
      }.bind(this)
    );
  },

  goToTabByClassname: function(tabClassname) {
    this.$tabs.find('.cart-sidebar-tab').each(function(i, tab) {
      $(tab).removeClass('active');
    });
    this.$tabs.find(tabClassname).addClass('active');
  },

  toggleSidebar: function(isSidebarHidden) {
    if (!isSidebarHidden) {
      this.$el.addClass('show');
      this.$toolbar.addClass('cart-sidebar-visible');
    } else {
      this.$el.removeClass('show');
      this.$toolbar.removeClass('cart-sidebar-visible');
      // TODO: сделать систему для очистки состояния и перехода на нужный шаг

      // При закрытии, если оплата завершилась убираемся за собой =)
      if (this.checkoutCompete) {
        this.$paymentSuccess.empty();
        this.$paymentFailed.empty();
      }

      // Убираем ошибку создания заказа
      this.clearOrderError();
    }
  },

  onRemoveItem: function(e) {
    if (e.target.dataset.skuId) {
      this.mag.eCommerceManager.removeFromCart(e.target.dataset.skuId);
    } else {
      console.log("Sku id wasn't found at this item");
    }
  },

  renderOrderTab: function() {
    var orderDetails = this.mag.eCommerceManager.getOrder();

    // Если у нас сохранен заказ то рендерим его
    if (orderDetails) {
      this.renderOrderDetails();
      return;
    }

    // Обновляем данные артикулов лежащих в корзине
    this.mag.eCommerceManager
      .updateCartData()
      .then(
        function() {
          var requestData = this.generateOrderRequestData();
          if (requestData && requestData instanceof Error) {
            this.renderOrderError(requestData.message);
          } else if (requestData && requestData.currency) {
            $.ajax({
              type: 'POST',
              url: '/api/projects/' + this.mag.num_id + '/ecommerce/stripe/orders/',
              data: JSON.stringify(requestData),
              contentType: 'application/json; charset=utf-8',
              dataType: 'json',
              success: function(result) {
                if (result && result.data) {
                  this.mag.eCommerceManager.setOrder(result.data);
                  this.renderOrderDetails();
                }
              }.bind(this),
              error: function(jqXHR) {
                console.log('jqXHR', jqXHR.responseText);
              },
            });
          }
        }.bind(this)
      )
      .catch(
        function(error) {
          if (error && error.message) {
            this.renderOrderError(error.message);
          }
        }.bind(this)
      );
  },

  renderOrderDetails: function() {
    var orderDetails = this.mag.eCommerceManager.getOrder();
    // Очишаем блоки
    this.$orderItems.empty();
    this.$orderShippingAddress.empty();
    this.$orderShippingMethod.empty();
    this.$orderShippingEmail.empty();
    this.$orderTax.empty();
    this.$orderTotalPrice.empty();

    var tax, shipping;
    orderDetails.items.forEach(
      function(item) {
        if (item.type === 'tax') {
          tax = item;
        } else if (item.type === 'shipping') {
          shipping = item;
        } else {
          var shippingItem = $('<div/>', { class: 'order-item' }).html(
            item.quantity + ' x ' + item.description + '<br/>' + item.currency + (item.amount / 100).toFixed(2)
          );
          this.$orderItems.append(shippingItem);
        }
      }.bind(this)
    );
    var shippingAddress = orderDetails.shipping.address;
    this.$orderShippingAddress.text(
      shippingAddress.line1 +
        ' ' +
        shippingAddress.city +
        ', ' +
        shippingAddress.state +
        ' ' +
        shippingAddress.postal_code
    );

    if (shipping) {
      this.$orderShippingMethod.text(
        shipping.description + ' ' + shipping.currency + (shipping.amount / 100).toFixed(2)
      );
    }

    if (orderDetails.email) {
      this.$orderShippingEmail.text(orderDetails.email);
    }

    if (tax) {
      this.$orderTax.text(tax.description + ' ' + tax.currency + (tax.amount / 100).toFixed(2));
    }

    this.$orderTotalPrice.text(orderDetails.currency + (orderDetails.amount / 100).toFixed(2));

    this.$orderButtons.find('.cart-pay-btn').removeClass('hidden');
  },

  renderOrderError: function(message) {
    this.$orderError
      .removeClass('hidden')
      .empty()
      .text(message);
    this.$orderDetails.addClass('hidden');
  },

  clearOrderError: function() {
    this.$orderError.addClass('hidden').empty();
    this.$orderDetails.removeClass('hidden');
  },

  generateOrderRequestData: function() {
    var cartData = this.mag.eCommerceManager.getCartData();

    if (!cartData || !cartData.skus) {
      return new Error('Empty cart');
    }

    var currencies = [];
    // Временные данные TODO: сделать форму для их ввода
    var requestData = {
      currency: 'usd',
      items: [],
      shipping: {
        name: 'Jenny Rosen',
        address: {
          line1: '1234 Main Street',
          city: 'San Francisco',
          state: 'CA',
          country: 'US',
          postal_code: '94111',
        },
      },
      email: 'jenny.rosen@example.com',
    };

    for (var sku_id in cartData.skus) {
      if (!cartData.skus[sku_id].active) {
        continue;
      }

      requestData.items.push({
        type: cartData.skus[sku_id].object,
        parent: sku_id,
        currency: cartData.skus[sku_id].currency,
        quantity: cartData.skus[sku_id].cart_count,
      });

      if (currencies.indexOf(cartData.skus[sku_id].currency) === -1) {
        currencies.push(cartData.skus[sku_id].currency);
      }
    }

    // Для одного заказа должна быть одна валюта!
    if (currencies.length > 1) {
      return new Error('mixed currencies');
    }

    requestData.currency = currencies[0];
    return requestData;
  },

  renderCheckoutForm: function() {
    var stripeConnectedUserId = this.mag.eCommerceManager.getStripeConnectedUserId();
    var stripe = Stripe(Constants.STRIPE_TEST_PUBLIC_KEY, {
      stripeAccount: stripeConnectedUserId,
    });
    var elements = stripe.elements();
    this.card = elements.create('card', { style: this.cardStyle, hidePostalCode: true });
    this.card.mount('#card-element');

    this.card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.form = document.getElementById('payment-form');
    this.form.addEventListener(
      'submit',
      function(event) {
        event.preventDefault();

        stripe.createToken(this.card).then(
          function(result) {
            if (result.error) {
              // Inform the customer that there was an error.
              var errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
            } else {
              // Send the token to your server.
              this.stripeTokenHandler(result.token);
            }
          }.bind(this)
        );
      }.bind(this)
    );
  },

  stripeTokenHandler: function(token) {
    var order = this.mag.eCommerceManager.getOrder();

    if (!order) {
      this.$paymentFailed.text('Order not found');
      return;
    }

    var checkoutEmail = $('#checkout-email').val();

    if (!checkoutEmail) {
      this.$paymentFailed.text('Email is required');
      return;
    }

    $.ajax({
      type: 'POST',
      url: '/api/projects/' + this.mag.num_id + '/ecommerce/stripe/orders/' + order.id + '/pay/',
      data: JSON.stringify({ stripe_token: token.id, email: checkoutEmail }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      success: function(result) {
        if (result && result.data) {
          this.mag.eCommerceManager.deleteOrder();
          if (result.data.status === 'paid') {
            this.$paymentSuccess.text('Payment Success');
          }
          this.checkoutCompete = true;
        }
      }.bind(this),
      error: function(jqXHR) {
        console.log('pay order Error', jqXHR.responseText);
        this.$paymentFailed.text('Payment Failed');
        this.checkoutCompete = true;
      },
    });
  },

  destroy: function() {
    this.mag.eCommerceManager.events.off('ecommerce:cartsidebar:visibility:changed');
    this.mag.eCommerceManager.events.off('ecommerce:cartdata:changed');
    this.form.removeEventListener('submit');
    this.card.removeEventListener('change');
    this.$tabs.find("[data-tab^='tab-']").each(function(i, btn) {
      $(btn).off('click');
    });
    this.$items.find('.cart-item-remove-btn').each(function(i, btn) {
      $(btn).off('click');
    });
  },
});

export default CartSidebarClass;
