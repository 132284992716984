/**
 * Класс вьюшки для ифрейма Gift карт, встраиваемого в страницы мэгов
 */

(function(RM, undefined) {
  'use strict';

  RM.classes.GiftView = Backbone.View.extend({
    MONTH_PRICE: 20,
    YEAR_PRICE: 192,

    template: RM.templates['template-common-gift'],

    events: {
      'click .send-button': 'onSendButtonClick',
      'click .back-button': 'onBackButtonClick',
      'click .period-selector .period': 'onPeriodClick',
      'click .card-switcher': 'onSwitchCardClick',

      'input .card-wrapper.new-card .card-date input': 'onCardExpChange',
      'input .card-wrapper.new-card .card-cvc input': 'onCardCvcChange',

      'input input': 'onInputChange',
      'focus input': 'clearAllErrors',
    },

    initialize: function(params) {
      _.bindAll(this);
      _.extend(this, params);

      this.stripe.setPublishableKey(
        RM.constants.stripe_live ? RM.constants.STRIPE_LIVE_PUBLIC_KEY : RM.constants.STRIPE_TEST_PUBLIC_KEY
      );

      this.Model = Backbone.Model.extend({
        url: '/api/stripe/gift/create',
      });

      this.me = this.router && this.router.me;
    },

    render: function() {
      var card = null;

      this.getCustomerData(
        function(err, data) {
          if (!err && data) {
            card = data.sources && data.sources.data && data.sources.data[0];
          }

          this.$el.html(
            this.template({
              month_price: this.MONTH_PRICE,
              year_price: this.YEAR_PRICE,
              existing_card: card,
            })
          );

          this.datePicker = new this.Pikaday({
            field: this.$('.field-wrapper.send input')[0],
            trigger: this.$('.field-wrapper.send .formated-date')[0],
            position: 'top',
            defaultDate: new Date(),
            setDefaultDate: true,
            minDate: new Date(),
            maxDate: new Date(2016, 0, 31),
            onSelect: this.updateFormatedDate,
            numberOfMonths: 1,
          });

          this.updateFormatedDate(new Date());

          this.messageAutoResize = this.$('.field-wrapper.message textarea')
            .RMAutoResizeTextArea({
              maxHeight: 26 * 12,
              maxVisibleHeight: 26 * 6,
              $scroll: this.$('.field-wrapper.message .description-scroll'),
              onChange: _.bind(function(text) {
                this.$('.field-wrapper.message .counter').text(200 - text.length);
              }, this),
            })
            .data('autoresize');

          this.$('.card-wrapper.new-card .card-number input').cardNumberFormatter();

          this.clearAllErrors();

          this.recalcSendButtonState();

          this.delegateEvents();
        }.bind(this)
      );
    },

    getCustomerData: function(callback) {
      if (!(this.router && this.router.me && this.router.me.get('stripe'))) {
        return callback(null);
      } else {
        var inProgress = true;
        var xhr = $.ajax({
          url: '/api/stripe/customer',
          method: 'GET',
          success: function(res) {
            callback(null, res);
          },
          error: function(xhr) {
            callback(xhr);
          },
          complete: function() {
            inProgress = false;
          },
        });

        // Если долго нет ответа от сервера, просто считаем, что нет карты
        setTimeout(function() {
          if (inProgress) {
            xhr && xhr.abort();
            return callback(null);
          }
        }, 7000);
      }
    },

    updateFormatedDate: function(date) {
      var formatedDate = this.getFormatedDate(date),
        todayFormatedDate = this.getFormatedDate(new Date());

      this.$('.field-wrapper.send .formated-date').text(
        (formatedDate == todayFormatedDate ? 'Today — ' : '') + this.getFormatedDate(date)
      );
    },

    getFormatedDate: function(date) {
      var day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear();

      var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      return day + ' ' + monthNames[month] + ', ' + year;
    },

    onPeriodClick: function(e) {
      var $period = $(e.currentTarget);

      $period
        .addClass('active')
        .siblings()
        .removeClass('active');

      var sum = $period.hasClass('month') ? this.MONTH_PRICE : this.YEAR_PRICE;

      this.$('.bottom-block .charge-sum span').text('$' + sum);
    },

    onSwitchCardClick: function(e) {
      var cardType = $(e.currentTarget).attr('data-value');

      this.$('.card-wrapper.' + cardType)
        .removeClass('hidden')
        .siblings('.card-wrapper')
        .addClass('hidden');

      this.clearAllErrors();

      this.recalcSendButtonState();
    },

    clearAllErrors: function() {
      this.$('.bottom-block').removeClass('show-error');
      this.$('.field-wrapper').removeClass('show-error');
    },

    showStripeError: function(error) {
      this.$('.bottom-block')
        .find('.error')
        .text(error);
      this.$('.bottom-block').addClass('show-error');
    },

    onInputChange: function() {
      this.clearAllErrors();

      this.recalcSendButtonState();
    },

    recalcSendButtonState: function() {
      var data = this.getFormData();

      this.$('.bottom-block').toggleClass('inactive', !!data.hasErrors);
    },

    onBackButtonClick: function() {
      this.$('.form-wrapper').removeClass('invisible');
      this.$('.congrats-wrapper').addClass('invisible');
      this.$('.field-wrapper.email input').val('');
      this.recalcSendButtonState();
    },

    onSendButtonClick: function() {
      this.clearAllErrors();

      // Модель - для простоты отправки запроса
      var model = new this.Model();

      var data = this.getFormData();

      if (data.hasErrors) {
        data.$errorFields.addClass('show-error');
        this.$('.bottom-block')
          .find('.error')
          .text('PLEASE CHECK THE FIELDS ABOVE MARKED WITH RED');
        this.$('.bottom-block').addClass('show-error');
        return;
      }

      this.$('.bottom-block .send-button').addClass('show-loader');
      this.$('.charging-overlay').show();

      // блокируем клавиатуру, ничего на ней особенно не сделать, но в общем и целом нефиг
      this.$el.on('keydown', function(e) {
        e.stopPropagation();
        e.preventDefault();
      });

      var requestOpts = {
        success: function(model) {
          // показываем блок congrats с данными из формы и кодом который вернул бэк
          this.$('.form-wrapper').addClass('invisible');
          this.$('.congrats-wrapper').removeClass('invisible');
          this.$('.congrats-wrapper .subscription .caption').html(
            '1 ' + data.period.capitalize() + ' of<br>' + model.get('plan_name')
          );
          this.$('.congrats-wrapper .subscription .price').text('$' + Math.round(model.get('amount') / 100));
          this.$('.congrats-wrapper .order-info .info-email').text(model.get('deliver_email'));
          this.$('.congrats-wrapper .order-info .info-date').text(formatDate(model.get('deliver_at')));
          this.$('.congrats-wrapper .order-info .info-code').text(model.get('code'));

          // проскроливаем страницу с виджетом так, чтобы увидеть текст поздравления с удачной покупкой
          // только для телефонов, там форма очень длинная
          Modernizr.isphone &&
            this.router &&
            this.router.mag &&
            this.router.mag.setScrollPosition({
              delta: this.$('.back-button').offset().top - this.$('.send-button').offset().top,
              animate: true,
            });

          this.router &&
            this.router.analytics &&
            this.router.analytics.sendEvent('Gift Code Purchase', 'superpublisher_' + data.period + 'ly');

          onRequestComplete();
        }.bind(this),
        error: function(model, xhr) {
          // показываем ошибку страйпа
          this.showStripeError(xhr.responseText);
          onRequestComplete();
        }.bind(this),
      };

      // Прячет прелоадепы и т.п.
      var onRequestComplete = function() {
        this.$('.bottom-block .send-button').removeClass('show-loader');
        this.$('.charging-overlay').hide();
        this.$el.off('keydown');
      }.bind(this);

      // В data содержатся лишние данные и флаги
      var saveData = _.pick(data, 'plan_id', 'deliver_at', 'deliver_message', 'deliver_email', 'from_name');

      if (!_.isEmpty(data.card)) {
        this.stripe.card.createToken(
          data.card,
          function(status, response) {
            if (response.error) {
              this.showStripeError(
                (response.error && response.error.message) || 'Sorry, some problems with your card.'
              );
              onRequestComplete();
            } else {
              saveData.card_token = response.id;
              model.save(saveData, requestOpts);
            }
          }.bind(this)
        );
      } else {
        model.save(saveData, requestOpts);
      }

      function formatDate(dateString) {
        var monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          tmp = dateString.split('-');

        return monthNames[tmp[1] - 1] + ' ' + parseInt(tmp[2]) + ', ' + parseInt(tmp[0]);
      }
    },

    getFormData: function() {
      var $period = this.$('.period-selector .period.active'),
        $emailWrapper = this.$('.field-wrapper.email'),
        $fromWrapper = this.$('.field-wrapper.from'),
        $messageWrapper = this.$('.field-wrapper.message'),
        $sendWrapper = this.$('.field-wrapper.send'),
        $cardWrapper = this.$('.card-wrapper:not(.hidden)'),
        $cardNameWrapper = this.$('.card-wrapper.new-card .field-wrapper.card-name'),
        $cardNumberWrapper = this.$('.card-wrapper.new-card .field-wrapper.card-number'),
        $cardExpWrapper = this.$('.card-wrapper.new-card .field-wrapper.card-date'),
        $cardCVCWrapper = this.$('.card-wrapper.new-card .field-wrapper.card-cvc');

      var date = new Date($sendWrapper.find('input').val()),
        day = date.getDate(),
        month = date.getMonth() + 1,
        year = date.getFullYear();

      var data = {
        plan_id: $period.attr('data-plan'),
        deliver_email: $.trim($emailWrapper.find('input').val()),
        from_name: $.trim($fromWrapper.find('input').val()),
        deliver_message: $.trim($messageWrapper.find('textarea').val()),
        deliver_at: year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day,
        useExistingCard: $cardWrapper.hasClass('existing-card'),
        period: $period.hasClass('month') ? 'month' : 'year',
      };

      var $errorFields = $();

      if (!RM.utils.isValidEmail(data.deliver_email)) {
        $errorFields = $errorFields.add($emailWrapper);
      }

      if (!data.useExistingCard) {
        var card = {};

        var cardName = $.trim($cardNameWrapper.find('input').val()),
          cardNumber = $cardNumberWrapper
            .find('input')
            .val()
            .replace(/\s/g, ''),
          cardExp = $cardExpWrapper
            .find('input')
            .val()
            .replace(/\s/g, ''),
          cardCVC = $cardCVCWrapper
            .find('input')
            .val()
            .replace(/\s/g, '');

        if ($.trim(cardName) == '') {
          $errorFields = $errorFields.add($cardNameWrapper);
        } else {
          card.name = cardName;
        }

        if (!/^\d{16,16}$/.test(cardNumber)) {
          $errorFields = $errorFields.add($cardNumberWrapper);
        } else {
          card.number = cardNumber;
        }

        // валидны только карточки с 01/16 (ну и отдельно проверяем на последний месяц этого года)
        if (!/^(0[1-9]|1[0-2])(\/|\\)(1[6-9]|[2-9][0-9])$/.test(cardExp) && cardExp != '12/15') {
          $errorFields = $errorFields.add($cardExpWrapper);
        } else {
          card.exp_month = parseInt(cardExp.substr(0, 2), 10);
          card.exp_year = 2000 + parseInt(cardExp.substr(3, 2), 10);
        }

        if (!/^\d{3,4}$/.test(cardCVC)) {
          $errorFields = $errorFields.add($cardCVCWrapper);
        } else {
          card.cvc = cardCVC;
        }
      }

      if ($errorFields.length) {
        data.hasErrors = true;
        data.$errorFields = $errorFields;
      } else {
        data.card = card;
      }

      return data;
    },

    onCardExpChange: function(e) {
      var $c_exp, exp_digits;

      // Не даем вводить ничего кроме цифр и слэшей
      $c_exp = $(e.currentTarget);
      if (/[^\d\/]/g.test($c_exp.val())) {
        $c_exp.val($c_exp.val().replace(/[^\d\/]/g, ''));
      }

      // Не даем вводить 2 слэша подряд
      if (/\/\//g.test($c_exp.val())) {
        $c_exp.val($c_exp.val().replace(/\/\//g, '/'));
      }

      exp_digits = $c_exp.val().replace(/\D/g, '');

      // Форматируем поле даты, если цифры добавляются, а не стираются
      if (this.prev_exp_digits && exp_digits.length > this.prev_exp_digits.length && exp_digits.length < 6) {
        $c_exp.val(exp_digits.replace(/^(\d{2})(.*)$/, '$1/$2'));

        // Ставим курсор после слэша
        if (/^\d\d\/$/g.test($c_exp.val())) {
          _.defer(function() {
            $c_exp.setCursorPos(3);
          });
        }
      }
      this.prev_exp_digits = exp_digits;
    },

    onCardCvcChange: function(e) {
      var $c_cvc = $(e.currentTarget),
        val = $c_cvc.val();

      // Не даем вводить ничего кроме цифр
      if (/\D/g.test(val)) {
        $c_cvc.val(val.replace(/\D/g, ''));
      }
    },
  });
})(RM);
