/**
 * Базовый класс для виджетов
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/viewer/widget.tpl';
import TextUtils from '../common/textutils';
import Scale from '../common/scale';
import AnimationUtils from '../common/animationutils';
import MathUtils from '../common/mathutils';
import { Utils } from '../common/utils';

var wid = 1;

const WidgetClass = Backbone.View.extend({
  template: templates['template-viewer-widget'],

  // Будет переопределен в некоторых потомках
  initialize: function(data, page, $container) {
    _.bindAll(this);

    this.updateWidgetData(data, page, $container);

    this.destroyed = false;
    this.$document = $(document);
    this._id = this._id || wid++;

    return this;
  },

  // Будет переопределен всегда
  // в самом начале там будет вызываться makeBox с классом
  // assumption: вызывается только один раз за время существования виджета
  // этим занимается страница и только в одном месте
  // никто больше не имеет права вызывать рендер кроме страницы (включая сам виджет!)
  // это связано с колбеками, ведь виджет не знает кто его слушает и как они отреагируют на два события лоад или еще чего
  // ОБЯЗАТЕЛЬНО ставим rendered = true в конце
  render: function() {
    this.makeBox('some-widget-class');

    this.rendered = true;
    this.checkLink();

    return this;
  },

  /**
   * Функция перерисовки виджета. Не путать с перерендерингом!
   * Здесь можно размещать код, кторый изменяет визуальные свойства виджета,
   * но не перерисовывает элемент с нуля
   * @return {RM.classes.Widget}
   */
  redraw: function() {
    this.checkLink();
    return this;
  },

  /**
   * Обновляет родительскую страницу у виджета
   * Нужно для глобальных виджетов, т.к. они могут иметь разные свойства на разных страницах
   * @param {RM.classes.Page} newPage
   */
  updatePage: function(newPage) {
    var oldPage = this.page;
    if (!oldPage || (newPage && oldPage.num !== newPage.num)) {
      this.page = newPage;
      this.redraw();
    }
  },

  canRenderImmediately: function() {
    return Boolean(this.DO_NOT_WAIT_FOR_PRELOAD);
  },

  // Будет переопределен в тех виджетах где требуются дополнительные действия после лоадинга
  widgetIsLoaded: function() {
    // реагируем только на первое событие лоад (некоторые виджеты могут послать их несколько раз из-за не очень правильного кода)
    // также ничего не делаем если виджет был удален в процессе лоадинга
    if (this.loaded || this.destroyed) return;

    this.loaded = true;

    // если мы просили виджет запуститься (и до сих пор просим, раз флаг не сброшен)
    // тогда говорим виджету об этом снова, когда он уже загружен
    if (this.started) this.start();

    this.trigger('loaded');
  },

  // Валидатор: возвращает true, если данные корректны и виджет может быть использован, в противном слчае виджета даже не будет создан
  // Переопределяется в тех потомках которые могут содержать некорректные данные
  isValid: function() {
    return true;
  },

  // опрашивается страницей, чтобы знать от каких виджетов ждать загрузки шрифтов, а от каких не стоит
  // переопределяется теми виджетами, у которых есть шрифты
  hasFontsToLoad: function() {
    return false;
  },

  /**
   * Убирает FOUT для текстовых виджетов, если это возможно
   */
  seamlessFontsShow: function(show, hide) {
    var identifier = 'widget ' + this._id + ' font load';

    // Только если доступна быстрая / нативная / пригодная для вьюера проверка загрузки шрифтов, делаем следующее:
    // Прячем виджет, ждём загрузки шрифтов и показываем виджет только когда все его шрифты загружены
    // (коллбек также вызовется когда истекло допустимое время ожидания загрузки шрифтов)
    if (TextUtils.isFastFontLoadCheckAvailable() && !RM.screenshot && !this.insideHotspot && this.hasFontsToLoad()) {
      hide();

      var onSuccess = function(fonts) {
        // Показываем по requestAnimationFrame, иначе в Сафари на долю секунды виден текст без шрифта https://trello.com/c/yWy28hYT/114-fout
        window.requestAnimationFrame(show);
      };

      var onFailure = function(fonts) {
        show();
      };

      this.makeSureFontsLoaded()
        .then(onSuccess)
        .catch(onFailure);
    }
  },

  makeSureFontsLoaded: function() {
    // Получим шрифты, исключая дефолтовый шрифт, если он фактически не используется https://trello.com/c/yWy28hYT/114-fout, пункт 4
    var variationsToLoad = this.page.getFontsVariations([this], true);

    var promises = [];
    _.each(variationsToLoad, function(v) {
      var promise = TextUtils.fastWaitForFontLoad(v.fontFamily, v.fontWeight, v.fontStyle);
      promises.push(promise);
    });

    return window.Promise.all(promises);
  },

  hasAnimation: function() {
    return Boolean(
      this.animation && this.animation.type && this.animation.type != 'none' && !_.isEmpty(this.animation.steps)
    );
  },

  // опрашивается страницей, чтобы знать какие виджеты имеют лоад анимацию
  hasLoadAnimation: function() {
    return this.hasAnimation() && this.animation.type == 'load';
  },

  // Будет переопределен в виджетах которые должны реагировать на то что страница стала видимой на экране
  // может вызываться кем угодно и на любой фазе работы виджета, пишем код соответсвенно!!!
  start: function() {
    this.started = true;

    return this;
  },

  // Будет переопределен в виджетах которые должны реагировать на то что страница перестала быть видимой на экране
  // может вызываться кем угодно и на любой фазе работы виджета, пишем код соответсвенно!!!
  stop: function() {
    this.started = false;

    return this;
  },

  /**
   * Прячет виджет. Метод используется для динамического скрытия
   * глобальных виджетов, отвязанных от страниц
   * @return {Object}
   */
  hide: function() {
    this.stop();
    this.$el.hide();
    this.wasHidden = true;
    this.trigger('hidden');

    return this;
  },

  /**
   * Показывает динамически скрытый виджет. Метод используется для динамического скрытия/показа
   * глобальных виджетов, отвязанных от страниц
   * @return {Object}
   */
  show: function() {
    this.$el.show();
    this.start();
    this.wasHidden = false;
    this.trigger('shown');

    return this;
  },

  // Будет переопределен в тех виджетах где это требуется (например если навешивали свои обработчики какие-то, или есть свои внутренние объекты)
  // assumption: вызывается только один раз за время существования виджета
  // этим занимается страница и только в одном месте
  // никто больше не имеет права вызывать destroy кроме страницы (включая сам виджет!)
  // это связано с колбеками, ведь виджет не знает кто его слушает и как они отреагируют на два события destroyed или еще чего
  // может быть вызван в любой момент на любой фазе работы виджета (ну разве что не раньше initialize)!
  destroy: function() {
    this.stop(); // обязательно надо вызвать, например в фб на стоп виджет удаляется и снимается колбек с виндоу

    this.destroyed = true; // это важно если где-то еще остались ссылки на объект, например в синхронном лоадере, который может не знать что данного виджет уже нет, но поскольку у него есть указатель на него, он прочитает свойство destroyed

    this.trigger('destroyed');

    // сами удаляем сслку на объект анимаций
    // чтобы бразузеру не пришлось разруливать циклические ссылки для GC
    // ибо внутри animationObj у нас есть массив с виджетами которые к нему относятся
    // здесь мы просто удаляем ссылку, сам объект уничтожается в page.js
    delete this.animationObj;

    this.$el.off();

    // удаляем вьюху стандратным методом бекбона:
    // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
    return this.remove();
  },

  // переносит виджет выше всех по Z уровню
  // при этом учитывает что у него может быть анимационный контейнер (а еще то, что этот контейнер может быть общим для нескольких виджетов)
  BringToTopZ: function() {
    if (this.animationObj) {
      var $container = this.animationObj.getAnimationContainer();
      if (this.animationObj.resetTimeout) {
        clearTimeout(this.animationObj.resetTimeout);
      }
      if (!this.animationObj.savedAnimationContainerZIndex) {
        this.animationObj.savedAnimationContainerZIndex = $container.css('z-index');
      }
      $container.css('z-index', 99999 + this.z);
    }

    this.$el.css('z-index', 99999 + this.z);
  },

  // переносит виджет обратно на его исходное положение по Z уровню
  // при этом учитывает что у него может быть анимационный контейнер (а еще то, что этот контейнер может быть общим для нескольких виджетов)
  BackToNormalZ: function() {
    var fResetZ = function() {
      if (this.animationObj && this.animationObj.savedAnimationContainerZIndex) {
        var $container = this.animationObj.getAnimationContainer();
        $container.css('z-index', this.animationObj.savedAnimationContainerZIndex || this.z);
        delete this.animationObj.savedAnimationContainerZIndex;
      }
      delete this.animationObj.resetTimeout;
    }.bind(this);
    if (this.animationObj && this.animationObj.savedAnimationContainerZIndex) {
      if (this.animationObj.resetTimeout) {
        clearTimeout(this.animationObj.resetTimeout);
      }
      this.animationObj.resetTimeout = setTimeout(fResetZ, 200);
    }

    this.$el.css('z-index', this.z); // Сбрасываем завышенный z-index (см. ниже)
  },

  // Создает DOM болванку виджета в контентной области либо в области для фиксед виджетов
  makeBox: function(className) {
    // создаем вьюху стандартым методом бекбона:
    // устанавливаем el, $el и делегацию событий из списка events
    this.setElement(this.template({ id: this._id }));

    var $container = this.fixed_position ? this.$fixedContainer : this.$pageContainer;

    // если у объекта есть данные по анимациям
    // тогда размещаем объект в контейнере для его группы анимаций
    // объект который создает эти контейнеры сам знает где их разместить (в обычном контейнере или в одном из фиксед)
    if (this.animationObj) {
      $container = this.animationObj.getAnimationContainer();
    }

    this.$el
      .appendTo($container)
      .addClass(className)
      .toggleClass('full-width', !!this.is_full_width)
      .toggleClass('full-height', !!this.is_full_height)
      .toggleClass('inside-hotspot', !!this.insideHotspot);

    this.applyBoxStyle();

    if (this.is_full_width || this.is_full_height || this.fixed_position || this.sticked) {
      this.listenTo(this.page, 'resize', this.onPageResize);
    }

    this.trigger('rendered');
  },

  // переопределяется в потомках (с обязательным вызовом супера)
  onPageResize: function() {
    this.applyBoxStyle();
  },

  // устанавливаем положения и размеры бокса включая трансформы (повороты, к примеру, или скейлы для фикседов)
  // бывший calculateDimensions
  // теперь скрещен с getFixedPositionCSS и applyTransformations
  calcBoxStyle: function(params) {
    params = params || {};

    var dimensions = {
      left: this.x,
      top: this.y,
      width: this.w,
      height: this.h,
      right: '',
      bottom: '',
      'margin-left': '',
      'margin-top': '',
      // this z-index causes vidgets overlaping in firefox
      'z-index': this.z || 1,
    };

    var fullwidthMargin = parseInt(this.full_width_margin, 10) || 0,
      fullheightMargin = parseInt(this.full_height_margin, 10) || 0,
      stickedMargin = parseInt(this.sticked_margin, 10) || 0,
      scale = this.page.scale,
      containerWidth = this.page.mag.getContainerSizeCached().width,
      widthDiffernce = containerWidth - $(window).width(),
      fixed = this.fixed_position,
      fullwidthNegativeMargin;

    // Если виджет повернут на угол, кратный 90,
    // производим корректировку высоты таким образом, чтобы
    // высота и ширина были обе: либо четные, либо нечетные
    // Иначе размывается все содержимое виджета
    if (this.angle) {
      dimensions.width = Math.round(this.w);
      dimensions.height = Math.round(this.h);
      if (this.angle % 90 == 0 && dimensions.width % 2 !== dimensions.height % 2) {
        dimensions.width += 1;
      }
    }

    if (this.is_full_height) {
      dimensions.top = -this.page.contentPosition.top + fullheightMargin;

      // фулхайт + фиксед
      if (fixed) {
        dimensions.top = fullheightMargin / scale;
        // в случае с фикседом было решено сделать, чтобы полная высота виджета была всегда равна высоте окна,
        // даже если высота проекта больше высоты окна, виджет с full-height занимает только высоту окна
        // и при скролле остается "прибитым";
        // fullheightMargin - пространство сверху и снизу виджета, задается пользователем.
        dimensions.height = $(window).height() / scale - fullheightMargin * 2;

        // переделываем 9 точек привязки в 3: nw, w, sw
        // чтоб правильно отработал getFixedPositionCSS который идет дальше
        if (['nw', 'w', 'sw'].indexOf(fixed) > -1) {
          fixed = 'nw';
        }

        if (['n', 'c', 's'].indexOf(fixed) > -1) {
          fixed = 'n';
        }

        if (['ne', 'e', 'se'].indexOf(fixed) > -1) {
          fixed = 'ne';
        }
      } else {
        dimensions.height = this.page.pageHeight / scale - fullheightMargin * 2;
      }
    }

    // фулвидх
    if (this.is_full_width) {
      dimensions.left = -this.page.contentPosition.left + fullwidthMargin;
      dimensions.width = this.page.mag.getContainerSizeCached().width / scale - fullwidthMargin * 2;
      fullwidthNegativeMargin = -((dimensions.width - this.page.$content[0].clientWidth) / 2).toFixed(3);
      // left задается по-другому, иначе картинка смещается вправо вместе с контейнером
      if (Scale.isAllowed() && this.page.mag.getContainerSizeCached().width > this.mag.viewOpts.scalewidth) {
        if (Modernizr.msie11) {
          fullwidthNegativeMargin = -(containerWidth - this.page.$content[0].clientWidth) / 2 - widthDiffernce / 2;
          dimensions.left = fullwidthNegativeMargin / scale;
        } else if (Modernizr.firefox) {
          // при различных scale width в FF иногда не хватает то 0.5px, то 1px, а иногда всё ровно,
          // выявить зависимость не удалось, поэтому всегда добавляю 1px с запасом и сдвигаю на 1px влево
          dimensions.width = dimensions.width + 1;
          dimensions.left = fullwidthNegativeMargin - 1;
        } else {
          dimensions.left = fullwidthNegativeMargin;
        }
      }

      // фулвидх + фиксед
      if (fixed) {
        dimensions.left = fullwidthMargin;

        // Делаем, чтобы растянутые фикседы не наезжали на скроллбары
        if (this.page.viewerType == 'horizontal') {
          var $scrollWrapper = this.page.$scrollWrapper,
            scrollbar_w = $scrollWrapper.width() - $scrollWrapper[0].clientWidth;

          dimensions.width -= scrollbar_w / scale;
        }

        // переделываем 9 точек привязки в 3: nw, w, sw
        // чтоб правильно отработал getFixedPositionCSS который идет дальше
        if (['nw', 'n', 'ne'].indexOf(fixed) > -1) {
          fixed = 'nw';
        }

        if (['w', 'c', 'e'].indexOf(fixed) > -1) {
          fixed = 'w';
        }

        if (['sw', 's', 'se'].indexOf(fixed) > -1) {
          fixed = 'sw';
        }
      }
    }

    // стики
    if (this.sticked) {
      var scrollbar_w = 0;

      if (this.page.viewerType == 'horizontal') {
        scrollbar_w = this.page.$scrollWrapper.width() - this.page.$scrollWrapper[0].clientWidth;
      }

      if (this.sticked == 'left') {
        dimensions.left = -this.page.contentPosition.left / scale + stickedMargin;
      }
      if (this.sticked == 'right') {
        dimensions.left =
          this.page.mag.getContainerSizeCached().width / scale -
          stickedMargin -
          this.w -
          this.page.contentPosition.left / scale -
          scrollbar_w / scale;
      }
      if (this.sticked == 'top') {
        dimensions.top = -this.page.contentPosition.top / scale + stickedMargin;
      }
      if (this.sticked == 'bottom') {
        dimensions.top = this.$pageContainer.height() - stickedMargin - this.h + this.page.contentPosition.top / scale;
      }
    }

    // нужно для расчета блоков анимаций
    // вычислет boundingBox
    // для фикседов говорит его с уже учтенным скейлом (т.е. к примеру при скейле 0 ширина высота bb будут нулевые)
    // важно вычислять после фулвидхов, но до фикседов
    if (params.calcBBox) {
      // сами скейлим иксед чтобы дальне правильно посчитать поворот
      if (fixed) {
        dimensions.left *= scale;
        dimensions.top *= scale;
        dimensions.width *= scale;
        dimensions.height *= scale;
      }

      dimensions.sinAngle = Math.sin((-(this.angle || 0) * Math.PI) / 180);
      dimensions.cosAngle = Math.cos((-(this.angle || 0) * Math.PI) / 180);

      // пересчитает размеры с учетом поворота
      var bbox = MathUtils.calcBoundingBox(dimensions, fixed);

      dimensions.width = bbox.bb_width;
      dimensions.height = bbox.bb_height;
      dimensions.left = bbox.bb_left;
      dimensions.top = bbox.bb_top;

      if (fixed) {
        // здесь scale 1! мы чуть выше сами отскейлили фиксед как надо, сделано так, чтобы сначала посчитать поворот
        _.extend(dimensions, Utils.getFixedPositionCSS(fixed, dimensions, 1));
      }

      return {
        dimensions: dimensions,
      };
    }

    // для всех фикседов, включая фулвидхи (для них начали править чуть выше, а эта функция закончит начатое)
    if (fixed) {
      // Не масштабируем transform: scale в большую сторону
      _.extend(dimensions, Utils.getFixedPositionCSS(fixed, dimensions, Scale.normalize(scale, 'transform')));
      if (scale >= 1 && Scale.isAllowed() && Scale.isZoom()) {
        dimensions.zoom = scale;
      }
    }

    // применяем трансформации к виджету (вращение, флип)
    // а для фикседов в режиме скейла страницы еще и кастомный скейл!
    var transform = {
      rotateX: this.angle && this.antialiasRotation ? '0deg' : '', // Используется для картинок и текста для сглаживания при повороте
      rotateY: this.angle && this.antialiasRotation ? '0deg' : '', // Используется для картинок и текста для сглаживания при повороте
      rotateZ: this.angle ? this.angle + 'deg' : '',
      scaleX: this.flip_h ? -1 : 1,
      scaleY: this.flip_v ? -1 : 1,
    };

    // фиксед виджеты скейлятся по одиночке, а не как обычные виджеты, у которых просто скейлится контейнер в котором они все лежат
    // при том нам надо учесть то что скейл уже может быть применен при флипах
    // а также то, что центр трансформации всегда центр объекта (это важно для поворотов, если они есть)
    // учитываем предыдущий скейл, он может быть 1 или -1 (при флипах)
    if (fixed) {
      transform.scaleX *= Scale.normalize(scale, 'transform');
      transform.scaleY *= Scale.normalize(scale, 'transform');
    }

    // Убираем скейлы если они равны 1 (-1 при этом естественно оставим)
    if (Math.abs(transform.scaleX - 1) < 0.000001) transform.scaleX = '';
    if (Math.abs(transform.scaleY - 1) < 0.000001) transform.scaleY = '';

    return {
      dimensions: dimensions,
      transform: transform,
    };
  },

  applyBoxStyle: function() {
    var styleData = this.calcBoxStyle();

    // если мы внутри контейнера анимаций, тогда нам надо сместить виджет внутри контейнера так, чтобы он визуально осталься бы на месте, как бужто он вне контейнера
    if (this.animationObj) {
      styleData = this.animationObj.modifyWidgetPosition(styleData);
    }

    this.$el.css(styleData.dimensions);

    var transformStr = _.map(styleData.transform, function(val, key) {
      return val ? key + '(' + val + ')' : '';
    }).join(' ');

    // трим обязателен, потому что у нас может быть собрана строка из пробелов
    // мы читаем такую строку пустой, а applyTransform так не считает и не сбрасывает стили трансформа как мы того хотим
    Utils.applyTransform(this.$el, $.trim(transformStr));
  },

  // скажет четыре числа: расстояния от центра виджета до краев экрана или вьюпорта или границ страницы
  // на данный момент нужно хотспоту, чтобы понять где есть область вокруг виджета где можно отобразить попап
  // и он не обрежется границами страницы или экрана
  // все рассчеты производим во ВНУТРЕННИХ координатах страницы, т.е. в том отсчете в котором живут виджеты (и не знают про скейл)
  calcDistsToPageVisibleBounds: function() {
    var res,
      // берем кешированную версию размеров контейнера
      cSize = this.page.mag.getContainerSizeCached(),
      // рассчитываем реально видимое расстояние от центра виджета до верха и низа экрана (не забываем про превью режим девайсов)
      wRect =
        Scale.isOn(this.page.scale) && Scale.isZoom()
          ? Scale.getBox(this.$el[0], this.page.scale)
          : this.$el[0].getBoundingClientRect(),
      cRect = this.page.mag.isPreview ? this.page.mag.$mag_container[0].getBoundingClientRect() : { top: 0, left: 0 },
      topToContainer = (wRect.top + wRect.height / 2 - cRect.top) / this.page.scale,
      bottomToContainer = (cSize.height - (wRect.top + wRect.height / 2 - cRect.top)) / this.page.scale,
      leftToContainer = (wRect.left + wRect.width / 2 - cRect.left) / this.page.scale,
      rightToContainer = (cSize.width - (wRect.left + wRect.width / 2 - cRect.left)) / this.page.scale;

    if (this.fixed_position) {
      var pRect = this.page.$el[0].getBoundingClientRect();

      // верхняя и нижняя координата границы страницы
      var topToPage = (wRect.top + wRect.height / 2 - pRect.top) / this.page.scale,
        bottomToPage = (pRect.height - (wRect.top + wRect.height / 2 - pRect.top)) / this.page.scale;

      // теперь учтем то, что в вертикальном режиме мы можем видеть и соседние страницы
      // поэтому нам надо брать минимум из расстояния до края страницы и до края экрана
      res = {
        left: Math.max(0, leftToContainer),
        right: Math.max(0, rightToContainer),
        top: Math.max(0, Math.min(topToContainer, topToPage)),
        bottom: Math.max(0, Math.min(bottomToContainer, bottomToPage)),
      };
    } else {
      // находим отступы блока контента от границ страницы (а в данном случае соответственно и от контейнера страниц) по горизонтали
      var contentHorzPadding = this.page.contentPosition.left / this.page.scale;

      // находим отступы блока контента от границ страницы по вертикали
      var contentVertPadding = this.page.contentPosition.top / this.page.scale;

      // находим центр виджета
      var cx = this.x + this.w / 2,
        cy = this.y + this.h / 2;

      // верхняя и нижняя координата границы страницы
      var topToPage = cy + contentVertPadding,
        bottomToPage = this.page.contentHeight - cy + contentVertPadding;

      // теперь учтем то, что в вертикальном режиме мы можем видеть и соседние страницы
      // поэтому нам надо брать минимум из расстояния до края страницы и до края экрана
      res = {
        left: Math.max(0, cx + contentHorzPadding),
        right: Math.max(0, this.page.pageWidth - cx + contentHorzPadding),
        top: Math.max(0, Math.min(topToContainer, topToPage)),
        bottom: Math.max(0, Math.min(bottomToContainer, bottomToPage)),
      };
    }

    return res;
  },

  checkLink: function() {
    var data = {};

    if (!this.clickLink) return;

    this.clickLink = this.page.mag.matchSameDomainLink(this.clickLink);

    var isUrl =
      /^http(s?)\:\/\//i.test(this.clickLink) || /^mailto\:/i.test(this.clickLink) || /^tel\:/i.test(this.clickLink);

    var isGoBack = /^__rm_goback$/i.test(this.clickLink);
    var isMailchimp = this.mailchimpMatcher.test(this.clickLink);
    var isAnchor = this.anchorRegexp.test(this.clickLink);
    var isShare = this.shareRegexp.test(this.clickLink);

    var link = this.clickLink,
      target = this.clickTarget || '_blank',
      linkClass = isUrl ? 'external-link' : isGoBack ? 'goback-link' : 'maglink';

    if (isMailchimp) {
      linkClass = 'mailchimp-link';
    }
    if (isAnchor) {
      linkClass = 'anchor-link';
    }
    if (isShare) {
      linkClass = 'share-link';
    }

    // если линк ведет на страницу мэга
    if (!isUrl) {
      var pageID = link;

      // если указан урл Back to Top, тогда просто заменяем его на номер текущей страницы
      // обработчик кликов по странице сам поймет что если пытаться совершить переход на текущую же страницу, тогда надо проскролить
      if (/^back\sto\stop$/i.test(link)) pageID = this.page._id;

      // получаем страницу мэга по её айдишнику
      var pageNum = this.page.mag.getPageNum(pageID),
        link = pageNum && this.page.mag.getPageUri ? '/' + this.page.mag.getPageUri(pageID) : 'javascript:void(0)';

      // если страница существует
      if (pageNum) target = '';
      else link = '';

      if (pageID == this.page._id) {
        linkClass += ' back-to-top';
        if (this.is_global) {
          linkClass += ' current';
        }
      }
    }

    if (isGoBack) {
      link = 'javascript:void(0)';
    }

    if (isMailchimp) {
      link = 'javascript:void(0)';
      var mailchimp_data = this.clickLink.match(this.mailchimpMatcher)[1];
      data['data-mailchimp'] = mailchimp_data;
    }

    if (isAnchor) {
      link = 'javascript:void(0)';
      data['data-anchor-link-pos'] = this.anchor_link_pos;
      var page = _.findWhere(this.mag.pages, { _id: this.clickPage }) || this.page;
      data['data-page-uri'] = page.uri || page.num;
    }

    if (isShare) {
      link = 'javascript:void(0)';
      var shareData = this.clickLink.match(this.shareRegexp);
      data['data-share-provider'] = shareData[1];
      data['data-share-type'] = shareData[2];
    }

    if (link.indexOf('mailto') == 0 || link.indexOf('tel') == 0 || linkClass == 'maglink') target = '';

    if (!link) {
      return;
    }

    _.extend(data, {
      class: linkClass,
      href: link,
      target: target,
    });

    var $a = this.$el.parent('a');
    if (!$a.length) {
      $a = $('<a>').attr(data);
      this.$el.wrap($a);
    } else {
      $a.attr(data);
    }

    // Для скриншотера убираем все ссылки кроме внешних, чтобы они не появлялись в PDF-ках
    if (!isUrl && RM.screenshot) {
      $a = this.$el.parent('a');
      $a.removeAttr('href');
    }
  },

  /**
   * Обновление данных виджета
   * @param  {Object} data
   */
  updateWidgetData: function(data, page, $container) {
    _.extend(this, data, data.params); // что за data.params никто уже наверное не знает

    this.page = page;
    this.mag = this.page.mag;
    this.$pageContainer = $container || this.page.$content;

    this.x = this.x || 0;
    this.y = this.y || 0;
  },

  /**
   * Возвращает id триггера или триггеров, приведённый к массиву
   * @return {Array}
   */
  getAnimationTriggers: function() {
    return AnimationUtils.normalizeAnimationTriggers(this.animation && this.animation.trigger);
  },
});

export default WidgetClass;
